/* eslint-disable no-magic-numbers */
import { useState, useEffect } from 'react';
import { FormEvent } from 'react';

import { PodcastAircheckAPI } from 'api/podcastAircheckAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton, TextArea, DatePickerGroup, Checkbox, Input } from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { usePopup } from 'context/GlobalPopupContext';

import { podcastAircheckValidationSchema } from 'helpers/validation/podcastAircheckValidationSchema';

import { spotMessages } from 'constants/messages/spots';

import { IPodcastAircheckForm } from 'interfaces/Aircheck/IPodcastAircheckForm';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

interface LegacyAircheckFormProps {
  onModalClose: () => void;
  successAction: () => void;
  currentSpot: IPodcastSpotsTable;
}

const PodcastAircheckForm = ({ onModalClose, successAction, currentSpot }: LegacyAircheckFormProps) => {
  const [podcastAircheckFormInformation, setPodcastAircheckFormInformation] = useState<IPodcastAircheckForm>({
    startDate: currentSpot.start_date,
    endDate: currentSpot.end_date,
    score: '',
    placement: '',
    duration: '',
    position: '',
    isFlagged: false,
    comment: '',
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const { openPopup } = usePopup();

  useEffect(() => {
    if (currentSpot.aircheck_id) {
      PodcastAircheckAPI.getOne(currentSpot.id, currentSpot.aircheck_id).then(({ data }) => {
        setPodcastAircheckFormInformation({
          startDate: data.start_date,
          endDate: data.end_date,
          isFlagged: data.is_flagged,
          score: data.score || '',
          placement: data.placement || '',
          duration: data.duration || '',
          position: data.position || '',
          comment: data.comment || '',
        } as IPodcastAircheckForm);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = (values: any) => {
    setIsDisabled(true);
    const handleFormClose = () => {
      onModalClose();
      successAction();
    };
    const request = () => {
      if (currentSpot.aircheck_id) {
        PodcastAircheckAPI.updateAircheck(values, currentSpot.id, currentSpot.aircheck_id!).then(handleFormClose);
      } else {
        PodcastAircheckAPI.createAircheck(values, currentSpot.id).then(handleFormClose);
      }
    };
    if (currentSpot.order_id) {
      openPopup({
        ...spotMessages['M-152'],
        submitCallback: request,
        closeCallback: () => setIsDisabled(false),
      });
    } else {
      request();
    }
  };

  return (
    <>
      <Formik
        initialValues={podcastAircheckFormInformation}
        enableReinitialize={true}
        validationSchema={podcastAircheckValidationSchema}
        validateOnBlur
        onSubmit={handleFormSubmit}
      >
        {({ errors, dirty, handleSubmit, values, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>AIRCHECK INFO</FieldGroupTitleContainer>
                <DatePickerGroup
                  label="Air Dates"
                  startProps={{
                    name: 'startDate',
                    required: true,
                    onChange: () => {
                      if (values.endDate) {
                        setFieldTouched('endDate', true);
                      }
                    },
                  }}
                  endProps={{ name: 'endDate', minDate: values.startDate }}
                />
                <Input name="score" label="Score" styles={shortFieldStyles} />
                <Input name="placement" label="Placement,%" styles={shortFieldStyles} />
                <Input name="duration" label="Duration, sec" styles={shortFieldStyles} />
                <Input name="position" label="Position in Set" styles={shortFieldStyles} />
                <Checkbox name="isFlagged" label="Flag for Follow-up" />
                <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
                <TextArea name="comment" label="Comment" />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length || isDisabled}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    handleSubmit(values);
                  }}
                >
                  {currentSpot.aircheck_id ? 'Save' : 'Add'}
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PodcastAircheckForm;
