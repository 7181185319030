import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const FooterRow = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  background-color: ${colors.darkGrey};
  overflow-y: scroll;
`;
// overflow-y: scroll is needed so that the width of the footer is the same as that of the list

export const FooterCell = styled.div`
  color: ${colors.white};
  font-size: 14px;
  line-height: 21px;
  box-sizing: border-box;

  padding-right: 16px;
  padding-left: 16px;

  &:nth-of-type(2) {
    padding-left: 8px;
  }
  &:first-of-type {
    padding: 0 8px;
  }
`;
