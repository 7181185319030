import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const ActivityStatusContainer = styled.span<{ active?: Boolean; activeColor: string }>`
  color: ${(props) => (props.active ? props.activeColor : colors.disabledTextGrey)};

  & svg {
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  }

  display: flex;
  align-items: center;
  gap: 4px;

  white-space: nowrap;
`;
