import { useField } from 'formik';

import { FormControl, Label } from 'components/UIComponents';

import { ReactComponent as CheckboxChecked } from 'assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/CheckboxUnchecked.svg';

import { colors } from 'styles/globalStyles';

import { StyledCheckbox } from './styledComponents';

interface CheckboxProps {
  label: string;
  name: string;
  disabled?: boolean;
  view?: 'default' | 'checkboxFirst';
  onChange?: (v: boolean) => any;
  styles?: {
    wrapper?: object;
  };
}

const Checkbox = ({ onChange, label, name, disabled = false, view = 'default', styles = {} }: CheckboxProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl styles={styles.wrapper}>
      {view === 'default' && <Label>{label}</Label>}
      <StyledCheckbox
        {...field}
        checked={field.value}
        checkedIcon={<CheckboxChecked color={disabled ? colors.disabledRed : colors.red} />}
        icon={<CheckboxUnchecked />}
        onChange={(e) => {
          helpers.setValue(e.target.checked);
          onChange?.(e.target.checked);
        }}
        disabled={disabled}
        inputProps={{ 'aria-label': label }}
      />
      {view === 'checkboxFirst' && <Label style={{ marginLeft: '4px' }}>{label}</Label>}
      <Label error>{meta.touched && meta.error}</Label>
    </FormControl>
  );
};

export default Checkbox;
