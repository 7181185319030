import { AxiosError, AxiosInstance } from 'axios';
import { handleError } from 'services/errorsHandler';

export const setUpErrorsInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: AxiosError) {
      handleError(error);

      return Promise.reject(error);
    }
  );
};
