import styled from '@emotion/styled';
import { TableCell } from '@mui/material';
import { colors } from '../../../styles/globalStyles';

export default styled(TableCell)`
  width: 1%;
  white-space: nowrap;
  padding: 0 0 6px 0;

  text-align: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${colors.darkGrey};

  border-bottom: none;
`;
