import { Breadcrumbs } from '@mui/material';

import styled from '@emotion/styled';

import { colors } from '../../../styles/globalStyles';

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 19px;

  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  color: ${colors.grey};

  li > a {
    text-decoration: none;
    color: ${colors.grey};

    &:hover {
      text-decoration: underline;
    }
  }

  li > span {
    color: ${colors.grey};
  }
`;
