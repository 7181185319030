import { styled } from '@mui/material/styles';

export const LoadingBlock = styled('div')(() => ({
  display: 'inline-flex',
  verticalAlign: 'middle',
  padding: '8px',
}));

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
}));
