import { useContext } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import BuyerSelectForm from 'components/forms/spots/BuyerSelectForm';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import { isPodcastSpot } from 'helpers/spotsHelper';

import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

const ASSIGN_TO_BUYER_FORM_MODAL_NAME = 'ASSIGN_TO_BUYER_FORM_MODAL';

const AssignToBuyer = ({ type }: { type: string }) => {
  const { reloadList } = useContext(SpotActionsContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);

  const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;

  const onClose = () => {
    onModalClose(ASSIGN_TO_BUYER_FORM_MODAL_NAME);
  };

  const onSubmit = (buyerId: number) => {
    return SpotApi.assignToBuyer(buyerId, { selectAll, excludedIds, ids }, getSerializedData()).then(
      (response: IResponse) => {
        if (response.status === RESPONSE_CODES.OK) {
          onModalClose(ASSIGN_TO_BUYER_FORM_MODAL_NAME);
          reloadList();
        }
        return response;
      }
    );
  };

  const onAssignClick = () => {
    onModalOpen(ASSIGN_TO_BUYER_FORM_MODAL_NAME);
  };

  return (
    <>
      <LinkButton onClick={onAssignClick}>Assign to Buyer</LinkButton>
      <Modal name={ASSIGN_TO_BUYER_FORM_MODAL_NAME} title="Assign to Buyer" onModalClose={onModalClose}>
        <BuyerSelectForm onCancel={onClose} onSubmit={onSubmit} />
      </Modal>
    </>
  );
};

export default AssignToBuyer;
