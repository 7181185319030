import { useContext, useState } from 'react';

import { ResponseAPI } from 'api/responseAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import Popup from 'components/base/Popup';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { RadioGroupProvider } from 'context/RadioGroupContext';

import { spotsComparator } from 'helpers/spotsHelper';

import { PopupType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IAttributionParams } from 'interfaces/Response/IAttributionParams';
import { IAPIError, IResponse } from 'interfaces/api';

import ManualAttributionModal from './ManualAttributionModal';

const MANUAL_ATTRIBUTION_ERROR_POPUP_NAME = 'MANUAL_ATTRIBUTION_ERROR_POPUP';
const MANUAL_ATTRIBUTION_MODAL_NAME = 'MANUAL_ATTRIBUTION_MODAL';

const ManualAttribution = () => {
  const { selectAll, ids, excludedIds, onChangeSelectAll } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const [attributionParams, setAttributionParams] = useState<IAttributionParams>();

  const [error, setError] = useState<IAPIError>();

  const onAttributeManuallyClick = () => {
    ResponseAPI.checkForManualAttributing({ selectAll, ids, excludedIds }, getSerializedData()).then(
      (response: IResponse) => {
        if (response.status === RESPONSE_CODES.OK) {
          onModalOpen(MANUAL_ATTRIBUTION_MODAL_NAME);
          setAttributionParams(response.data.data);
        } else {
          setError(response.data.error);
          onModalOpen(MANUAL_ATTRIBUTION_ERROR_POPUP_NAME);
        }
      }
    );
  };

  return (
    <>
      <LinkButton onClick={onAttributeManuallyClick}>Attribute manually</LinkButton>
      {error && (
        <Popup
          name={MANUAL_ATTRIBUTION_ERROR_POPUP_NAME}
          type={PopupType.error}
          title={error.title}
          onModalClose={() => {
            onModalClose(MANUAL_ATTRIBUTION_ERROR_POPUP_NAME);
            setError(undefined);
          }}
        >
          {error.details}
        </Popup>
      )}
      <Modal
        name={MANUAL_ATTRIBUTION_MODAL_NAME}
        title="Manual Attribution"
        onModalClose={() => onModalClose(MANUAL_ATTRIBUTION_MODAL_NAME)}
        size="md"
      >
        {attributionParams && (
          <RadioGroupProvider comparator={spotsComparator}>
            <ManualAttributionModal
              attributionParams={attributionParams}
              onClose={(error?: IAPIError) => {
                onModalClose(MANUAL_ATTRIBUTION_MODAL_NAME);
                setAttributionParams(undefined);

                if (error) {
                  setError(error);
                  onModalOpen(MANUAL_ATTRIBUTION_ERROR_POPUP_NAME);
                }
              }}
              onSubmit={() => {
                onModalClose(MANUAL_ATTRIBUTION_MODAL_NAME);
                reloadList();
                onChangeSelectAll(false);
                setAttributionParams(undefined);
              }}
            />
          </RadioGroupProvider>
        )}
      </Modal>
    </>
  );
};

export default ManualAttribution;
