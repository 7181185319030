import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

export const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 13px 0 16px 0;
    margin: 0 16px;

    border-bottom: 1px solid; 
  };
`;
