import styled from '@emotion/styled';

export const StyledSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  aligned-items: center;
  margin-bottom: 14px;
`;

export const FilterContainer = styled.div`
  margin: 6px 0 14px;
`;
