import { UserRole } from 'constants/enums/UserRole';

import ErrorBlock from '..';

export const GROUP_ERROR = 'Please, contact the person who sent you the link.';
export const EMPLOYEE_ERROR = 'Please, contact the Admin.';

const ExpirationErrorBlock = ({ role = UserRole.employee }: { role?: string | null }) => {
  return (
    <ErrorBlock
      title="The link you followed has expired"
      details={role === UserRole.group ? GROUP_ERROR : EMPLOYEE_ERROR}
    />
  );
};

export default ExpirationErrorBlock;
