import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

// TODO: combine styles with SubmitButton
export const StyledOutlinedButton = styled(Button)(() => ({
  '&': {
    minWidth: '100px',
    border: '1px solid',
    color: colors.darkGrey,
    maxHeight: '32px',
    textTransform: 'none',
  },
  '&:hover': {
    backgroundColor: colors.darkGrey,
    border: '1px solid',
    color: colors.white,
    borderColor: colors.darkGrey,
  },
}));
