import { useState } from 'react';

import { OverflowTip, InlineInput, InlineDateInput } from 'components/UIComponents';

import { stringMaxLength } from 'constants/fieldsLimits';

import { IResponse } from 'interfaces/api';

import { InputContainer, DisplayContainer } from './styledComponents';

interface Props {
  initialValue: string | null;
  displayView?: (value: string) => React.ReactElement | string;
  update: (value: string) => Promise<IResponse>;
  type?: string;
}

const InlineEdit = ({ initialValue, displayView, update, type = 'string' }: Props) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue || '');

  const handleBlur = (newValue: string) => {
    update(newValue)
      .then((response: IResponse) => {
        setValue(newValue);
        setShowInput(false);
      })
      .catch((error: any) => {});
  };

  return (
    <>
      {showInput ? (
        <InputContainer>
          {type === 'date' ? (
            <InlineDateInput initialValue={value} onAccept={handleBlur} onClose={() => setShowInput(false)} />
          ) : (
            <InlineInput initialValue={value} onBlur={handleBlur} maxLength={stringMaxLength} />
          )}
        </InputContainer>
      ) : (
        <DisplayContainer onClick={() => setShowInput(true)}>
          {displayView ? displayView(value) : <OverflowTip title={value}>{value}</OverflowTip>}
        </DisplayContainer>
      )}
    </>
  );
};

export default InlineEdit;
