import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { UserAPI } from 'authApi/userAPI';

import ExpirationErrorBlock from 'containers/Auth/shared/ErrorBlock/ExpirationErrorBlock';

import { CustomIcon, SubmitButton } from 'components/UIComponents';
import NewPasswordForm from 'components/forms/users/NewPasswordForm';

import RESPONSE_CODES from 'constants/responseCodes';

import { IPasswords } from 'interfaces/User/IPasswords';

import { colors } from 'styles/globalStyles';

const ResetPasswordForm = ({ role, token }: { role: string | null; token: string }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = async (passwords: IPasswords) => {
    const response = await UserAPI.resetPassword(token, passwords);

    if (response?.status === RESPONSE_CODES.NO_CONTENT) {
      setIsSuccess(true);
    } else {
      setIsError(true);
    }
  };

  if (isSuccess) {
    return (
      <>
        <Typography variant="h2">Password Changed</Typography>
        <CustomIcon name="check" color={colors.green} size="large" />
        <SubmitButton onClick={() => navigate('/login')} sx={{ width: '100%', marginTop: '20px' }}>
          Log in
        </SubmitButton>
      </>
    );
  }

  if (isError) {
    return <ExpirationErrorBlock role={role} />;
  }

  return (
    <>
      <Typography variant="h2">Create New Password</Typography>
      <NewPasswordForm onSubmit={onSubmit} submitTitle="Reset password" />
    </>
  );
};

export default ResetPasswordForm;
