import { useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';
import { useField } from 'formik';

import { ReactComponent as Time } from 'assets/icons/time.svg';

import { Label } from 'components/UIComponents';
import { StyledTextField, StyledContainer } from './styledComponents';

interface TimePickerFieldProps {
  name?: string;
  onChange?: any;
  maxTime?: any;
  minTime?: any;
}

const TimePickerField = ({
  onChange,
  name = '',
  maxTime,
  minTime,
}: TimePickerFieldProps) => {
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField(name);

  const handleChange = async (value: any) => {
    const val = value?.format('hh:mm A') || '';
    helpers.setValue(val);
    await field.onBlur(val);
    onChange?.(val);
  };

  return (
    <StyledContainer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          {...field}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={!field.value ? null : dayjs(field.value, 'hh:mm A')}
          onChange={(value: any) => handleChange(value)}
          renderInput={(params: any) => {
            return (
              <>
                <StyledTextField
                  {...params}
                  name={name}
                  ref={params.inputRef}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    placeholder: 'hh:mm',
                  }}
                  onClick={() => setOpen(true)}
                  error={meta.touched && !!meta.error}
                />
              </>
            );
          }}
          components={{
            OpenPickerIcon: Time,
          }}
          InputAdornmentProps={{
            position: 'start',
          }}
          maxTime={maxTime}
          minTime={dayjs(minTime, 'hh:mm A')}
          onAccept={() => helpers.setTouched(true, false)}
          closeOnSelect
          minutesStep={30}
        />
      </LocalizationProvider>
      <Label error>{meta.touched && meta.error}</Label>
    </StyledContainer>
  );
};

export default TimePickerField;
