import { ActionsGroup } from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import { ResponseAPI } from 'api/responseAPI';
import DeleteAction from './DeleteAction';
import ManualAttribution from './ManualAttribution';
import BaseBulkAction from 'containers/Orders/shared/BaseBulkAction';

const BulkActions = () => {
  return (
    <>
      <ActionsGroup>
        <ManualAttribution />
      </ActionsGroup>
      <ActionsGroup>
        <BaseBulkAction buttonText="Re-Attribute" apiMethod={ResponseAPI.attributeExceptions} />
      </ActionsGroup>
      <DeleteAction />
    </>
  );
};

export default BulkActions;
