import iconsMap from './iconsMap';

type IconSize = 'small' | 'normal' | 'x-small' | 'large'; //Add more sizes

interface Props {
  name: string,
  size?: IconSize,
  color?: string,
}

const sizes = {
  'x-small': {
    width: 11,
    height: 11,
  },
  'small': {
    width: 14,
    height: 14,
  },
  'normal': {
    width: 16,
    height: 16,
  },
  'large': {
    width: 24,
    height: 24,
  },
};

// NOTE: If you want to use color argument, make sure that your svg file uses currentColor argument. (i.e. `Check` icon)

const CustomIcon = ({ name, color = 'inherit', size = 'small' }: Props) => {
  const Icon = iconsMap[name];
  return (
    <Icon data-testid={`${name}-img`} style={{ color: color }} width={sizes[size].width} height={sizes[size].height} />
  );
};

export default CustomIcon;
