import * as React from 'react';
import { MenuItem } from '@mui/material';

import { StyledNavLink as LinkItem } from './styledNavLink';
import { StyledDropdownNavLink as DropdownLinkItem } from './styledDropdownNavLink';

interface Props {
  text: string;
  linkTo: string;
  dropdownItem?: boolean;
}

const NavBarItem = ({ text, linkTo, dropdownItem }: Props) => {
  if (dropdownItem)
    return (
      <MenuItem component={DropdownLinkItem} to={linkTo}>
        {text}
      </MenuItem>
    );

  return <LinkItem to={linkTo}>{text}</LinkItem>;
};

export default NavBarItem;
