import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { UserAPI } from 'authApi/userAPI';

import ExpirationErrorBlock from 'containers/Auth/shared/ErrorBlock/ExpirationErrorBlock';

import NewPasswordForm from 'components/forms/users/NewPasswordForm';

import RESPONSE_CODES from 'constants/responseCodes';

import { IPasswords } from 'interfaces/User/IPasswords';

const Activation = ({ token }: { token: string }) => {
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const onActivate = async (passwords: IPasswords) => {
    const response = await UserAPI.activate(token, passwords);

    if (response?.status === RESPONSE_CODES.NO_CONTENT) {
      navigate('/login');
    } else {
      setIsError(true);
    }
  };

  if (isError) {
    return <ExpirationErrorBlock />;
  }

  return (
    <>
      <Typography variant="h2">Activate Account</Typography>
      <NewPasswordForm onSubmit={onActivate} submitTitle="Activate" />
    </>
  );
};

export default Activation;
