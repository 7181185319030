import { useContext, useState } from 'react';

import { UserAPI } from 'api/userAPI';

import { OutlinedButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import Popup from 'components/base/Popup';
import EmployeeForm from 'components/forms/users/EmployeeForm';

import { BaseListActionsContext } from 'context/BaseListActions';
import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IEmployeeForm } from 'interfaces/User/Employees/IEmployeeForm';
import { IAPIError, IResponse } from 'interfaces/api';

export const CREATE_EMPLOYEE_MODAL_NAME = 'CREATE_EMPLOYEE_MODAL';
export const CREATE_CONFIRMATION_POPUP_NAME = 'CREATE_CONFIRMATION_POPUP';
export const CREATE_ERROR_POPUP_NAME = 'CREATE_ERROR_POPUP';

const AddUserAction = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const [error, setError] = useState<IAPIError>();
  const [employeeData, setEmployeeData] = useState<IEmployeeForm>();

  const onAddNewClick = () => {
    onModalOpen(CREATE_EMPLOYEE_MODAL_NAME);
  };

  const onCreate = () => {
    onModalClose(CREATE_CONFIRMATION_POPUP_NAME);
    UserAPI.create(employeeData!, true).then(() => {
      setEmployeeData(undefined);
      onModalClose(CREATE_EMPLOYEE_MODAL_NAME);
      reloadList();
    });
  };

  const onFormSubmit = (values: IEmployeeForm) => {
    setEmployeeData(values);
    return UserAPI.create(values).then((response: IResponse) => {
      if (RESPONSE_CODES.CREATED === response.status) {
        onModalOpen(CREATE_CONFIRMATION_POPUP_NAME);
      } else {
        setError(response.data.error);
        onModalOpen(CREATE_ERROR_POPUP_NAME);
      }
    });
  };

  return (
    <>
      <OutlinedButton onClick={onAddNewClick}>Add User</OutlinedButton>
      <Modal name={CREATE_EMPLOYEE_MODAL_NAME} title="Add User" onModalClose={onModalClose}>
        <EmployeeForm onClose={() => onModalClose(CREATE_EMPLOYEE_MODAL_NAME)} onSubmit={onFormSubmit} />
      </Modal>
      {error && (
        <Popup
          name={CREATE_ERROR_POPUP_NAME}
          type={PopupType.error}
          title={error.title}
          onModalClose={() => {
            setError(undefined);
            onModalClose(CREATE_ERROR_POPUP_NAME);
          }}
        >
          {error.details}
        </Popup>
      )}
      <Popup
        name={CREATE_CONFIRMATION_POPUP_NAME}
        type={PopupType.warning}
        title="Confirm Action"
        onModalClose={() => onModalClose(CREATE_CONFIRMATION_POPUP_NAME)}
        handleSubmit={onCreate}
        submitText="Yes, proceed"
        cancelText="No"
      >
        You're about to add a new user. They will be sent an email with an invite link. Do you want to proceed?
      </Popup>
    </>
  );
};

export default AddUserAction;
