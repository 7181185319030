import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { DropdownButton } from './styledDropdownButton';
import { DropdownMenu } from './styledDropdownMenu';

import { colors } from '../../../../../styles/globalStyles';

interface Props {
  text: string;
  active: boolean;
  children?: React.ReactNode;
}

const NavBarDropdown = ({ text, active, children }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <DropdownButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={open? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
        sx={[
          active && {
            backgroundColor: colors.red,
            '&:hover': { backgroundColor: `${colors.red} !important` },
          },
        ]}
      >
        {text}
      </DropdownButton>
      <DropdownMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          disablePadding: true,
        }}
      >
        {children}
      </DropdownMenu>
    </div>
  );
};

export default NavBarDropdown;
