import { Accordion, AccordionSummary } from '@mui/material';

import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const AccordionSummaryContainer = styled.div`
  display: flex;
  flex-diraction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const styledAccordion = styled(Accordion)`
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -3px;
  margin-bottom: 16px;

  padding: 0 14px;

  &.Mui-expanded {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -3px;
  }

  box-shadow: none;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  &:before {
    height: 0;
  }
`;

export const styledAccordionSummary = styled(AccordionSummary)`
  &.Mui-expanded {
    min-height: unset;
    align-items: baseline;
  }

  .MuiAccordionSummary-content {
    margin: 12px 0;
    transition: none;

    &.Mui-expanded {
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }
`;

export const ToggleLabel = styled.div`
  padding-right: 9px;
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.darkGrey};
`;

export const FilterTitle = styled.span`
  font-family: ${fontFamily};
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  margin-right: 16px;
  margin-left: 3px;
`;
