import styled from '@emotion/styled';
import { colors, defaultButtonsGap } from 'styles/globalStyles';

const HeaderDiv = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 30px 0 15px;
  background-color: ${colors.darkBlue};
`;

const AuthDiv = styled.div`
  display: flex;
  gap: ${defaultButtonsGap};
  height: 19px;
`;

const Username = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;

  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  color: ${colors.white};

  border-right: 1px solid ${colors.white}
`;

const LogoImage = styled.img`
  width: 153px;
  height: 54px;
`;

export { HeaderDiv, AuthDiv, Username, LogoImage };
