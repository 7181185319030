import { styled } from '@mui/material/styles';

import { colors, fontFamily } from 'styles/globalStyles';

export const InputFieldContainer = styled('div')({
  width: '100%',
  display: 'flex',
});

export const Connector = styled('div')({
  margin: 'auto',
  marginTop: '10px',
  fontFamily: fontFamily,
  color: colors.grey,
  fontSize: '13px',
  lineHeight: '14px',
  minWidth: '32px',
  textAlign: 'center',
  letterSpacing: '0.06em',
});

export const DashConnector = styled('span')({
  fontWeight: '600',
  fontSize: '12px',
});
