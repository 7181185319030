import * as Yup from 'yup';
import { stringMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const contactValidationSchema = Yup.object({
  name: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  phoneNumber: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.EMAIL)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  isTraffic: Yup.bool()
    .optional(),
  isAccounting: Yup.bool()
    .optional(),
});
