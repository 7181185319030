import { Button } from '@mui/material';
import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const DropdownButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  color: ${colors.white};
  font-family: ${fontFamily}

  &:hover {
    background-color: ${colors.darkBlue};
  }

  .MuiButton-endIcon {
    margin-left: 0;
  }
`;
