import { useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';

import PopupContent from './PopupContent';
import { StyledDialog as Dialog } from './styledDialog';
import { StyledDialogTitle as DialogTitle } from './styledDialogTitle';

interface ModalProps {
  name: string;
  type: PopupType;
  children: React.ReactNode;
  title: string;
  onModalClose: () => void;
  handleSubmit?: () => void;
  cancelAction?: () => void;
  submitText?: string;
  cancelText?: string;
}

const Popup = ({
  name,
  type,
  children,
  title,
  submitText,
  cancelText,
  onModalClose,
  handleSubmit,
  cancelAction,
}: ModalProps) => {
  const { modalOpen } = useContext(ModalContext);

  return (
    <Dialog onClose={onModalClose} open={modalOpen[name] || false} type={type}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={onModalClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <PopupContent
        type={type}
        onModalClose={onModalClose}
        handleSubmit={handleSubmit}
        cancelAction={cancelAction}
        submitText={submitText}
        cancelText={cancelText}
      >
        {children}
      </PopupContent>
    </Dialog>
  );
};

export default Popup;
