import { useContext } from 'react';

import { ResponseAPI } from 'api/responseAPI';

import { CustomIcon } from 'components/UIComponents';
import Popup from 'components/base/Popup';
import { DeleteActionItem, StyledIconButton } from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { ToastContext } from 'context/ToastContext';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

import { colors } from 'styles/globalStyles';

const DELETE_RESPONSE_WARNING = 'bulkDeleteResponseWarning';

const DeleteAction = () => {
  const { selectAll, excludedIds, ids, onChangeSelectAll } = useContext(BulkSelectContext);
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const { getSerializedData } = useContext(FilterContext);

  const handleBulkRemove = (force: boolean = false) => {
    ResponseAPI.bulkRemove({ selectAll, excludedIds, ids }, getSerializedData(), force).then((response: IResponse) => {
      if (response.status === RESPONSE_CODES.OK) {
        onToastOpen('RESPONSE_SUCCESSFULLY_DELETED', response.data.data, ToastType.success);
        onModalClose(DELETE_RESPONSE_WARNING);
        reloadList();
        onChangeSelectAll(false);
      }

      if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
        onModalOpen(DELETE_RESPONSE_WARNING);
      }
    });
  };

  const onDeleteClick = () => {
    handleBulkRemove(false);
  };

  return (
    <DeleteActionItem>
      <StyledIconButton onClick={onDeleteClick}>
        <CustomIcon size="normal" name="trash" color={colors.trashIconColor} />
      </StyledIconButton>
      <Popup
        name={DELETE_RESPONSE_WARNING}
        type={PopupType.warning}
        title="Confirm Response Deletion"
        onModalClose={() => onModalClose(DELETE_RESPONSE_WARNING)}
        handleSubmit={() => handleBulkRemove(true)}
        submitText="Yes, delete"
        cancelText="No"
      >
        At least one Response is associated with a Spot record. Are you sure you want to delete the Response(s)?
      </Popup>
    </DeleteActionItem>
  );
};

export default DeleteAction;
