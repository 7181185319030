import styled from '@emotion/styled';
import { colors } from '../../../styles/globalStyles';

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 30px;
  background-color: ${colors.white};
`;

export { StyledPageContainer };
