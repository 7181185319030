import { forwardRef } from 'react';
import { IconButton } from '@mui/material';
import { CustomIcon } from 'components/UIComponents';

interface IconProps {
  email: string | null;
}

const Icon = forwardRef<HTMLButtonElement, IconProps>(
  ({ email, ...props }, ref) => {
    const handleOnClick = () => {
      const mailto = `mailto:${email || ''}`;
      window.location.href = mailto;
    };

    return (
      <IconButton onClick={handleOnClick} ref={ref} {...props}>
        <CustomIcon name="mail" size="normal" />
      </IconButton>
    );
  }
);

export default Icon;
