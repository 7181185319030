import { AxiosError, AxiosRequestConfig } from 'axios';

import { EVENTS_MAP, SCREEN_KEY, ERRORS_PATHS, NOT_FOUND_TYPE } from 'constants/errors';
import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

export interface ExtendedConfig extends AxiosRequestConfig {
  SCREEN?: string;
}

const isNotFoundError = (error: AxiosError) => {
  const errorResponse = error.response as IResponse;

  return errorResponse?.status === RESPONSE_CODES.NOT_FOUND || errorResponse.data?.error?.type === NOT_FOUND_TYPE;
};

export const handleError = (error: AxiosError) => {
  const errorCode = error.response?.status;
  if (!errorCode) {
    return;
  }

  if (isNotFoundError(error) && !(error.config as ExtendedConfig)[SCREEN_KEY]) {
    const event = new Event(EVENTS_MAP[RESPONSE_CODES.NOT_FOUND]);
    window.dispatchEvent(event);
  } else if (Object.keys(ERRORS_PATHS).includes(String(errorCode))) {
    window.location.href = ERRORS_PATHS[errorCode];
  }
};
