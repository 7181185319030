import RESPONSE_CODES from 'constants/responseCodes';

const downloadFileFromCloud = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
  });

  if (response.status !== RESPONSE_CODES.OK) {
    throw new Error('Unable to get the file');
  }

  return response;
};

export default downloadFileFromCloud;
