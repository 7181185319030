import { StyledFormControl } from './styledComponents';

interface FormControlProps {
  children: React.ReactNode;
  styles?: object;
  direction?: 'column' | 'row';
}

const FormControl = ({
  children,
  styles = {},
  direction = 'row',
}: FormControlProps) => {
  return (
    <StyledFormControl direction={direction} variant="standard" sx={styles}>
      {children}
    </StyledFormControl>
  );
};

export default FormControl;
