import { useState } from 'react';

import { InputFieldContainer, StyledInput } from './styledComponents';

interface InputProps {
  initialValue: string;
  onBlur: (value: string) => any;
  maxLength?: number;
}

const InlineInput = ({ initialValue, onBlur, maxLength }: InputProps) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleKeyClick = (event: any) => {
    if (event.key === 'Enter') {
      onBlur(value);
    }
  };

  return (
    <InputFieldContainer>
      <StyledInput
        autoFocus
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          onBlur(value);
        }}
        onKeyDown={handleKeyClick}
        inputProps={{
          maxLength,
        }}
      />
    </InputFieldContainer>
  );
};

export default InlineInput;
