import { IconButton, CircularProgress } from '@mui/material';

import styled from '@emotion/styled';
import { colors } from 'styles/globalStyles';

export const SearchIcon = styled.img`
  width: 13px;
  height: 13px;
  padding-left: 8px;
  color: ${colors.grey};
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: -8px;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 8px;
`;
