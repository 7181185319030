import { Outlet, Navigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logoBlack.svg';

import useAuth from 'hooks/useAuth';

import { Container, Content } from './styledComponents';

const PublicLayout = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Content>
        <Logo />
        <Outlet />
      </Content>
    </Container>
  );
};

export default PublicLayout;
