import { useCallback } from 'react';

import { ResponseAPI } from 'api/responseAPI';

import { Link, OverflowTip } from 'components/UIComponents';
import ListOfRecords from 'components/base/ListOfRecords';
import RadioCheckbox from 'components/base/Radio';
import AttributionFilterForm from 'components/forms/responses/AttributionFilterForm';

import { FilterContextState } from 'context/FilterContext';

import { moneyDisplayView } from 'helpers/money';
import { prepareFilterData } from 'helpers/responses';
import { datesRange } from 'helpers/spotsHelper';

import { defaultAttributionOptions } from 'constants/responses';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IAttributionParams } from 'interfaces/Response/IAttributionParams';
import { ISpotsForAttributionTable } from 'interfaces/spots/ISpotsForAttributionTable';

import { columns } from './columnsMap';

const ListOfSpotsForAttribution = ({ attributionParams }: { attributionParams: IAttributionParams }) => {
  const dataFormatter = (item: ISpotsForAttributionTable, index: number) => ({
    id: `${item.id}${item.type}`,
    checkbox: <RadioCheckbox value={{ id: item.id, type: item.type }} index={index} />,
    outlet: (
      <Link target="_blank" to={`/spots/${item.type?.toLowerCase()}?spotId=${item.id}`}>
        <OverflowTip title={item.outlet_name}>{item.outlet_name}</OverflowTip>
      </Link>
    ),
    type: item.type,
    date: datesRange(item, false),
    totalGross: moneyDisplayView(item.total_gross),
    spotsNumber: item.spots_number,
    product: <OverflowTip title={item.product_name}>{item.product_name}</OverflowTip>,
    market: <OverflowTip title={item.market}>{item.market}</OverflowTip>,
  });

  const dataProvider = useCallback(
    (props: IFilterParams) => ResponseAPI.getSpotsForAttribution(attributionParams, props),
    [attributionParams]
  );

  return (
    <FilterContextState ignoreUrlParams initialOptions={defaultAttributionOptions} dataSerializer={prepareFilterData}>
      <ListOfRecords
        tableProps={{
          name: 'spotsForAttribution',
          columns: columns as ITableColumn[],
          rowsAsLinks: false,
          withActions: false,
          tableStyle: { display: 'table', tableLayout: 'fixed' },
        }}
        barActions={<></>}
        dataFormatter={dataFormatter}
        dataProvider={dataProvider}
        filter={<AttributionFilterForm attributionParams={attributionParams} />}
        extraPageElementsHeight={314}
        skipSearch
        skipBulkSelect
      />
    </FilterContextState>
  );
};

export default ListOfSpotsForAttribution;
