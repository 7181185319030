import { useState } from 'react';

import { UserAPI } from 'api/userAPI';

import SwitchActivationAction from 'containers/users/shared/SwitchActivationAction';

import { ActiveStatusLabel } from 'components/UIComponents';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';

import { BaseListActionsContext } from 'context/BaseListActions';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IEmployeesTable } from 'interfaces/User/Employees/IEmployeesTable';

import AddEmployeeAction from './AddEmployeeAction';
import EditEmployeeAction from './EditEmployeeAction';

const columns = [
  { dataField: 'name', text: 'Name' },
  { dataField: 'phone', text: 'Phone Number' },
  { dataField: 'cell', text: 'Cell Number' },
  { dataField: 'email', text: 'Email' },
  { dataField: 'status', text: 'Status' },
];

const ListOfEmployees = () => {
  const [employeesProvider, setEmployeesProvider] = useState(() => (props: IFilterParams) => UserAPI.getList(props));
  const [editItemId, setEditItemId] = useState<number>();
  const [activationItem, setActivationItem] = useState<IEmployeesTable>();

  const reloadList = () => {
    setEmployeesProvider(() => (props: any) => UserAPI.getList(props));
  };

  const onEditClick = (id: number) => {
    setEditItemId(id);
  };

  const switchActivation = (item: IEmployeesTable) => {
    setActivationItem(item);
  };

  const dataFormatter = (item: IEmployeesTable) => ({
    id: item.id,
    name: item.name,
    phone: item.phone,
    cell: item.cell,
    email: item.email,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      {
        name: item.is_active ? 'Deactivate' : 'Activate',
        action: () => switchActivation(item),
      },
    ],
  });

  return (
    <PageContainer>
      <PageTitle>Employees</PageTitle>
      <BaseListActionsContext.Provider value={{ reloadList }}>
        <ListOfRecords
          tableProps={{
            name: 'users',
            columns: columns as ITableColumn[],
            editAction: onEditClick,
            rowsAsLinks: false,
          }}
          barActions={<AddEmployeeAction />}
          dataFormatter={dataFormatter}
          dataProvider={employeesProvider}
        />

        {editItemId && <EditEmployeeAction id={editItemId} onClose={() => setEditItemId(undefined)} />}
        {activationItem && (
          <SwitchActivationAction user={activationItem} onClose={() => setActivationItem(undefined)} />
        )}
      </BaseListActionsContext.Provider>
    </PageContainer>
  );
};

export default ListOfEmployees;
