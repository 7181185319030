import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #f6f6f6;
  padding-top: 100px;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: ${colors.white};
  width: 290px;
  height: fit-content;
  padding: 40px 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 5px;
`;
