import { IContact } from '../interfaces/Contact/IContact';
import { request, screenRequest } from './instance';

export const GroupContactAPI = {
  getAll: async (groupId: number) => {
    try {
      const response = await screenRequest({
        url: `v1/groups/${groupId}/contacts`,
        method: 'get',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getOne: async (groupId: number, contactId: number) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/contacts/${contactId}`,
        method: 'get',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  createGroupContact: async (groupId: number, { name, phoneNumber, email, isTraffic, isAccounting }: IContact) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/contacts`,
        method: 'post',
        data: formData({ name, phoneNumber, email, isTraffic, isAccounting }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateGroupContact: async (
    groupId: number,
    contactId: number,
    { name, phoneNumber, email, isTraffic, isAccounting }: IContact
  ) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/contacts/${contactId}`,
        method: 'put',
        data: formData({ name, phoneNumber, email, isTraffic, isAccounting }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  markContactAsDefault: async (groupId: number, contactId: number) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/contacts/${contactId}/mark_as_default`,
        method: 'patch',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchContactActivation: async (groupId: number, contactId: number, activate: boolean) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/contacts/${contactId}/switch_activation`,
        method: 'patch',
        data: {
          data: {
            contact: {
              is_active: activate,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  removeGroupContact: async (groupId: number, contactId: number) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/contacts/${contactId}`,
        method: 'delete',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};

const formData = ({ name, phoneNumber, email, isTraffic, isAccounting }: IContact) => ({
  data: {
    contact: {
      name,
      phone: phoneNumber,
      email,
      is_traffic: isTraffic === 'true',
      is_accounting: isAccounting === 'true',
    },
  },
});
