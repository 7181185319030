import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { UserAPI } from 'authApi/userAPI';

import RESPONSE_CODES from 'constants/responseCodes';

import ExpirationErrorBlock from '../shared/ErrorBlock/ExpirationErrorBlock';
import Activation from './Activation';

const AcceptInvitation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activationToken, setToken] = useState<string>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('invitation_token');

    if (token) {
      UserAPI.checkInvitationToken(token).then((response) => {
        if (response.status === RESPONSE_CODES.OK) {
          setToken(token);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <CircularProgress sx={{ marginTop: '20px' }} color="inherit" />;
  }

  return activationToken ? <Activation token={activationToken} /> : <ExpirationErrorBlock />;
};

export default AcceptInvitation;
