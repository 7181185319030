import { useContext } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { CustomIcon } from 'components/UIComponents';
import { StyledIconButton } from 'components/UIComponents/input/SearchField/styledComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { SpotActionsContext } from 'context/SpotActionsContext';
import { ToastContext } from 'context/ToastContext';

import useRequestWithConfirmation from 'hooks/useRequestWithConfirmation';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';

import { IGlobalPopupData } from 'interfaces/IGlobalPopupData';
import { IResponse } from 'interfaces/api';

import { colors } from 'styles/globalStyles';

const DeleteSpotsAction = ({ type }: { type: SpotType }) => {
  const { selectAll, excludedIds, ids, onChangeSelectAll } = useContext(BulkSelectContext);
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(SpotActionsContext);
  const { getSerializedData } = useContext(FilterContext);

  const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;

  const handleRequest = (force: boolean = false) => {
    return SpotApi.bulkDelete({ selectAll, excludedIds, ids }, getSerializedData(), force);
  };

  const handleSuccess = (response: IResponse) => {
    reloadList();
    onToastOpen(response.data.data, response.data.data, ToastType.success);
    onChangeSelectAll(false);
  };

  const popupDataCustomizer = (popupData: IGlobalPopupData): IGlobalPopupData => {
    if (popupData.title === 'Confirm Spot Deletion') {
      return {
        ...popupData,
        submitText: 'Yes, delete',
        cancelText: 'No',
      };
    }
    return popupData;
  };

  const { initiate } = useRequestWithConfirmation({
    apiCall: handleRequest,
    handleSuccess,
    popupDataCustomizer,
  });

  return (
    <StyledIconButton onClick={initiate}>
      <CustomIcon size="normal" name="trash" color={colors.trashIconColor} />
    </StyledIconButton>
  );
};

export default DeleteSpotsAction;
