import * as React from 'react';
import { useLocation } from 'react-router-dom';

import WithPermission from 'components/base/WithPermission';

import { UserRole } from 'constants/enums/UserRole';

import NavBarDropdown from '../NavBarComponents/NavBarDropdown';
import NavBarItem from '../NavBarComponents/NavBarItem';

const SMINavItems = () => {
  let location = useLocation();
  const currentLocation = location.pathname;

  return (
    <>
      <NavBarDropdown
        text="Spots"
        active={['spots/podcast', 'spots/legacy'].some((url) => currentLocation.includes(url))}
      >
        <NavBarItem text="Podcast" dropdownItem linkTo="/spots/podcast" />
        <NavBarItem text="Legacy" dropdownItem linkTo="/spots/legacy" />
      </NavBarDropdown>
      <NavBarItem text="Orders" linkTo="/orders" />
      <NavBarItem text="Customers" linkTo="/customers" />
      <NavBarDropdown text="Media" active={['/groups', '/outlets'].some((url) => currentLocation.startsWith(url))}>
        <NavBarItem text="Groups" dropdownItem linkTo="/groups" />
        <NavBarItem text="Outlets" dropdownItem linkTo="/outlets" />
      </NavBarDropdown>
      <NavBarItem text="Creatives" linkTo="/creatives" />
      <NavBarItem text="Responses" linkTo="/responses" />
      <NavBarItem text="Payments" linkTo="/payments" />
      <WithPermission permittedRoles={[UserRole.admin]}>
        <NavBarDropdown
          text="Users"
          active={['/users/employees', '/users/groups'].some((url) => currentLocation.startsWith(url))}
        >
          <NavBarItem text="Employees" dropdownItem linkTo="/users/employees" />
          <NavBarItem text="Groups" dropdownItem linkTo="/users/groups" />
        </NavBarDropdown>
      </WithPermission>
    </>
  );
};

export default SMINavItems;
