import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export default styled(Link)(({ theme }) => ({
  '&': {
    ...theme.typography.body1,
    fontWeight: 600,
    textDecorationColor: colors.darkGrey,
    color: colors.darkGrey,
  },
}));
