import { PopupType } from 'constants/enums/PopupType';

export const ORDER_CHANGES_WARNING_COMMON = {
  type: PopupType.warning,
  title: 'Order Changes Confirmation',
  submitText: 'Yes',
  cancelText: 'No, cancel',
};

export const spotMessages = {
  'M-146': {
    ...ORDER_CHANGES_WARNING_COMMON,
    content: `This Spot record is associated with an Order. Are you sure you want to cancel it? 
              The Spot record will be removed from the Order. 
              If you wish to inform the Group of Order changes, please, resend the Order.`,
  },
  'M-148': {
    ...ORDER_CHANGES_WARNING_COMMON,
    content: `This Spot record is associated with an Order. Are you sure you want to mark it as DNR? 
              This will not remove the Spot record from the Order. 
              If you wish to inform the Group of Order changes, please, resend the Order.`,
  },
  'M-149': {
    ...ORDER_CHANGES_WARNING_COMMON,
    content: `This Spot record is associated with an Order. Are you sure you want to mark it as Error? 
              This will not remove the Spot record from the Order. 
              If you wish to inform the Group of Order changes, please, resend the Order.`,
  },
  'M-152': {
    ...ORDER_CHANGES_WARNING_COMMON,
    content: `This Spot record is associated with an Order. 
              Are you sure you want to apply the changes you've just made? 
              If you wish to inform the Group of Order changes, please, resend the Order.`,
  },
  'M-157': {
    type: PopupType.error,
    title: 'Spot(s) Cannot be Updated',
    content: `The selected Spot(s) cannot be updated because at least one of them is associated with a Payment.
              Make sure to select the Spots that are not associated with Payments and try again.`,
  },
  'M-32': {
    type: PopupType.warning,
    title: 'Confirm Spot Cancellation',
    content: 'The Spot has probably been aired. Are you sure you want to cancel the Spot?',
    submitText: 'Yes, cancel',
    cancelText: 'No',
  },
  'M-173': {
    ...ORDER_CHANGES_WARNING_COMMON,
    content: `This Spot record is associated with an Order. Are you sure you want to cancel it?
              If you wish to inform the Group of Order changes, please, resend the Order.`,
  },
};
