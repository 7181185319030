import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const LoaderBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  color: colors.spinner,
});
