import { ReactNode } from 'react';

import { CustomIcon, Tooltip } from 'components/UIComponents';

import { colors } from 'styles/globalStyles';

import { ActivityStatusContainer } from './styledComponents';

interface Props {
  active?: Boolean;
  title: string;
  activeColor?: string;
  tooltipText?: string | ReactNode | null;
  icon?: string;
}

const ActivityStatus = ({ active = false, activeColor = colors.green, title, tooltipText, icon = 'check' }: Props) => {
  const Status = () => (
    <ActivityStatusContainer active={active} activeColor={activeColor}>
      <CustomIcon name={icon} color={activeColor} size="x-small" />
      {title}
    </ActivityStatusContainer>
  );

  return (
    <>
      {tooltipText && active ? (
        <Tooltip title={tooltipText} arrow>
          <span>
            <Status />
          </span>
        </Tooltip>
      ) : (
        <Status />
      )}
    </>
  );
};

export default ActivityStatus;
