import { useField } from 'formik';
import { FormControl, Label, Chip } from 'components/UIComponents';
import { stringMaxLength } from 'constants/fieldsLimits';
import {
  InputFieldContainer,
  StyledTagsInput,
  StyledTextField,
  errorLabelStyle,
} from './styledComponents';

interface SelectProps {
  label: string;
  name: string;
  onChange?: any;
  styles?: {
    wrapper?: object;
  };
  required?: boolean;
  placeholder?: string;
}

const TagsInput = ({
  onChange,
  label,
  name = '',
  styles = {},
  required,
  placeholder = '',
}: SelectProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl styles={styles.wrapper}>
      <Label required={required}>{label}</Label>
      <InputFieldContainer>
        <StyledTagsInput
          {...field}
          multiple
          options={[]}
          freeSolo
          onChange={(e, values) => {
            helpers.setValue(values);
            onChange?.(e, values);
          }}
          onBlur={(e) => {
            const newValues = field.value.map((v: string) => v.trim());
            helpers.setTouched(true);
            helpers.setValue(newValues);
          }}
          renderTags={(value: any, getTagProps) =>
            value.map((option: string, index: number) => {
              return <Chip option={option} {...getTagProps({ index })} />;
            })
          }
          aria-label={label}
          renderInput={(params) => {
            return (
              <StyledTextField
                {...params}
                name={name}
                placeholder={field.value ? '' : placeholder}
                inputProps={{
                  ...params.inputProps,
                  maxLength: stringMaxLength,
                }}
              />
            );
          }}
        />
        <Label error style={errorLabelStyle}>
          {meta.touched && meta.error}
        </Label>
      </InputFieldContainer>
    </FormControl>
  );
};

export default TagsInput;
