import { useContext } from 'react';

import Checkbox from '@mui/material/Checkbox';

import { BulkSelectContext } from 'context/BulkSelectContext';

import { ReactComponent as CheckboxChecked } from 'assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/CheckboxUnchecked.svg';

import { colors } from 'styles/globalStyles';

const SelectAllCheckbox = () => {
  const { selectAll, onChangeSelectAll } = useContext(BulkSelectContext);

  return (
    <Checkbox
      checked={selectAll}
      checkedIcon={<CheckboxChecked color={colors.red} />}
      icon={<CheckboxUnchecked />}
      onChange={(e) => {
        onChangeSelectAll(e.target.checked);
      }}
      sx={{ padding: 0 }}
    />
  );
};

export default SelectAllCheckbox;
