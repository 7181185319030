import { IAudioFile } from 'interfaces/Code/IAudioFile';
import { request } from '../instance';

export const IsciCodeAPI = {
  addFileInfo: async (creativeId: number, codeId: number, isciCodeId: number, fileData: IAudioFile) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/isci_codes/${isciCodeId}/add_audio_info`,
        method: 'PATCH',
        data: {
          data: {
            isci_code: fileData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  removeFile: async (creativeId: number, codeId: number, isciCodeId: number) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/isci_codes/${isciCodeId}/destroy_audio`,
        method: 'DELETE',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};
