import { FormControl } from '@mui/material';
import { FormControlProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CustomFormControlProps extends FormControlProps {
  direction: 'column' | 'row';
}

export const StyledFormControl = styled(FormControl)(({ direction }: CustomFormControlProps) => ({
  '&.MuiFormControl-root': {
    display: 'flex',
    flexDirection: direction,
    alignItems: 'baseline',
    margin: '10px 0',
  },
  '&.MuiFormControl-root > .MuiFormLabel-root': {
    marginBottom: direction === 'column' ? '4px' : 'none',
  },
}));
