import styled from '@emotion/styled';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
`;

export const LinkButtonText = styled.span`
  margin-left: 4px;
`;
