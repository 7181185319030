import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { ToastType } from 'constants/enums/PopupType';

import { colors } from '../../../styles/globalStyles';
import { StyledSnackbar } from './styledSnackbar';

const COLORS: Record<ToastType, any> = {
  warning: {
    backgroundColor: colors.warningToastBackground,
    textColor: colors.warningToastTextColor,
    borderColor: colors.warningToastBorder,
  },
  info: {
    backgroundColor: colors.infoToastBackground,
    textColor: colors.infoToastTextColor,
    borderColor: colors.infoToastBorder,
  },
  success: {
    backgroundColor: colors.successToastBackground,
    textColor: colors.successToastTextColor,
    borderColor: colors.successToastBorder,
  },
  error: {
    backgroundColor: colors.errorToastBackground,
    textColor: colors.errorToastTextColor,
    borderColor: colors.errorToastBorder,
  },
};

export interface ToastParams {
  type: ToastType;
  message: string;
}

interface Props {
  open: boolean;
  params: ToastParams;
  onClose: () => void;
}

const Toast = ({ open, params, onClose }: Props) => {
  const { type, message }: { type: ToastType; message: string } = params;
  const action = (
    <IconButton aria-label="close" color="inherit" onClick={onClose} sx={{ padding: 0 }}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      message={message}
      action={action}
      sx={{
        width: '100%',
        top: '60px !important',
        '.MuiSnackbarContent-root': {
          color: COLORS[type].textColor,
          backgroundColor: COLORS[type].backgroundColor,
          borderColor: COLORS[type].borderColor,
        },
      }}
    />
  );
};

export default Toast;
