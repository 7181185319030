import RESPONSE_CODES from './responseCodes';

export const EVENTS_MAP: { [key: string]: string } = {
  [RESPONSE_CODES.NOT_FOUND]: '404Error',
};

export const SCREEN_KEY = 'SCREEN';
export const ERRORS_PATHS: { [key: number]: string } = {
  [RESPONSE_CODES.UNAUTHENTICATED]: '/401',
  [RESPONSE_CODES.NOT_FOUND]: '/404',
  [RESPONSE_CODES.UNAUTHORIZED]: '/403',
  [RESPONSE_CODES.SYSTEM_ERROR]: '/500',
};

export const NOT_FOUND_TYPE = 'Not found.';
