import { Link, LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

interface CustomLinkProps extends LinkProps {
  mode: 'red' | 'dark' | 'light' | 'green' | 'orange';
  disabled: boolean;
}

const colorsMap = {
  red: colors.red,
  dark: colors.darkGrey,
  light: colors.white,
  green: colors.green,
  orange: colors.orange,
};

const hoverColorsMap = {
  red: colors.hoveredRed,
  dark: colors.darkGrey,
  light: colors.white,
  green: colors.green,
  orange: colors.orange,
};

export const StyledLink = styled(Link)(({ mode, disabled }: CustomLinkProps) => {
  const color = disabled ? colors.disabledRed : colorsMap[mode];

  return {
    '&': {
      fontSize: '14px',
      letterSpacing: '-0.32px',
      textDecorationColor: color,
      color: color,
      cursor: disabled ? 'default' : 'pointer',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: disabled ? 'none' : 'auto',
    },
    '&:hover': {
      color: hoverColorsMap[mode],
      textDecorationColor: hoverColorsMap[mode],
    },
  };
});
