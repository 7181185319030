import { useContext, useState } from 'react';

import { OrderAPI } from 'api/orderAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import OrderSendForm from 'components/forms/OrderSendForm';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { usePopup } from 'context/GlobalPopupContext';

import RESPONSE_CODES from 'constants/responseCodes';
import { PopupType } from 'constants/enums/PopupType';
import { apiErrorToPopupData } from 'helpers/utils';
import { IOrderGroups } from 'interfaces/Order/IOrderForm';
import { IAPIError, IResponse } from 'interfaces/api';


const SendAction = () => {
  const { selectAll, excludedIds, ids, totalCount } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const { openPopup } = usePopup();

  const [orderGroupsInfo, setOrderGroupsInfo] = useState<IOrderGroups | null>(null);

  const openConfirmPopup = () => {
    OrderAPI.groupSpotsByOrders({ selectAll, ids, excludedIds }, getSerializedData()).then(handleResponse);

    const selectedCount = selectAll ? totalCount - excludedIds.length : ids.length;
    const plularizedOrders = selectedCount === 1 ? 'Order' : 'Orders';
    openPopup({
      title: 'Confirm Order Revision',
      type: PopupType.warning,
      content: `You are about to revise ${selectedCount} ${plularizedOrders}.
        As the result, the ${plularizedOrders} will be sent to Groups and marked as Revised.
        That will also reset current ${plularizedOrders} statuses. Continue?`,
      cancelText: 'No, cancel',
      submitText: 'Yes, continue',
      submitCallback: () => onModalOpen('COMMENT_MODAL_FORM_SEND_ORDERS'),
    });
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup(response.data.error);
    } else {
      setOrderGroupsInfo(response.data.data as IOrderGroups);
    }
  };

  const onOrderModalClose = (error?: IAPIError) => {
    if (error) {
      openPopup(apiErrorToPopupData(error));
    }
    onModalClose('COMMENT_MODAL_FORM_SEND_ORDERS');
    setOrderGroupsInfo(null);
  };

  return (
    <>
      <LinkButton onClick={openConfirmPopup}>Send</LinkButton>
      {orderGroupsInfo && (
        <Modal
          name={'COMMENT_MODAL_FORM_SEND_ORDERS'}
          title="Order Revision"
          onModalClose={() => onOrderModalClose()}
        >
          <OrderSendForm data={orderGroupsInfo} onClose={onOrderModalClose} />
        </Modal>
      )}
    </>
  );
};

export default SendAction;
