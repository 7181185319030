import { useContext } from 'react';

import Popup from 'components/base/Popup';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';
import { paymentPopups } from 'constants/payments';

import { IBulkActionData } from 'interfaces/IBulkActionData';

interface Props {
  handleRemove: () => void;
  handleBulkRemove: ({ selectAll, excludedIds, ids }: IBulkActionData, filterParams: object) => void;
}

const PaymentActionsPopups = ({ handleBulkRemove, handleRemove }: Props) => {
  const { onModalClose } = useContext(ModalContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);

  return (
    <>
      <Popup
        name={paymentPopups.CONFIRM_BULK_PAYMENT_DELETING}
        type={PopupType.warning}
        title="Confirm Payment Deletion"
        onModalClose={() => onModalClose(paymentPopups.CONFIRM_BULK_PAYMENT_DELETING)}
        handleSubmit={() => handleBulkRemove({ selectAll, excludedIds, ids }, getSerializedData())}
        submitText="Yes, delete"
        cancelText="No"
      >
        Payment deletion will make associated Spots unpaid. Are you sure you want to delete the Payment(s)?
      </Popup>

      <Popup
        name={paymentPopups.CONFIRM_PAYMENT_DELETING}
        type={PopupType.warning}
        title="Confirm Payment Deletion"
        onModalClose={() => onModalClose(paymentPopups.CONFIRM_PAYMENT_DELETING)}
        handleSubmit={handleRemove}
        submitText="Yes, delete"
        cancelText="No"
      >
        Payment deletion will make associated Spots unpaid. Are you sure you want to delete the Payment(s)?
      </Popup>
    </>
  );
};

export default PaymentActionsPopups;
