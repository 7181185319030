import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import GlobalLayout from 'layouts/GlobalLayout';
import ProtectedLayout from 'layouts/ProtectedLayout';
import PublicLayout from 'layouts/PublicLayout';

import AcceptInvitation from 'containers/Auth/AcceptInvitation';
import Login from 'containers/Auth/Login';
import ResetPassword from 'containers/Auth/ResetPassword/ResetPassword';
import SendRecoveryEmail from 'containers/Auth/ResetPassword/SendRecoveryEmail';
import CreativeDetails from 'containers/Creatives/CreativeDetails';
import ListOfCreatives from 'containers/Creatives/ListOfCreatives';
import CustomerDetails from 'containers/Customers/CustomerDetails';
import ListOfCustomers from 'containers/Customers/ListOfCustomers';
import NotFound from 'containers/Errors/NotFound';
import SystemError from 'containers/Errors/SystemError';
import Unauthenticated from 'containers/Errors/Unauthenticated';
import Unauthorized from 'containers/Errors/Unauthorized';
import ListOfGroupOrders from 'containers/Orders/ListOfGroupOrders';
import GroupDetails from 'containers/Groups/GroupDetails';
import ListOfGroups from 'containers/Groups/ListOfGroups/ListOfGroups';
import IndexPage from 'containers/IndexPage';
import ListOfOrders from 'containers/Orders/ListOfOrders';
import ListOfOutlets from 'containers/Outlets/ListOfOutlets';
import OutletDetails from 'containers/Outlets/OutletDetails';
import ListOfPayments from 'containers/Payments/ListOfPayments';
import ListOfResponses from 'containers/Responses/ListOfResponses';
import ListOfLegacySpots from 'containers/spots/ListOfLegacySpots';
import ListOfPodcastSpots from 'containers/spots/ListOfPodcastSpots';
import ListOfSpotImportRecords from 'containers/spots/ListOfSpotImportRecords/index';
import ListOfEmployees from 'containers/users/Employees/ListOfEmployees';
import ListOfGroupsUsers from 'containers/users/Groups/ListOfGroupsUsers';

import AccessController from 'components/base/AccessController';
import Spreader from 'components/base/Spreader';

import { SpotType } from 'constants/enums/SpotTypes';
import { UserRole } from 'constants/enums/UserRole';
import { ERRORS_PATHS } from 'constants/errors';
import RESPONSE_CODES from 'constants/responseCodes';

export default createBrowserRouter(
  createRoutesFromElements(
    <Route element={<GlobalLayout />} errorElement={<SystemError />}>
      <Route index element={<IndexPage />} />

      <Route element={<ProtectedLayout />}>
        <Route element={<AccessController permittedRoles={[UserRole.admin, UserRole.employee]} />}>
          <Route path="/spots/podcast" element={<ListOfPodcastSpots />} />
          <Route path="/spots/legacy" element={<ListOfLegacySpots />} />
          <Route path="/groups" element={<ListOfGroups />} />
          <Route path="/groups/:groupId" element={<GroupDetails />} />
          <Route path="/outlets/:outletId" element={<OutletDetails />} />
          <Route path="/outlets" element={<ListOfOutlets />} />
          <Route path="/customers" element={<ListOfCustomers />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />} />
          <Route path="/creatives/:creativeId" element={<CreativeDetails />} />
          <Route path="/creatives" element={<ListOfCreatives />} />
          <Route
            path="/spots/legacy/spot-import-monitor"
            element={<ListOfSpotImportRecords type={SpotType.legacy} />}
          />
          <Route
            path="/spots/podcast/spot-import-monitor"
            element={<ListOfSpotImportRecords type={SpotType.podcast} />}
          />
          <Route path="/payments" element={<ListOfPayments />} />
          <Route path="/responses" element={<ListOfResponses />} />
        </Route>

        <Route element={<AccessController permittedRoles={[UserRole.admin]} />}>
          <Route path="/users/employees" element={<ListOfEmployees />} />
          <Route path="/users/groups" element={<ListOfGroupsUsers />} />
        </Route>

        <Route path="/orders" element={<Spreader smi={ListOfOrders} group={ListOfGroupOrders} />} />

        <Route path={ERRORS_PATHS[RESPONSE_CODES.UNAUTHORIZED]} element={<Unauthorized />} />
        <Route path={ERRORS_PATHS[RESPONSE_CODES.SYSTEM_ERROR]} element={<SystemError />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/accept_invitation" element={<AcceptInvitation />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/send_recovery_email" element={<SendRecoveryEmail />} />
      </Route>

      <Route path={ERRORS_PATHS[RESPONSE_CODES.UNAUTHENTICATED]} element={<Unauthenticated />} />
    </Route>
  )
);
