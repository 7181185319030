const UTCSettings = { timeZone: 'UTC' };

// always with user time zone
export const formatDate = (dateString: string) => {
  if (!dateString) return null;

  const date = new Date(dateString);

  const time = getFormattedTime(date);

  const formattedDate = `${getFormattedDate(date)} at ${time}`;

  return formattedDate;
};

export const dateWithDayOfWeek = (dateString: string) => {
  if (!dateString) return null;

  const date = new Date(dateString);
  const dayOfWeek = date.toLocaleDateString('en-US', {
    weekday: 'short',
    ...UTCSettings,
  });

  return `${dayOfWeek}, ${getFormattedDate(date, UTCSettings)}`;
};

// always with user timezone
export const getFormattedTime = (date: Date) => {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const getFormattedDate = (date: Date, options: object = {}) => {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    ...options,
  });
};

export const getDate = (dateString: string, options: object = {}) => {
  if (!dateString) return null;

  const date = new Date(dateString);

  return `${getFormattedDate(date, options)}`;
};

export const dateBEFormat = (dateString: string) => {
  const date = new Date(dateString);

  return date.toLocaleDateString('en-ZA');
};

export const getUTCDate = (dateString: string) => {
  return getDate(dateString, UTCSettings);
};
