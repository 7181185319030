export enum PopupType {
  warning = 'warning',
  info = 'info',
  error = 'error',
}

export enum ToastType {
  warning = 'warning',
  info = 'info',
  success = 'success',
  error = 'error',
}
