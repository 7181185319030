import { useState, useEffect, FormEvent } from 'react';
import { Form, Formik } from 'formik';
import { OutlinedButton, Input, SubmitButton, TextArea } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import {
  FieldGroupTitleContainer,
  shortFieldStyles,
} from 'components/UIComponents/layout/styledFormComponents';
import { ICustomerForm } from 'interfaces/Customer/ICustomerForm';
import RESPONSE_CODES from 'constants/responseCodes';
import { customerValidationSchema } from 'helpers/validation/customerValidationSchema';
import { CustomerAPI } from 'api/customerAPI';

interface CustomerFormProps {
  onModalClose: () => void,
  successAction: () => void,
  editCustomerId: number,
}

const CustomerForm = ({ onModalClose, successAction, editCustomerId }: CustomerFormProps) => {

  const [customerFormInformation, setCustomerFormInformation] = useState<ICustomerForm>({
    name: '',
    contactName: '',
    contactEmail: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    preferredContactMethod: '',
    customerFee: 0,
    comment: '',
  });

  useEffect(() => {
    if (editCustomerId) {
      CustomerAPI.getOne(editCustomerId).then(({ data }) => {
        setCustomerFormInformation({
          name: data.name,
          contactName: data.contact_name || '',
          contactEmail: data.contact_email || '',
          address: data.address || '',
          city: data.city || '',
          state: data.state || '',
          zip: data.zip || '',
          country: data.country || '',
          phone: data.phone || '',
          preferredContactMethod: data.preferred_contact_method,
          // eslint-disable-next-line no-magic-numbers
          customerFee: Number((data.customer_fee * 100).toFixed(1)),
          comment: data.comment || '',
        });
      });
    }
  }, [editCustomerId]);

  return (
    <>
      <Formik
        initialValues={customerFormInformation}
        enableReinitialize={true}
        validationSchema={customerValidationSchema}
        validateOnBlur
        onSubmit={async (values, { setFieldError }) => {
          let response;
          if (editCustomerId) {
            response = await CustomerAPI.updateCustomer(editCustomerId, values);
          } else {
            response = await CustomerAPI.createCustomer(values);
          }
          // TODO: make this error check more universal
          if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
            JSON.parse(response.data.error.details).name[0] === 'has already been taken') {
            setFieldError('name', 'This customer name already exists');
          } else {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>
                BILLING INFO
              </FieldGroupTitleContainer>
              <Input name="name" label="Customer Name" required={true} />
              <Input name="contactName" label="Contact" />
              <Input name="contactEmail" label="Email" />
              <Input name="address" label="Address" />
              <Input name="city" label="City" />
              <Input name="state" label="State" />
              <Input name="zip" label="ZIP Code" styles={shortFieldStyles} />
              <Input name="country" label="Country" />
              <Input name="phone" label="Phone Number" placeholder='(XXX) XXX-XXXX' type='phone' />
              <Input name="preferredContactMethod" label="Contact Method" required={true} />
              <FieldGroupTitleContainer>
                ACCOUNTING INFO
              </FieldGroupTitleContainer>
              <Input name="customerFee" label="Customer fee, %" required={true} />
              <FieldGroupTitleContainer>
                COMMENT
              </FieldGroupTitleContainer>
              <TextArea name="comment" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose} >
                Cancel
              </OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                {editCustomerId ? 'Save' : 'Add'}
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerForm;
