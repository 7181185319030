import Tooltip from './Tooltip';
import Icon from './Icon';
import { StyledTooltip } from './styledComponents';

interface EmailButtonProps {
  email: string | null;
  name: string | null;
}

const EmailButton = ({ email, name }: EmailButtonProps) => {
  return (
    <StyledTooltip
      title={<Tooltip email={email} name={name} />}
      placement="top"
      arrow
    >
      <Icon email={email} />
    </StyledTooltip>
  );
};

export default EmailButton;
