import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors, fontFamily } from 'styles/globalStyles';

export const StyledSubmitButton = styled(Button)(() => ({
  '&': {
    minWidth: '100px',
    textTransform: 'none',
    border: '1px solid',
    borderColor: colors.red,
    color: colors.white,
    backgroundColor: colors.red,
    boxShadow: 'none',
    maxHeight: '32px',
    fontFamily: fontFamily,
    fontSize: '14px',
    letterSpacing: '-0.32px',
    fontWeight: '400',
  },
  '&:hover': {
    backgroundColor: colors.hoveredRed,
    borderColor: colors.hoveredRed,
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    borderColor: colors.disabledRed,
    backgroundColor: colors.disabledRed,
    color: colors.white,
  },
}));
