import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { Container } from './styledComponents';

interface Props {
  title: string;
  children: React.ReactNode;
}

const OverflowTip = ({ children, title }: Props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setIsOverflow(compare);
    }
  }, [children]);

  return (
    <Tooltip
      title={title}
      disableHoverListener={!isOverflowed}
      placement="top-start"
    >
      <Container ref={textElementRef}>{children}</Container>
    </Tooltip>
  );
};

export default OverflowTip;
