import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export default styled.div<{ color: string; textColor?: string; borderColor?: string }>`
  height: 20px;
  width: 70px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  color: ${(props) => props.textColor || colors.white};
  outline: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};

  border-radius: 12px;
  background-color: ${(props) => props.color};
`;
