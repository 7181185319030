import { useCallback, useContext, useState } from 'react';

import { OrderAPI } from 'api/orderAPI';

import { CustomIcon, LinkButton, OverflowTip, StatusLabel } from 'components/UIComponents';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CheckboxCell from 'components/base/tableComponents/CheckboxCell';
import Modal from 'components/base/BaseModal';
import CommentModalForm from 'components/forms/CommentModalForm';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectState } from 'context/BulkSelectContext';
import { FilterContextState } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { useToast } from 'context/ToastContext';
import { usePopup } from 'context/GlobalPopupContext';

import { getDate } from 'helpers/dateHelper';
import { prepareFilterData } from 'helpers/orders';
import { apiErrorToPopupData } from 'helpers/utils';

import { ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IOrderTable } from 'interfaces/Order/IOrderTable';
import { DataResponse, IErrorResponseData, IResponse } from 'interfaces/api';

import { colors } from 'styles/globalStyles';

import { columns } from './columnsMap';
import SpotRecords from './SpotRecords';
import OrderStatus from './OrderStatus';
import { Actions, LinkButtonText } from './styledComponents';

const spotsRecordsModalName = 'spotsRecordsModal';

interface Props {
  apiCall: (props: IFilterParams) => Promise<DataResponse>,
  orderFilterForm: React.ReactNode,
  bulkActions: React.ReactNode,
  disableSpotLinks?: boolean
}

const BaseListOfOrders = ({
  apiCall,
  orderFilterForm,
  bulkActions,
  disableSpotLinks = false,
}: Props) => {
  const [ordersProvider, setOrdersProvider] = useState(() => (props: IFilterParams) => apiCall(props));
  const [applyingStatus, setApplyingStatus] = useState('');
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { onToastOpen } = useToast();
  const { openPopup } = usePopup();
  const [selectedItem, setSelectedItem] = useState<IOrderTable | null>(null);

  const reloadList = () => {
    setOrdersProvider(() => (props: IFilterParams) => apiCall(props));
  };

  const onSpotsNumberClick = useCallback((item: IOrderTable) => {
    setSelectedItem(item);
    onModalOpen(spotsRecordsModalName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      reloadList();
      onToastOpen('ORDER_ACTION_SUCCESS', response.data.data, ToastType.success);
      setSelectedItem(null);
    } else {

      openPopup({
        ...apiErrorToPopupData((response.data as IErrorResponseData).error),
      });
    }
  };

  const onAcceptOrderClick = (id: string) => {
    OrderAPI.changeStatus(id, 'Accepted').then(handleResponse);
  };

  const onChangeStatusWithCommentClick = (item: IOrderTable, status: string) => {
    setSelectedItem(item);
    setApplyingStatus(status);
    onModalOpen(`SINGLE_COMMENT_MODAL_FORM_FOR_${status}`);
  };

  const handleStatusChange = (comment: string | undefined) => {
    if (comment && selectedItem) {
      OrderAPI.changeStatus(String(selectedItem.id), applyingStatus, comment).then(handleResponse);
    }
  };

  const dataFormatter = (item: IOrderTable) => ({
    id: item.id,
    checkbox: <CheckboxCell id={item.id} />,
    week_start: getDate(item.week_start),
    product_name: <OverflowTip title={item.product_name}>{item.product_name}</OverflowTip>,
    outlet_name: <OverflowTip title={item.outlet_name}>{item.outlet_name}</OverflowTip>,
    total_gross: `$${item.total_gross}`,
    total_spots_number: (
      <LinkButton mode="dark" onClick={() => onSpotsNumberClick(item)}>
        {item.total_spots_number}
      </LinkButton>
    ),
    spots_type: item.spots_type,
    creator: item.created_by,
    last_sent: getDate(item.last_sent),
    daypart: <OverflowTip title={item.daypart}>{item.daypart}</OverflowTip>,
    revised: item.is_revised && (
      <StatusLabel borderColor={colors.darkGrey} color={colors.white} textColor={colors.darkGrey}>
        Revised
      </StatusLabel>
    ),
    actions: (
      <Actions>
        {item.status === 'Sent' &&
          <>
            <LinkButton mode='green' onClick={() => onAcceptOrderClick(String(item.id))}>
              <CustomIcon size="x-small" name="check" />
              <LinkButtonText>Accept</LinkButtonText>
            </LinkButton>
            <LinkButton mode='orange' onClick={() => onChangeStatusWithCommentClick(item, 'Suggested')}>
              <CustomIcon size="x-small" name="pause" />
              <LinkButtonText>Suggest</LinkButtonText>
            </LinkButton>
            <LinkButton mode='red' onClick={() => onChangeStatusWithCommentClick(item, 'Declined')}>
              <CustomIcon size="x-small" name="close" />
              <LinkButtonText>Decline</LinkButtonText>
            </LinkButton>
          </>
        }
        {['Accepted', 'Declined', 'Suggested'].includes(item.status) &&
          <OrderStatus title={item.status} comment={item.last_comment}/>
        }
      </Actions>
    ),
  });

  return (
    <>
      <BaseListActionsContext.Provider value={{ reloadList }}>
        <FilterContextState dataSerializer={(filters: object) => prepareFilterData(filters)}>
          <PageContainer>
            <PageTitle>Orders</PageTitle>
            <BulkSelectState actions={bulkActions}>
              <ListOfRecords
                tableProps={{
                  name: 'orders',
                  columns: columns as ITableColumn[],
                  rowsAsLinks: false,
                  withActions: false,
                  tableStyle: { display: 'table', tableLayout: 'fixed' },
                }}
                dataFormatter={dataFormatter}
                dataProvider={ordersProvider}
                filter={orderFilterForm}
                extraPageElementsHeight={127}
                skipSearch
              />
            </BulkSelectState>
          </PageContainer>
        </FilterContextState>
      </BaseListActionsContext.Provider>
      {selectedItem && (
        <>
          <Modal
            name={spotsRecordsModalName}
            title={`${selectedItem.week_start} - ${selectedItem.product_name} - ${selectedItem.outlet_name}`}
            onModalClose={() => onModalClose(spotsRecordsModalName)}
            size="md"
          >
            <SpotRecords
              onClose={() => onModalClose(spotsRecordsModalName)}
              order={selectedItem}
              disableSpotLinks={disableSpotLinks}
            />
          </Modal>
          <CommentModalForm
            onSubmit={handleStatusChange}
            modalName={`SINGLE_COMMENT_MODAL_FORM_FOR_${applyingStatus}`}
            isMandatory
          />
        </>
      )}
    </>
  );
};

export default BaseListOfOrders;
