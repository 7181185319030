import { CustomIcon, Tooltip } from 'components/UIComponents';
import { statusColorsMap, statusIconsMap } from 'constants/orders';
import { StatusContainer } from './styledComponents';

interface Props {
  title: string;
  comment: string | null;
};

const OrderStatus = ({ title, comment }: Props) => {
  const Status = () => (
    <StatusContainer activeColor={statusColorsMap[title]}>
      <CustomIcon name={statusIconsMap[title]} size='x-small'/>
      {title}
    </StatusContainer>
  );

  return(
    <>
    {
      ['Declined', 'Suggested'].includes(title) ?
        <Tooltip title={comment} arrow>
          <span>
            <Status />
          </span>
        </Tooltip>
      :
      <Status />
    }
    </>
  );
};

export default OrderStatus;
