import { Autocomplete, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ISelectOption } from 'interfaces/ISelectOption';

import { colors } from 'styles/globalStyles';

interface SelectProps {
  error?: number;
  value: ISelectOption[];
}

export const StyledAutocomplete = styled(Autocomplete)(
  ({ value, error }: SelectProps) => {
    const isEmptyValue = !value.length;

    return {
      '&': {
        width: '100%',
        display: 'inline-flex',
      },
      '& .MuiInputBase-input': {
        borderRadius: '4px',
        position: 'relative',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        '&::placeholder': {
          color: isEmptyValue ? '' : colors.darkGrey,
          opacity: isEmptyValue ? '' : 1,
        },
      },
      '& .MuiInputBase-root .MuiChip-root': {
        display: 'none',
      },
      '& .MuiInputBase-root .MuiAutocomplete-input': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
        {
          paddingRight: '24px',
        },
      '& .MuiInputBase-root .MuiAutocomplete-clearIndicator': {
        display: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-root ': {
        padding: 0,
        border: '1px solid',
        borderColor: error ? colors.red : colors.borderGrey,
        '&:focus': {
          borderColor: colors.focusBlue,
          borderRadius: '4px',
          backgroundColor: colors.white,
        },
        '& .MuiAutocomplete-endAdornment ': {
          right: 0,
        },
      },
    };
  }
);

export const StyledCheckbox = styled(Checkbox)(() => ({
  '&.MuiCheckbox-root': {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const InputFieldContainer = styled('div')({
  width: '100%',
});

export const StyledListItem = styled('li')({
  '&.MuiAutocomplete-option': {
    paddingLeft: '8px',
    '&[aria-selected=true]': {
      backgroundColor: colors.white,
      '&.Mui-focused': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
});
