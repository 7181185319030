import API from 'api';

import { usePopup } from 'context/GlobalPopupContext';
import { useToast } from 'context/ToastContext';

import { apiErrorToPopupData } from 'helpers/utils';

import { ModelName } from 'constants/enums/ModelName';
import { PopupType, ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

interface IItemBase {
  id: number;
  name: string;
}

const useDeleteFlow = (modelName: ModelName, successCallback: () => void, extra?: object) => {
  const { openPopup } = usePopup();
  const { onToastOpen } = useToast();

  const modelAPI = API[modelName];

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      onToastOpen(response.data.data, response.data.data, ToastType.success);
      successCallback();
    } else {
      openPopup(apiErrorToPopupData(response.data.error));
    }
  };

  const apiCall = (item: any) => {
    modelAPI.delete(item.id, extra).then(handleResponse);
  };

  const onDeleteClick = (item: IItemBase) => {
    openPopup({
      type: PopupType.warning,
      content: `You're about to permanently delete ${item.name}`,
      title: `Delete ${modelName}`,
      submitText: `Delete ${modelName}`,
      submitCallback: () => apiCall(item),
    });
  };

  return onDeleteClick;
};

export default useDeleteFlow;
