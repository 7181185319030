import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import {
  minValue,
  spotRateMaxValue,
  spotNumberMaxValue,
  spotNumberMaxLength,
} from 'constants/fieldsLimits';

export const podcastValidationSchema = Yup.object({
  outletId: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
  productId: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
  creativeId: Yup.number().optional(),
  codeId: Yup.number().optional(),
  startDate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  endDate: Yup.string()
    .optional()
    .nullable()
    .test(
      'not earlier than the start date',
      VALIDATION_MESSAGES.DATE,
      (value, context) => {
        if (value && context.parent.startDate) {
          return value >= context.parent.startDate;
        }
        return true;
      }
    ),
  impressions: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotNumberMaxValue,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(spotNumberMaxLength)
    ),
  grossRate: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotRateMaxValue,
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2')
    )
    .test(
      'is valid',
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2'),
      (value: number | undefined) =>
        /^[0-9]{0,7}[.]{0,1}[0-9]{0,2}$/.test(String(value))
    ),
  netRate: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotRateMaxValue,
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2')
    )
    .test(
      'is valid',
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2'),
      (value: number | undefined) =>
        /^[0-9]{0,7}[.]{0,1}[0-9]{0,2}$/.test(String(value))
    ),
  isTestShow: Yup.boolean().required(VALIDATION_MESSAGES.REQUIRED),
  isDigitallyInserted: Yup.boolean().required(VALIDATION_MESSAGES.REQUIRED),
  isPixelRequired: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  read: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  endorsement: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  position: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
});
