import InputBase from '@mui/material/InputBase';
import styled from '@emotion/styled';
import { colors } from 'styles/globalStyles';

export const StyledInputBase = styled(InputBase)`
  width: 272px;
  height: 30px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: ${colors.grey};

  & .MuiInputBase-input {
    width: 100%;
    color: ${colors.grey};
  },
  &.Mui-focused {
    border-color: ${colors.focusBlue};
  }
`;
