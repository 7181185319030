import { Outlet, Navigate } from 'react-router-dom';

import Header from 'components/base/Header';
import NotFoundPopup from 'components/base/NotFoundPopup';

import useAuth from 'hooks/useAuth';

import { ERRORS_PATHS } from 'constants/errors';
import RESPONSE_CODES from 'constants/responseCodes';

const ProtectedLayout = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={ERRORS_PATHS[RESPONSE_CODES.UNAUTHENTICATED]} />;
  }

  return (
    <>
      <Header />
      <Outlet />
      <NotFoundPopup />
    </>
  );
};

export default ProtectedLayout;
