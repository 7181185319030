import { Container, Box } from '@mui/material';
import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.grey};
`;

export const SearchIcon = styled.img`
  width: 30px;
  height: 30px;
  color: ${colors.grey};
  margin-bottom: 7px;
`;


export { StyledContainer, StyledBox };
