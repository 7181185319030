import { createContext, useContext, useState } from 'react';

import Toast, { ToastParams } from 'components/base/Toast';

import { ToastType } from 'constants/enums/PopupType';

interface IToastContext {
  onToastOpen: (id: string, message: string, type: ToastType) => void;
}

export const ToastContext = createContext<IToastContext>({
  onToastOpen: () => {},
});

export const ToastState = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Record<string, ToastParams>>({});

  const onToastOpen = (id: string, message: string, type: ToastType) => {
    setToasts((prevToasts) => ({ ...prevToasts, [id]: { message, type } }));
  };

  const onToastClose = (id: string) => {
    setToasts((prevToasts) => {
      const { [id]: toastToRemove, ...restToasts } = prevToasts;
      return restToasts;
    });
  };

  return (
    <ToastContext.Provider value={{ onToastOpen }}>
      <>
        {Object.keys(toasts).map((key) => (
          <Toast key={key} open params={toasts[key]} onClose={() => onToastClose(key)} />
        ))}
      </>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
