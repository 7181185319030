import { request } from './instance';
import { IPodcastAircheckForm } from 'interfaces/Aircheck/IPodcastAircheckForm';

export const PodcastAircheckAPI = {
  createAircheck: async (
    data: IPodcastAircheckForm,
    podcastSpotId: number,
  ) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${podcastSpotId}/podcast_airchecks`,
        method: 'POST',
        data: formData(data),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateAircheck: async (
    data: IPodcastAircheckForm,
    spotId: number,
    aircheckId: number,
  ) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${spotId}/podcast_airchecks/${aircheckId}`,
        method: 'PUT',
        data: formData(data),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getOne: async (spotId: number, aircheckId: number) => {
    try {
      const response = await request({
        url: `v1/podcast_spots/${spotId}/podcast_airchecks/${aircheckId}`,
        method: 'GET',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};


const formData = (
  {
    startDate,
    endDate,
    score,
    placement,
    duration,
    position,
    isFlagged,
    comment,
  }: IPodcastAircheckForm,
) => ({
  data: {
    podcast_aircheck: {
      start_date: startDate,
      end_date: endDate,
      score,
      placement,
      duration,
      position,
      is_flagged: isFlagged,
      comment,
    },
  },
});

export default PodcastAircheckAPI;
