import { IsciCodeAPI } from 'api/isciCodeAPI';
import { BYTES_IN_KB } from 'constants/general';
import { IAudioFile } from 'interfaces/Code/IAudioFile';

const saveCodeFileInfo = async (file: File, creativeId: number, codeId: number, isciCodeId: number) => {
  const fileData = await buildFileInfo(file);

  return IsciCodeAPI.addFileInfo(
    creativeId,
    codeId,
    isciCodeId,
    fileData,
  );
};

const buildFileInfo = (file: File): Promise<IAudioFile> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const data: IAudioFile = {
      audio_duration: 0,
      audio_name: file.name,
      audio_size: Math.round(file.size / BYTES_IN_KB),
    };
    reader.onerror = reject;
    reader.onload = (e) => {
      const audioContext = new window.AudioContext();
      audioContext.decodeAudioData(reader.result as ArrayBuffer,
        (buffer) => {
          data.audio_duration = buffer.duration;
          resolve(data);
        },
      );
    };

    reader.readAsArrayBuffer(file);
  });
};

export default saveCodeFileInfo;
