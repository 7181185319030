import { styled } from '@mui/material/styles';
import { BodyCell } from 'components/base/BaseTable';

export const TalkingPointsCell = styled(BodyCell)(() => ({
  '&': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ButtonsBlock = styled('div')(() => ({
  '&:last-of-type': { marginLeft: 'auto' },
}));
