import { ProductAPI } from 'api/productAPI';

import { ActiveStatusLabel, OverflowTip } from 'components/UIComponents';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';

import useDeleteFlow from 'hooks/useDeleteFlow';

import { ModelName } from 'constants/enums/ModelName';

import { ITableColumn } from 'interfaces/ITableColumn';
import { IProductsTable } from 'interfaces/Product/IProductsTable';

const columns = [
  { dataField: 'name', text: 'Product Name' },
  {
    dataField: 'description',
    text: 'Description',
    style: { maxWidth: '590px' },
  },
  { dataField: 'status', text: 'Status' },
];

interface Props {
  customerId: number;
  editAction: (id: number) => void;
  productsData: IProductsTable[];
  getCustomerProducts: (customerId: number) => void;
}

const ALL_EXCEPT_TABLE_HEIGHT = 575;
const tableContainerStyles = {
  maxHeight: window.innerHeight - ALL_EXCEPT_TABLE_HEIGHT,
};

const ListOfCustomerProducts = ({ customerId, editAction, productsData, getCustomerProducts }: Props) => {
  const onDeleteClick = useDeleteFlow(ModelName.Product, () => getCustomerProducts(customerId), { customerId });

  const switchProductActivation = (productId: number, active: boolean) => {
    ProductAPI.switchProductActivation(customerId, productId, active).then(() => {
      getCustomerProducts(customerId);
    });
  };

  const preparedData = productsData.map((item: IProductsTable) => ({
    id: item.id,
    name: item.name,
    description: <OverflowTip title={item.description}>{item.description}</OverflowTip>,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      {
        name: item.is_active ? 'Deactivate' : 'Activate',
        action: () => switchProductActivation(item.id, !item.is_active),
      },
      { name: 'Delete', action: () => onDeleteClick(item) },
    ],
  }));

  return (
    <>
      <TableWithLazyLoad
        name="products"
        columns={columns as ITableColumn[]}
        data={preparedData}
        editAction={editAction}
        rowsAsLinks={false}
        tableContainerStyles={tableContainerStyles}
      />
    </>
  );
};

export default ListOfCustomerProducts;
