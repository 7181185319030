import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
`;

export const ErrorBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
`;

export const ErrorTitle = styled.div`
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 128px;
  line-height: 153px;
  color: ${colors.greyBlue};
`;

export const ErrorSubTitle = styled.div`
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.15px;
  line-height: 36px;
  color: ${colors.greyBlue};
`;

export const ErrorDetails = styled.div`
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${colors.grey};
`;

export const ImageBox = styled.div`
  margin-right: 30px;
  margin-top: 8px;
`;
