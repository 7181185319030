import { useState, useContext } from 'react';

import { GroupAPI } from 'api/groupAPI';

import { OutlinedButton, ActiveStatusLabel } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import Popup from 'components/base/Popup';
import Toast, { ToastParams } from 'components/base/Toast';
import GroupForm from 'components/forms/GroupForm';

import { ModalContext } from 'context/ModalContext';

import { messageForGroupRemoveModal } from 'helpers/messageForRemoveModal/index';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IGroupsTable } from 'interfaces/Group/IGroupsTable';
import { IRemoveGroup } from 'interfaces/Group/IRemoveGroup';
import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';

const columns = [
  { dataField: 'name', text: 'Name' },
  { dataField: 'defaultContact', text: 'Default Contact' },
  { dataField: 'phoneNumber', text: 'Phone Number' },
  { dataField: 'email', text: 'Email' },
  { dataField: 'status', text: 'Status' },
];
const GROUP_FORM_MODAL_NAME = 'groupFormModal';
const REMOVE_GROUP_MODAL_NAME = 'removeGroup';
const FAILURE_REMOVE_GROUP_MODAL_NAME = 'failureRemoveGroup';

const ListOfGroups = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<ToastParams>();
  const [editGroupId, setEditGroupId] = useState(0);
  const [groupToRemove, setGroupToRemove] = useState<IRemoveGroup | null>(null);
  const [groupsProvider, setGroupsProvider] = useState(() => (props: IFilterParams) => GroupAPI.getList(props));

  const reloadList = () => {
    setGroupsProvider(() => (props: any) => GroupAPI.getList(props));
  };

  const onEditClick = (groupId: number) => {
    setEditGroupId(groupId);
    onModalOpen(GROUP_FORM_MODAL_NAME);
  };

  const onAddNewClick = () => {
    setEditGroupId(0);
    onModalOpen(GROUP_FORM_MODAL_NAME);
  };

  const onDeleteClick = (group: any) => {
    setGroupToRemove(group);
    onModalOpen(REMOVE_GROUP_MODAL_NAME);
  };

  const onSubmitGroupRemove = () => {
    GroupAPI.removeGroup(Number(groupToRemove?.id)).then((response) => {
      if (
        response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
        response.data.error.details.includes(`Group '${groupToRemove?.name}' has several media outlets`)
      )
        onModalOpen(FAILURE_REMOVE_GROUP_MODAL_NAME);
      else {
        setShowToast(true);
        setToastParams({ type: ToastType.success, message: response.data });
        reloadList();
      }
    });

    onModalClose(REMOVE_GROUP_MODAL_NAME);
  };

  const dataFormatter = (item: IGroupsTable) => ({
    id: item.id,
    name: item.company_name,
    defaultContact: item.contact_name,
    phoneNumber: item.contact_phone,
    email: item.contact_email,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [{ name: 'Delete', action: onDeleteClick }].filter(Boolean),
  });

  return (
    <PageContainer>
      <PageTitle>Groups</PageTitle>
      <ListOfRecords
        tableProps={{
          name: 'groups',
          columns: columns as ITableColumn[],
          editAction: onEditClick,
          rowsAsLinks: true,
        }}
        barActions={<OutlinedButton onClick={onAddNewClick}>Add Group</OutlinedButton>}
        dataFormatter={dataFormatter}
        dataProvider={groupsProvider}
      />
      {
        <Modal
          name={GROUP_FORM_MODAL_NAME}
          title={editGroupId ? 'Edit Group' : 'Add Group'}
          onModalClose={onModalClose}
        >
          <GroupForm
            onModalClose={() => onModalClose(GROUP_FORM_MODAL_NAME)}
            successAction={reloadList}
            editGroupId={editGroupId}
          />
        </Modal>
      }
      {
        <Popup
          name={REMOVE_GROUP_MODAL_NAME}
          type={PopupType.warning}
          title="Delete Group"
          onModalClose={() => onModalClose(REMOVE_GROUP_MODAL_NAME)}
          handleSubmit={onSubmitGroupRemove}
          submitText="Delete Group"
        >
          {messageForGroupRemoveModal('warning', groupToRemove?.name!)}
        </Popup>
      }
      {
        <Popup
          name={FAILURE_REMOVE_GROUP_MODAL_NAME}
          type={PopupType.info}
          title="Group Cannot be Deleted"
          onModalClose={() => onModalClose(FAILURE_REMOVE_GROUP_MODAL_NAME)}
        >
          {messageForGroupRemoveModal('info', groupToRemove?.name!)}
        </Popup>
      }
      {toastParams && <Toast open={showToast} params={toastParams} onClose={() => setShowToast(false)} />}
    </PageContainer>
  );
};

export default ListOfGroups;
