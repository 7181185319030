import { FormEvent, useState } from 'react';

import { Form, Formik } from 'formik';

import { OutlinedButton, SelectWithSearch, SubmitButton } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { getUserOptions } from 'helpers/collections';
import { getResponseHandler } from 'helpers/forms';

import { ModelName } from 'constants/enums/ModelName';

interface Props {
  onSubmit: (buyerId: number) => Promise<any>;
  onCancel: () => void;
}

const BuyerSelectForm = ({ onSubmit, onCancel }: Props) => {
  const [isDisabled, setDisabled] = useState<boolean>();

  return (
    <>
      <Formik
        initialValues={{ buyerId: null }}
        onSubmit={(values, actions) => {
          const responseHandler = getResponseHandler(ModelName.LegacySpot, () => {}, actions);
          setDisabled(true);
          onSubmit(values.buyerId!)
            .then(responseHandler)
            .finally(() => setDisabled(false));
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <Form>
            <DialogContent>
              <SelectWithSearch
                name="buyerId"
                label="Buyer"
                getOptionsList={(value: any) => getUserOptions(value, false)}
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onCancel}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!values.buyerId || isDisabled || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => {
                  setDisabled(true);
                  handleSubmit(values);
                }}
              >
                Assign
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BuyerSelectForm;
