import { buildAuthorizationHeader } from 'helpers/api';
import { keysToSnakeCase } from 'helpers/utils';

import { IPasswords } from 'interfaces/User/IPasswords';

import { request } from '../instance';

interface ILoginData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const UserAPI = {
  login: async (data: ILoginData) => {
    try {
      const response = await request({
        url: '/v1/users/sign_in',
        method: 'POST',
        data: { user: keysToSnakeCase(data) },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  checkInvitationToken: async (token: string) => {
    try {
      const response = await request({
        url: '/v1/users/invitation/check_for_validity',
        method: 'GET',
        params: { invitation_token: token },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  activate: async (token: string, passwords: IPasswords) => {
    try {
      const response = await request({
        url: '/v1/users/invitation',
        method: 'PATCH',
        data: {
          user: {
            invitation_token: token,
            ...passwords,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  checkResetPasswordToken: async (token: string) => {
    try {
      const response = await request({
        url: '/v1/users/password/check_for_validity',
        method: 'GET',
        params: { reset_password_token: token },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  resetPassword: async (token: string, passwords: IPasswords) => {
    try {
      const response = await request({
        url: '/v1/users/password',
        method: 'PATCH',
        data: {
          user: {
            reset_password_token: token,
            ...passwords,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  sendRecoveryEmail: async (email: string) => {
    const response = await request({
      url: '/v1/users/password',
      method: 'POST',
      data: {
        user: {
          email,
        },
      },
    });

    return response;
  },

  logout: async (token: string) => {
    const response = await request({
      url: '/v1/users/sign_out',
      method: 'DELETE',
      headers: { Authorization: buildAuthorizationHeader(token) },
    });

    return response.data;
  },
};
