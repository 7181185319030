import { ModelName } from './enums/ModelName';

const spotRelations = ['outlet', 'product', 'creative', 'code', 'buyer'];

export const RELATIONS: { [key in ModelName]: string[] | null } = {
  [ModelName.PodcastSpot]: spotRelations,
  [ModelName.LegacySpot]: spotRelations,
  [ModelName.Customer]: null,
  [ModelName.Creative]: ['product'],
  [ModelName.Outlet]: ['group'],
  [ModelName.Product]: null,
};
