import { SxProps } from '@mui/material';
import { StyledOutlinedButton } from './styledComponents';

interface OutlinedButtonProps {
  // TODO: remove void
  onClick: () => void,
  children: React.ReactNode,
  sx?: SxProps
}

const OutlinedButton = ({ onClick, children, sx }: OutlinedButtonProps) => {
  return (
    <StyledOutlinedButton variant="outlined" onClick={() => onClick()} sx={sx}>
      {children}
    </StyledOutlinedButton>
  );
};

export default OutlinedButton;
