import { useContext, useState } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import Popup from 'components/base/Popup';
import Toast, { ToastParams } from 'components/base/Toast';
import TrafficForm from 'components/forms/spots/TrafficForm';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';
import { trafficNoSpotsError } from 'constants/spots';

import { IGroupForTrafficSuccessfulResponseData, ITrafficGroups } from 'interfaces/Traffic/ITrafficForm';
import { IAPIError, IErrorResponseData, IResponse } from 'interfaces/api';

const SEND_TRAFFIC_ERROR_POPUP_NAME = 'sendTrafficErrorPopup';
const SEND_TRAFFIC_FORM_MODAL_NAME = 'sendTrafficFormModal';
const isWarningPoup = (type: string) => type === 'Warning';

const warningPopupProps = {
  type: PopupType.warning,
  cancelText: 'No',
  submitText: 'Yes, send',
};

const infoPopupProps = {
  type: PopupType.info,
};

const SendTrafficAction = ({ type }: { type: SpotType }) => {
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const { reloadList } = useContext(SpotActionsContext);
  const [error, setError] = useState<IAPIError | null>();
  const [trafficGroupsInfo, setTrafficGroupsInfo] = useState<ITrafficGroups>();
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<ToastParams>();
  const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;

  const onSendTrafficClick = () => {
    SpotApi.groupForTraffic({ selectAll, excludedIds, ids }, getSerializedData()).then(handleResponse);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      setError((response.data as IErrorResponseData).error);
      onModalOpen(SEND_TRAFFIC_ERROR_POPUP_NAME);
    } else {
      const data = response.data as IGroupForTrafficSuccessfulResponseData;
      if (Object.keys(data.data).length !== 0) {
        setTrafficGroupsInfo(data.data);

        if (data.meta) {
          setError(data.meta);
          onModalOpen(SEND_TRAFFIC_ERROR_POPUP_NAME);
        } else {
          onModalOpen(SEND_TRAFFIC_FORM_MODAL_NAME);
        }
      } else {
        setError(trafficNoSpotsError);
        onModalOpen(SEND_TRAFFIC_ERROR_POPUP_NAME);
      }
    }
  };

  const handleSendTrafficSuccess = (message: string) => {
    onCloseFormModal();
    setToastParams({ type: ToastType.success, message });
    setShowToast(true);
    reloadList();
    setTrafficGroupsInfo(undefined);
  };

  const onCloseFormModal = () => {
    onModalClose(SEND_TRAFFIC_FORM_MODAL_NAME);
    setTrafficGroupsInfo(undefined);
  };

  return (
    <>
      <LinkButton onClick={onSendTrafficClick}>Send Traffic</LinkButton>
      {error && (
        <Popup
          name={SEND_TRAFFIC_ERROR_POPUP_NAME}
          title={error.title}
          onModalClose={() => {
            onModalClose(SEND_TRAFFIC_ERROR_POPUP_NAME);
            setTrafficGroupsInfo(undefined);
          }}
          handleSubmit={() => {
            onModalClose(SEND_TRAFFIC_ERROR_POPUP_NAME);
            onModalOpen(SEND_TRAFFIC_FORM_MODAL_NAME);
          }}
          {...(isWarningPoup(error.type) ? warningPopupProps : infoPopupProps)}
        >
          {error.details}
        </Popup>
      )}
      {trafficGroupsInfo && (
        <Modal name={SEND_TRAFFIC_FORM_MODAL_NAME} title="Traffic Details" onModalClose={onModalClose}>
          <TrafficForm
            type={type}
            data={trafficGroupsInfo}
            onClose={onCloseFormModal}
            onSuccess={handleSendTrafficSuccess}
          />
        </Modal>
      )}
      {toastParams && <Toast open={showToast} params={toastParams} onClose={() => setShowToast(false)} />}
    </>
  );
};

export default SendTrafficAction;
