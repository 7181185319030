import { StyledStatusIndicator } from './styledComponents';

interface Props {
  active?: boolean;
}

const StatusIndicator = ({ active = false }: Props) => {
  return <StyledStatusIndicator active={active} />;
};

export default StatusIndicator;
