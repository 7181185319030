import { CellContainer, CellBody, CellHeader, CellContent } from './styledComponents';

interface Props {
  content?: string | Element | React.ReactNode;
  header?: string | React.ReactNode;
  cellProps?: Object;
}

const Cell = ({ header = '', content, cellProps = {} }: Props) => {
  return (
    <CellContainer {...cellProps}>
      <CellBody>
        <CellHeader>{header}</CellHeader>
        <CellContent>{content as React.ReactNode}</CellContent>
      </CellBody>
    </CellContainer>
  );
};

export default Cell;
