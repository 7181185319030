import { createTheme } from '@mui/material';

import { colors, fontFamily } from './globalStyles';

export const theme = {
  typography: {
    body1: {
      fontFamily: fontFamily,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '-0.32px',
      color: colors.darkGrey,
    },
    h2: {
      fontFamily: fontFamily,
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '33px',
      color: colors.darkGrey,
      marginTop: '14px',
      marginBottom: '10px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'span',
        },
      },
    },
  },
};

export default createTheme(theme);
