import { useContext } from 'react';

import { OverflowTip } from 'components/UIComponents';

import { SpotTypeContext } from 'context/SpotTypeContext';

import { moneyDisplayView } from 'helpers/money';
import { datesRange, timeRange, spotsPlayed, isPodcastSpot } from 'helpers/spotsHelper';

import { cellStyles } from 'constants/spots';

import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import RowCheckBox from '../../RowCheckBox';
import ActionsCell from '../ActionsCell';
import Cell from '../Cell';
import ActivitiesCells from './ActivitiesCells';
import CodeInfoCell from './CodeInfoCell';
import { RowContainer, MutedLabel, OutletLink, DatesBox } from './styledComponents';

interface Props {
  spot: IPodcastSpotsTable | ILegacySpotsTable;
  editAction: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
  highlighted?: boolean;
}

const Row = ({ spot, editAction, highlighted = false }: Props) => {
  const type = useContext(SpotTypeContext);
  const isPodcast = isPodcastSpot(type);

  return (
    <RowContainer highlighted={highlighted} data-testid="row">
      <RowCheckBox id={spot.id} />
      <Cell
        cellProps={{ style: cellStyles[type].outlet }}
        header={
          <OutletLink to={`/outlets/${spot.outlet_id}`}>
            <OverflowTip title={spot.outlet_name}>{spot.outlet_name}</OverflowTip>
          </OutletLink>
        }
        content={
          <>
            <DatesBox>{datesRange(spot)}</DatesBox>
            {isPodcast ? (
              spot.code ? (
                <OverflowTip title={spot.code}>{spot.code}</OverflowTip>
              ) : (
                ''
              )
            ) : (
              <span>{timeRange(spot as ILegacySpotsTable)}</span>
            )}
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles[type].market }}
        header={<OverflowTip title={spot.outlet_market}>{spot.outlet_market}</OverflowTip>}
        content={
          <>
            {isPodcast ? spot.impressions : spotsPlayed((spot as ILegacySpotsTable).aired_spots, spot.spots_number,
              false, spot.status)}
            <br />
            {spot.creative_duration ? `${spot.creative_duration} sec` : ''}
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles[type].format }}
        header={spot.format_name ? <OverflowTip title={spot.format_name}>{spot.format_name}</OverflowTip> : ''}
        content={
          <>
            <OverflowTip title={spot.product_name}>{spot.product_name}</OverflowTip>
            {spot.creative_name ? <OverflowTip title={spot.creative_name}>{spot.creative_name}</OverflowTip> : ''}
          </>
        }
      />
      {isPodcast ? (
        <ActivitiesCells spot={spot as IPodcastSpotsTable} />
      ) : (
        <CodeInfoCell spot={spot as ILegacySpotsTable} />
      )}
      <Cell cellProps={{ style: cellStyles[type].gross }} content={<MutedLabel>GROSS</MutedLabel>} />
      <Cell
        cellProps={{ style: cellStyles[type].grossAmount }}
        content={
          <>
            {moneyDisplayView(spot.gross_rate)}
            <br />
            {moneyDisplayView(spot.total_gross)}
          </>
        }
      />
      <Cell cellProps={{ style: cellStyles[type].net }} content={<MutedLabel>NET</MutedLabel>} />
      <Cell
        cellProps={{ style: cellStyles[type].netAmount }}
        content={
          <>
            {moneyDisplayView(spot.net_rate)}
            <br />
            {moneyDisplayView(spot.total_net)}
          </>
        }
      />
      <ActionsCell spot={spot} editAction={editAction} />
    </RowContainer>
  );
};

export default Row;
