import { createContext, useContext, useMemo, useState } from 'react';

import Popup from 'components/base/Popup';

import { ModalContext } from 'context/ModalContext';

import { GLOBAL_POPUP_NAME } from 'constants/general';

import { IGlobalPopupData } from 'interfaces/IGlobalPopupData';

interface IGlobalPopupContext {
  openPopup: (data: IGlobalPopupData) => void;
  closePopup: () => void;
  openSequentialPopups: (dataArray: IGlobalPopupData[], finalCallback?: () => void, index?: number) => void;
}

export const GlobalPopupContext = createContext<IGlobalPopupContext>({
  openPopup: (data: IGlobalPopupData) => {},
  closePopup: () => {},
  openSequentialPopups: (dataArray: IGlobalPopupData[], finalCallback?: () => void, index?: number) => {},
});

export const GlobalPopupProvider = ({ children }: { children: React.ReactNode }) => {
  const [popupData, setPopupData] = useState<IGlobalPopupData>();
  const { onModalOpen, onModalClose } = useContext(ModalContext);

  const openPopup = (data: IGlobalPopupData) => {
    setPopupData(data);
    onModalOpen(GLOBAL_POPUP_NAME);
  };

  const closePopup = () => {
    setPopupData(undefined);
    onModalClose(GLOBAL_POPUP_NAME);
  };

  const openSequentialPopups = (dataArray: IGlobalPopupData[], finalCallback?: () => void, index = 0) => {
    if (index >= dataArray.length) {
      finalCallback && finalCallback();
      return;
    }

    const currentData = dataArray[index];
    openPopup({
      ...currentData,
      closeCallback: () => {
        closePopup();
        currentData.closeCallback && currentData.closeCallback();
      },
      submitCallback: () => {
        closePopup();
        currentData.submitCallback && currentData.submitCallback();
        openSequentialPopups(dataArray, finalCallback, index + 1);
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ openPopup, closePopup, openSequentialPopups }), []);

  return (
    <>
      <GlobalPopupContext.Provider value={contextValue}>{children}</GlobalPopupContext.Provider>
      {popupData && (
        <Popup
          name={GLOBAL_POPUP_NAME}
          {...popupData}
          onModalClose={() => {
            closePopup();
            popupData.closeCallback && popupData.closeCallback();
          }}
          handleSubmit={() => {
            closePopup();
            popupData.submitCallback && popupData.submitCallback();
          }}
        >
          {popupData.content}
        </Popup>
      )}
    </>
  );
};

export const usePopup = () => {
  const value = useContext(GlobalPopupContext);
  return value;
};
