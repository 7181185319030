/* eslint-disable no-magic-numbers */
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton, TableBody, Typography } from '@mui/material';

import { CustomerAPI } from 'api/customerAPI';
import { ProductAPI } from 'api/productAPI';

import { OutlinedButton, CustomIcon, StatusIndicator } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
  InformationBlocks,
  PageSubtitle,
  OtherRecordInfo,
  TableTitle,
} from 'components/base/BaseTable';
import Breadcrumbs from 'components/base/Breadcrumbs/Breadcrumbs';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CustomerForm from 'components/forms/CustomerForm';
import ProductForm from 'components/forms/ProductForm';

import { ModalContext } from 'context/ModalContext';

import { ICustomerDetails } from 'interfaces/Customer/ICustomerDetails';
import { ICommonRecordInfo } from 'interfaces/ICommonRecordInfo';
import { IProductsTable } from 'interfaces/Product/IProductsTable';

import { colors } from 'styles/globalStyles';

import ListOfCustomerProducts from './ListOfCustomerProducts';

const PRODUCT_FORM_MODAL_NAME = 'productFormModal';
const CUSTOMER_FORM_MODAL_NAME = 'customerFormModal';

const CustomerDetails = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { customerId } = useParams();
  const [editProductId, setEditProductId] = useState(0);
  const [currentCustomer, setCustomer] = useState<ICustomerDetails | null>(null);
  const [productsData, setProductsData] = useState<IProductsTable[]>([]);

  const onEditClick = () => {
    onModalOpen(CUSTOMER_FORM_MODAL_NAME);
  };

  const getCustomerDetails = (id: number) => {
    CustomerAPI.getDetails(id).then(({ data }: { data: ICustomerDetails }) => {
      setCustomer(data);
    });
  };

  const getCustomerProducts = (id: number) => {
    ProductAPI.getAll(id).then(({ data }) => {
      setProductsData(data);
    });
  };

  const onEditProductClick = (productId: number) => {
    setEditProductId(productId);
    onModalOpen(PRODUCT_FORM_MODAL_NAME);
  };

  const onAddProductClick = () => {
    setEditProductId(0);
    onModalOpen(PRODUCT_FORM_MODAL_NAME);
  };

  useEffect(() => {
    getCustomerDetails(Number(customerId!));
    getCustomerProducts(Number(customerId!));
  }, [customerId]);

  if (!currentCustomer) return null;

  const switchCustomerActivation = (id: number, activate: boolean) => {
    CustomerAPI.switchCustomerActivation(id, activate).then(({ data }) => {
      if (data === 'Successfully changed.' && currentCustomer) {
        setCustomer({ ...currentCustomer, is_active: activate });
        setProductsData(productsData.map((product) => ({ ...product, is_active: activate })));
      }
    });
  };

  const ActivateButtonText = currentCustomer.is_active ? 'Deactivate' : 'Activate';

  return (
    <PageContainer>
      <Breadcrumbs>
        <Link to="/customers">Customers</Link>
        <Typography>{currentCustomer.name}</Typography>
      </Breadcrumbs>
      <PageTitle>
        {currentCustomer.name}
        <StatusIndicator active={currentCustomer.is_active} />
        <OutlinedButton
          sx={{ '&:last-of-type': { marginLeft: 'auto' } }}
          onClick={() => switchCustomerActivation(currentCustomer.id, !currentCustomer.is_active)}
        >
          {ActivateButtonText}
        </OutlinedButton>
      </PageTitle>
      <PageSubtitle>
        Information
        <IconButton onClick={onEditClick}>
          <CustomIcon name="edit" color={colors.red} />
        </IconButton>
      </PageSubtitle>
      <InformationBlocks>
        <FlexChild>
          <InfoBlockTitle>Billing Info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Customer name</HeadCell>
                <BodyCell>{currentCustomer.name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Contact</HeadCell>
                <BodyCell>{currentCustomer.contact_name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Email</HeadCell>
                <BodyCell>{currentCustomer.contact_email}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Address</HeadCell>
                <BodyCell>{currentCustomer.address}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>City</HeadCell>
                <BodyCell>{currentCustomer.city}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>State</HeadCell>
                <BodyCell>{currentCustomer.state}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>ZIP Code</HeadCell>
                <BodyCell>{currentCustomer.zip}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Country</HeadCell>
                <BodyCell>{currentCustomer.country}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Phone Number</HeadCell>
                <BodyCell>{currentCustomer.phone}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Contact Method</HeadCell>
                <BodyCell>{currentCustomer.preferred_contact_method}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <FlexChild>
          <InfoBlockTitle>Accounting Info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Customer fee, %</HeadCell>
                <BodyCell>{Number((currentCustomer.customer_fee * 100).toFixed(1))}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
          <InfoBlockTitle>Comment</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <BodyCell>{currentCustomer.comment}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <OtherRecordInfo info={currentCustomer as ICommonRecordInfo} />
      </InformationBlocks>
      <TableTitle>
        Products
        <OutlinedButton onClick={onAddProductClick}>Add Product</OutlinedButton>
      </TableTitle>
      <ListOfCustomerProducts
        customerId={Number(customerId!)}
        editAction={onEditProductClick}
        productsData={productsData}
        getCustomerProducts={getCustomerProducts}
      />
      {
        <Modal name={CUSTOMER_FORM_MODAL_NAME} title="Edit Customer" onModalClose={onModalClose}>
          <CustomerForm
            onModalClose={() => onModalClose(CUSTOMER_FORM_MODAL_NAME)}
            successAction={() => getCustomerDetails(Number(customerId!))}
            editCustomerId={Number(customerId!)}
          />
        </Modal>
      }
      {
        <Modal
          name={PRODUCT_FORM_MODAL_NAME}
          title={editProductId ? 'Edit Product' : 'Add Product'}
          onModalClose={onModalClose}
        >
          <ProductForm
            customerId={Number(customerId!)}
            onModalClose={() => onModalClose(PRODUCT_FORM_MODAL_NAME)}
            editProductId={editProductId}
            successAction={() => getCustomerProducts(Number(customerId!))}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default CustomerDetails;
