import InputBase from '@mui/material/InputBase';

import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const StyledInputBase = styled(InputBase)(() => ({
  '&': {
    width: '100%',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: colors.borderGrey,
  },
  '&.Mui-focused': {
    borderColor: colors.focusBlue,
  },
  '& .MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
    position: 'relative',
    padding: '6px 8px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    '::placeholder': {
      opacity: 0.42,
    },
  },

  '&.Mui-error': {
    borderColor: colors.red,
  },
}));
