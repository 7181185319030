/* eslint-disable no-magic-numbers */
import { IconButton } from '@mui/material';

import { CustomIcon } from 'components/UIComponents';

import useAuth from 'hooks/useAuth';

import { UserRole } from 'constants/enums/UserRole';

interface Props {
  createdDate: string;
  onClick: () => void;
  isDeleted: boolean;
}

const TrashCell = ({ createdDate, onClick, isDeleted }: Props) => {
  const { user } = useAuth();

  const hasPermissionToDelete = () => {
    if (user?.role === UserRole.admin) {
      return true;
    }

    const currentDate = new Date().getTime();
    const importRecortCreatedDate = new Date(createdDate).getTime();
    const comparator = 24 * 60 * 60 * 1000;
    const diffDate = currentDate - importRecortCreatedDate;
    return diffDate <= comparator;
  };

  return (
    <>
      {hasPermissionToDelete() && !isDeleted ? (
        <IconButton onClick={onClick}>
          <CustomIcon size="normal" name="trash" />
        </IconButton>
      ) : (
        ''
      )}
    </>
  );
};

export default TrashCell;
