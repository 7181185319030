import * as Yup from 'yup';
import { stringMaxLength, textMaxLength } from 'constants/fieldsLimits';

export const productValidationSchema = Yup.object({
  name: Yup.string()
    .required('Required field')
    .max(stringMaxLength, `Must be less than ${stringMaxLength} characters`),
  description: Yup.string()
    .optional()
    .max(textMaxLength, `Must be less than ${textMaxLength} characters`),
  pixel: Yup.boolean(),
  endorsement: Yup.string().matches(/PE Required|PE Preferred|N\/A/, 'Invalid value'),
});
