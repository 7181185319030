import { useContext, useState } from 'react';

import { IconButton } from '@mui/material';

import { CustomIcon } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import ResponseForm from 'components/forms/responses/ResponseForm';

import { ModalContext } from 'context/ModalContext';

const EDIT_RESPONSE_FORM_NAME = 'editResponseForm';

const EditAction = ({ responseId }: { responseId: number }) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);

  const [editInProgress, setEditInProgress] = useState<boolean>();

  const onEditClick = () => {
    setEditInProgress(true);
    onModalOpen(EDIT_RESPONSE_FORM_NAME);
  };

  const onClose = () => {
    setEditInProgress(false);
    onModalClose(EDIT_RESPONSE_FORM_NAME);
  };

  return (
    <>
      <IconButton onClick={onEditClick}>
        <CustomIcon name="information" size="normal" />
      </IconButton>

      {editInProgress && (
        <Modal name={EDIT_RESPONSE_FORM_NAME} title="Details" onModalClose={(_: string) => onClose()}>
          <ResponseForm onClose={onClose} id={responseId} />
        </Modal>
      )}
    </>
  );
};

export default EditAction;
