import { IconButton } from '@mui/material';

import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  overflow-x: auto;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 1px;
  top: 1px;
  color: colors.grey;
`;
