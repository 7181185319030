import { AsteriskContainer, StyledLabel } from './styledComponents';

interface LabelProps {
  required?: boolean;
  error?: boolean;
  children: React.ReactNode;
  style?: object;
  disabled?: boolean;
  shrink?: boolean;
}

const Label = ({ error, children, required = false, style = {}, disabled = false, shrink }: LabelProps) => {
  return (
    <StyledLabel error={error} style={style} disabled={disabled} shrink={shrink}>
      {children}
      {required && <AsteriskContainer> *</AsteriskContainer>}
    </StyledLabel>
  );
};

export default Label;
