import * as Yup from 'yup';
import { stringMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const validationCreateCode = Yup.object({
  keywords: Yup.array()
    .of(
      Yup.string()
        .max(
          stringMaxLength,
          VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
        )
        .required(VALIDATION_MESSAGES.REQUIRED)
    )
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const validationEditCode = Yup.object({
  code: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(
      stringMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
    ),
  isci_codes: Yup.array().of(
    Yup.string()
      .max(
        stringMaxLength,
        VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
      )
      .optional()
      .nullable()
      .test('required', VALIDATION_MESSAGES.REQUIRED, function(value: any) {
        if (this.path === 'isci_codes[0]') {
          return !!value;
        }
        return true;
      })
  ),
  keyword: Yup.string()
    .optional()
    .max(
      stringMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
    ),
});
