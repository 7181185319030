import { useLayoutEffect, useCallback } from 'react';

import { EVENTS_MAP } from 'constants/errors';
import RESPONSE_CODES from 'constants/responseCodes';

const useErrorEvent = (eventName: string, callback: () => void) => {
  const eventListener = useCallback(
    (event: Event) => {
      callback();
    },
    [callback]
  );

  useLayoutEffect(() => {
    window.addEventListener(eventName, eventListener);
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, eventListener]);
};

export default useErrorEvent;

export const useNotFoundEvent = (callback: () => void) => useErrorEvent(EVENTS_MAP[RESPONSE_CODES.NOT_FOUND], callback);
