import { IFilterParams } from 'interfaces/IFilterParams';

import { IGroupForm } from '../interfaces/Group/IGroupForm';
import { request, screenRequest } from './instance';

export const GroupAPI = {
  getList: async (params: IFilterParams) => {
    const response = await request({
      url: 'v1/groups',
      method: 'GET',
      params,
    });

    return response.data;
  },

  getByName: async (value: string) => {
    const response = await request({
      url: 'v1/groups/search',
      method: 'GET',
      params: { value },
    });

    return response.data.data;
  },

  getOne: async (groupId: number) => {
    const response = await request({
      url: `v1/groups/${groupId}`,
      method: 'GET',
    });

    return response.data;
  },

  getDetails: async (groupId: number) => {
    const response = await screenRequest({
      url: `v1/groups/${groupId}`,
      method: 'GET',
    });

    return response.data;
  },

  createGroup: async ({
    companyName,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    terms,
    comment,
    orderPreferences,
  }: IGroupForm) => {
    try {
      const response = await request({
        url: 'v1/groups',
        method: 'post',
        data: formData({
          companyName,
          address1,
          address2,
          city,
          state,
          zip,
          country,
          terms,
          comment,
          orderPreferences,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateGroup: async (
    id: number,
    { companyName, address1, address2, city, state, zip, country, terms, comment, orderPreferences }: IGroupForm
  ) => {
    try {
      const response = await request({
        url: `v1/groups/${id}`,
        method: 'put',
        data: formData({
          companyName,
          address1,
          address2,
          city,
          state,
          zip,
          country,
          terms,
          comment,
          orderPreferences,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchGroupActivation: async (groupId: number, activate: boolean) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}/switch_activation`,
        method: 'patch',
        data: {
          data: {
            group: {
              activate: activate,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  removeGroup: async (groupId: number) => {
    try {
      const response = await request({
        url: `v1/groups/${groupId}`,
        method: 'delete',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};

const formData = ({
  companyName,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  terms,
  comment,
  orderPreferences,
}: IGroupForm) => ({
  data: {
    group: {
      company_name: companyName,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      terms,
      comment,
      order_preferences: orderPreferences,
    },
  },
});
