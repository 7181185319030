import { FormEvent, useContext } from 'react';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import RESPONSE_CODES from 'constants/responseCodes';
import RateFields from 'components/forms/spots/commonComponents/RateFields';
import { reviseRatesValidationSchema } from 'helpers/validation/reviseRatesValidationSchema';
import { IResponse } from 'interfaces/api';
import { usePopup } from 'context/GlobalPopupContext';
import { spotMessages } from 'constants/messages/spots';

const REVISE_RATES_FORM_MODAL_NAME = 'REVISE_RATES_FORM_MODAL';

const reviseRatesFormInformation = {
  grossRate: '',
  netRate: '',
};

const ReviseRates = () => {
  const { reloadList } = useContext(SpotActionsContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { openPopup } = usePopup();
  const { onModalClose, onModalOpen } = useContext(ModalContext);

  const onReviseRatesClick = () => {
    LegacySpotAPI.checkForRevise(
    { selectAll, excludedIds, ids }, getSerializedData(), 'rates'
  ).then(handleResponse);
  };

  const onClose = () => {
    onModalClose(REVISE_RATES_FORM_MODAL_NAME);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      onModalOpen(REVISE_RATES_FORM_MODAL_NAME);
    } else {
      openPopup({...spotMessages['M-157']});
    }
  };

  return (
    <>
      <LinkButton onClick={onReviseRatesClick}>Revise Rates</LinkButton>
      <Modal name={REVISE_RATES_FORM_MODAL_NAME} title="Revise Rates" onModalClose={onModalClose}>
      <Formik
        initialValues={reviseRatesFormInformation}
        enableReinitialize
        validationSchema={reviseRatesValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          const response = await LegacySpotAPI.reviseRates(
            values,
            { selectAll, excludedIds, ids },
            getSerializedData()
          );

          if (response?.status === RESPONSE_CODES.OK) {
            onClose();
            reloadList();
          }
        }}
      >
        {({ errors, handleSubmit, dirty, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>PAYMENT INFO</FieldGroupTitleContainer>
              <RateFields
                grossRateValue={values.grossRate}
                netRateValue={values.netRate}
                grossRateError={errors.grossRate}
                netRateError={errors.netRate}
                setFieldValue={setFieldValue}
                isNetRateDisabled={true}
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
      </Modal>
    </>
  );
};

export default ReviseRates;

