import { useContext } from 'react';

import { CustomIcon } from 'components/UIComponents';
import { LinkButton } from 'components/UIComponents';
import {
  ActionsGroup,
  DeleteActionItem,
  StyledIconButton,
} from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { PaymentActionsContext } from 'context/PaymentActionsContext';

import { paymentStatuses } from 'constants/payments';

import { colors } from 'styles/globalStyles';

const PaymentActions = () => {
  const { onBulkDeleteClick, onBulkChangeStatusClick } = useContext(PaymentActionsContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);

  const onDeleteClick = () => {
    onBulkDeleteClick({ selectAll, excludedIds, ids }, getSerializedData());
  };

  const onStatusClick = (newStatus: string) => {
    onBulkChangeStatusClick({ selectAll, excludedIds, ids }, getSerializedData(), newStatus);
  };

  return (
    <>
      <ActionsGroup>
        <LinkButton onClick={() => onStatusClick(paymentStatuses.cleared)}>Mark as Cleared</LinkButton>
        <LinkButton onClick={() => onStatusClick(paymentStatuses.settled)}>Unmark as Cleared</LinkButton>
      </ActionsGroup>
      <DeleteActionItem>
        <StyledIconButton onClick={onDeleteClick}>
          <CustomIcon size="normal" name="trash" color={colors.trashIconColor} />
        </StyledIconButton>
      </DeleteActionItem>
    </>
  );
};

export default PaymentActions;
