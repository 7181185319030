import { useState } from 'react';

import { Typography } from '@mui/material';

import { UserAPI } from 'authApi/userAPI';

import { Details } from 'containers/Auth/shared/Details';

import { ReactComponent as CheckEmail } from 'assets/icons/email-action-read-document.svg';

import { colors } from 'styles/globalStyles';

import RecoveryEmailForm from './RecoveryEmailForm';
import { StyledIconContainer } from './styledComponents';

const SendRecoveryEmail = () => {
  const [isSend, setIsSend] = useState(false);

  const onSubmit = (values: { email: string }) => {
    UserAPI.sendRecoveryEmail(values.email);
    setIsSend(true);
  };

  if (isSend) {
    return (
      <>
        <Typography variant="h2">Check your email</Typography>
        <Details color={colors.darkGrey}>
          We sent you a link to reset password. If it doesn't appear within a few minutes, check your spam folder.
        </Details>
        <StyledIconContainer>
          <CheckEmail />
        </StyledIconContainer>
      </>
    );
  }

  return (
    <>
      <Typography variant="h2">Forgot password</Typography>
      <RecoveryEmailForm onSubmit={onSubmit} />
    </>
  );
};

export default SendRecoveryEmail;
