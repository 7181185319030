import React from 'react';

import { SxProps } from '@mui/material';

import { StyledLink } from './styledComponents';

interface LinkButtonProps {
  onClick: (data?: any) => void;
  children: React.ReactNode;
  mode?: 'dark' | 'red' | 'light' | 'green' | 'orange';
  disabled?: boolean;
  sx?: SxProps;
}

const LinkButton = ({ onClick, children, sx, mode = 'red', disabled = false }: LinkButtonProps) => {
  return (
    <StyledLink
      variant="inherit"
      underline="always"
      mode={mode}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      sx={sx}
    >
      {children}
    </StyledLink>
  );
};

export default LinkButton;
