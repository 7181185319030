import { useState, useContext, useEffect } from 'react';
import { FormEvent } from 'react';

import { LegacyAircheckAPI } from 'api/LegacyAircheckAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, Select, TextArea } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import Popup from 'components/base/Popup';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { usePopup } from 'context/GlobalPopupContext';
import { ModalContext } from 'context/ModalContext';

import { apiErrorToPopupData } from 'helpers/utils';
import { legacyAircheckValidationSchema } from 'helpers/validation/legacyAircheckValidationSchema';

import { PopupType } from 'constants/enums/PopupType';
import { spotNumberMaxValue } from 'constants/fieldsLimits';
import { ORDER_CHANGES_WARNING_COMMON, spotMessages } from 'constants/messages/spots';
import RESPONSE_CODES from 'constants/responseCodes';
import { spotStatuses } from 'constants/spots';

import { ILegacyAircheckForm } from 'interfaces/Aircheck/ILegacyAircheckForm';
import { IResponse } from 'interfaces/api';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';

interface LegacyAircheckFormProps {
  successAction: () => void;
  currentSpot: ILegacySpotsTable | undefined;
}

const statusOptions = [
  { key: spotStatuses.success, value: spotStatuses.success },
  { key: spotStatuses.dnr, value: spotStatuses.dnr },
  { key: spotStatuses.error, value: spotStatuses.error },
];

const CONFIRM_BULK_ACTION_MODAL = 'confirmBulkActionModal';
const LEGACY_AIRCHECK_FORM_MODAL_NAME = 'legacyAircheckFormModal';

const LegacyAircheckForm = ({ successAction, currentSpot }: LegacyAircheckFormProps) => {
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { getSerializedData } = useContext(FilterContext);
  const { openPopup } = usePopup();

  const [legacyAircheckFormInformation, setLegacyAircheckFormInformation] = useState<ILegacyAircheckForm>({
    status: spotStatuses.success,
    airedSpots: '',
    comment: '',
  });
  const legacyAircheckId = currentSpot?.aircheck_id;

  useEffect(() => {
    if (legacyAircheckId) {
      LegacyAircheckAPI.getOne(legacyAircheckId).then(({ data }) => {
        setLegacyAircheckFormInformation({
          status: currentSpot.status,
          airedSpots: currentSpot.status === spotStatuses.success ? '' : currentSpot.aired_spots,
          comment: data.comment,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (name: string, value: any) => void,
    setFieldTouched: (name: string, value: any) => void
  ) => {
    const airedSpots =
      [spotStatuses.dnr, spotStatuses.error].includes(e.target.value) && currentSpot ? currentSpot.spots_number : '';
    setFieldValue('airedSpots', airedSpots);
    setFieldTouched('airedSpots', true);
  };

  const getResponseHandler = (setFieldError: (name: string, value: any) => void) => {
    return (response: IResponse) => {
      if (
        response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
        response.data.error.title === 'Invalid number of aired spots.'
      ) {
        setFieldError('airedSpots', response.data.error.details);
      } else {
        onModalClose(LEGACY_AIRCHECK_FORM_MODAL_NAME);
        successAction();
      }
    };
  };

  const singleAircheck = (values: ILegacyAircheckForm, responseHandler: (response: IResponse) => void) => {
    const request = () => {
      if (legacyAircheckId) {
        LegacyAircheckAPI.updateAircheck(legacyAircheckId, values).then(responseHandler);
      } else {
        LegacyAircheckAPI.createAircheck(
          values,
          {
            selectAll: false,
            ids: [currentSpot!.id],
            excludedIds: [],
          },
          {},
          true
        ).then(responseHandler);
      }
    };

    if (currentSpot!.order_id) {
      openPopup({
        ...spotMessages['M-152'],
        submitCallback: request,
      });
    } else {
      request();
    }
  };

  const bulkAircheck = (values: ILegacyAircheckForm, responseHandler: (response: IResponse) => void) => {
    const request = (force: boolean) => {
      return LegacyAircheckAPI.createAircheck(
        values,
        {
          selectAll: selectAll,
          ids: ids,
          excludedIds: selectAll ? excludedIds : [],
        },
        selectAll ? getSerializedData() : {},
        force
      );
    };
    request(false).then((response: IResponse) => {
      if (
        response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
        response.data.error.title === ORDER_CHANGES_WARNING_COMMON.title
      ) {
        openPopup({
          ...apiErrorToPopupData(response.data.error),
          ...ORDER_CHANGES_WARNING_COMMON,
          submitCallback: () => request(true).then(responseHandler),
        });
      } else {
        responseHandler(response);
      }
    });
  };

  const handleFormSubmit = (values: ILegacyAircheckForm, setFieldError: (name: string, value: any) => void) => {
    onModalClose(CONFIRM_BULK_ACTION_MODAL);
    const responseHandler = getResponseHandler(setFieldError);
    if (currentSpot) {
      singleAircheck(values, responseHandler);
    } else {
      bulkAircheck(values, responseHandler);
    }
  };

  return (
    <>
      <Formik
        initialValues={legacyAircheckFormInformation}
        enableReinitialize={true}
        validationSchema={() =>
          legacyAircheckValidationSchema(currentSpot ? currentSpot.spots_number : spotNumberMaxValue)
        }
        validateOnBlur
        onSubmit={(values, { setFieldError }) => {
          if (currentSpot) {
            handleFormSubmit(values, setFieldError);
          } else {
            onModalOpen(CONFIRM_BULK_ACTION_MODAL);
          }
        }}
      >
        {({ errors, handleSubmit, values, setFieldValue, setFieldTouched, setFieldError }) => {
          return (
            <>
              <Form>
                <DialogContent>
                  <FieldGroupTitleContainer>AIRCHECK INFO</FieldGroupTitleContainer>
                  <Select
                    name="status"
                    label="Status"
                    options={statusOptions}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleStatusChange(e, setFieldValue, setFieldTouched);
                    }}
                    required
                    disabled={!!currentSpot?.payment_id}
                  />
                  <Input
                    name="airedSpots"
                    label="Aired Spots"
                    required
                    disabled={!!currentSpot?.payment_id || values.status === spotStatuses.success}
                  />
                  <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
                  <TextArea name="comment" />
                </DialogContent>
                <DialogActions>
                  <OutlinedButton onClick={() => onModalClose(LEGACY_AIRCHECK_FORM_MODAL_NAME)}>Cancel</OutlinedButton>
                  <SubmitButton
                    disabled={!!Object.values(errors).length}
                    onClick={(values: FormEvent<HTMLFormElement>) => {
                      handleSubmit(values);
                    }}
                  >
                    Add
                  </SubmitButton>
                </DialogActions>
              </Form>
              <Popup
                name={CONFIRM_BULK_ACTION_MODAL}
                type={PopupType.warning}
                title="Confirm Bulk Action"
                onModalClose={() => onModalClose(CONFIRM_BULK_ACTION_MODAL)}
                handleSubmit={() => handleFormSubmit(values, setFieldError)}
                submitText="Confirm"
              >
                You're about to perform bulk action on several Spots
              </Popup>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default LegacyAircheckForm;
