import { Snackbar } from '@mui/material';
import styled from '@emotion/styled';

export const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    width: 100%;
    padding: 0 30px;

    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    border-radius: 0;

    border-width: 1px;
    border-style: solid;
    box-shadow: none;
  }
  .MuiSnackbarContent-message {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  .MuiSnackbarContent-action {
    margin-left: 0;
  }
`;
