import styled from '@emotion/styled';
import { colors, fontFamily } from 'styles/globalStyles';

export const FieldGroupTitleContainer = styled('div')({
  '&:first-of-type': {
    marginTop: '0px',
  },
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  letterSpacing: '0.06em',
  color: colors.textGrey,
  marginBottom: '10px',
  marginTop: '20px',
});

export const CommentTitle = styled(FieldGroupTitleContainer)({
  marginTop: '16px',
});

export const shortFieldStyles = {
  wrapper: {
    width: '200px',
    '& .MuiFormLabel-root.Mui-error ': { overflow: 'initial' },
  },
};

export const ModalInfoBlock = styled.div`
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const ModalInfoBlockTitle = styled.div`
  margin-bottom: 20px;
  font-family: ${fontFamily};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.06em;
`;
