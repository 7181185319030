import { ISearializedPaymentsFormData } from 'interfaces/Payment/IPaymentsForm';
import { IFilterParams } from 'interfaces/IFilterParams';
import { IBulkActionData } from 'interfaces/IBulkActionData';

import { keysToSnakeCase } from 'helpers/utils';

import { request } from './instance';

export const PaymentAPI = {
  getList: async ({ page, per_page, filterOptions = {}, search }: IFilterParams) => {
    const response = await request({
      url: 'v1/payments',
      method: 'GET',
      params: {
        page,
        per_page,
        search,
        ...filterOptions,
      },
    });

    return response.data;
  },

  create: async (data: ISearializedPaymentsFormData, force?: boolean) => {
    try {
      const response = await request({
        url: 'v1/payments',
        method: 'POST',
        data: { data: data },
        params: { force_create: force },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateCheck: async (paymentId: number, check: string) => {
    try {
      const response = await request({
        url: `v1/payments/${paymentId}`,
        method: 'PUT',
        data: { data: { payment: { check } } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  removePayment: async (paymentId: number) => {
    const response = await request({
      url: `v1/payments/${paymentId}`,
      method: 'DELETE',
    });

    return response;
  },

  bulkRemovePayment: async ({ selectAll, excludedIds, ids }: IBulkActionData, filterParams = {}) => {
    const response = await request({
      url: 'v1/payments/bulk_destroy',
      method: 'DELETE',
      data: {
        data: {
          payment: {
            ...filterParams,
            ...keysToSnakeCase({ selectAll, excludedIds, ids }),
          },
        },
      },
    });

    return response;
  },

  changeStatus: async (paymentId: number, newStatus: string) => {
    try {
      const response = await request({
        url: `v1/payments/${paymentId}/change_status`,
        method: 'PATCH',
        data: { data: { payment: { new_status: newStatus } } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  bulkChangeStatus: async (newStatus: string, bulkData: IBulkActionData, filterParams: object) => {
    try {
      const response = await request({
        url: 'v1/payments/bulk_change_status',
        method: 'PATCH',
        data: {
          data: {
            payment: {
              new_status: newStatus,
              ...keysToSnakeCase(bulkData),
            },
          },
        },
        params: filterParams,
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },
};
