import { ChangeEvent, useContext, useRef } from 'react';

import Popup from 'components/base/Popup';

import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';
import { BYTES_IN_KB } from 'constants/general';
import VALIDATION_MESSAGES from 'constants/validationMessages';

interface Props {
  maxFileSize: number;
  onProcessFile: (file: File) => void;
  inputProps: object;
  ActionButton: React.FunctionComponent<any>;
}

const FileUploadControl = ({ maxFileSize, onProcessFile, inputProps, ActionButton }: Props) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const errorModelName = 'fileUploadError';
  const onUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    if (!files?.length) return;

    const file = files[0];

    if (file.size > maxFileSize * BYTES_IN_KB * BYTES_IN_KB) {
      onModalOpen(errorModelName);
      inputRef.current!.value = '';
    } else {
      onProcessFile(files[0]);
    }
  };

  return (
    <>
      <ActionButton onClick={onUploadClick}></ActionButton>
      <input
        type="file"
        onChange={onFileChange}
        onClick={(event): string => (event.currentTarget.value = '')}
        ref={inputRef}
        style={{ display: 'none' }}
        {...inputProps}
      />
      <Popup
        name={errorModelName}
        type={PopupType.error}
        title="Error"
        onModalClose={() => onModalClose(errorModelName)}
      >
        {VALIDATION_MESSAGES.MAX_FILE_SIZE(maxFileSize)}
      </Popup>
    </>
  );
};

export default FileUploadControl;
