import { FormEvent, useState, useEffect } from 'react';

import { ProductAPI } from 'api/productAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, TextArea, Checkbox, Select } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { productValidationSchema } from 'helpers/validation/productValidationSchema';

import RESPONSE_CODES from 'constants/responseCodes';

import { IProductForm } from 'interfaces/Product/IProductForm';

interface ContactFormProps {
  customerId: number;
  onModalClose: () => void;
  editProductId: number;
  successAction: () => void;
}

const endorsementOptions = [
  { key: 'PE Required', value: 'PE Required' },
  { key: 'PE Preferred', value: 'PE Preferred' },
  { key: 'N/A', value: 'N/A' },
];

const ProductForm = ({ customerId, onModalClose, editProductId, successAction }: ContactFormProps) => {
  const [productFormInformation, setProductFormInformation] = useState<IProductForm>({
    name: '',
    description: '',
    pixel: false,
    endorsement: 'PE Required',
  });

  useEffect(() => {
    if (editProductId) {
      ProductAPI.getOne(customerId, editProductId).then(({ data }) => {
        setProductFormInformation({
          name: data.name,
          description: data.description || '',
          pixel: data.pixel || false,
          endorsement: data.endorsement || 'PE Required',
        });
      });
    }
  }, [customerId, editProductId]);

  return (
    <>
      <Formik
        initialValues={productFormInformation}
        enableReinitialize={true}
        validationSchema={productValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          let response;
          if (editProductId) {
            response = await ProductAPI.updateProduct(customerId, editProductId, values);
          } else {
            response = await ProductAPI.createProduct(customerId, values);
          }
          if (response?.status === RESPONSE_CODES.OK) {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <Input name="name" label="Product Name" required />
              <Checkbox name="pixel" label="Pixel" />
              <Select name="endorsement" label="Endorsement" options={endorsementOptions} required />
              <TextArea name="description" label="Description" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                {editProductId ? 'Save' : 'Add'}
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductForm;
