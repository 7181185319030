import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { colors, fontFamily } from 'styles/globalStyles';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.tableHeadColor,
    borderRadius: 0,
    padding: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.tableHeadColor,
  },
  '& .MuiButtonBase-root': {
    padding: 0,
  },
}));

export const StyledBox = styled('div')(() => ({
  padding: '3px 8px',
  display: 'flex',
  alignItems: 'center',
  fontFamily,
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#000000',
}));

export const StyledContactInfo = styled('div')(() => ({
  marginLeft: '4px',
  overflowY: 'auto',
}));
