import { InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors, fontFamily } from 'styles/globalStyles';

export const StyledLabel = styled(InputLabel)(({ disabled }) => ({
  '&.MuiInputLabel-root': {
    fontFamily: fontFamily,
    transformOrigin: 'inherit',
    position: 'relative',
    transform: 'inherit',
    color: disabled ? colors.grey : colors.darkGrey,
    minWidth: '160px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
  },
  '&.Mui-error': {
    color: colors.red,
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'inherit',
  },
}));

export const AsteriskContainer = styled('div')({
  color: colors.red,
  display: 'inline',
  fontSize: '18px',
});
