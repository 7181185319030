import { useContext } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { LinkButton } from 'components/UIComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import useRequestWithConfirmation from 'hooks/useRequestWithConfirmation';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { SpotType } from 'constants/enums/SpotTypes';

import { IGlobalPopupData } from 'interfaces/IGlobalPopupData';
import { IResponse } from 'interfaces/api';

const BulkChangeStatus = ({ buttonText, status, type }: { buttonText: string; status: string; type: SpotType }) => {
  const { selectAll, excludedIds, ids, onChangeSelectAll } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { reloadList } = useContext(SpotActionsContext);

  const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;

  const handleRequest = (force: boolean = false) => {
    return SpotApi.bulkChangeStatus(
      status,
      {
        selectAll,
        excludedIds,
        ids,
      },
      getSerializedData(),
      force
    );
  };

  const handleSuccess = (_: IResponse) => {
    reloadList();
    onChangeSelectAll(false);
  };

  const popupDataCustomizer = (popupData: IGlobalPopupData): IGlobalPopupData => {
    if (popupData.title === 'Order Changes Confirmation') {
      return {
        ...popupData,
        submitText: 'Yes',
        cancelText: 'No, cancel',
      };
    }
    if (popupData.title === 'Confirm Spot Cancellation') {
      return {
        ...popupData,
        submitText: 'Yes, cancel',
        cancelText: 'No',
      };
    }
    return popupData;
  };

  const { initiate } = useRequestWithConfirmation({
    apiCall: handleRequest,
    handleSuccess,
    popupDataCustomizer,
  });

  return <LinkButton onClick={initiate}>{buttonText}</LinkButton>;
};

export default BulkChangeStatus;
