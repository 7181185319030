import { useState } from 'react';

import { Form, Formik } from 'formik';

import { SubmitButton } from 'components/UIComponents';
import { PasswordInput } from 'components/UIComponents';

import { newPasswordFormValidationSchema } from 'helpers/validation/newPasswordFormValidationSchema';

import { PASSWORD_REGEXP } from 'constants/general';

import { IPasswords } from 'interfaces/User/IPasswords';

import { colors } from 'styles/globalStyles';

import { RulesBlock } from './styledComponents';

const NewPasswordForm = ({
  onSubmit,
  submitTitle,
}: {
  onSubmit: ({ password, password_confirmation }: IPasswords) => void;
  submitTitle: string;
}) => {
  const [disabled, setDisabled] = useState(false);

  const checkPasswordValidity = ({ password }: IPasswords) => {
    return password === '' || password.match(PASSWORD_REGEXP);
  };

  return (
    <Formik
      initialValues={{ password: '', password_confirmation: '' }}
      validationSchema={newPasswordFormValidationSchema}
      validateOnBlur
      onSubmit={(values) => {
        setDisabled(true);
        onSubmit(values);
      }}
    >
      {({ values, handleSubmit, dirty, errors }) => (
        <Form>
          <PasswordInput name="password" label="Password" />
          <PasswordInput name="password_confirmation" label="Confirm Password" />
          <RulesBlock
            style={{
              color: !dirty || checkPasswordValidity(values) ? colors.black : colors.red,
            }}
          >
            At least 8 characters, max 64 characters, min 1 lower case letter, 1 uppercase letter, 1 special character,
            1 numeral
          </RulesBlock>
          <SubmitButton
            disabled={!checkPasswordValidity(values) || !dirty || !!Object.values(errors).length || disabled}
            onClick={handleSubmit}
            sx={{ width: '100%', marginTop: '20px' }}
          >
            {submitTitle}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
