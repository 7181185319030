import { FormEvent, useContext } from 'react';

import { Input, LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import RESPONSE_CODES from 'constants/responseCodes';
import { IResponse } from 'interfaces/api';
import { usePopup } from 'context/GlobalPopupContext';
import { shortFieldStyles } from 'components/forms/spots/commonComponents/RateFields/styledComponents';
import { reviseSpotNumberValidationSchema } from 'helpers/validation/reviseSpotNumberValidationSchema';

const REVISE_SPOT_NUMBER_FORM_MODAL_NAME = 'REVISE_SPOT_NUMBER_FORM_MODAL';

const reviseSpotNumberFormInformation = {
  spotsNumber: '',
};

const ReviseSpotNumber = () => {
  const { reloadList } = useContext(SpotActionsContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { openPopup } = usePopup();
  const { onModalClose, onModalOpen } = useContext(ModalContext);

  const onReviseSpotNumberClick = () => {
    LegacySpotAPI.checkForRevise(
      { selectAll, excludedIds, ids }, getSerializedData(), 'spotNumber'
    ).then(handleResponse);
  };

  const onClose = () => {
    onModalClose(REVISE_SPOT_NUMBER_FORM_MODAL_NAME);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      onModalOpen(REVISE_SPOT_NUMBER_FORM_MODAL_NAME);
    } else {
      openPopup({...response.data.error, content: response.data.error.details});
    }
  };

  return (
    <>
      <LinkButton onClick={onReviseSpotNumberClick}>Revise Spot Number</LinkButton>
      <Modal name={REVISE_SPOT_NUMBER_FORM_MODAL_NAME} title="Revise Spot Number" onModalClose={onModalClose}>
      <Formik
        initialValues={reviseSpotNumberFormInformation}
        enableReinitialize
        validationSchema={reviseSpotNumberValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          const response = await LegacySpotAPI.reviseSpotNumber(
            values,
            { selectAll, excludedIds, ids },
            getSerializedData()
          );

          if (response?.status === RESPONSE_CODES.OK) {
            onClose();
            reloadList();
          }
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>PAYMENT INFO</FieldGroupTitleContainer>
                <Input
                  name="spotsNumber"
                  label="Spots"
                  required
                  styles={shortFieldStyles}
                />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
      </Modal>
    </>
  );
};

export default ReviseSpotNumber;

