import * as Yup from 'yup';

import { stringMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const responseValidationSchema = Yup.object({
  callsIn: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  callsAnswered: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  texts: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  visits: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  views: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  midfunnel: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  orders: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  revenue1: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  revenue2: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  revenue3: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  metric1: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  metric2: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  metric3: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  impressions: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  reach: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER),
  text1: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  text2: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
});
