import { IProductForm } from 'interfaces/Product/IProductForm';

import { request, screenRequest } from '../instance';

export const ProductAPI = {
  getAll: async (customerId: number) => {
    try {
      const response = await screenRequest({
        url: `v1/customers/${customerId}/products`,
        method: 'GET',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getOne: async (customerId: number, productId: number) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}/products/${productId}`,
        method: 'get',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  delete: async (productId: number, { customerId }: { customerId: number }) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}/products/${productId}`,
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getByName: async (value: string, for_order?: boolean) => {
    const response = await request({
      url: 'v1/products/search',
      method: 'GET',
      params: {
        value,
        for_order,
      },
    });

    return response.data.data;
  },

  createProduct: async (customerId: number, productFormData: IProductForm) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}/products`,
        method: 'POST',
        data: { data: { product: productFormData } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateProduct: async (customerId: number, productId: number, productFormData: IProductForm) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}/products/${productId}`,
        method: 'put',
        data: { data: { product: productFormData } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchProductActivation: async (customerId: number, productId: number, active: boolean) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}/products/${productId}/switch_activation`,
        method: 'patch',
        data: {
          data: {
            product: {
              is_active: active,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};
