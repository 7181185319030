import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const StyledTagsInput = styled(Autocomplete)(() => ({
  '&': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    padding: '6px 8px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root ': {
    padding: '4px 0',
    border: '1px solid',
    minHeight: '60px',
    alignItems: 'start',
    borderColor: colors.borderGrey,
    '&:focus': {
      borderColor: colors.focusBlue,
      borderRadius: '4px',
      backgroundColor: colors.white,
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  '&': {
    maxWidth: '565px',
  },
}));

export const InputFieldContainer = styled('div')({
  width: '100%',
});

export const errorLabelStyle = { whiteSpace: 'normal' };
