import { Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

interface SelectProps {
  error?: number;
}

export const StyledSelect = styled(Autocomplete)(({ error }: SelectProps) => {
  return {
    '&': {
      width: '100%',
      display: 'inline-flex',
    },
    '& .MuiInputBase-input': {
      borderRadius: '4px',
      position: 'relative',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
    },
    '& .MuiInputBase-root .MuiAutocomplete-input': {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-root ': {
      padding: 0,
      border: '1px solid',
      borderColor: error ? colors.red : colors.borderGrey,
      '&:focus': {
        borderColor: colors.focusBlue,
        borderRadius: '4px',
        backgroundColor: colors.white,
      },
      '& .MuiAutocomplete-endAdornment ': {
        right: 0,
      },
    },
  };
});

export const InputFieldContainer = styled('div')({
  width: '100%',
});
