import { createContext, useEffect, useState } from 'react';

import BulkActionsPanel from 'components/base/tableComponents/BulkActionsPanel';

export interface IBulkSelectContext {
  ids: Array<number>;
  excludedIds: Array<number>;
  selectAll: boolean;
  isSelected: (id: number) => boolean;
  onChangeSelectAll: (id: boolean) => void;
  onChangeSingle: (id: number, value: boolean) => void;
  setTotalCount: (count: number) => void;
  totalCount: number;
}

interface IBulkSelectState {
  children: React.ReactNode;
  actions?: React.ReactNode;
  total?: number;
}

export const BulkSelectContext = createContext<IBulkSelectContext>({
  ids: [],
  excludedIds: [],
  selectAll: false,
  isSelected: (id: number): boolean => {
    return false;
  },
  onChangeSelectAll: (id: boolean) => {},
  onChangeSingle: (id: number, value: boolean) => {},
  setTotalCount: (count: number) => {},
  totalCount: 0,
});

export const BulkSelectState = ({ children, actions, total = 0 }: IBulkSelectState) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [excludedIds, setExcludedIds] = useState<Array<number>>([]);
  const [ids, setSelectedIds] = useState<Array<number>>([]);
  const [totalCount, setTotalCount] = useState(total);

  const onChangeSelectAll = (value: boolean) => {
    setSelectAll(value);
    setSelectedIds([]);
    setExcludedIds([]);
  };

  const updateExcludedIds = (id: number, value: boolean) => {
    if (value) {
      setExcludedIds(excludedIds.filter((exceptId) => exceptId !== id));
    } else {
      if (!excludedIds.includes(id)) {
        const newExcludedIds = excludedIds.concat(id);
        if (newExcludedIds.length === totalCount) {
          setSelectAll(false);
          setExcludedIds([]);
        } else {
          setExcludedIds(excludedIds.concat(id));
        }
      }
    }
  };

  const updateSelectedIds = (id: number, value: boolean) => {
    if (value) {
      if (!ids.includes(id)) {
        if (ids.length + 1 === totalCount) {
          setSelectAll(true);
          setSelectedIds([]);
        } else {
          setSelectedIds(ids.concat(id));
        }
      }
    } else {
      setSelectedIds(ids.filter((selectedId) => selectedId !== id));
    }
  };

  const onChangeSingle = (id: number, value: boolean) => {
    if (selectAll) {
      updateExcludedIds(id, value);
    } else {
      updateSelectedIds(id, value);
    }
  };

  const isSelected = (id: number): boolean => {
    if (selectAll) {
      return !excludedIds.includes(id);
    } else {
      return ids.includes(id);
    }
  };

  useEffect(() => {
    if (total) {
      setTotalCount(total);
    }
  }, [total]);

  return (
    <BulkSelectContext.Provider
      value={{
        excludedIds,
        ids,
        selectAll,
        isSelected,
        onChangeSelectAll,
        onChangeSingle,
        setTotalCount,
        totalCount,
      }}
    >
      {children}
      {actions && (
        <BulkActionsPanel totalCount={totalCount} ids={ids} selectAll={selectAll} excludedIds={excludedIds}>
          {actions}
        </BulkActionsPanel>
      )}
    </BulkSelectContext.Provider>
  );
};
