export const keywordFieldStyles = {
  wrapper: {
    alignItems: 'center !important',
    '& .MuiFormControl-root': {
      '& .MuiInputBase-root ': {
        padding: 0,
        minHeight: '30px',
      },
    },
  },
};
