import { SxProps } from '@mui/material';

import { StyledSubmitButton } from './styledComponents';

interface SubmitButtonProps {
  onClick: Function;
  disabled?: boolean;
  children: React.ReactNode;
  sx?: SxProps;
  type?: 'submit' | 'button' | 'reset';
}

const SubmitButton = ({ onClick, children, disabled = false, sx, type }: SubmitButtonProps) => {
  return (
    <StyledSubmitButton variant="contained" disabled={disabled} onClick={() => onClick()} sx={sx} type={type}>
      {children}
    </StyledSubmitButton>
  );
};

export default SubmitButton;
