import { STRATEGIC_MEDIA_CHANNEL } from 'constants/general';

import { IBCMessage } from 'interfaces/IBCMessage';

export class CommunicationService {
  bc: BroadcastChannel;
  callbacks: { [message: string]: Function[] } = {};

  constructor() {
    this.bc = new BroadcastChannel(STRATEGIC_MEDIA_CHANNEL);
    this.bc.onmessage = this.onMessage;
  }

  onMessage = (event: MessageEvent) => {
    const message = event.data;
    const callbacks = this.callbacks[message.name];
    callbacks?.forEach((callback) => callback(message.details));
  };

  sendMessage = (message: IBCMessage) => {
    this.bc.postMessage(message);
  };

  subscribe = (name: string, callback: Function) => {
    const callbacks = this.callbacks[name] || [];
    callbacks.push(callback);
    this.callbacks[name] = callbacks;
  };

  unsubscribe = (name: string, callback: Function) => {
    const callbacks = this.callbacks[name] || [];
    if (callbacks) {
      const filtered = callbacks.filter((cb) => cb !== callback);
      this.callbacks[name] = filtered;
    }
  };
}

export default new CommunicationService();
