const VALIDATION_MESSAGES = {
  REQUIRED: 'Required field',
  POSITIVE: 'Must be positive',
  NUMBER: 'Must be a number',
  EMAIL: 'Invalid email',
  INTEGER: 'Must be an integer',
  STRING_MAX_LENGTH: (stringMaxLength: number) =>
    `Must be less than ${stringMaxLength} characters`,
  FRACTIONAL_NUMBER_MAX_LENGTH: (
    integerLength: string,
    decimalLength: string
  ) =>
    `Max number of digits for integer part - ${integerLength}, for decimal part - ${decimalLength}`,
  DATE: 'The end date cannot be earlier than the start date',
  TIME: 'The end time cannot be earlier than the start time',
  MAX_NUMBER: (limit: number) => `Must be less or equal to ${limit}`,
  MAX_FILE_SIZE: (size: number) => `The file exceeds the maximum limit of ${size} MB`,
};

export default VALIDATION_MESSAGES;
