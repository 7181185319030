import { keysToSnakeCase } from 'helpers/utils';

import { ISelectOption } from 'interfaces/ISelectOption';
import { IAttributionFilterProps } from 'interfaces/Response/IAttributionFilterProps';
import { IResponseFilterOptions } from 'interfaces/Response/IResponseFilterOptions';

const multiSelectKeys = ['outlet_id', 'product_id', 'file_id'];
export const prepareFilterData = (filterOptions: IResponseFilterOptions | IAttributionFilterProps) => {
  const options: { [key: string]: any } = keysToSnakeCase(filterOptions);

  multiSelectKeys.forEach((value) => {
    if (options[value]) {
      options[value] = (options[value] as Array<ISelectOption>).map((option) => option.value);
    }
  });

  return options;
};
