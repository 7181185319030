import { useContext, useState } from 'react';

import { ResponseAPI } from 'api/responseAPI';

import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { StyledDialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent } from 'components/base/BaseModal/styledDialogContent';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { RadioGroupContext } from 'context/RadioGroupContext';

import RESPONSE_CODES from 'constants/responseCodes';

import { IAttributionParams } from 'interfaces/Response/IAttributionParams';
import { IAPIError, IResponse } from 'interfaces/api';

import ListOfSpotsForAttribution from './ListOfSpotsForAttribution';

interface ManualAttributionModalProps {
  attributionParams: IAttributionParams;
  onClose: (error?: IAPIError) => void;
  onSubmit: () => void;
}

const ManualAttributionModal = ({ attributionParams, onClose, onSubmit }: ManualAttributionModalProps) => {
  const { selectAll, ids, excludedIds } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { value } = useContext(RadioGroupContext);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = () => {
    setIsDisabled(true);
    ResponseAPI.attribute({ selectAll, ids, excludedIds }, getSerializedData(), value.id, value.type).then(
      (response: IResponse) => {
        setIsDisabled(false);
        if (response.status === RESPONSE_CODES.OK) {
          onSubmit();
        } else {
          onClose(response.data.error);
        }
      }
    );
  };

  return (
    <>
      <StyledDialogContent>
        <ListOfSpotsForAttribution attributionParams={attributionParams} />
      </StyledDialogContent>
      <StyledDialogActions>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
        <SubmitButton disabled={isDisabled || value === undefined || value === null} onClick={handleSubmit}>
          Save
        </SubmitButton>
      </StyledDialogActions>
    </>
  );
};

export default ManualAttributionModal;
