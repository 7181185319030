import { ICreativeForm } from 'interfaces/Creative/ICreativeForm';
import { IFilterParams } from 'interfaces/IFilterParams';

import { request, screenRequest } from './instance';

export const CreativeAPI = {
  getList: async (params: IFilterParams) => {
    const response = await request({
      url: 'v1/creatives',
      method: 'GET',
      params,
    });

    return response.data;
  },

  getDetails: async (creativeId: number) => {
    const response = await screenRequest({
      url: `v1/creatives/${creativeId}`,
      method: 'GET',
    });

    return response.data;
  },

  getOne: async (creativeId: number) => {
    const response = await request({
      url: `v1/creatives/${creativeId}`,
      method: 'GET',
    });

    return response.data;
  },

  delete: async (creativeId: number) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}`,
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getByName: async (value: string, productId: number | '', isFitler?: boolean) => {
    const response = await request({
      url: 'v1/creatives/search',
      method: 'GET',
      params: { value, product_id: productId, is_filter: isFitler },
    });

    return response.data.data;
  },

  create: async ({
    name,
    productId,
    duration,
    codePrefix,
    isciPrefix,
    shortCode,
    keywords,
    comment,
    type,
  }: ICreativeForm) => {
    try {
      const response = await request({
        url: 'v1/creatives',
        method: 'POST',
        data: formData({
          name,
          productId,
          duration,
          codePrefix,
          isciPrefix,
          shortCode,
          keywords,
          comment,
          type,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  update: async (
    id: number,
    { name, productId, duration, codePrefix, isciPrefix, shortCode, comment, type }: ICreativeForm
  ) => {
    try {
      const response = await request({
        url: `v1/creatives/${id}`,
        method: 'PUT',
        data: formData({
          name,
          productId,
          duration,
          codePrefix,
          isciPrefix,
          shortCode,
          comment,
          type,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchCreativeActivation: async (creativeId: number, activate: boolean) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/switch_activation`,
        method: 'patch',
        data: {
          data: {
            creative: {
              activate,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateTalkingPoints: async (creativeId: number, values: { talkingPoints: string }) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/update_talking_points`,
        method: 'PATCH',
        data: { data: { creative: { talking_points: values.talkingPoints } } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },
};

const formData = ({
  name,
  productId,
  duration,
  codePrefix,
  isciPrefix,
  shortCode,
  keywords,
  comment,
  type,
}: ICreativeForm) => ({
  data: {
    creative: {
      name,
      product_id: productId,
      duration,
      code_prefix: codePrefix,
      isci_prefix: isciPrefix,
      short_code: shortCode,
      keywords,
      comment,
      type,
    },
  },
});
