import { useContext } from 'react';

import { Checkbox } from '@mui/material';

import { BulkSelectContext } from 'context/BulkSelectContext';

import { ReactComponent as CheckboxChecked } from 'assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/CheckboxUnchecked.svg';

import { colors } from 'styles/globalStyles';

interface Props {
  id: number;
}

const CheckboxCell = ({ id }: Props) => {
  const { isSelected, onChangeSingle } = useContext(BulkSelectContext);

  return (
    <Checkbox
      checked={isSelected(id)}
      checkedIcon={<CheckboxChecked color={colors.red} />}
      icon={<CheckboxUnchecked />}
      onChange={(e) => {
        onChangeSingle(id, e.target.checked);
      }}
      sx={{ padding: 0 }}
    />
  );
};

export default CheckboxCell;
