import { Form, Formik } from 'formik';

import { OutlinedButton, Select, SubmitButton } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { groupingTypes } from '..';

interface Props {
  onSubmit: (groupingType: string) => void;
  onCancel: () => void;
  groupingType: string;
}

const FirstStep = ({ onSubmit, onCancel, groupingType }: Props) => {
  return (
    <>
      <Formik
        initialValues={{ groupingType }}
        enableReinitialize={true}
        validateOnBlur
        onSubmit={(values) => {
          onSubmit(values.groupingType);
        }}
      >
        {({ handleSubmit }) => (
          <Form>
            <DialogContent>
              <Select
                name="groupingType"
                label="Pay to"
                options={groupingTypes}
                allowEmpty={false}
                styles={{ label: { minWidth: '71px' }}}
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onCancel}>Cancel</OutlinedButton>
              <SubmitButton onClick={handleSubmit}>Next</SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FirstStep;
