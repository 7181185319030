import { useCallback, useContext } from 'react';

import { ModalContext } from 'context/ModalContext';

import { useNotFoundEvent } from 'hooks/useErrorEvent';

import { PopupType } from 'constants/enums/PopupType';

import Popup from '../Popup';

const NOT_FOUND_ERROR_POPUP = 'NOT_FOUND_ERROR_POPUP';

const NotFoundPopup = () => {
  const { onModalOpen, onModalClose, closeAll } = useContext(ModalContext);

  const handleShowError = useCallback(() => {
    closeAll();
    onModalOpen(NOT_FOUND_ERROR_POPUP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNotFoundEvent(handleShowError);

  return (
    <Popup
      name={NOT_FOUND_ERROR_POPUP}
      type={PopupType.error}
      title="Object not found"
      onModalClose={() => onModalClose(NOT_FOUND_ERROR_POPUP)}
    >
      The object you selected cannot be found, so no action can be performed on it.
    </Popup>
  );
};

export default NotFoundPopup;
