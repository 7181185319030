import { IconButton } from '@mui/material';

import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding: 18px 30px;
  border-top: 2px solid ${colors.red};
  box-shadow: 0 0 18px 0 rgba(255, 115, 115, 0.4);
  background-color: white;

  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const SelectedInfo = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.32px;
  color: ${colors.darkGrey};
`;

export const ActionsContainer = styled('div')({
  marginLeft: '70px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '34px',
});

export const ActionsGroup = styled('div')({
  display: 'flex',
  gap: '10px',
});

export const DeleteActionItem = styled(ActionsGroup)({
  position: 'absolute',
  right: '150px',
});

export const StyledIconButton = styled(IconButton)({
  padding: 0,
});
