import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserAPI } from 'authApi/userAPI';
import jwtDecode from 'jwt-decode';
import CommunicationService from 'services/CommunicationService';

import { ModalContext } from 'context/ModalContext';

import useIdle from 'hooks/useIdle';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { TABS_MESSAGES, accessTokenKey } from 'constants/general';

import { IAuthUserData } from 'interfaces/User/IAuthUserData';

interface IAuthContextProps {
  user: IAuthUserData | null;
  login: (token: string) => void;
  logout: (firstborn?: boolean) => void;
}

export const AuthContext = createContext<IAuthContextProps>({
  user: null,
  login: (token: string) => {},
  logout: (firstborn: boolean = false) => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useLocalStorage(accessTokenKey, null);
  const [user, setUser] = useState<IAuthUserData | null>(token ? jwtDecode(token) : null);
  const { closeAll } = useContext(ModalContext);
  const navigate = useNavigate();

  const login = (token: string) => {
    setToken(token);
    const userData = jwtDecode(token) as IAuthUserData;
    setUser(userData);
    navigate('/');
  };

  const logout = (firstborn: boolean = false) => {
    if (firstborn) {
      CommunicationService.sendMessage({ name: TABS_MESSAGES.LOGOUT });
      UserAPI.logout(token);
    }

    setUser(null);
    setToken(null);
    closeAll();
    navigate('/login', { replace: true });
  };

  useIdle(() => logout(true));

  useEffect(() => {
    const logoutCallback = () => logout(false);
    CommunicationService.subscribe(TABS_MESSAGES.LOGOUT, logoutCallback);
    return () => CommunicationService.unsubscribe(TABS_MESSAGES.LOGOUT, logoutCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(user)]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
