import { useContext, useEffect, useState } from 'react';

import { UserAPI } from 'api/userAPI';

import Modal from 'components/base/BaseModal';
import Popup from 'components/base/Popup';
import EmployeeForm from 'components/forms/users/EmployeeForm';

import { BaseListActionsContext } from 'context/BaseListActions';
import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';
import { UserRole } from 'constants/enums/UserRole';
import RESPONSE_CODES from 'constants/responseCodes';

import { IEmployeeForm } from 'interfaces/User/Employees/IEmployeeForm';
import { IAPIError, IResponse } from 'interfaces/api';

export const EDIT_EMPLOYEE_MODAL_NAME = 'EDIT_EMPLOYEE_MODAL';
export const EDIT_ERROR_POPUP_NAME = 'EDIT_ERROR_POPUP';
export const CHANGE_ROLE_CONFIRMATION_POPUP_NAME = 'CHANGE_ROLE_CONFIRMATION_POPUP';

const EditEmployeeAction = ({ id, onClose }: { id: number; onClose: () => void }) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const [error, setError] = useState<IAPIError>();

  const [employeeData, setEmployeeData] = useState<IEmployeeForm>();
  const [updatedData, setUpdatedData] = useState<IEmployeeForm>();

  const onFormModalClose = () => {
    onClose();
    onModalClose(EDIT_EMPLOYEE_MODAL_NAME);
    setEmployeeData(undefined);
  };

  const onUpdate = (values: IEmployeeForm) => {
    return UserAPI.update(id, values).then((response: IResponse) => {
      if (RESPONSE_CODES.OK === response.status) {
        onFormModalClose();
        reloadList();
      } else {
        setError(response.data.error);
        onModalOpen(EDIT_ERROR_POPUP_NAME);
      }
    });
  };

  const onConfirm = () => {
    onUpdate(updatedData!);
    onModalClose(CHANGE_ROLE_CONFIRMATION_POPUP_NAME);
  };

  const onFormSubmit = (values: IEmployeeForm) => {
    if (values.role === UserRole.admin && employeeData!.role === UserRole.employee) {
      onModalOpen(CHANGE_ROLE_CONFIRMATION_POPUP_NAME);
      setUpdatedData(values);
    } else {
      return onUpdate(values);
    }
  };

  useEffect(() => {
    UserAPI.getOne(id).then(({ data }: { data: IEmployeeForm }) => {
      setEmployeeData(data);
    });
    onModalOpen(EDIT_EMPLOYEE_MODAL_NAME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Modal name={EDIT_EMPLOYEE_MODAL_NAME} title="Edit User" onModalClose={onFormModalClose}>
        <EmployeeForm initialValues={employeeData} onClose={onFormModalClose} onSubmit={onFormSubmit} />
      </Modal>
      {error && (
        <Popup
          name={EDIT_ERROR_POPUP_NAME}
          type={PopupType.error}
          title={error.title}
          onModalClose={() => {
            setError(undefined);
            onModalClose(EDIT_ERROR_POPUP_NAME);
          }}
        >
          {error.details}
        </Popup>
      )}
      <Popup
        name={CHANGE_ROLE_CONFIRMATION_POPUP_NAME}
        type={PopupType.warning}
        title="Access Rights Change"
        onModalClose={() => onModalClose(CHANGE_ROLE_CONFIRMATION_POPUP_NAME)}
        handleSubmit={onConfirm}
        submitText="Yes, proceed"
        cancelText="No"
      >
        You are about to give this user Admin access. This will grant them full privileges and access to all parts of
        the system. Are you sure you want to proceed? Remember that you can always edit their access rights later if
        needed.
      </Popup>
    </>
  );
};

export default EditEmployeeAction;
