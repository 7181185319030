import { FormEvent, useContext } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton, TimePickerGroup } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import { reviseTimesValidationSchema } from 'helpers/validation/reviseTimeValidationSchema';

import RESPONSE_CODES from 'constants/responseCodes';

import { datesFieldStyles } from './styledComponents';

const REVISE_TIMES_FORM_MODAL_NAME = 'REVISE_TIMES_FORM_MODAL';

const reviseTimesFormInformation = {
  startTime: '',
  endTime: '',
};

const ReviseTimesForm = () => {
  const { reloadList } = useContext(SpotActionsContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose } = useContext(ModalContext);

  const onClose = () => {
    onModalClose(REVISE_TIMES_FORM_MODAL_NAME);
  };

  return (
    <>
      <Formik
        initialValues={reviseTimesFormInformation}
        enableReinitialize
        validationSchema={reviseTimesValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          const response = await LegacySpotAPI.reviseTimes(
            values,
            { selectAll, excludedIds, ids },
            getSerializedData()
          );

          if (response?.status === RESPONSE_CODES.OK) {
            onClose();
            reloadList();
          }
        }}
      >
        {({ errors, handleSubmit, dirty, values, setFieldTouched }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>AIR PERIOD</FieldGroupTitleContainer>
              <TimePickerGroup
                label="Air Time"
                startProps={{
                  name: 'startTime',
                  onChange: () => {
                    if (values.endTime) {
                      setFieldTouched('endTime', true);
                    }
                  },
                }}
                endProps={{ name: 'endTime', minTime: values.startTime }}
                styles={datesFieldStyles}
                required
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ReviseTimesForm;
