import styled from '@emotion/styled';

export const StatusContainer = styled.span<{ activeColor: string }>`
  color: ${(props) => (props.activeColor)};
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  letter-spacing: -0.32px
`;
