import { useEffect, useState } from 'react';

import _ from 'lodash';

import { CustomIcon, LinkButton } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';

import { ITableColumn } from 'interfaces/ITableColumn';
import { ParsingErrors } from 'interfaces/spots/ISpotImportRecordsTable';

interface EditCodeFormProps {
  errors: ParsingErrors | null;
}

const columns = [
  { dataField: 'rowNumber', text: 'Row number' },
  { dataField: 'details', text: 'Details' },
];

const ErrorsModal = ({ errors }: EditCodeFormProps) => {
  const [errorsList, setErrorsList] = useState<{ rowNumber: number; details: string }[]>([]);

  const handleCopy = () => {
    const rows = errorsList.map((item) => `${item.rowNumber}\t${item.details}`).join('\n');
    const header = 'Row number\tDetails\n';
    navigator.clipboard.writeText(`${header}${rows}`);
  };

  useEffect(() => {
    if (errors) {
      const newErrorsList: { rowNumber: number; details: string }[] = [];
      _.forOwn(errors, (value: string[], key) => {
        value.forEach((error) => newErrorsList.push({ rowNumber: Number(key), details: error }));
      });

      setErrorsList(newErrorsList);
    }
  }, [errors]);

  return (
    <>
      <DialogContent>
        <TableWithLazyLoad
          name="errorsList"
          columns={columns as ITableColumn[]}
          data={errorsList}
          withActions={false}
          tableStyle={{ minWidth: '100%' }}
          tableContainerStyles={{ maxHeight: '536px' }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <LinkButton mode="dark" onClick={handleCopy}>
          <CustomIcon name="copy" size="large" />
          Copy list
        </LinkButton>
      </DialogActions>
    </>
  );
};

export default ErrorsModal;
