import { createConsumer } from '@rails/actioncable';
import { useEffect } from 'react';

const { REACT_APP_WS_URL } = process.env;

export const consumer = createConsumer(REACT_APP_WS_URL);

const useWebSocket = (channel: string | undefined, callback: any) => {
  useEffect(() => {
    const subscription = channel && consumer.subscriptions.create({ channel }, {
      received: ({ data, type }: any) => callback(data, type),
    });

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [channel, callback]);
};

export default useWebSocket;
