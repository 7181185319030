import { FormControl, Label, TimePickerField } from 'components/UIComponents';
import { InputFieldContainer, StyledDash } from './styledComponents';

interface TimePickerFieldProps {
  name?: string;
  onChange?: any;
  maxTime?: any;
  minTime?: any;
}

interface SelectProps {
  label: string;
  styles?: {
    wrapper?: object;
  };
  startProps: TimePickerFieldProps;
  endProps: TimePickerFieldProps;
  required?: boolean;
}

const TimePickerGroup = ({
  startProps,
  endProps,
  label,
  styles = {},
  required = false,
}: SelectProps) => {
  return (
    <FormControl styles={styles.wrapper}>
      <Label required={required}>{label}</Label>
      <InputFieldContainer>
        <TimePickerField {...startProps} />
        <StyledDash>—</StyledDash>
        <TimePickerField {...endProps} />
      </InputFieldContainer>
    </FormControl>
  );
};

export default TimePickerGroup;
