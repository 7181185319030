/* eslint-disable no-magic-numbers */
import { BYTES_IN_KB } from 'constants/general';
import { formatDate } from 'helpers/dateHelper';
import { IIsciCode } from 'interfaces/IsciCode/IIsciCode';

export const codeFileInfo = (code: IIsciCode) => {
  if (!code.audio_name) { return; }

  const date = formatDate(code.audio_created_at!);
  let size;
  if (code.audio_size! >= BYTES_IN_KB) {
    size = `${(code.audio_size! / BYTES_IN_KB).toFixed(2)}MB`;
  } else {
    size = `${code.audio_size!}KB`;
  }
  const duration = `${code.audio_duration} sec`;

  return `Uploaded ${date}, size ${size}, dur ${duration}`;
};
