import { FormEvent, useEffect, useState } from 'react';

import { GroupContactAPI } from 'api/groupContactAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, RadioField } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { contactValidationSchema } from 'helpers/validation/contactValidationSchema';

import RESPONSE_CODES from 'constants/responseCodes';

import { IContact } from 'interfaces/Contact/IContact';

interface ContactFormProps {
  groupId: number;
  onModalClose: () => void;
  editContactId: number;
  successAction: () => void;
}

const ContactForm = ({ groupId, onModalClose, editContactId, successAction }: ContactFormProps) => {
  // Only strings are allowed
  const [contactFormInformation, setContactFormInformation] = useState<IContact>({
    name: '',
    phoneNumber: '',
    email: '',
    isTraffic: 'true',
    isAccounting: 'true',
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const radioOptions = [
    { key: 'Yes', value: 'true' },
    { key: 'No', value: 'false' },
  ];

  useEffect(() => {
    if (editContactId) {
      GroupContactAPI.getOne(groupId, editContactId).then(({ data }) => {
        setContactFormInformation({
          name: data.name || '',
          phoneNumber: data.phone || '',
          email: data.email || '',
          isTraffic: data.is_traffic.toString(),
          isAccounting: data.is_accounting.toString(),
        });
      });
    }
  }, [groupId, editContactId]);

  return (
    <>
      <Formik
        initialValues={contactFormInformation}
        enableReinitialize={true}
        validationSchema={contactValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          setIsDisabled(true);
          let response;
          if (editContactId) {
            response = await GroupContactAPI.updateGroupContact(groupId, editContactId, values);
          } else {
            response = await GroupContactAPI.createGroupContact(groupId, values);
          }
          if (response?.status === RESPONSE_CODES.OK) {
            onModalClose();
            successAction();
          }
          setIsDisabled(false);
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <Input name="name" label="Contact" />
              <Input name="phoneNumber" label="Phone Number" placeholder="(XXX) XXX-XXXX" type="phone" />
              <Input name="email" label="Email" required={true} />
              <RadioField name="isTraffic" label="Traffic" options={radioOptions} />
              <RadioField name="isAccounting" label="Accounting" options={radioOptions} />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={isDisabled || !dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                {editContactId ? 'Save' : 'Add'}
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
