import { Typography } from '@mui/material';

import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';

import { trafficContactsInfo } from 'helpers/spotsHelper';

import { ITrafficFormItem } from 'interfaces/Traffic/ITrafficForm';

import { ContactInfo } from '../styledComponents';

const ContactsInfo = ({ trafficInfo }: { trafficInfo: ITrafficFormItem }) => {
  const contacts = trafficContactsInfo(trafficInfo);

  return (
    <>
      <FieldGroupTitleContainer>
        CONTACT
        {!contacts.length && <ContactInfo>No active traffic contacts</ContactInfo>}
        {contacts.length === 1 && <ContactInfo>{contacts[0]}</ContactInfo>}
      </FieldGroupTitleContainer>
      {contacts.length !== 1 &&
        contacts.map((contact, index) => (
          <div key={index}>
            <Typography>{contact}</Typography>
          </div>
        ))}
    </>
  );
};

export default ContactsInfo;
