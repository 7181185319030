import { useContext, useState } from 'react';

import { OrderAPI } from 'api/orderAPI';
import { FieldArray, Form, Formik } from 'formik';

import { LinkButton, OutlinedButton, SubmitButton, TextArea } from 'components/UIComponents';
import {
  FieldGroupTitleContainer,
  ModalInfoBlock,
  ModalInfoBlockTitle,
  CommentTitle,
} from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { contactInfo } from 'helpers/spotsHelper';
import { apiErrorToPopupData } from 'helpers/utils';
import { orderValidationSchema } from 'helpers/validation/orderValidationSchema';

import RESPONSE_CODES from 'constants/responseCodes';

import { IOrderFormItem, IOrderGroups } from 'interfaces/Order/IOrderForm';
import { IAPIError, IErrorResponseData, IResponse } from 'interfaces/api';

import { ButtonsBlock, ContactInfo } from '../OrderForm/styledComponents';
import { usePopup } from '../../../context/GlobalPopupContext';
import { BulkSelectContext } from '../../../context/BulkSelectContext';
import { FilterContext } from '../../../context/FilterContext';
import { BaseListActionsContext } from 'context/BaseListActions';
import { ToastContext } from 'context/ToastContext';
import { ToastType } from 'constants/enums/PopupType';

import AttachmentPreviewForm from '.././AttachmentPreviewForm/index';

interface Props {
  onClose: (error?: IAPIError) => void;
  data: IOrderGroups;
}

const OrderSendForm = ({ onClose, data }: Props) => {
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const [isDisabled] = useState(Object.values(data).every((infoGroup) => !infoGroup.contact_email));
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(BaseListActionsContext);

  const initialValues = {
    orders: Object.keys(data).map((groupName) => ({
      ...data[groupName],
      name: groupName,
      comment: '',
    })),
  };
  const { openPopup } = usePopup();

  const onApplyCommentToAll = (
    values: { orders: IOrderFormItem[] },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    const comment = values.orders[0].comment;
    const orders = values.orders.map((item) => ({ ...item, comment }));
    setFieldValue('orders', orders);
  };

  const handleRequest = (values: any) => {
    const commentObject = values.orders.reduce((acc: any, item: any) => {
      acc[item.group_id] = item.comment;
      return acc;
    }, {});

    OrderAPI.sendOrders({ selectAll, ids, excludedIds }, getSerializedData(), false, commentObject)
      .then(handleResponse);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      reloadList();
      onToastOpen('ORDER_BULK_ACTION_SUCCESS', response.data.data, ToastType.success);
      onClose();
    } else {
      let popupData = apiErrorToPopupData((response.data as IErrorResponseData).error);
      openPopup({
        ...popupData,
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={orderValidationSchema}
        enableReinitialize={true}
        validateOnBlur
        onSubmit={handleRequest}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <Form>
            <DialogContent>
              <FieldArray
                name="orders"
                render={() => {
                  return (
                    <>
                      {values.orders.map((orderInfo: any, index: number) => {
                        const showApplyToAllButton = index === 0 && values.orders.length !== 1;
                        return (
                          <ModalInfoBlock key={index}>
                            <ButtonsBlock>
                              <ModalInfoBlockTitle>{orderInfo.name}</ModalInfoBlockTitle>
                              <AttachmentPreviewForm orderInfo={orderInfo}/>
                            </ButtonsBlock>
                            <FieldGroupTitleContainer>
                              CONTACT
                              <ContactInfo>
                                {orderInfo.contact_email
                                  ? contactInfo(orderInfo.contact_name, orderInfo.contact_email)
                                  : 'No active default contact'}
                              </ContactInfo>
                            </FieldGroupTitleContainer>
                            <CommentTitle>COMMENT</CommentTitle>
                            <TextArea name={`orders[${index}].comment`} />
                            {showApplyToAllButton && (
                              <LinkButton
                                disabled={!values.orders[0].comment}
                                onClick={() => onApplyCommentToAll(values, setFieldValue)}
                              >
                                Apply comment to all orders
                              </LinkButton>
                            )}
                          </ModalInfoBlock>
                        );
                      })}
                    </>
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!!Object.values(errors).length || isDisabled}
                onClick={() => handleRequest(values)}
              >
                Proceed
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrderSendForm;