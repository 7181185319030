import { keysToCamelCase } from 'helpers/utils';

import { ModelName } from 'constants/enums/ModelName';
import { RELATIONS } from 'constants/relations';

import { IAPIError } from 'interfaces/api';

export const parseValidationError = (error: IAPIError, modelName: ModelName) => {
  if (error.type !== 'Validation error.') {
    return null;
  }

  try {
    let details = JSON.parse(error.details);
    details = keysToCamelCase(details);

    Object.keys(details).forEach((key) => {
      if (RELATIONS[modelName]) {
        if (RELATIONS[modelName]!.includes(key)) {
          details[`${key}Id`] = details[key];
          delete details[key];
        }
      }
    });

    return details;
  } catch (err) {
    return null;
  }
};
