import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { IDLE_LOGOUT_TIME } from 'constants/general';

const useIdle = (onIdle: () => void) => {
  const { pause, start } = useIdleTimer({
    onIdle: onIdle,
    timeout: IDLE_LOGOUT_TIME,
    crossTab: true,
  });

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.hidden) {
        pause();
      } else {
        start();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [pause, start]);
};

export default useIdle;
