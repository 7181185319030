import { TableContainer, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const StyledBodyTableCell = styled(TableCell)`
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.7px;
  color: ${colors.darkGrey};
  font-family: ${fontFamily};

  border-bottom: 1px solid ${colors.tableDelimiter};

  & .highlighted {
    background-color: rgba(255, 243, 139, 0.31);
    padding: 6px 0;
  }
`;

export const StyledHeadTableCell = styled(TableCell)`
  padding: 13px 16px;

  text-align: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: ${colors.darkGrey};
  font-family: ${fontFamily};

  border-bottom: 1px solid ${colors.tableDelimiter};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledTableHead = styled(TableHead)`
  height: 40px;

  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: ${colors.darkGrey};

  background-color: ${colors.tableHeadColor};

  position: sticky;
  top: 0;
  z-index: 1;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: ${colors.tableHoverColor};
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  border-radius: 0;
  box-shadow: none;
  overflow-y: auto;
`;
