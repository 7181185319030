import SelectAllCheckbox from 'components/base/tableComponents/SelectAllCheckbox';

import { colors } from 'styles/globalStyles';

export const statusToColorsMap: { [key: string]: string } = {
  Settled: colors.orange,
  Cleared: colors.green,
};

const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const columns = [
  {
    dataField: 'checkbox',
    text: <SelectAllCheckbox />,
    headStyle: { width: '3%' },
  },
  {
    dataField: 'code',
    text: 'Code',
    style: cellStyles,
    headStyle: { width: '14%' },
  },
  {
    dataField: 'date',
    text: 'Date',
    style: cellStyles,
    headStyle: { width: '13%' },
  },
  {
    dataField: 'zipCode',
    text: 'ZIP Code',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
  {
    dataField: 'phone',
    text: 'Phone Number',
    style: cellStyles,
    headStyle: { width: '14%' },
  },
  {
    dataField: 'source',
    text: 'Source',
    style: cellStyles,
    headStyle: { width: '13%' },
  },
  {
    dataField: 'fileName',
    text: 'File Name',
    style: cellStyles,
    headStyle: { width: '24%' },
  },
  {
    dataField: 'status',
    text: 'Status',
    style: cellStyles,
    headStyle: { width: '12%' },
  },
  {
    dataField: 'actions',
    text: '',
    headStyle: { width: '7%' },
  },
];
