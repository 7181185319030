import React from 'react';
import { RenderElementProps } from 'slate-react';
import * as CSS from 'csstype';

interface ElementProps {
  align: CSS.Property.TextAlign;
  type: string;
  children: React.ReactNode;
}

const Element = ({ attributes, children, element }: RenderElementProps) => {
  const convertedElement = element as unknown as ElementProps;
  const style = {
    textAlign: convertedElement.align,
  };
  const quoteStyles = {
    borderLeft: '2px solid #ddd',
    marginLeft: '0',
    marginRight: '0',
    paddingLeft: '10px',
    color: '#aaa',
    fontStyle: 'italic',
  };
  switch (convertedElement.type) {
    case 'quote':
      return (
        <blockquote style={{ ...style, ...quoteStyles }} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export default Element;
