import { Typography } from '@mui/material';

import styled from '@emotion/styled';

export const ContactInfo = styled(Typography)`
  margin-left: 28px;
`;

export const ButtonsBlock = styled(Typography)`
  display: flex;
  justify-content: space-between;
`;
