import * as Yup from 'yup';
import { stringMaxLength, textMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

const maxFeeValue = 100;
const minFeeValue = 0;

export const customerValidationSchema = Yup.object({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  contactName: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  contactEmail: Yup.string()
    .optional()
    .email(VALIDATION_MESSAGES.EMAIL)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  address: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  city: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  state: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  zip: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  country: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  phone: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  preferredContactMethod: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  customerFee: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minFeeValue, VALIDATION_MESSAGES.POSITIVE)
    .max(maxFeeValue, `Must be less than or equal to ${maxFeeValue}; max number of digits in decimal is 1`)
    .test(
      'is valid',
      `Must be less than or equal to ${maxFeeValue}; max number of digits in decimal is 1`,
      (value: number | undefined) => (/^[0-9]{0,3}[.]{0,1}[0-9]{0,1}$/).test(String(value))
    ),
  comment: Yup.string()
    .optional()
    .max(textMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)),
});
