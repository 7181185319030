import * as Yup from 'yup';

import VALIDATION_MESSAGES from 'constants/validationMessages';

export const newPasswordFormValidationSchema = Yup.object({
  password: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required(VALIDATION_MESSAGES.REQUIRED),
});
