import { useState, useEffect } from 'react';
import { FormEvent } from 'react';

import { OutletAPI } from 'api/outletAPI';
import { Form, Formik } from 'formik';

import {
  OutlinedButton,
  Input,
  Select,
  SubmitButton,
  TextArea,
  SelectWithSearch,
  RadioField,
  Checkbox,
} from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { getFormatOptions, getGroupOptions, getSecondaryFormatOptions } from 'helpers/collections';
import { getResponseHandler } from 'helpers/forms';
import { outletValidationSchema } from 'helpers/validation/outletValidationSchema';

import { ModelName } from 'constants/enums/ModelName';
import {
  mediaTypeOptions,
  bandOptions,
  typeOptions,
  marketOptions,
  isDigitallyInsertedOptions,
  readOptions,
} from 'constants/outletOptions';

import { ISelectOption } from 'interfaces/ISelectOption';
import { IOutletForm } from 'interfaces/Outlet/IOutletForm';

interface OutletFormProps {
  onModalClose: () => void;
  successAction: () => void;
  editOutletId: number;
}

const OutletForm = ({ onModalClose, successAction, editOutletId }: OutletFormProps) => {
  const [formatOptions, setFormatOptions] = useState<ISelectOption[]>([]);
  const [secondaryFormatOptions, setSecondaryFormatOptions] = useState<ISelectOption[]>([]);
  const [outletFormInformation, setOutletFormInformation] = useState<IOutletForm>({
    groupId: '',
    name: '',
    type: 'Local',
    band: 'FM',
    adType: 'Spot',
    isDigitallyInserted: null,
    pixel: null,
    read: null,
    market: 'National',
    zip: '',
    comment: '',
    secondaryFormatId: '',
    formatId: '',
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (editOutletId) {
      OutletAPI.getOne(editOutletId).then(({ data }) => {
        setOutletFormInformation({
          groupId: data.group_id,
          name: data.name,
          type: data.type,
          band: data.band,
          adType: data.ad_type,
          zip: data.zip,
          comment: data.comment || '',
          isDigitallyInserted: data.is_digitally_inserted,
          pixel: data.pixel,
          read: data.read,
          market: data.market,
          formatId: data.format_id || '',
          secondaryFormatId: data.secondary_format_id || '',
          companyName: data.company_name || '',
        });
      });
    }
  }, [editOutletId]);

  useEffect(() => {
    getFormatOptions().then((data) => setFormatOptions(data));
    getSecondaryFormatOptions().then((data) => setSecondaryFormatOptions(data));
  }, []);

  const handleMediaChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (name: string, value: any) => void
  ) => {
    if (['Podcast', 'Endorsed'].includes(e.target.value)) {
      setFieldValue('adType', 'Live');
    }
    if (e.target.value === 'Network') {
      setFieldValue('band', 'AM-FM');
    }
    if (e.target.value !== 'Podcast') {
      setFieldValue('isDigitallyInserted', null);
      setFieldValue('read', null);
      setFieldValue('pixel', null);
    } else {
      setFieldValue('isDigitallyInserted', 'Baked In');
      setFieldValue('read', 'Host Read');
      setFieldValue('pixel', true);
    }
  };

  return (
    <>
      <Formik
        initialValues={outletFormInformation}
        enableReinitialize
        validationSchema={outletValidationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const responseHandler = getResponseHandler(
            ModelName.Outlet,
            () => {
              onModalClose();
              successAction();
            },
            actions
          );

          let request;
          if (editOutletId) {
            request = OutletAPI.update(editOutletId, values);
          } else {
            request = OutletAPI.create(values);
          }
          request.then(responseHandler).finally(() => setIsDisabled(false));
        }}
      >
        {({ errors, handleSubmit, dirty, setFieldValue, values }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>MEDIA INFO</FieldGroupTitleContainer>
                <SelectWithSearch
                  name="groupId"
                  label="Group"
                  getOptionsList={getGroupOptions}
                  initial={{
                    key: outletFormInformation.companyName || '',
                    value: outletFormInformation.groupId || '',
                  }}
                  required
                />
                <Input name="name" label="Name" required />
                <Select
                  name="type"
                  label="Media Type"
                  options={mediaTypeOptions}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMediaChange(e, setFieldValue)}
                  required
                />
                <Select name="band" label="Band" options={bandOptions} required />
                <RadioField name="adType" label="Ad Type" options={typeOptions} required />
                {
                  values?.type === 'Podcast' &&
                  <Select
                    name="isDigitallyInserted"
                    label="Digitaly Inserted"
                    options={isDigitallyInsertedOptions}
                    required
                  />
                }
                {
                  values?.type === 'Podcast' &&
                  <Select
                    name="read"
                    label="Read"
                    options={readOptions}
                    required
                  />
                }
                {values?.type === 'Podcast' && <Checkbox name="pixel" label="Pixel" />}
                <FieldGroupTitleContainer>BROADCAST INFO</FieldGroupTitleContainer>
                <Select name="market" label="Market" options={marketOptions} required />
                <Input name="zip" label="ZIP Code" required styles={shortFieldStyles} />
                <FieldGroupTitleContainer>FORMATS</FieldGroupTitleContainer>
                <Select name="formatId" label="Format" options={formatOptions} placeholder="Select" />
                <Select
                  name="secondaryFormatId"
                  label="Secondary Format"
                  options={secondaryFormatOptions}
                  placeholder="Select"
                />
                <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
                <TextArea name="comment" />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={isDisabled || !dirty || !!Object.values(errors).length}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  {editOutletId ? 'Save' : 'Add'}
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OutletForm;
