import { useContext, useState } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import Popup from 'components/base/Popup';
import PaymentForm from 'components/forms/spots/PaymentForm';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { PopupType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IAPIError, IErrorResponseData, IResponse } from 'interfaces/api';

const PAYMENT_FORM_MODAL_NAME = 'paymentModalForm';
const PAYMENT_ERROR_POPUP_NAME = 'paymentErrorPopup';

const PayAction = ({ type }: { type: string }) => {
  const { reloadList } = useContext(SpotActionsContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const [groupingData, setGroupingData] = useState<object | null>();

  const [error, setError] = useState<IAPIError>();
  const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      setGroupingData((response.data as any).data);
      onModalOpen(PAYMENT_FORM_MODAL_NAME);
    } else {
      setError((response.data as IErrorResponseData).error);
      onModalOpen(PAYMENT_ERROR_POPUP_NAME);
    }
  };

  const onClose = () => {
    onModalClose(PAYMENT_FORM_MODAL_NAME);
    setGroupingData(null);
  };

  const onPayClick = () => {
    SpotApi.groupingForPayment(getSerializedData(), { selectAll, excludedIds, ids }).then(handleResponse);
  };

  return (
    <>
      <LinkButton onClick={onPayClick}>Pay</LinkButton>
      {groupingData && (
        <Modal name={PAYMENT_FORM_MODAL_NAME} title="Payment Details" onModalClose={onModalClose}>
          <PaymentForm type={type} groupedData={groupingData} onClose={onClose} successAction={reloadList} />
        </Modal>
      )}
      {error && (
        <Popup
          name={PAYMENT_ERROR_POPUP_NAME}
          type={PopupType.info}
          title={error.title}
          onModalClose={() => onModalClose(PAYMENT_ERROR_POPUP_NAME)}
        >
          {error.details}
        </Popup>
      )}
    </>
  );
};

export default PayAction;
