import styled from '@emotion/styled';

import { fontFamily } from 'styles/globalStyles';

export const Totals = styled.div`
  height: 30px;
  font-family: ${fontFamily};
  min-width: 160px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
`;
