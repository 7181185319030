import { useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { StyledActionsMenuContainer as ActionsMenuContainer } from './styledActionsMenuContainer';

interface Props {
  data: any
  actions: any[]
}

const ActionsMenu = ({ data, actions }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="actions"
        id="actions-button"
        aria-controls={open ? 'context-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <ActionsMenuContainer
        id={`${data.id}-context-menu`}
        key={data.id}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map(({ name, action, disabled = false }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action(data);
              handleClose();
            }}
            disabled={disabled}
          >
            {name}
          </MenuItem>
        ))}
      </ActionsMenuContainer>
    </>
  );
};

export default ActionsMenu;
