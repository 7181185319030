import * as Yup from 'yup';

import { stringMaxLength, textMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const employeeValidationSchema = Yup.object({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  phone: Yup.string().nullable().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  cell: Yup.string().nullable().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.EMAIL)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  role: Yup.string().required(),
  comment: Yup.string().nullable().max(textMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)),
});
