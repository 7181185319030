import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.div`
  font-family: ${fontFamily};
  font-size: 13px;
  font-weight: 400;
  line-height: 15.61px;
  letter-spacing: -0.32px;
  color: ${colors.red};

  margin-bottom: 10px;
  margin-top: 10px;
`;
