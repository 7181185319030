import { useContext } from 'react';

import { AccordionDetails } from '@mui/material';

import { CustomIcon } from 'components/UIComponents';

import { FilterContext } from 'context/FilterContext';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import Results from '../Results';
import {
  styledAccordion as Accordion,
  styledAccordionSummary as AccordionSummary,
  AccordionSummaryContainer,
  FilterTitle,
  ToggleLabel,
} from './styledComponents';

interface Props {
  children: React.ReactNode;
  results?: number;
}

const FilterAccordion = ({ children, results }: Props) => {
  const { expanded, setExpanded } = useContext(FilterContext);
  const handleFilterChange = (e: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleFilterChange}>
      <AccordionSummary expandIcon={<CustomIcon name="expand" size="x-small" />}>
        <AccordionSummaryContainer>
          <div>
            <FilterIcon />
            <FilterTitle>Filter</FilterTitle>
            {expanded ? '' : <Results value={results} />}
          </div>
          <ToggleLabel>{expanded ? 'Hide filter' : 'Show filter'}</ToggleLabel>
        </AccordionSummaryContainer>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default FilterAccordion;
