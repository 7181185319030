import { StatusLabel } from 'components/UIComponents';

import { spotStatuses } from 'constants/spots';

import { colors } from 'styles/globalStyles';

interface Props {
  status: string;
}

const statusToColorsMap: { [key: string]: string } = {
  Error: colors.spotErrorStatus,
  DNR: colors.orange,
  Success: colors.green,
  Canceled: colors.blue,
};

const SpotStatusLabel = ({ status }: Props) => {
  if (status === spotStatuses.noStatus) {
    return null;
  }

  return (
    <div style={{ marginRight: 'auto' }}>
      <StatusLabel color={statusToColorsMap[status]}>{status}</StatusLabel>
    </div>
  );
};

export default SpotStatusLabel;
