import { useContext } from 'react';

import { LinkButton } from 'components/UIComponents';
import { ActionsGroup, DeleteActionItem } from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import { SpotActionsContext } from 'context/SpotActionsContext';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { SpotType } from 'constants/enums/SpotTypes';
import { spotStatuses, spotTransitions } from 'constants/spots';

import AllocateAction from './AllocateAction';
import AssignToBuyer from './AssignToBuyer';
import BulkChangeStatus from './BulkChangeStatus';
import CreateOrderAction from './CreateOrderAction';
import DeleteSpotsAction from './DeleteSpotsAction';
import PayAction from './PayAction';
import ReviseTimes from './ReviseTimes';
import SendTrafficAction from './SendTrafficAction';
import ReviseRates from './ReviseRates';
import ReviseSpotNumber from './ReviseSpotNumber';

const SpotActions = ({ type }: { type: SpotType }) => {
  const { onAircheckClick } = useContext(SpotActionsContext);

  return (
    <>
      <ActionsGroup>
        <AllocateAction type={type} />
      </ActionsGroup>
      <ActionsGroup>
        <CreateOrderAction type={type} />
      </ActionsGroup>
      <ActionsGroup>
        <SendTrafficAction type={type} />
      </ActionsGroup>
      <ActionsGroup>
        <BulkChangeStatus
          type={type}
          buttonText="Cancel"
          status={isPodcastSpot(type) ? spotTransitions.cancel : spotStatuses.canceled}
        />
        <BulkChangeStatus
          type={type}
          buttonText="Uncancel"
          status={isPodcastSpot(type) ? spotTransitions.uncancel : spotStatuses.noStatus}
        />
      </ActionsGroup>
      {isPodcastSpot(type) && (
        <>
          <ActionsGroup>
            <BulkChangeStatus type={type} buttonText="Mark as DNR" status={spotTransitions.markAsDnr} />
            <BulkChangeStatus type={type} buttonText="Unmark as DNR" status={spotTransitions.ummarkAsDnr} />
          </ActionsGroup>
          <ActionsGroup>
            <BulkChangeStatus type={type} buttonText="Mark as Error" status={spotTransitions.markAsError} />
            <BulkChangeStatus type={type} buttonText="Unmark as Error" status={spotTransitions.ummarkAsError} />
          </ActionsGroup>
        </>
      )}
      {!isPodcastSpot(type) && <LinkButton onClick={() => onAircheckClick(undefined)}>Aircheck</LinkButton>}
      <ActionsGroup>
        <PayAction type={type} />
      </ActionsGroup>
      <ActionsGroup>
        <AssignToBuyer type={type} />
      </ActionsGroup>
      {!isPodcastSpot(type) && (
        <>
          <ActionsGroup>
            <ReviseTimes />
          </ActionsGroup>
          <ActionsGroup>
            <ReviseRates />
          </ActionsGroup>
          <ActionsGroup>
            <ReviseSpotNumber />
          </ActionsGroup>
        </>
      )}
      <DeleteActionItem style={{ right: '90px' }}>
        <DeleteSpotsAction type={type} />
      </DeleteActionItem>
    </>
  );
};

export default SpotActions;
