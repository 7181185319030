import FilterForm from 'components/forms/spots/FilterForm';

import FilterAccordion from './FilterAccordion';

interface Props {
  total?: number;
}

const Filter = ({ total }: Props) => {
  return (
    <FilterAccordion results={total}>
      <FilterForm total={total} />
    </FilterAccordion>
  );
};

export default Filter;
