import Menu from '@mui/material/Menu';
import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const StyledActionsMenuContainer = styled(Menu)`
  > .MuiPopover-paper {
    border: 1px solid ${colors.lightGrey};
    box-shadow: none;
  }

  .MuiMenuItem-root:hover {
    background-color: ${colors.aliceBlue} !important;
  }
`;
