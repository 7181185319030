const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const columns = [
  {
    dataField: 'checkbox',
    text: '',
    headStyle: { width: '1%' },
  },
  {
    dataField: 'outlet',
    text: 'Outlet',
    style: cellStyles,
    headStyle: { width: '14%' },
  },
  {
    dataField: 'type',
    text: 'Type',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
  {
    dataField: 'date',
    text: 'Date',
    style: cellStyles,
    headStyle: { width: '29%' },
  },
  {
    dataField: 'totalGross',
    text: 'Total Gross',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
  {
    dataField: 'spotsNumber',
    text: 'Spots Number',
    style: cellStyles,
    headStyle: { width: '7%' },
  },
  {
    dataField: 'product',
    text: 'Product',
    style: cellStyles,
    headStyle: { width: '15%' },
  },
  {
    dataField: 'market',
    text: 'Market',
    style: cellStyles,
    headStyle: { width: '14%' },
  },
];
