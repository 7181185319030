import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const StyledStatusLabel = styled.div<{ active: boolean }>`
  width: 60px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  color: ${(props) => (props.active ? colors.white : colors.inactiveStatusColor)};

  border: 1px solid ${(props) => (props.active ? colors.green : colors.inactiveStatusColor)};
  border-radius: 18px;
  background-color: ${(props) => (props.active ? colors.green : colors.white)};
`;
