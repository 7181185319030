import { RadioProps } from '@mui/material';

import { useField } from 'formik';

import { FormControl, Label } from 'components/UIComponents';

import {
  RadioCheckedIcon,
  StyledRadioGroup,
  StyledRadio,
  StyledFormControlLabel,
  RadioIcon,
  radioFieldStyles,
} from './styledComponents';

interface RadioFieldProps {
  label: string;
  name?: string;
  onChange?: any;
  options?: { key: string; value: string }[];
  required?: boolean;
}

const Radio = (props: RadioProps) => {
  return (
    <StyledRadio disableRipple color="default" icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} {...props} />
  );
};

const RadioField = ({ onChange, label, name = '', options = [], required = false }: RadioFieldProps) => {
  const [field] = useField(name);

  return (
    <FormControl styles={radioFieldStyles}>
      <Label required={required}>{label}</Label>
      <StyledRadioGroup
        row
        aria-labelledby="radio-buttons-group"
        name={name}
        value={field.value}
        onChange={(e) => {
          onChange?.(e);
          field.onChange(e);
        }}
      >
        {options.map(({ key, value }) => (
          <StyledFormControlLabel key={key} value={value} control={<Radio />} label={key} />
        ))}
      </StyledRadioGroup>
    </FormControl>
  );
};

export default RadioField;
