import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton, TableBody, Typography } from '@mui/material';

import { CodeAPI } from 'api/codeAPI';
import { CreativeAPI } from 'api/creativeAPI';

import { OutlinedButton, CustomIcon, StatusIndicator } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
  InformationBlocks,
  PageSubtitle,
  OtherRecordInfo,
} from 'components/base/BaseTable';
import Breadcrumbs from 'components/base/Breadcrumbs/Breadcrumbs';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CreativeForm from 'components/forms/CreativeForm';
import TalkingPointsForm from 'components/forms/TalkingPointsForm';

import { ModalContext } from 'context/ModalContext';

import { checkIsEmpty } from 'helpers/talkingPointsHelper/index';

import { ICreativeDetails } from 'interfaces/Creative/ICreativeDetails';
import { ICommonRecordInfo } from 'interfaces/ICommonRecordInfo';

import { colors, defaultButtonsGap } from 'styles/globalStyles';

import ListOfCreativeCodes from './ListOfCreativeCodes';
import { TalkingPointsCell, ButtonsBlock } from './styledComponents';

const CREATIVE_FORM_MODAL_NAME = 'creativeFormModal';
const TALKING_POINTS_FORM_MODAL_NAME = 'talkingPointsFormModal';

const CreativeDetails = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { creativeId } = useParams();
  const [currentCreative, setCreative] = useState<ICreativeDetails | null>(null);
  const [codesProvider, setCodesProvider] = useState(() => () => CodeAPI.getAll(Number(creativeId!)));

  const getCreativeCodes = () => {
    setCodesProvider(() => () => CodeAPI.getAll(Number(creativeId!)));
  };

  const onEditClick = () => {
    onModalOpen(CREATIVE_FORM_MODAL_NAME);
  };

  const successEditCreative = () => {
    getCreativeDetails(Number(creativeId!));
    getCreativeCodes();
  };

  const onTalkingPointsClick = () => {
    onModalOpen(TALKING_POINTS_FORM_MODAL_NAME);
  };

  const getCreativeDetails = (id: number) => {
    CreativeAPI.getDetails(id).then(({ data }) => {
      setCreative(data);
    });
  };

  useEffect(() => {
    getCreativeDetails(Number(creativeId!));
  }, [creativeId]);

  if (!currentCreative) return null;

  const switchCreativeActivation = (id: number, activate: boolean) => {
    CreativeAPI.switchCreativeActivation(id, activate).then(({ data }) => {
      if (data === 'Successfully changed.' && currentCreative) {
        setCreative({ ...currentCreative, is_active: activate });
        getCreativeCodes();
      }
    });
  };

  const ActivateButtonText = currentCreative.is_active ? 'Deactivate' : 'Activate';

  const TalkingPointsButtonText = checkIsEmpty(currentCreative.talking_points)
    ? 'Add Talking Points'
    : 'Edit Talking Points';

  return (
    <PageContainer>
      <Breadcrumbs>
        <Link to="/creatives">All Creatives</Link>
        <Typography>{currentCreative.name}</Typography>
      </Breadcrumbs>
      <PageTitle>
        {currentCreative.name}
        <StatusIndicator active={currentCreative.is_active} />
        <ButtonsBlock>
          {currentCreative.type === 'Live' && (
            <OutlinedButton sx={{ marginRight: defaultButtonsGap }} onClick={onTalkingPointsClick}>
              {TalkingPointsButtonText}
            </OutlinedButton>
          )}
          <OutlinedButton onClick={() => switchCreativeActivation(currentCreative.id, !currentCreative.is_active)}>
            {ActivateButtonText}
          </OutlinedButton>
        </ButtonsBlock>
      </PageTitle>
      <PageSubtitle>
        Information
        <IconButton onClick={onEditClick} data-testid="editCreativeIcon">
          <CustomIcon name="edit" color={colors.red} />
        </IconButton>
      </PageSubtitle>
      <InformationBlocks>
        <FlexChild>
          <InfoBlockTitle>Creative Info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Creative</HeadCell>
                <BodyCell>{currentCreative.name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Product</HeadCell>
                <BodyCell>{currentCreative.product_name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Duration, sec</HeadCell>
                <BodyCell>{currentCreative.duration}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Prefix for Code</HeadCell>
                <BodyCell>{currentCreative.code_prefix}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Prefix for ISCI</HeadCell>
                <BodyCell>{currentCreative.isci_prefix}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Short Code</HeadCell>
                <BodyCell>{currentCreative.short_code}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <FlexChild>
          <InfoBlockTitle>Comment</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <BodyCell>{currentCreative.comment}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
          {currentCreative.type === 'Live' && (
            <>
              <InfoBlockTitle>Talking Points</InfoBlockTitle>
              <InformationTable>
                <TableBody>
                  <TableRow>
                    <HeadCell>Talking points</HeadCell>
                    <TalkingPointsCell>
                      {checkIsEmpty(currentCreative.talking_points) ? 'No' : 'Yes'}
                      <StatusIndicator active={!checkIsEmpty(currentCreative.talking_points)} />
                    </TalkingPointsCell>
                  </TableRow>
                </TableBody>
              </InformationTable>
            </>
          )}
        </FlexChild>
        <OtherRecordInfo info={currentCreative as ICommonRecordInfo} />
      </InformationBlocks>
      <ListOfCreativeCodes currentCreativeType={currentCreative.type} getCreativeCodes={codesProvider} />
      {
        <Modal
          name={CREATIVE_FORM_MODAL_NAME}
          title={`Edit ${currentCreative.type} Creative`}
          onModalClose={onModalClose}
        >
          <CreativeForm
            onModalClose={() => onModalClose(CREATIVE_FORM_MODAL_NAME)}
            successAction={successEditCreative}
            creativeId={Number(creativeId!)}
            type={currentCreative.type}
          />
        </Modal>
      }
      {
        <Modal
          name={TALKING_POINTS_FORM_MODAL_NAME}
          title={checkIsEmpty(currentCreative.talking_points) ? 'Add Talking Points' : 'Edit Talking Points'}
          onModalClose={onModalClose}
        >
          <TalkingPointsForm
            onModalClose={() => onModalClose(TALKING_POINTS_FORM_MODAL_NAME)}
            successAction={successEditCreative}
            currentCreative={currentCreative!}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default CreativeDetails;
