export const checkIsEmpty = (value?: string) => {
  let isEmpty = true;
  if (value) {
    const parsed = new DOMParser().parseFromString(value, 'text/html');
    const elements = Array.from(parsed.body.children);
    isEmpty =
      elements?.every((n) => !n.textContent) &&
      !elements.some((n) => ['OL', 'UL'].includes(n.tagName));
  }
  return isEmpty;
};
