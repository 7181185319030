import dayjs from 'dayjs';
import * as Yup from 'yup';

import VALIDATION_MESSAGES from 'constants/validationMessages';

export const reviseTimesValidationSchema = Yup.object({
  startTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  endTime: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .test('not earlier than the start time', VALIDATION_MESSAGES.TIME, (value, context) => {
      if (value && context.parent.startTime) {
        return (
          dayjs(value, 'hh:mm A').isAfter(dayjs(context.parent.startTime, 'hh:mm A')) ||
          dayjs(value, 'hh:mm A').isSame(dayjs(context.parent.startTime, 'hh:mm A'))
        );
      }
      return true;
    }),
});
