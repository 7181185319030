import { RouterProvider } from 'react-router-dom';

import { Global } from '@emotion/react';
import router from 'router';

import { setUpInterceptors } from './api/setUpInterceptors';
import { GlobalStyles } from './styles/globalStyles';

function App() {
  setUpInterceptors();

  return (
    <>
      <Global styles={GlobalStyles} />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
