import { usePopup } from 'context/GlobalPopupContext';

import { apiErrorToPopupData } from 'helpers/utils';

import RESPONSE_CODES from 'constants/responseCodes';

import { IGlobalPopupData } from 'interfaces/IGlobalPopupData';
import { IErrorResponseData, IResponse } from 'interfaces/api';

const useRequestWithConfirmation = ({
  apiCall,
  handleSuccess,
  popupDataCustomizer,
  forceHandler,
}: {
  apiCall: (force: boolean) => Promise<IResponse>;
  handleSuccess: (response: IResponse) => void;
  popupDataCustomizer?: (popupData: IGlobalPopupData) => IGlobalPopupData;
  forceHandler?: () => void;
}) => {
  const { openPopup } = usePopup();
  const handleRequest = (force: boolean = false) => {
    apiCall(force).then(handleResponse);
  };

  const initiate = () => {
    handleRequest(false);
  };

  const forceProceed = () => {
    if (forceHandler) {
      forceHandler();
    } else {
      handleRequest(true);
    }
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      handleSuccess(response);
    } else {
      let popupData = apiErrorToPopupData((response.data as IErrorResponseData).error);
      if (popupDataCustomizer) {
        popupData = popupDataCustomizer(popupData);
      }

      openPopup({
        ...popupData,
        submitCallback: forceProceed,
      });
    }
  };

  return { initiate, forceProceed };
};

export default useRequestWithConfirmation;
