import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CodeAPI } from 'api/codeAPI';

import { OutlinedButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import { TableTitle } from 'components/base/BaseTable';
import Popup from 'components/base/Popup';
import Toast, { ToastParams } from 'components/base/Toast';
import CreateCodeForm from 'components/forms/CodeForm/CreateCodeForm';
import EditCodeForm from 'components/forms/CodeForm/EditCodeForm';

import { usePopup } from 'context/GlobalPopupContext';
import { ModalContext } from 'context/ModalContext';

import { messageForCodeRemoveModal } from 'helpers/messageForRemoveModal/index';

import { PopupType, ToastType } from 'constants/enums/PopupType';

import { ICodesTable } from 'interfaces/Code/ICodesTable';

import ListOfLiveCreativeCodes from '../ListOfLiveCreativeCodes';
import ListOfSpotCreativeCodes from '../ListOfSpotCreativeCodes';
import RESPONSE_CODES from 'constants/responseCodes';

const CODE_FORM_MODAL_NAME = 'createCodeFormModal';
const EDIT_CODE_FORM_MODAL_NAME = 'editCodeFormModal';
const REMOVE_CODE_MODAL_NAME = 'removeCode';

interface Props {
  currentCreativeType: string;
  getCreativeCodes: () => Promise<any>;
}

const CreativeDetails = ({ currentCreativeType, getCreativeCodes }: Props) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { creativeId } = useParams();
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<ToastParams>();
  const [codeToRemove, setCodeToRemove] = useState<ICodesTable | null>(null);
  const [editCodeId, setEditCodeId] = useState(0);
  const [codesData, setCodesData] = useState<ICodesTable[]>([]);
  const { openPopup } = usePopup();

  const getCodesList = () => {
    getCreativeCodes().then(({ data }) => {
      setCodesData(data);
    });
  };

  useEffect(() => {
    getCodesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCreativeCodes]);

  const onAddCodeClick = () => {
    onModalOpen(CODE_FORM_MODAL_NAME);
  };

  const onEditCodeClick = (codeId: number) => {
    setEditCodeId(codeId);
    onModalOpen(EDIT_CODE_FORM_MODAL_NAME);
  };

  const switchCodeActivation = (codeId: number, active: boolean) => {
    CodeAPI.switchCodeActivation(Number(creativeId), codeId, active).then(() => {
      getCodesList();
    });
  };

  const onDeleteCodeClick = (code: any) => {
    setCodeToRemove(code);
    onModalOpen(REMOVE_CODE_MODAL_NAME);
  };

  const handleSubmitRemove = async () => {
    const response = await CodeAPI.removeCode(Number(creativeId), Number(codeToRemove?.id));

    if (response?.status === RESPONSE_CODES.OK) {
      setShowToast(true);
      setToastParams({ type: ToastType.success, message: response.data.data });
      getCodesList();
    }

    if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup({
        type: response.data.error.type.toLowerCase(),
        content: response.data.error.details,
        title: response.data.error.title,
      });
    }

    onModalClose(REMOVE_CODE_MODAL_NAME);
  };

  return (
    <>
      <TableTitle>
        Codes
        <OutlinedButton onClick={onAddCodeClick}>Add Code</OutlinedButton>
      </TableTitle>
      {currentCreativeType === 'Live' ? (
        <ListOfLiveCreativeCodes
          editAction={onEditCodeClick}
          codesData={codesData}
          switchActivation={switchCodeActivation}
          deleteAction={onDeleteCodeClick}
        />
      ) : (
        <ListOfSpotCreativeCodes
          editAction={onEditCodeClick}
          codesData={codesData}
          switchActivation={switchCodeActivation}
          deleteAction={onDeleteCodeClick}
          reloadData={getCodesList}
        />
      )}
      {
        <Modal name={CODE_FORM_MODAL_NAME} title="Add Code" onModalClose={onModalClose}>
          <CreateCodeForm
            creativeId={Number(creativeId!)}
            onModalClose={() => onModalClose(CODE_FORM_MODAL_NAME)}
            successAction={() => getCodesList()}
          />
        </Modal>
      }
      {
        <Modal name={EDIT_CODE_FORM_MODAL_NAME} title="Edit Code" onModalClose={onModalClose}>
          <EditCodeForm
            creativeId={Number(creativeId!)}
            onModalClose={() => onModalClose(EDIT_CODE_FORM_MODAL_NAME)}
            editCodeId={editCodeId}
            successAction={() => getCodesList()}
            currentCreativeType={currentCreativeType}
          />
        </Modal>
      }
      {
        <Popup
          name={REMOVE_CODE_MODAL_NAME}
          type={PopupType.warning}
          title="Delete Code"
          onModalClose={() => onModalClose(REMOVE_CODE_MODAL_NAME)}
          handleSubmit={handleSubmitRemove}
          submitText="Delete Code"
        >
          {messageForCodeRemoveModal(codeToRemove?.code)}
        </Popup>
      }
      {toastParams && <Toast open={showToast} params={toastParams} onClose={() => setShowToast(false)} />}
    </>
  );
};

export default CreativeDetails;
