import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import {
  stringMaxLength,
  textMaxLength,
  maxPercentageValue,
} from 'constants/fieldsLimits';

const maxDurationValue = 999;
const maxPositionValue = 999;

export const podcastAircheckValidationSchema = Yup.object({
  startDate: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  endDate: Yup.string()
    .optional()
    .nullable()
    .test(
      'not earlier than the start date',
      VALIDATION_MESSAGES.DATE,
      (value, context) => {
        if (value && context.parent.startDate) {
          return value >= context.parent.startDate;
        }
        return true;
      }
    ),
  score: Yup.string()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  placement: Yup.number()
    .optional()
    .max(maxPercentageValue, VALIDATION_MESSAGES.MAX_NUMBER(maxPercentageValue)),
  duration: Yup.number()
    .optional()
    .max(maxDurationValue, VALIDATION_MESSAGES.MAX_NUMBER(maxDurationValue)),
  position: Yup.number()
    .optional()
    .max(maxPositionValue, VALIDATION_MESSAGES.MAX_NUMBER(maxPositionValue)),
  comment: Yup.string()
    .optional()
    .max(textMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)),
});
