import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { buildAuthorizationHeader } from 'helpers/api';

import { ERRORS_PATHS } from 'constants/errors';
import { accessTokenKey } from 'constants/general';
import RESPONSE_CODES from 'constants/responseCodes';

export const setUpAuthInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = localStorage.getItem(accessTokenKey);

      if (token) {
        config.headers!.Authorization = buildAuthorizationHeader(JSON.parse(token));
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === RESPONSE_CODES.UNAUTHENTICATED) {
        localStorage.removeItem(accessTokenKey);
        window.location.href = ERRORS_PATHS[RESPONSE_CODES.UNAUTHENTICATED];
      }

      return Promise.reject(error);
    }
  );
};
