import useAuth from 'hooks/useAuth';

import { UserRole } from 'constants/enums/UserRole';

const Spreader = ({ smi, group }: { smi: React.ElementType; group: React.ElementType }) => {
  const { user } = useAuth();
  const Component = (user!.role === UserRole.group ? group : smi) as React.ElementType;

  return <Component />;
};

export default Spreader;
