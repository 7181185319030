import { CodeAPI } from 'api/codeAPI';
import { CreativeAPI } from 'api/creativeAPI';
import { FormatAPI } from 'api/formatAPI';
import { GroupAPI } from 'api/groupAPI';
import { OutletAPI } from 'api/outletAPI';
import { OrderAPI } from 'api/orderAPI';
import { ProductAPI } from 'api/productAPI';
import { ResponseAPI } from 'api/responseAPI';
import { SecondaryFormatAPI } from 'api/secondaryFormatAPI';
import { UserAPI } from 'api/userAPI';
import { GroupOutletAPI } from 'api/GroupScope/outletAPI';

import { getOptions } from 'helpers/options';

import { marketOptions } from 'constants/outletOptions';

import { ISelectOption } from 'interfaces/ISelectOption';
import { GroupProductAPI } from 'api/GroupScope/productAPI';
import { GroupOrderAPI } from 'api/GroupScope/orderAPI';

export const getOutletOptions = async (value: string = '', type?: string, groupId?: number) =>
  await getOptions({
    source: OutletAPI.getByName(value, { type, group_id: groupId }),
  });

export const getGroupOutletOptions = async (value: string = '') =>
  await getOptions({
    source: GroupOutletAPI.getByName(value),
  });

export const getProductOptions = async (value: string = '') =>
  await getOptions({
    source: ProductAPI.getByName(value),
  });

export const getProductOptionsForOrder = async (value: string = '') =>
  await getOptions({
    source: ProductAPI.getByName(value, true),
  });

export const getGroupProductOptionsForOrder = async (value: string = '') =>
  await getOptions({
    source: GroupProductAPI.getByName(value, true),
  });

export const getCreativeOptions = async (value: string, productId?: number | '', isFitler?: boolean) =>
  await getOptions({
    source: CreativeAPI.getByName(value, productId || '', isFitler),
  });

export const getGroupOptions = async (value: string = '') =>
  await getOptions({
    source: GroupAPI.getByName(value),
    keyAttr: 'company_name',
  });

export const getFormatOptions = async (value?: string) => {
  const result = await getOptions({
    source: FormatAPI.getAll(),
  });

  if (value) {
    const lowercaseValue = value.toLocaleLowerCase();
    return Promise.resolve(
      result.filter((market: ISelectOption) => String(market.key).toLowerCase().includes(lowercaseValue))
    );
  }

  return result;
};

export const getMarketOptions = (value?: string) => {
  if (value) {
    const lowercaseValue = value.toLocaleLowerCase();
    return Promise.resolve(
      marketOptions.filter((market: ISelectOption) => String(market.key).toLowerCase().includes(lowercaseValue))
    );
  }

  return Promise.resolve(marketOptions);
};

export const getSecondaryFormatOptions = async () =>
  await getOptions({
    source: SecondaryFormatAPI.getAll(),
  });

export const getCodeOptions = async (value: string, creativeId?: number | '') =>
  await getOptions({
    source: CodeAPI.getByName(value, creativeId || ''),
    keyAttr: 'code',
  });

export const getEstimateOptions = async (value: string = '') =>
  await getOptions({
    source: OrderAPI.getEstimates(value),
    keyAttr: 'estimate',
    valueAttr: 'estimate',
  });

export const getGroupEstimateOptions = async (value: string = '') =>
  await getOptions({
    source: GroupOrderAPI.getEstimates(value),
    keyAttr: 'estimate',
    valueAttr: 'estimate',
  });

export const getWeekStartOptions = async (value: string = '') =>
  await getOptions({
    source: OrderAPI.getWeekStarts(value),
    keyAttr: 'week_start',
    valueAttr: 'week_start',
  });

export const getGroupWeekStartOptions = async (value: string = '') =>
  await getOptions({
    source: GroupOrderAPI.getWeekStarts(value),
    keyAttr: 'week_start',
    valueAttr: 'week_start',
  });

export const getUserOptions = async (value: string = '', isFilter?: boolean) =>
  await getOptions({
    source: UserAPI.getByName(value, isFilter),
  });

export const getResponseFileNameOptions = async (value: string = '') =>
  await getOptions({
    source: ResponseAPI.getFilesByName(value),
    keyAttr: 'file_name',
  });

export const getResponseCodeOptions = async (value: string = '') =>
  await getOptions({
    source: ResponseAPI.getCodes(value),
  });
