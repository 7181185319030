import * as Yup from 'yup';
import { textMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import { spotStatuses } from 'constants/spots';

const minValue = 0;

export const legacyAircheckValidationSchema = (spotsNumber: number) => Yup.object({
  status: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  airedSpots: Yup.number().optional().typeError(VALIDATION_MESSAGES.NUMBER).when('status', {
    is: (val: string) => val !== spotStatuses.success,
    then: (scheme: any) => scheme.required(VALIDATION_MESSAGES.REQUIRED)
                                 .min(minValue, VALIDATION_MESSAGES.POSITIVE)
                                 .max(spotsNumber, 'Must be less than or equal to the number of ordered spots'),
    otherwise: (scheme: any) => scheme,
  }),
  comment: Yup.string()
    .optional()
    .max(textMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)),
});
