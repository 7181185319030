import { useContext } from 'react';

import { LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import ReviseTimesForm from 'components/forms/spots/ReviseTimesForm';

import { ModalContext } from 'context/ModalContext';

const REVISE_TIMES_FORM_MODAL_NAME = 'REVISE_TIMES_FORM_MODAL';

const ReviseTimes = () => {
  const { onModalClose, onModalOpen } = useContext(ModalContext);

  const onReviseTimesClick = () => {
    onModalOpen(REVISE_TIMES_FORM_MODAL_NAME);
  };

  return (
    <>
      <LinkButton onClick={onReviseTimesClick}>Revise Times</LinkButton>
      <Modal name={REVISE_TIMES_FORM_MODAL_NAME} title="Revise Times" onModalClose={onModalClose}>
        <ReviseTimesForm />
      </Modal>
    </>
  );
};

export default ReviseTimes;
