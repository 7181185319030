import { useContext } from 'react';

import { CircularProgress } from '@mui/material';

import { SpotTypeContext } from 'context/SpotTypeContext';

import { moneyDisplayView } from 'helpers/money';
import { isPodcastSpot } from 'helpers/spotsHelper';

import { cellStyles } from 'constants/spots';

import { IListTotals } from 'interfaces/spots/IListTotals';

import { FooterRow, FooterCell } from './styledComponents';

interface Props {
  totals?: IListTotals;
}

const hardcodedWidthLegacy = { minWidth: '141px', width: '20%' };
const hardcodedWidthPodcast = { minWidth: '141px', width: '16%' };
const hardcodedLegacyAmountsWidth = '13%';
const hardcodedPodcastAmountsWidth = '11%';

// TODO: automate widths calculations depending on cells widths
const Footer = ({ totals }: Props) => {
  const type = useContext(SpotTypeContext);
  const isPodcast = isPodcastSpot(type);
  const loader = <CircularProgress color="inherit" size={14} />;

  const amountsWith = isPodcast ? hardcodedPodcastAmountsWidth : hardcodedLegacyAmountsWidth;
  return (
    <FooterRow>
      <FooterCell style={cellStyles[type].checkbox} />
      <FooterCell style={cellStyles[type].outlet}>TOTALS:</FooterCell>
      <FooterCell
        style={{
          ...(isPodcast ? hardcodedWidthPodcast : hardcodedWidthLegacy),
          paddingLeft: '16px',
        }}
      >
        {isPodcast ? '' : totals ? totals.totalAiredSpots : loader}
      </FooterCell>
      {isPodcast ? (
        <>
          <FooterCell style={cellStyles.Podcast.testShow} />
          <FooterCell style={cellStyles.Podcast.pixelRequired} />
        </>
      ) : (
        <FooterCell style={cellStyles.Legacy.code} />
      )}
      <FooterCell style={{ width: amountsWith, textAlign: 'right', paddingRight: '16px', paddingLeft: '5px' }}>
        {totals ? moneyDisplayView(totals.totalGross) : loader}
      </FooterCell>
      <FooterCell style={{ width: amountsWith, textAlign: 'right', paddingRight: '16px', paddingLeft: '5px' }}>
        {totals ? moneyDisplayView(totals.totalNet) : loader}
      </FooterCell>
      <FooterCell style={cellStyles[type].actions} />
    </FooterRow>
  );
};

export default Footer;
