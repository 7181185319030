import { StyledButtonsBlock } from './styledComponents';

interface ButtonsBlockProps {
  children: React.ReactNode;
}

const ButtonsBlock = ({ children }: ButtonsBlockProps) => {
  return (
    <StyledButtonsBlock>
      {children}
    </StyledButtonsBlock>
  );
};

export default ButtonsBlock;
