import { ReactElement } from 'react';

import ErrorImage from 'assets/images/error.svg';

import { Container, ErrorBlock, ErrorSubTitle, ErrorDetails, ErrorTitle, ImageBox } from './styledComponents';

const ErrorPage = ({
  title,
  subtitle,
  details,
}: {
  title: string;
  subtitle?: string;
  details: string | ReactElement;
}) => {
  return (
    <Container>
      <ErrorBlock>
        <ErrorTitle>{title}</ErrorTitle>
        {subtitle && <ErrorSubTitle>{subtitle}</ErrorSubTitle>}
        <ErrorDetails>{details}</ErrorDetails>
        <ImageBox>
          <img src={ErrorImage} alt="SMI Logo" />
        </ImageBox>
      </ErrorBlock>
    </Container>
  );
};

export default ErrorPage;
