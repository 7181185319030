import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

export const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 16px;

    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  };
`;
