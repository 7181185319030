/* eslint-disable no-magic-numbers */
export const BYTES_IN_KB = 1024;
export const accessTokenKey = 'accessToken';

export const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^ ]{8,64}$/;

export const IDLE_LOGOUT_TIME = 15 * 60 * 1000;

export const STRATEGIC_MEDIA_CHANNEL = 'STRATEGIC_MEDIA_CHANNEL';
export const TABS_MESSAGES = {
  LOGOUT: 'LOGOUT',
};

export const GLOBAL_POPUP_NAME = 'GLOBAL_POPUP';
