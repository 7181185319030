import { PaymentAPI } from 'api/paymentAPI';

import { CustomIcon, OverflowTip } from 'components/UIComponents';
import InlineEdit from 'components/base/InlineEdit';

import { colors } from 'styles/globalStyles';

import { StyledIconContainer } from './styledComponents';

interface Props {
  id: number;
  check: string;
  status: string;
}

const CheckCell = ({ id, check, status }: Props) => {
  return (
    <InlineEdit
      initialValue={check}
      update={(value: string) => PaymentAPI.updateCheck(id, value)}
      displayView={(value: string) =>
        status === 'Cleared' && !value ? (
          <StyledIconContainer>
            <CustomIcon name="information" size="normal" color={colors.orange} />
          </StyledIconContainer>
        ) : (
          <OverflowTip title={value}>{value}</OverflowTip>
        )
      }
    />
  );
};

export default CheckCell;
