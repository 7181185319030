import React from 'react';
import { TableBody } from '@mui/material';
import { formatDate } from 'helpers/dateHelper';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
} from 'components/base/BaseTable';
import { ICommonRecordInfo } from 'interfaces/ICommonRecordInfo';

const OtherRecordInfo = ({ info }: { info: ICommonRecordInfo }) => {
  return (
    <FlexChild>
      <InfoBlockTitle>Other</InfoBlockTitle>
      <InformationTable>
        <TableBody>
          <TableRow>
            <HeadCell>ID</HeadCell>
            <BodyCell>{info.id}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Created by</HeadCell>
            <BodyCell>{info.created_by}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Created when</HeadCell>
            <BodyCell>{formatDate(info.created_at)}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Updated by</HeadCell>
            <BodyCell>{info.updated_by}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Updated when</HeadCell>
            <BodyCell>{formatDate(info.updated_at)}</BodyCell>
          </TableRow>
        </TableBody>
      </InformationTable>
    </FlexChild>
  );
};

export default OtherRecordInfo;
