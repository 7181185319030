import { useEffect, useState } from 'react';
import { OutlinedButton, Input, Select, SubmitButton, TextArea } from 'components/UIComponents';
import {
  FieldGroupTitleContainer,
  shortFieldStyles,
} from 'components/UIComponents/layout/styledFormComponents';
import { Form, Formik } from 'formik';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import { FormEvent } from 'react';
import { IGroupForm } from 'interfaces/Group/IGroupForm';
import RESPONSE_CODES from 'constants/responseCodes';
import { groupValidationSchema } from 'helpers/validation/groupValidationSchema';
import { GroupAPI } from 'api/groupAPI';


interface GroupFormProps {
  onModalClose: () => void,
  successAction: () => void,
  editGroupId: number,
}

const GroupForm = ({ onModalClose, successAction, editGroupId }: GroupFormProps) => {

  const [groupFormInformation, setGroupFormInformation] = useState<IGroupForm>({
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    terms: 'PrePaid',
    orderPreferences: 'PDF',
    comment: '',
  });

  const countryOptions = [{ key: 'US', value: 'US' }, { key: 'CA', value: 'CA' }];

  const orderPreferencesOptions = [
    { key: 'PDF', value: 'PDF' },
    { key: 'XLSX By Day', value: 'XLSX By Day' },
    { key: 'XLSX By Week', value: 'XLSX By Week' },
  ];

  const termsOptions = [
    { key: 'PrePaid', value: 'PrePaid' },
    { key: 'Net 30', value: 'Net 30' },
    { key: 'Net 45', value: 'Net 45' },
    { key: 'Net 60', value: 'Net 60' },
    { key: 'Net 90', value: 'Net 90' },
  ];

  useEffect(() => {
    if (editGroupId) {
      GroupAPI.getOne(editGroupId).then(({ data }) => {
        setGroupFormInformation({
          companyName: data.company_name,
          address1: data.address1 || '',
          address2: data.address2 || '',
          city: data.city || '',
          state: data.state || '',
          zip: data.zip || '',
          country: data.country || '',
          terms: data.terms,
          orderPreferences: data.order_preferences,
          comment: data.comment || '',
        });
      });
    }
  }, [editGroupId]);

  return (
    <>
      <Formik
        initialValues={groupFormInformation}
        enableReinitialize={true}
        validationSchema={groupValidationSchema}
        validateOnBlur
        onSubmit={async (values, { setFieldError }) => {
          let response;
          if (editGroupId) {
            response = await GroupAPI.updateGroup(editGroupId, values);
          } else {
            response = await GroupAPI.createGroup(values);
          }
          // TODO: make this error check more universal
          if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
            JSON.parse(response.data.error.details).company_name[0] === 'has already been taken') {
            setFieldError('companyName', 'This company name already exists');
          } else {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>
                BILLING INFO
              </FieldGroupTitleContainer>
              <Input name="companyName" label="Company Name" required />
              <Input name="address1" label="Address 1" />
              <Input name="address2" label="Address 2" />
              <Input name="city" label="City" />
              <Input name="state" label="State" />
              <Input name="zip" label="ZIP Code" styles={shortFieldStyles} />
              <Select name="country" label="Country" options={countryOptions} />
              <FieldGroupTitleContainer>
                ACCOUNTING INFO
              </FieldGroupTitleContainer>
              <Select name="terms" label="Terms" options={termsOptions} required />
              <Select name="orderPreferences" label="Order Preferences" options={orderPreferencesOptions} required />
              <FieldGroupTitleContainer>
                COMMENT
              </FieldGroupTitleContainer>
              <TextArea name="comment" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose} >
                Cancel
              </OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                {editGroupId ? 'Save' : 'Add'}
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GroupForm;
