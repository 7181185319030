import { SpotType } from 'constants/enums/SpotTypes';

const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

const dimension = {
  [SpotType.legacy]: {
    dates: '38%',
    time: '22%',
    spotsNumber: '10%',
    spend: '17.5%',
    spotLength: '12.5%',
  },
  [SpotType.podcast]: {
    dates: '38%',
    spotsNumber: '14%',
    spend: '24%',
    spotLength: '24%',
  },
};

const commonColumns = (type: SpotType) => [
  {
    text: 'Dates',
    dataField: 'dates',
    style: cellStyles as React.CSSProperties,
    headStyle: { width: dimension[type].dates },
  },
  {
    text: 'Spots',
    dataField: 'spotsNumber',
    headStyle: { width: dimension[type].spotsNumber },
  },
  {
    text: 'Spend',
    dataField: 'spend',
    headStyle: { width: dimension[type].spend },
  },
  {
    text: 'Spot Length',
    dataField: 'spotLength',
    headStyle: { width: dimension[type].spotLength },
  },
];

const timeColumn = {
  text: 'Time',
  dataField: 'time',
  style: cellStyles as React.CSSProperties,
  headStyle: { width: dimension[SpotType.legacy].time },
};

export const getColumns = (spotType: SpotType) => {
  if (spotType === SpotType.podcast) {
    return commonColumns(SpotType.podcast);
  }

  const legacyColumns = commonColumns(SpotType.legacy);
  return [...legacyColumns.slice(0, 1), timeColumn, ...legacyColumns.slice(1)];
};

