import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const RulesBlock = styled.div`
  color: ${colors.black};
  font-family: ${fontFamily};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.382px;
  letter-spacing: -0.32px;

  margin-top: 14px;
`;

export const ConfirmMatchError = styled.div`
  margin-top: -10px;
`;
