import { StyledPageTitle } from './styledComponents';

interface Props {
  children: React.ReactNode;
}

const PageTitle = ({ children }: Props) => {
  return <StyledPageTitle>{children}</StyledPageTitle>;
};

export default PageTitle;
