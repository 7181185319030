import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumbs } from './styledBreadcrumbs';

interface Props {
  children: React.ReactNode;
}

const Breadcrumbs = ({ children }: Props) => {
  return (
    <StyledBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {children}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
