import { IFilterParams } from 'interfaces/IFilterParams';

import { IOutletForm } from '../interfaces/Outlet/IOutletForm';
import { request, screenRequest } from './instance';

export const OutletAPI = {
  getList: async (params: IFilterParams) => {
    const response = await request({
      url: 'v1/outlets',
      method: 'GET',
      params,
    });

    return response.data;
  },

  getDetails: async (outletId: number) => {
    const response = await screenRequest({
      url: `v1/outlets/${outletId}`,
      method: 'GET',
    });

    return response.data;
  },

  getOne: async (outletId: number) => {
    const response = await request({
      url: `v1/outlets/${outletId}`,
      method: 'GET',
    });

    return response.data;
  },

  delete: async (outletId: number) => {
    try {
      const response = await request({
        url: `v1/outlets/${outletId}`,
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getByName: async (value: string, details: { type?: string; group_id?: number } = {}) => {
    const response = await request({
      url: 'v1/outlets/search',
      method: 'GET',
      params: { value, ...details },
    });

    return response.data.data;
  },

  create: async ({
    name,
    groupId,
    type,
    band,
    adType,
    isDigitallyInserted,
    market,
    zip,
    formatId,
    secondaryFormatId,
    comment,
    pixel,
    read,
  }: IOutletForm) => {
    try {
      const response = await request({
        url: 'v1/outlets',
        method: 'post',
        data: formData({
          name,
          groupId,
          type,
          band,
          adType,
          isDigitallyInserted,
          market,
          zip,
          formatId,
          secondaryFormatId,
          comment,
          pixel,
          read,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  update: async (
    id: number,
    {
      name,
      groupId,
      type,
      band,
      adType,
      isDigitallyInserted,
      market,
      zip,
      formatId,
      secondaryFormatId,
      comment,
      pixel,
      read,
    }: IOutletForm
  ) => {
    try {
      const response = await request({
        url: `v1/outlets/${id}`,
        method: 'put',
        data: formData({
          name,
          groupId,
          type,
          band,
          adType,
          isDigitallyInserted,
          market,
          zip,
          formatId,
          secondaryFormatId,
          comment,
          pixel,
          read,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchOutletActivation: async (outletId: number, activate: boolean) => {
    try {
      const response = await request({
        url: `v1/outlets/${outletId}/switch_activation`,
        method: 'patch',
        data: {
          data: {
            outlet: {
              activate,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};

const formData = ({
  name,
  groupId,
  type,
  band,
  adType,
  isDigitallyInserted,
  read,
  market,
  zip,
  formatId,
  secondaryFormatId,
  comment,
  pixel,
}: IOutletForm) => ({
  data: {
    outlet: {
      name,
      group_id: groupId,
      type,
      band,
      ad_type: adType,
      is_digitally_inserted: isDigitallyInserted,
      market,
      zip,
      format_id: formatId,
      secondary_format_id: secondaryFormatId,
      comment,
      pixel,
      read,
    },
  },
});
