import { FormEvent } from 'react';
import { Form, Formik } from 'formik';
import {
  OutlinedButton,
  SubmitButton,
  TagsInput,
} from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import { ICodeAddForm } from 'interfaces/Code/ICodeAddForm';
import RESPONSE_CODES from 'constants/responseCodes';
import { validationCreateCode } from 'helpers/validation/codeValidationSchema';
import { CodeAPI } from 'api/codeAPI';
import { keywordFieldStyles } from './styledComponents';

interface CreateCodeFormProps {
  creativeId: number;
  onModalClose: () => void;
  successAction: () => void;
}

const CreateCodeForm = ({
  creativeId,
  onModalClose,
  successAction,
}: CreateCodeFormProps) => {
  const initialValues: ICodeAddForm = {
    keywords: [],
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationCreateCode}
        validateOnBlur
        onSubmit={async (values, { setFieldError }) => {
          const response = await CodeAPI.createCode(creativeId, values);
          if (
            response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
            response.data.error.title === 'Duplication found.'
          ) {
            setFieldError('keywords', response.data.error.details);
          }

          if (response?.status === RESPONSE_CODES.OK) {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <TagsInput
                name="keywords"
                label="Keyword"
                styles={keywordFieldStyles}
                placeholder="Specify keywords"
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose}>
                Cancel
              </OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) =>
                  handleSubmit(values)
                }
              >
                Add
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateCodeForm;
