import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton, TableBody, Typography } from '@mui/material';

import { GroupAPI } from 'api/groupAPI';
import { GroupContactAPI } from 'api/groupContactAPI';

import { CustomIcon } from 'components/UIComponents';
import { OutlinedButton } from 'components/UIComponents';
import { StatusIndicator } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
  InformationBlocks,
  PageSubtitle,
  TableTitle,
  OtherRecordInfo,
} from 'components/base/BaseTable';
import Breadcrumbs from 'components/base/Breadcrumbs/Breadcrumbs';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import ContactForm from 'components/forms/ContactForm';
import GroupForm from 'components/forms/GroupForm';

import { ModalContext } from 'context/ModalContext';

import { IContactsTable } from 'interfaces/Contact/IContactsTable';
import { IGroupDetails } from 'interfaces/Group/IGroupDetails';
import { ICommonRecordInfo } from 'interfaces/ICommonRecordInfo';

import { colors } from 'styles/globalStyles';

import ListOfGroupContacts from './ListOfGroupContacts/ListOfGroupContacts';

const GROUP_FORM_MODAL_NAME = 'groupFormModal';
const CONTACT_FORM_MODAL_NAME = 'contactFormModal';

const GroupDetails = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { groupId } = useParams();
  const [currentGroup, setGroup] = useState<IGroupDetails | null>(null);
  const [editContactId, setEditContactId] = useState(0);
  const [contactsData, setContactsData] = useState<IContactsTable[]>([]);

  const onEditClick = () => {
    onModalOpen(GROUP_FORM_MODAL_NAME);
  };

  const onAddContactClick = () => {
    setEditContactId(0);
    onModalOpen(CONTACT_FORM_MODAL_NAME);
  };

  const onEditContactClick = (contactId: number) => {
    setEditContactId(contactId);
    onModalOpen(CONTACT_FORM_MODAL_NAME);
  };

  const getGroupDetails = (id: number) => {
    GroupAPI.getDetails(id).then(({ data }) => {
      setGroup(data);
    });
  };

  const getGroupContacts = (id: number) => {
    GroupContactAPI.getAll(id).then(({ data }) => {
      setContactsData(data);
    });
  };

  const switchGroupActivation = (id: number, activate: boolean) => {
    GroupAPI.switchGroupActivation(id, activate).then(({ data }) => {
      if (data === 'Successfully changed.' && currentGroup) {
        setGroup({ ...currentGroup, is_active: activate });
        setContactsData(contactsData.map((contact) => ({ ...contact, is_active: activate })));
      }
    });
  };

  useEffect(() => {
    getGroupDetails(Number(groupId!));
    getGroupContacts(Number(groupId!));
  }, [groupId]);

  if (!currentGroup) return null;

  const ActivateButtonText = currentGroup.is_active ? 'Deactivate' : 'Activate';

  return (
    <PageContainer>
      <Breadcrumbs>
        <Link to="/groups">All Groups</Link>
        <Typography>{currentGroup.company_name}</Typography>
      </Breadcrumbs>
      <PageTitle>
        {currentGroup.company_name}
        <StatusIndicator active={currentGroup.is_active} />
        <OutlinedButton
          sx={{ '&:last-of-type': { marginLeft: 'auto' } }}
          onClick={() => switchGroupActivation(currentGroup.id, !currentGroup.is_active)}
        >
          {ActivateButtonText}
        </OutlinedButton>
      </PageTitle>
      <PageSubtitle>
        Information
        <IconButton onClick={onEditClick}>
          <CustomIcon name="edit" color={colors.red} />
        </IconButton>
      </PageSubtitle>
      <InformationBlocks>
        <FlexChild>
          <InfoBlockTitle>Billing info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Company Name</HeadCell>
                <BodyCell>{currentGroup.company_name}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Address 1</HeadCell>
                <BodyCell>{currentGroup.address1}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Address 2</HeadCell>
                <BodyCell>{currentGroup.address2}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>City</HeadCell>
                <BodyCell>{currentGroup.city}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>State</HeadCell>
                <BodyCell>{currentGroup.state}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>ZIP Code</HeadCell>
                <BodyCell>{currentGroup.zip}</BodyCell>
              </TableRow>
              <TableRow>
                <HeadCell>Country</HeadCell>
                <BodyCell>{currentGroup.country}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <FlexChild>
          <InfoBlockTitle>Accounting info</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <HeadCell>Terms</HeadCell>
                <BodyCell>{currentGroup.terms}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
          <InfoBlockTitle>Comment</InfoBlockTitle>
          <InformationTable>
            <TableBody>
              <TableRow>
                <BodyCell>{currentGroup.comment}</BodyCell>
              </TableRow>
            </TableBody>
          </InformationTable>
        </FlexChild>
        <OtherRecordInfo info={currentGroup as ICommonRecordInfo} />
      </InformationBlocks>
      <TableTitle>
        Contacts
        <OutlinedButton onClick={onAddContactClick}>Add Contact</OutlinedButton>
      </TableTitle>
      <ListOfGroupContacts
        groupId={Number(groupId!)}
        editAction={onEditContactClick}
        contactsData={contactsData}
        getGroupContacts={getGroupContacts}
      />
      {
        <Modal name={GROUP_FORM_MODAL_NAME} title="Edit Group" onModalClose={onModalClose}>
          <GroupForm
            onModalClose={() => onModalClose(GROUP_FORM_MODAL_NAME)}
            successAction={() => getGroupDetails(Number(groupId!))}
            editGroupId={Number(groupId!)}
          />
        </Modal>
      }
      {
        <Modal
          name={CONTACT_FORM_MODAL_NAME}
          title={editContactId ? 'Edit Contact' : 'Add Contact'}
          onModalClose={onModalClose}
        >
          <ContactForm
            groupId={Number(groupId!)}
            onModalClose={() => onModalClose(CONTACT_FORM_MODAL_NAME)}
            editContactId={editContactId}
            successAction={() => getGroupContacts(Number(groupId!))}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default GroupDetails;
