import { useState } from 'react';

import SpotImportRecordsAPI from 'api/spotImportRecordsAPI';

import { FileUploadControl } from 'components/UIComponents';
import UploadButton from 'components/base/UploadButton';

const ImportSpotsForm = ({ type }: { type: string }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onProcessFile = (file: File) => {
    setLoading(true);
    const filename = file.name;
    SpotImportRecordsAPI.create({ file_name: filename, type }, file).then(() => {
      setLoading(false);
    });
  };

  return (
    <FileUploadControl
      inputProps={{
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'data-testid': 'import-spots-input',
      }}
      maxFileSize={10}
      ActionButton={({ onClick }: { onClick: () => void }) => (
        <UploadButton title="Upload spots" loading={loading} onClick={onClick} />
      )}
      onProcessFile={onProcessFile}
    />
  );
};

export default ImportSpotsForm;
