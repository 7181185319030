import { useField } from 'formik';

import { FormControl, Label } from 'components/UIComponents';

import { InputFieldContainer, StyledInput } from './styledComponents';

interface TextAreaProps {
  label?: string;
  name: string;
  disabled?: boolean;
  onChange?: any;
  styles?: {
    wrapper?: object;
    error?: object;
  };
}

const TextArea = ({ label = '', name = '', disabled = false, onChange, styles = {} }: TextAreaProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name);

  return (
    <FormControl styles={styles.wrapper}>
      {label && <Label>{label}</Label>}
      <InputFieldContainer>
        <StyledInput
          error={meta.touched && !!meta.error}
          {...field}
          value={field.value || ''}
          onChange={(e) => {
            onChange?.(e);
            field.onChange(e);
          }}
          inputProps={{ 'aria-label': label || name }}
          name={name}
          disabled={disabled}
          multiline={true}
          rows={3}
          onBlur={() => {
            setTouched(true); // To validate/show errors for field after trimming
            setValue(field.value.trim());
          }}
        />
        <Label style={styles.error} error>
          {meta.touched && meta.error}
        </Label>
      </InputFieldContainer>
    </FormControl>
  );
};

export default TextArea;
