import { Snackbar, styled } from '@mui/material';

import { colors } from 'styles/globalStyles';
import theme from 'styles/theme';

export default styled(Snackbar)(() => ({
  '&.MuiSnackbar-root': {
    top: '75px',
  },
  '& .MuiSnackbarContent-root': {
    backgroundColor: colors.darkBlue,
    paddingRight: '22px',
    paddingLeft: '30px',
  },
  '& .MuiSnackbarContent-message': {
    ...theme.typography.body1,
    color: colors.white,
  },
}));
