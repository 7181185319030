import { useContext } from 'react';

import { IconButton } from '@mui/material';

import { CustomIcon } from 'components/UIComponents';
import ActionsMenu from 'components/base/tableComponents/ActionsMenu';

import { SpotActionsContext } from 'context/SpotActionsContext';
import { SpotTypeContext } from 'context/SpotTypeContext';

import { getDate } from 'helpers/dateHelper';
import { isPodcastSpot } from 'helpers/spotsHelper';

import { SpotType } from 'constants/enums/SpotTypes';
import {
  statusesForCancelAction,
  statusesForDisabledCancel,
  spotStatuses,
  statusesForErrorAction,
  statusesForDisabledErrorAction,
  statusesForDNRAction,
  statusesForDisabledDNRAction,
  cellStyles,
} from 'constants/spots';

import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import { colors } from 'styles/globalStyles';

import ActivityStatus from '../ActivityStatus';
import Cell from '../Cell';
import EmailButton from '../EmailButton';
import SpotStatusLabel from '../SpotStatusLabel';
import OrderTooltip from './OrderTooltip';
import { CellContent, ActivityStatusesHeader } from './styledComponents';

const aircheckIconColor = (spot: IPodcastSpotsTable | ILegacySpotsTable, type: string) => {
  if (isPodcastSpot(type) && (spot as IPodcastSpotsTable).aircheck_is_flagged) {
    return colors.red;
  }

  if (spot.status === spotStatuses.canceled) {
    return colors.borderGrey;
  }

  if (spot.aircheck_id) {
    return colors.green;
  }

  return colors.black;
};

interface Props {
  spot: IPodcastSpotsTable | ILegacySpotsTable;
  editAction: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
}

const ActionsCell = ({ spot, editAction }: Props) => {
  const type = useContext(SpotTypeContext);
  const { onAircheckClick, onCommentClick, onChangeStatusClick, onDeleteClick } = useContext(SpotActionsContext);

  const isPaid = !!spot.payment_id;

  const cancelAction = (spot: IPodcastSpotsTable | ILegacySpotsTable) => {
    if (statusesForCancelAction.includes(spot.status)) {
      return {
        name: 'Cancel',
        action: () => onChangeStatusClick(spot, spotStatuses.canceled),
        disabled: statusesForDisabledCancel.includes(spot.status) || isPaid,
      };
    } else {
      return {
        name: 'Uncancel',
        action: () => onChangeStatusClick(spot, spotStatuses.noStatus),
      };
    }
  };

  const errorAction = (spot: IPodcastSpotsTable) => {
    if (statusesForErrorAction.includes(spot.status)) {
      return {
        name: 'Mark as Error',
        action: () => onChangeStatusClick(spot, spotStatuses.error),
        disabled: statusesForDisabledErrorAction.includes(spot.status) || isPaid,
      };
    } else {
      return { name: 'Unmark as Error', action: () => onChangeStatusClick(spot, spotStatuses.noStatus) };
    }
  };

  const dnrAction = (spot: IPodcastSpotsTable) => {
    if (statusesForDNRAction.includes(spot.status)) {
      return {
        name: 'Mark as DNR',
        action: () => onChangeStatusClick(spot, spotStatuses.dnr),
        disabled: statusesForDisabledDNRAction.includes(spot.status) || isPaid,
      };
    } else {
      return { name: 'Unmark as DNR', action: () => onChangeStatusClick(spot, spotStatuses.noStatus) };
    }
  };

  const deleteAction = { name: 'Delete', action: () => onDeleteClick(spot.id) };
  const spotActions: { [key: string]: any } = {
    [SpotType.legacy]: [cancelAction(spot), deleteAction],
    [SpotType.podcast]: [
      cancelAction(spot),
      dnrAction(spot as IPodcastSpotsTable),
      errorAction(spot as IPodcastSpotsTable),
      deleteAction,
    ],
  };

  const paymentTooltipText =
    `${spot.payment_created_at ? getDate(spot.payment_created_at) : ''}` +
    `${spot.payment_created_at && spot.payment_check ? ', ' : ''}` +
    `${spot.payment_check ? spot.payment_check : ''}`;

  return (
    <Cell
      cellProps={{ style: cellStyles[type].actions }}
      header={
        <ActivityStatusesHeader>
          <ActivityStatus
            title="Order"
            icon={spot.order_sent_at ? 'check' : 'time'}
            active={!!spot.order_id}
            activeColor={spot.order_sent_at ? colors.green : colors.yellow}
            tooltipText={
              spot.order_sent_at && <OrderTooltip sentAt={spot.order_sent_at} />
            }
          />
          <ActivityStatus
            title="Traffic"
            tooltipText={spot.traffic_sent_at_date}
            active={!!spot.traffic_sent_at_date}
          />
          <ActivityStatus title="Payment" tooltipText={paymentTooltipText} active={isPaid} />
        </ActivityStatusesHeader>
      }
      content={
        <CellContent>
          <SpotStatusLabel status={spot.status} />
          <EmailButton email={spot.contact_email} name={spot.contact_name} />
          <IconButton onClick={() => onCommentClick(spot)}>
            <CustomIcon name="message" size="normal" />
          </IconButton>
          <IconButton disabled={spot.status === spotStatuses.canceled} onClick={() => onAircheckClick(spot)}>
            <CustomIcon color={aircheckIconColor(spot, type)} name="aircheck" size="normal" />
          </IconButton>
          <IconButton data-testid={'edit-button'} disabled={isPaid} onClick={() => editAction(spot)}>
            <CustomIcon color={isPaid ? colors.disabledRed : colors.red} name="edit" />
          </IconButton>
          <ActionsMenu data={spot} actions={spotActions[type]} />
        </CellContent>
      }
    />
  );
};

export default ActionsCell;
