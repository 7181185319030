/* eslint-disable no-magic-numbers */
import { IFilterParams } from 'interfaces/IFilterParams';

import { ICustomerForm } from '../interfaces/Customer/ICustomerForm';
import { request, screenRequest } from './instance';

export const CustomerAPI = {
  getAllCustomers: async (params: IFilterParams) => {
    const response = await request({
      url: 'v1/customers',
      method: 'GET',
      params,
    });

    return response.data;
  },

  getDetails: async (customerId: number) => {
    const response = await screenRequest({
      url: `v1/customers/${customerId}`,
      method: 'GET',
    });

    return response.data;
  },

  getOne: async (customerId: number) => {
    const response = await request({
      url: `v1/customers/${customerId}`,
      method: 'GET',
    });

    return response.data;
  },

  delete: async (customerId: number) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}`,
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  createCustomer: async ({
    name,
    contactName,
    contactEmail,
    address,
    city,
    state,
    zip,
    country,
    phone,
    preferredContactMethod,
    customerFee,
    comment,
  }: ICustomerForm) => {
    try {
      const response = await request({
        url: 'v1/customers',
        method: 'POST',
        data: formData({
          name,
          contactName,
          contactEmail,
          address,
          city,
          state,
          zip,
          country,
          phone,
          preferredContactMethod,
          customerFee,
          comment,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateCustomer: async (
    id: number,
    {
      name,
      contactName,
      contactEmail,
      address,
      city,
      state,
      zip,
      country,
      phone,
      preferredContactMethod,
      customerFee,
      comment,
    }: ICustomerForm
  ) => {
    try {
      const response = await request({
        url: `v1/customers/${id}`,
        method: 'put',
        data: formData({
          name,
          contactName,
          contactEmail,
          address,
          city,
          state,
          zip,
          country,
          phone,
          preferredContactMethod,
          customerFee,
          comment,
        }),
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchCustomerActivation: async (customerId: number, activate: boolean) => {
    try {
      const response = await request({
        url: `v1/customers/${customerId}/switch_activation`,
        method: 'patch',
        data: {
          data: {
            customer: {
              activate,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};

const formData = ({
  name,
  contactName,
  contactEmail,
  address,
  city,
  state,
  zip,
  country,
  phone,
  preferredContactMethod,
  customerFee,
  comment,
}: ICustomerForm) => ({
  data: {
    customer: {
      name,
      contact_name: contactName,
      contact_email: contactEmail,
      address,
      city,
      state,
      zip,
      country,
      phone,
      preferred_contact_method: preferredContactMethod,
      customer_fee: (customerFee / 100).toFixed(3),
      comment,
    },
  },
});
