import { request } from '../instance';
import { IFilterParams } from 'interfaces/IFilterParams';

export const SpotImportRecordsAPI = {
  getList: async (params: IFilterParams) => {
    try {
      const response = await request({
        url: 'v1/spot_import_records',
        method: 'GET',
        params,
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  create: async (params: { file_name: string; type: string }, file: File) => {
    try {
      const response = await request({
        url: 'v1/spot_import_records',
        method: 'POST',
        headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        data: file,
        params,
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  removeImportRecord: async (importRecordId: number) => {
    try {
      const response = await request({
        url: `v1/spot_import_records/${importRecordId}`,
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },
};

export default SpotImportRecordsAPI;
