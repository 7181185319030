import { useState } from 'react';

import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, RadioField, TextArea } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { employeeValidationSchema } from 'helpers/validation/employeeValidationSchema';

import { UserRole } from 'constants/enums/UserRole';

import { IEmployeeForm } from 'interfaces/User/Employees/IEmployeeForm';

interface EmployeeFormProps {
  onClose: () => void;
  onSubmit: (values: IEmployeeForm) => Promise<any> | void;
  initialValues?: IEmployeeForm;
}

const EmployeeForm = ({
  onClose,
  onSubmit,
  initialValues = {
    name: '',
    email: '',
    comment: undefined,
    phone: undefined,
    cell: undefined,
    role: UserRole.employee,
  },
}: EmployeeFormProps) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const radioOptions = [
    { key: 'Yes', value: UserRole.admin },
    { key: 'No', value: UserRole.employee },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={employeeValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          setDisabled(true);
          await onSubmit(values);
          setDisabled(false);
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>USER INFO</FieldGroupTitleContainer>
              <Input name="name" label="Name" required />
              <Input name="email" label="Email" required />
              <Input name="phone" label="Phone Number" placeholder="(XXX) XXX-XXXX" type="phone" />
              <Input name="cell" label="Cell Number" placeholder="(XXX) XXX-XXXX" type="phone" />
              <RadioField name="role" label="Admin" options={radioOptions} />
              <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
              <TextArea name="comment" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton disabled={!dirty || !!Object.values(errors).length || disabled} onClick={handleSubmit}>
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmployeeForm;
