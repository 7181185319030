import styled from '@emotion/styled';
import { colors } from 'styles/globalStyles';

export const StyledStatusIndicator = styled.div<{ active: boolean }>`
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 8px;

  background-color: ${props => props.active ? colors.green : colors.red};
  border-radius: 50%;
`;
