import { FormEvent, useState } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';
import { FieldArray, Form, Formik } from 'formik';

import { ButtonsBlock, LinkButton, OutlinedButton, SubmitButton, TextArea } from 'components/UIComponents';
import { CommentTitle } from 'components/UIComponents/layout/styledFormComponents';
import { ModalInfoBlock, ModalInfoBlockTitle } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { isPodcastSpot } from 'helpers/spotsHelper';
import { trafficValidationSchema } from 'helpers/validation/trafficValidationSchema';

import { SpotType } from 'constants/enums/SpotTypes';

import { ITrafficFormItem, ITrafficGroups } from 'interfaces/Traffic/ITrafficForm';

import ContactsInfo from './ContactsInfo';

interface Props {
  type: SpotType;
  onClose: () => void;
  onSuccess: (message: string) => void;
  data: ITrafficGroups;
}

const TrafficForm = ({ type, onClose, onSuccess, data }: Props) => {
  const initialValues = {
    trafficGroups: Object.keys(data).map((groupName) => ({
      ...data[groupName],
      name: groupName,
      comment: '',
    })),
  };

  const [isDisabled, setIsDisabled] = useState(
    initialValues.trafficGroups.every((info) => info.contact_emails.length === 0)
  );

  const onApplyCommentToAll = (
    values: { trafficGroups: ITrafficFormItem[] },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    const comment = values.trafficGroups[0].comment;
    const trafficGroups = values.trafficGroups.map((item) => ({ ...item, comment }));
    setFieldValue('trafficGroups', trafficGroups);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={trafficValidationSchema}
        enableReinitialize={true}
        validateOnBlur
        onSubmit={(values) => {
          setIsDisabled(false);

          const traffic = values.trafficGroups.map((item) => ({
            group_id: item.group_id,
            comment: item.comment,
            ids: item.spot_ids,
          }));

          const SpotApi = isPodcastSpot(type) ? PodcastSpotAPI : LegacySpotAPI;
          SpotApi.sendTraffic(traffic).then((response) => {
            onSuccess(response.data.data);
          });
        }}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <Form>
            <DialogContent>
              <FieldArray
                name="trafficGroups"
                render={() => {
                  return (
                    <>
                      {values.trafficGroups.map((trafficInfo, index) => (
                        <ModalInfoBlock key={index}>
                          <ModalInfoBlockTitle>{trafficInfo.name}</ModalInfoBlockTitle>
                          <ContactsInfo trafficInfo={trafficInfo} />
                          <CommentTitle>COMMENT</CommentTitle>
                          <TextArea name={`trafficGroups[${index}].comment`} />
                          {index === 0 && values.trafficGroups.length !== 1 && (
                            <LinkButton
                              disabled={!values.trafficGroups[0].comment}
                              onClick={() => onApplyCommentToAll(values, setFieldValue)}
                            >
                              Apply comment to all emails
                            </LinkButton>
                          )}
                        </ModalInfoBlock>
                      ))}
                    </>
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <ButtonsBlock>
                <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!!Object.values(errors).length || isDisabled}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  Send
                </SubmitButton>
              </ButtonsBlock>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TrafficForm;
