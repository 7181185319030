import { Link } from 'react-router-dom';

import ErrorPage from 'components/base/ErrorPage';

const Unauthenticated = () => {
  return (
    <ErrorPage
      title="401"
      details={
        <span>
          Access is allowed only for registered users.
          <br />
          Please{' '}
          <Link style={{ color: 'inherit ' }} to="/login">
            log in
          </Link>{' '}
          to access the page you requested
        </span>
      }
    />
  );
};

export default Unauthenticated;
