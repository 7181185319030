import { useState, useContext } from 'react';

import { Typography } from '@mui/material';

import { SpotImportRecordsAPI } from 'api/spotImportRecordsAPI';

import { OverflowTip } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import Breadcrumbs from 'components/base/Breadcrumbs/Breadcrumbs';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import Popup from 'components/base/Popup';
import Toast, { ToastParams } from 'components/base/Toast';
import ImportSpotsForm from 'components/forms/spots/ImportSpotsForm';

import { ModalContext } from 'context/ModalContext';

import useWebSocket from 'hooks/useWebSocket';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';
import { spotImportRecordsStatuses } from 'constants/spots';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IAPIError, IErrorResponseData } from 'interfaces/api';
import { ISpotImportRecordsTable } from 'interfaces/spots/ISpotImportRecordsTable';

import InformationCell from './InformationCell';
import ErrorsModal from './InformationCell/ErrorsModal';
import TrashCell from './TrashCell';
import { getExecutionTimeCell } from './getExecutionTimeCell';
import { StyledLink, cellStyles, StyledStatusLabel } from './styledComponents';

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    style: cellStyles,
    headStyle: { width: '5%' },
  },
  {
    dataField: 'name',
    text: 'File Name',
    style: cellStyles,
    headStyle: { width: '18%' },
  },
  {
    dataField: 'type',
    text: 'Spot Type',
    style: cellStyles,
    headStyle: { width: '8%' },
  },
  {
    dataField: 'spots',
    text: 'Spots',
    style: cellStyles,
    headStyle: { width: '5%' },
  },
  {
    dataField: 'initiatedAt',
    text: 'Initiated at',
    style: cellStyles,
    headStyle: { width: '17%' },
  },
  {
    dataField: 'executedAt',
    text: 'Executed at',
    style: cellStyles,
    headStyle: { width: '17%' },
  },
  {
    dataField: 'createdBy',
    text: 'Initiated by',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
  {
    dataField: 'status',
    text: 'Status',
    headStyle: { width: '10%' },
  },
  {
    dataField: 'information',
    text: '',
    headStyle: { width: '5%' },
  },
  {
    dataField: 'trash',
    text: '',
    headStyle: { width: '5%' },
  },
];

interface ListOfSpotImportRecordsProps {
  type: SpotType;
}

const NAVIGATION_HEIGHT = 41;
const REMOVE_IMPORT_RECORD_ERROR_MODAL_NAME = 'removeImportRecordErrorPopup';
const IMPORT_ERRORS_MODAL_NAME = 'importErrorsPopup';

const ListOfSpotImportRecords = ({ type }: ListOfSpotImportRecordsProps) => {
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const [creativesProvider, setCreativesProvider] = useState(
    () => (props: IFilterParams) => SpotImportRecordsAPI.getList(props)
  );
  const [removeError, setRemoveError] = useState<IAPIError & { type: PopupType }>();
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<ToastParams>();
  const [currentImportRecord, setCurrentImportRecord] = useState<ISpotImportRecordsTable>();

  useWebSocket('SpotImportRecordsChannel', (data: any, type: string) => {
    if (type === 'delete') {
      setToastParams({ type: ToastType.info, message: `${data.spots_number || ''} Spot records have been deleted` });
      setShowToast(true);
    } else if (type === 'error') {
      setRemoveError({ title: 'Error', details: data, type: PopupType.error });
      onModalOpen(REMOVE_IMPORT_RECORD_ERROR_MODAL_NAME);
      reloadList();
    }
  });

  const reloadList = () => {
    setCreativesProvider(() => (props: any) => SpotImportRecordsAPI.getList(props));
  };

  const onDeleteClick = async (id: any) => {
    const response = await SpotImportRecordsAPI.removeImportRecord(id);
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      setRemoveError({ ...(response.data as IErrorResponseData).error, type: PopupType.info });
      onModalOpen(REMOVE_IMPORT_RECORD_ERROR_MODAL_NAME);
    }
  };

  const onErrorsInfoClick = (item: any) => {
    setCurrentImportRecord(item);
    onModalOpen(IMPORT_ERRORS_MODAL_NAME);
  };

  const dataFormatter = (item: ISpotImportRecordsTable) => ({
    id: <OverflowTip title={String(item.id)}>{item.id}</OverflowTip>,
    name: <OverflowTip title={item.file_name}>{item.file_name}</OverflowTip>,
    type: <OverflowTip title={item.type}>{item.type}</OverflowTip>,
    spots:
      item.status === spotImportRecordsStatuses.error ? (
        ''
      ) : (
        <OverflowTip title={String(item.spots_number)}>{item.spots_number}</OverflowTip>
      ),
    initiatedAt: <OverflowTip title={item.initiated_at}>{item.initiated_at}</OverflowTip>,
    executedAt: getExecutionTimeCell({ status: item.status, executionTime: item.executed_at }),
    createdBy: <OverflowTip title={item.created_by}>{item.created_by}</OverflowTip>,
    status: <StyledStatusLabel status={item.status}>{item.status}</StyledStatusLabel>,
    information: <InformationCell onClick={() => onErrorsInfoClick(item)} status={item.status} />,
    trash: (
      <TrashCell
        createdDate={item.created_at}
        isDeleted={item.status === spotImportRecordsStatuses.deleted}
        onClick={() => onDeleteClick(item.id)}
      />
    ),
  });

  return (
    <PageContainer>
      <Breadcrumbs>
        <StyledLink to={`/spots/${type}`}>{`${type} Spots`}</StyledLink>
        <Typography>Spot Import Monitor</Typography>
      </Breadcrumbs>
      <PageTitle>Spot Import Monitor</PageTitle>
      <ListOfRecords
        tableProps={{
          name: 'spotImportRecords',
          columns: columns as ITableColumn[],
          rowsAsLinks: false,
          editAction: () => {},
          withActions: false,
          tableStyle: { display: 'table', tableLayout: 'fixed' },
        }}
        barActions={<ImportSpotsForm type={type} />}
        dataFormatter={dataFormatter}
        dataProvider={creativesProvider}
        webSocketChannel="SpotImportRecordsChannel"
        extraPageElementsHeight={NAVIGATION_HEIGHT}
      />
      {removeError && (
        <Popup
          name={REMOVE_IMPORT_RECORD_ERROR_MODAL_NAME}
          type={removeError.type}
          title={removeError.title}
          onModalClose={() => onModalClose(REMOVE_IMPORT_RECORD_ERROR_MODAL_NAME)}
        >
          {removeError.details}
        </Popup>
      )}
      {toastParams && <Toast open={showToast} params={toastParams} onClose={() => setShowToast(false)} />}
      {currentImportRecord && (
        <Modal name={IMPORT_ERRORS_MODAL_NAME} title="Errors" onModalClose={onModalClose}>
          <ErrorsModal errors={currentImportRecord.parsing_errors} />
        </Modal>
      )}
    </PageContainer>
  );
};

export default ListOfSpotImportRecords;
