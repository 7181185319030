import { useContext } from 'react';

import { LinkButton } from 'components/UIComponents';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ToastContext } from 'context/ToastContext';

import useRequestWithConfirmation from 'hooks/useRequestWithConfirmation';

import { ToastType } from 'constants/enums/PopupType';

import { IResponse } from 'interfaces/api';
import { OrderAPI } from 'api/orderAPI';

const BaseChangeStatusBulkAction = ({
  buttonText,
  status,
  apiMethod,
  handleSuccess,
}: {
  buttonText: string;
  status: string;
  apiMethod?: Function;
  handleSuccess?: () => void;
}) => {
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const { getSerializedData } = useContext(FilterContext);

  const defaultSuccessHandler = (response: IResponse) => {
    reloadList();
    onToastOpen('ORDER_BULK_ACTION_SUCCESS', response.data.data, ToastType.success);
  };

  const handleRequest = () => {
    const apiCall = apiMethod ? apiMethod : OrderAPI.bulkChangeStatus;
    return apiCall({ selectAll, excludedIds, ids }, getSerializedData?.(), status);
  };

  const { initiate } = useRequestWithConfirmation({
    apiCall: handleRequest,
    handleSuccess: handleSuccess || defaultSuccessHandler,
  });

  return <LinkButton onClick={initiate}>{buttonText}</LinkButton>;
};

export default BaseChangeStatusBulkAction;
