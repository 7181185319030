import { dateWithDayOfWeek, getUTCDate } from 'helpers/dateHelper';
import { keysToSnakeCase } from 'helpers/utils';

import { SpotType } from 'constants/enums/SpotTypes';

import { ISelectOption } from 'interfaces/ISelectOption';
import { ITrafficFormItem } from 'interfaces/Traffic/ITrafficForm';
import { IFilterOptions } from 'interfaces/spots/IFilterOptions';

export const datesRange = (
  spot: { start_date: string; end_date?: string | null | undefined },
  withDateOfWeek: boolean = true
) => {
  const dateFormat = withDateOfWeek ? dateWithDayOfWeek : getUTCDate;
  if (!spot.end_date) {
    return String(dateFormat(spot.start_date));
  }

  return `${dateFormat(spot.start_date)}—${dateFormat(spot.end_date)}`;
};

export const timeRange = (spot: { start_time: string; end_time: string }) => {
  return `${spot.start_time}—${spot.end_time}`.replaceAll(' ', '');
};

export const spotsPlayed = (airedSpots: number, spotsNumber: number, totals?: boolean, status?: string) => {
  let spots;
  if (airedSpots === 0 && status === 'No Status') {
    spots = String(spotsNumber);
  } else {
    spots = `${airedSpots}(${spotsNumber})`;
  }

  if (totals) {
    return spots;
  }

  return `${spots} spots`;
};

export const isPodcastSpot = (type: string) => {
  return type === SpotType.podcast;
};

const multiSelectValues = [
  'outlet_id',
  'market',
  'product_id',
  'format_id',
  'status',
  'podcast_aircheck_status',
  'buyer_id',
  'creative_id',
  'outlet_type',
];
export const prepareFilterData = (filterOptions: IFilterOptions, type: string) => {
  const options: { [key: string]: any } = keysToSnakeCase(filterOptions);

  multiSelectValues.forEach((value) => {
    if (options[value]) {
      options[value] = (options[value] as Array<ISelectOption>).map((option) => option.value);
    }
  });

  if (options.group_id) {
    options.group_id = filterOptions.groupId?.value;
  }

  if (isPodcastSpot(type)) {
    if (options.podcast_aircheck_status) {
      options.aircheck_status = options.podcast_aircheck_status;
      delete options.podcast_aircheck_status;
    }
  } else {
    if (options.legacy_aircheck_status) {
      options.aircheck_status = options.legacy_aircheck_status;
      delete options.legacy_aircheck_status;
    }
  }

  return options;
};

export const trafficContactsInfo = (info: ITrafficFormItem) => {
  return info.contact_emails.map((email, index) => contactInfo(info.contact_names[index], email));
};

export const contactInfo = (name: string | null, email: string) => {
  if (name) {
    return `${name}, ${email}`;
  }

  return email;
};

export const spotsComparator = (
  spot1: { id: number; type: SpotType } | undefined,
  spot2: { id: number; type: SpotType }
) => {
  if (!spot1) {
    return false;
  }

  return spot1.id === spot2.id && spot1.type === spot2.type;
};
