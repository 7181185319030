import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderAPI } from 'api/orderAPI';

import { LinkButton, OutlinedButton } from 'components/UIComponents';
import { StyledDialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent } from 'components/base/BaseModal/styledDialogContent';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';

import { datesRange, timeRange } from 'helpers/spotsHelper';

import { SpotType } from 'constants/enums/SpotTypes';

import { ITableColumn } from 'interfaces/ITableColumn';
import { ILegacyInfoForOrder } from 'interfaces/spots/Legacy/ILegacyInfoForOrder';
import { IPodcastInfoForOrder } from 'interfaces/spots/Podcast/IPodcastInfoForOrder';

import { getColumns } from './columnsMap';
import { IOrderTable } from 'interfaces/Order/IOrderTable';

const tableContainerStyles = {
  overflowY: 'auto',
  maxHeight: '536px',
};

const datesRangeStyles = {
  letterSpacing: '-0.32px',
};

const SpotRecords = ({
  order,
  onClose,
  disableSpotLinks = false,
}: {
  order: IOrderTable;
  onClose: () => void;
  disableSpotLinks?: boolean;
}) => {
  const navigate = useNavigate();
  const [spots, setSpots] = useState<IPodcastInfoForOrder[] | ILegacyInfoForOrder[]>([]);
  const type = order.spots_type;
  const [columns] = useState<ITableColumn[]>(getColumns(type));

  const formatItem = (item: IPodcastInfoForOrder | ILegacyInfoForOrder) => ({
    id: item.id,
    dates: (
      disableSpotLinks ? <span style={datesRangeStyles}>{datesRange(item, true)}</span> :
      <LinkButton mode="dark" onClick={() => { navigate(`/spots/${type}?spotId=${item.id}`); }}>
        {datesRange(item, true)}
      </LinkButton>
    ),
    time: type === SpotType.legacy ? timeRange(item as ILegacyInfoForOrder) : '',
    spotsNumber: item.spots_number,
    spend: item.total_gross,
    spotLength: item.spot_length,
  });

  useEffect(() => {
    OrderAPI.getSpots(String(order.id)).then(
    ({ data }: { data: { spots: IPodcastInfoForOrder[] | ILegacyInfoForOrder[] } }) => {
      setSpots(data.spots);
    });
  }, [order.id]);

  return (
    <>
      <StyledDialogContent>
        <TableWithLazyLoad
          name="spotRecords"
          columns={columns}
          data={spots.map(formatItem)}
          rowsAsLinks={false}
          withActions={false}
          tableContainerStyles={tableContainerStyles}
          tableStyle={{ display: 'table', tableLayout: 'fixed' }}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <OutlinedButton onClick={onClose}>Close</OutlinedButton>
      </StyledDialogActions>
    </>
  );
};

export default SpotRecords;

