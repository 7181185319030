import { ActionsGroup } from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import DeclineAction from 'containers/Orders/shared/DeclineAction';
import BaseChangeStatusBulkAction from 'containers/Orders/shared/BaseChangeStatusBulkAction';

const ListOfOrdersActions = () => {
  return (
    <>
      <ActionsGroup>
        <BaseChangeStatusBulkAction buttonText="Accept" status='Accepted' />
        <DeclineAction />
      </ActionsGroup>
    </>
  );
};

export default ListOfOrdersActions;
