import { Typography } from '@mui/material';

import { colors } from 'styles/globalStyles';

import { Details } from '../Details';

const ErrorBlock = ({ title, details }: { title: string; details: string }) => {
  return (
    <div style={{ maxWidth: '227px', textAlign: 'center' }}>
      <Typography sx={{ textAlign: 'center' }} variant="h2">
        {title}
      </Typography>
      <Details color={colors.grey}>{details}</Details>
    </div>
  );
};

export default ErrorBlock;
