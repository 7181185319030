import React, { createContext, useState, useContext, useEffect } from 'react';

interface MP3StateProps {
  children: React.ReactNode;
}

export const MP3Context = createContext({
  playAudio: (url: string) => {},
  pauseAudio: () => {},
  isPlaying: false,
  isLoading: false,
  selectedAudioId: 0,
  setSelectedAudioId: (value: number) => {},
  setIsLoading: (value: boolean) => {},
});

export const useAudioContext = () => useContext(MP3Context);

export const MP3State = ({ children }: MP3StateProps) => {
  const [audio, setAudio] = useState(new Audio(''));
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAudioId, setSelectedAudioId] = useState(0);

  const playAudio = (url: string) => {
    const newSong = new Audio(url);
    setAudio(newSong);
    newSong.play();
    setIsPlaying(true);
  };

  const pauseAudio = () => {
    audio.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    audio.addEventListener('ended', () => setIsPlaying(false));
    audio.addEventListener('canplaythrough', () => setIsLoading(false));
    return () => {
      audio.pause();
      audio.removeEventListener('ended', () => setIsPlaying(false));
      audio.removeEventListener('canplaythrough', () => setIsLoading(false));
    };
  }, [audio]);

  return (
    <MP3Context.Provider
      value={{
        playAudio,
        pauseAudio,
        isPlaying,
        isLoading,
        selectedAudioId,
        setSelectedAudioId,
        setIsLoading,
      }}
    >
      {children}
    </MP3Context.Provider>
  );
};
