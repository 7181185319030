import { useState } from 'react';

import { UserAPI } from 'authApi/userAPI';
import { Form, Formik } from 'formik';

import { Checkbox, Input, SubmitButton } from 'components/UIComponents';
import { PasswordInput } from 'components/UIComponents';

import useAuth from 'hooks/useAuth';

import { getTokenFromHeader } from 'helpers/api';

import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

import { Error } from './styledComponents';

const LoginForm = ({ onLock }: { onLock: () => void }) => {
  const [disabled, setDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{ email: '', password: '', rememberMe: false }}
      onSubmit={(values) => {
        setDisabled(true);
        UserAPI.login(values).then((response: IResponse) => {
          setDisabled(false);
          if (response.status === RESPONSE_CODES.CREATED) {
            login(getTokenFromHeader(response.headers.authorization));
          } else if (response.data.error === 'Your account is locked.') {
            onLock();
          } else {
            setDisabled(false);
            setShowError(true);
          }
        });
      }}
    >
      {({ values, handleSubmit }) => (
        <Form style={{ width: '100%' }}>
          <Input direction="column" label="Email" name="email" placeholder="Enter your email" />
          <PasswordInput name="password" label="Password" />
          <Checkbox
            styles={{ wrapper: { marginTop: '-10px !important' } }}
            view="checkboxFirst"
            label="Remember me"
            name="rememberMe"
          />
          {showError && <Error>Wrong email and/or password</Error>}
          <SubmitButton
            type="submit"
            disabled={!values.email || !values.password || disabled}
            onClick={handleSubmit}
            sx={{ width: '100%', marginTop: '10px' }}
          >
            Log in
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
