import React from 'react';
import Highlighter from 'react-highlight-words';

import { LinkButton, OverflowTip } from 'components/UIComponents';

import { StyledBodyTableCell as BodyTableCell } from '../styledComponents';

interface Props {
  search?: string;
  content: any;
  style?: React.CSSProperties;
  customSearchMatchRule?: RegExp;
}

const SEARCHABLE_INNER_COMPONENTS = [OverflowTip.name, LinkButton.name];

const BodyTableCellWithData = ({ search = '', content, customSearchMatchRule, style }: Props) => {
  let cellContent = content;

  const highlighted = (content: string | number) => {
    const text = String(content);
    const searchWords = search === '' ? [] : [search];
    if (customSearchMatchRule && String(text).match(customSearchMatchRule)) {
      searchWords.push(text);
    }
    return (
      <Highlighter
        highlightClassName="highlighted"
        searchWords={searchWords}
        textToHighlight={String(text)}
        autoEscape
      />
    );
  };

  if (search) {
    if (['string', 'number'].includes(typeof content)) {
      cellContent = highlighted(content);
    }

    if (SEARCHABLE_INNER_COMPONENTS.includes(content?.type?.name)) {
      cellContent = content.type({
        ...content.props,
        children: content.props.children && highlighted(content.props.children),
      });
    }
  }

  return (
    <BodyTableCell align="right" sx={style}>
      {cellContent}
    </BodyTableCell>
  );
};

export default BodyTableCellWithData;
