import axios from 'axios';

import { SCREEN_KEY } from 'constants/errors';

const { REACT_APP_API_URL } = process.env;

interface REQUEST_OPTIONS {
  method: string;
  url: string;
  data?: object;
  params?: object;
  headers?: object;
}
export const request = async (options: REQUEST_OPTIONS, extra?: object) => {
  const { method, url, data = {}, params = {}, headers = {} } = options;
  return await axiosInstance({
    method,
    data,
    url,
    params,
    headers,
    ...extra,
  });
};

export const screenRequest = (options: REQUEST_OPTIONS) => {
  return request(options, { [SCREEN_KEY]: true });
};

export const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});
