import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

const COLORS: {
  [key: string]: {
    submitButton: string;
    submitButtonHover: string;
    cancelButtonHover: string;
    divider: string;
    border: string;
    background: string;
    text: string;
  };
} = {
  warning: {
    submitButton: '#dfa704',
    submitButtonHover: '#d39e02',
    cancelButtonHover: '#856404',
    divider: '#664d03',
    border: '#ffecb5',
    background: '#fff3cd',
    text: '#856404',
  },
  info: {
    submitButton: colors.infoToastTextColor,
    submitButtonHover: colors.infoToastTextColor,
    cancelButtonHover: colors.infoToastTextColor,
    divider: colors.infoToastTextColor,
    border: '#b8daff',
    background: '#ddeeff',
    text: colors.infoToastTextColor,
  },
  error: {
    submitButton: '#FFE7E9',
    submitButtonHover: '#BE0700',
    cancelButtonHover: '#DA3832',
    divider: '#EB4848',
    border: '#F5C6CB',
    background: '#FFE7E9',
    text: '#EB4848',
  },
};

export const StyledDialog = styled(Dialog)<{ type: string }>`
  .MuiDialog-paper {
    width: 400px;

    border: 1px solid;
    border-radius: 4px;

    background-color: ${(props) => COLORS[props.type].background};
    border-color: ${(props) => COLORS[props.type].border};
  }

  & .MuiDialogTitle-root {
    background-color: ${(props) => COLORS[props.type].background};
    color: ${(props) => COLORS[props.type].text};
  }

  & .MuiIconButton-root {
    color: ${(props) => COLORS[props.type].text};
  }

  & .MuiDialogContent-root {
    background-color: ${(props) => COLORS[props.type].background};
    border-color: ${(props) => COLORS[props.type].divider};
    color: ${(props) => COLORS[props.type].text};
  }

  & MuiDialogActions-root {
    background-color: ${(props) => COLORS[props.type].background};
    color: ${(props) => COLORS[props.type].text};
  },

 & .MuiButton-outlinedPrimary {
    border-color: ${(props) => COLORS[props.type].divider};
    color: ${(props) => COLORS[props.type].text};

    &:hover {
      border-color: ${(props) => COLORS[props.type].cancelButtonHover};
      background-color: ${(props) => COLORS[props.type].cancelButtonHover};
      color: ${colors.white};
    },
  },

 & .MuiButton-containedPrimary {
    border-color: ${(props) => COLORS[props.type].submitButton};
    background-color: ${(props) => COLORS[props.type].submitButton};

    &:hover {
      border-color: ${(props) => COLORS[props.type].submitButtonHover};
      background-color: ${(props) => COLORS[props.type].submitButtonHover};
      color: ${colors.white};
    },
  },
`;
