import { Link, StatusLabel, Tooltip } from 'components/UIComponents';

import { datesRange } from 'helpers/spotsHelper';

import { SpotType } from 'constants/enums/SpotTypes';
import { SELECTED_SPOT_ID_KEY } from 'constants/spots';

import { colors } from 'styles/globalStyles';

interface IResponseStatusProps {
  outlet?: string;
  spotId?: number;
  spotType?: SpotType;
  startDate?: string;
  endDate?: string;
}

const ResponseStatus = ({ outlet, spotId, spotType, startDate, endDate }: IResponseStatusProps) => {
  if (spotId) {
    return (
      <Link target="_blank" to={`/spots/${spotType?.toLowerCase()}?${SELECTED_SPOT_ID_KEY}=${spotId}`}>
        <Tooltip title={datesRange({ start_date: startDate!, end_date: endDate })}>
          <span>{outlet}</span>
        </Tooltip>
      </Link>
    );
  }

  return <StatusLabel color={colors.orange}>Exception</StatusLabel>;
};

export default ResponseStatus;
