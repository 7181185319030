import { Select as MuiSelect } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const StyledSelect = styled(MuiSelect)(({ placeholder }) => ({
  '&': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    border: '1px solid',
    borderColor: colors.borderGrey,
    padding: '6px 8px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    '&:focus': {
      borderColor: colors.focusBlue,
      borderRadius: '4px',
      backgroundColor: colors.white,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-select .notranslate::after': placeholder
    ? {
        content: `"${placeholder}"`,
        opacity: 0.42,
      }
    : {},
}));

export const InputFieldContainer = styled('div')({
  width: '100%',
});
