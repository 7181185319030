import { RadioGroup, Radio, FormControlLabel } from '@mui/material';

import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
`;

export const StyledRadio = styled(Radio)`
  padding: 0 4px 0 0;
  color: ${colors.radioButtonBorder};

  &.Mui-checked {
    padding: 0 4px 0 0;
  }
`;

export const RadioIcon = styled.span`
  border-radius: 50%;
  width: 14px;
  height: 14px;

  background-color: ${colors.white};
  border: 1px solid ${colors.radioButtonBorder};
`;

export const RadioCheckedIcon = styled.span`
  border-radius: 50%;
  width: 16px;
  height: 16px;

  background-color: ${colors.red};
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));

  .Mui-focusVisible & {
    outline: 2px auto rgba(19, 124, 189, 0.6);
    outline-offset: 2;
  }

  &:before {
    display: block;
    width: 16px;
    height: 16px;
    background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
    content: '';
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  width: 100%;
`;

export const radioFieldStyles = { alignItems: 'center !important', padding: '8px 0' };
