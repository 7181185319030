import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

const MIN_SEARCH_LENGTH = 3;
const DEBOUNCE_TIME = 700;

const useSearch = (handleSearch: (value: string, callback: () => void) => void) => {
  const [loading, setLoading] = useState(false);

  const hideLoadingCallback = () => {
    setLoading(false);
  };

  const handleNewSearchValue = useMemo(() => (newValue: string) => {
    if (newValue === '' || newValue.length >= MIN_SEARCH_LENGTH) {
      setLoading(true);
      handleSearch(newValue, hideLoadingCallback);
    }
  }, [handleSearch]);

  const debouncedSearch = useMemo(() => {
    return debounce(handleNewSearchValue, DEBOUNCE_TIME);
  }, [handleNewSearchValue]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return { loading, handleSearch: debouncedSearch };
};


export default useSearch;
