import { OrderAPI } from 'api/orderAPI';
import BaseListOfOrders from 'containers/Orders/shared/BaseListOfOrders';
import OrderFilterForm from 'components/forms/OrderFilterForm';
import ListOfOrdersActions from './ListOfOrdersActions';

const ListOfOrders = () => {
  return (
    <BaseListOfOrders
      apiCall={OrderAPI.getList}
      orderFilterForm={<OrderFilterForm />}
      bulkActions={<ListOfOrdersActions />}
    />
  );
};

export default ListOfOrders;
