import React from 'react';
import ReactDOM from 'react-dom/client';

import { ThemeProvider } from '@mui/material';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { GlobalPopupProvider } from 'context/GlobalPopupContext';
import { ToastState } from 'context/ToastContext';

import theme from 'styles/theme';

import App from './App';
import './assets/fonts/sf-pro-text-bold.ttf';
import './assets/fonts/sf-pro-text-medium.ttf';
import './assets/fonts/sf-pro-text-regular.ttf';
import './assets/fonts/sf-pro-text-semibold.ttf';
import { ModalState } from './context/ModalContext';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

Sentry.init({
  dsn: 'https://a87eb818988d467aa7e81ecee0ea6e42@sentry.sumatosoft.work/27',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ModalState>
        <ToastState>
          <GlobalPopupProvider>
            <App />
          </GlobalPopupProvider>
        </ToastState>
      </ModalState>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
