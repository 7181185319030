const mediaTypeOptions = [
  { key: 'Local', value: 'Local' },
  { key: 'Podcast', value: 'Podcast' },
  { key: 'Network', value: 'Network' },
  { key: 'Satellite', value: 'Satellite' },
  { key: 'Streaming', value: 'Streaming' },
  { key: 'Endorsed', value: 'Endorsed' },
  { key: 'Programmatic', value: 'Programmatic' },
  { key: 'Additional', value: 'Additional' },
];

const bandOptions = [
  { key: 'AM', value: 'AM' },
  { key: 'FM', value: 'FM' },
  { key: 'AM-FM', value: 'AM-FM' },
  { key: 'Podcast', value: 'Podcast' },
  { key: 'Streaming', value: 'Streaming' },
  { key: 'Satellite', value: 'Satellite' },
];

const typeOptions = [
  { key: 'Spot', value: 'Spot' },
  { key: 'Live', value: 'Live' },
];

const isDigitallyInsertedOptions = [
  { key: 'Baked In', value: 'Baked In' },
  { key: 'DAI', value: 'DAI' },
  { key: 'Embedded', value: 'Embedded' },
];

const readOptions = [
  { key: 'Host Read', value: 'Host Read' },
  { key: 'Producer Read', value: 'Producer Read' },
  { key: 'Pre Produced', value: 'Pre Produced' },
];

const marketOptions = [
	{ key: 'National', value: 'National' },
	{ key: 'Abilene, TX', value: 'Abilene, TX' },
	{ key: 'Akron', value: 'Akron' },
	{ key: 'Albany, GA', value: 'Albany, GA' },
	{ key: 'Albany-Schenectady-Troy', value: 'Albany-Schenectady-Troy' },
	{ key: 'Albuquerque', value: 'Albuquerque' },
	{ key: 'Alexandria', value: 'Alexandria' },
	{ key: 'Alpena', value: 'Alpena' },
	{ key: 'Allentown-Bethlehem', value: 'Allentown-Bethlehem' },
	{ key: 'Amarillo, TX', value: 'Amarillo, TX' },
	{ key: 'Anchorage', value: 'Anchorage' },
	{ key: 'Ann Arbor, MI', value: 'Ann Arbor, MI' },
	{ key: 'Appleton-Oshkosh', value: 'Appleton-Oshkosh' },
	{ key: 'Asheville', value: 'Asheville' },
	{ key: 'Atlanta', value: 'Atlanta' },
	{ key: 'Atlantic City-Cape May', value: 'Atlantic City-Cape May' },
	{ key: 'Augusta, GA', value: 'Augusta, GA' },
	{ key: 'Augusta-Waterville, ME', value: 'Augusta-Waterville, ME' },
	{ key: 'Austin', value: 'Austin' },
	{ key: 'Bakersfield', value: 'Bakersfield' },
	{ key: 'Baltimore', value: 'Baltimore' },
	{ key: 'Bangor', value: 'Bangor' },
	{ key: 'Barrie/Orillia, ON', value: 'Barrie/Orillia, ON' },
	{ key: 'Baton Rouge', value: 'Baton Rouge' },
	{ key: 'Beaumont-Port Arthur, TX', value: 'Beaumont-Port Arthur, TX' },
	{ key: 'Beckley, WV', value: 'Beckley, WV' },
	{ key: 'Billings', value: 'Billings' },
	{ key: 'Biloxi-Gulfport-Pascagoula', value: 'Biloxi-Gulfport-Pascagoula' },
	{ key: 'Binghamton', value: 'Binghamton' },
	{ key: 'Birmingham', value: 'Birmingham' },
	{ key: 'Bismarck, ND', value: 'Bismarck, ND' },
	{ key: 'Bloomington', value: 'Bloomington' },
	{ key: 'Boise', value: 'Boise' },
	{ key: 'Boston', value: 'Boston' },
	{ key: 'Bowling Green, KY', value: 'Bowling Green, KY' },
	{ key: 'Bozeman, MT', value: 'Bozeman, MT' },
	{ key: 'Brunswick, GA', value: 'Brunswick, GA' },
	{ key: 'Bridgeport, CT', value: 'Bridgeport, CT' },
	{ key: 'Bryan-College Station, TX', value: 'Bryan-College Station, TX' },
	{ key: 'Buffalo-Niagara Falls', value: 'Buffalo-Niagara Falls' },
	{ key: 'Burlington-Plattsburgh', value: 'Burlington-Plattsburgh' },
	{ key: 'Butte-Bozeman', value: 'Butte-Bozeman' },
	{ key: 'Calgary/Lethbridge, AB', value: 'Calgary/Lethbridge, AB' },
	{ key: 'Canton', value: 'Canton' },
	{ key: 'Cape Cod, MA', value: 'Cape Cod, MA' },
	{ key: 'Casper, WY', value: 'Casper, WY' },
	{ key: 'Cedar Rapids', value: 'Cedar Rapids' },
	{ key: 'Champaign, IL', value: 'Champaign, IL' },
	{ key: 'Charleston, SC', value: 'Charleston, SC' },
	{ key: 'Charleston, WV', value: 'Charleston, WV' },
	{ key: 'Charlotte-Gastonia-Rock Hill', value: 'Charlotte-Gastonia-Rock Hill' },
	{ key: 'Charlottesville, VA', value: 'Charlottesville, VA' },
	{ key: 'Chattanooga', value: 'Chattanooga' },
	{ key: 'Cheyenne, WY', value: 'Cheyenne, WY' },
	{ key: 'Chicago', value: 'Chicago' },
	{ key: 'Chico, CA', value: 'Chico, CA' },
	{ key: 'Cincinnati', value: 'Cincinnati' },
	{ key: 'Cleveland', value: 'Cleveland' },
	{ key: 'Colorado Springs', value: 'Colorado Springs' },
	{ key: 'Columbia, MO', value: 'Columbia, MO' },
	{ key: 'Columbia, SC', value: 'Columbia, SC' },
	{ key: 'Columbus, GA', value: 'Columbus, GA' },
	{ key: 'Columbus, OH', value: 'Columbus, OH' },
	{ key: 'Concord (Lakes Region)', value: 'Concord (Lakes Region)' },
	{ key: 'Corpus Christi', value: 'Corpus Christi' },
	{ key: 'Dallas-Ft. Worth', value: 'Dallas-Ft. Worth' },
	{ key: 'Danbury, CT', value: 'Danbury, CT' },
	{ key: 'Dayton', value: 'Dayton' },
	{ key: 'Daytona Beach', value: 'Daytona Beach' },
	{ key: 'Decatur', value: 'Decatur' },
	{ key: 'Denver-Boulder', value: 'Denver-Boulder' },
	{ key: 'Des Moines', value: 'Des Moines' },
	{ key: 'Detroit', value: 'Detroit' },
	{ key: 'Dothan, AL', value: 'Dothan, AL' },
	{ key: 'Duluth-Superior', value: 'Duluth-Superior' },
	{ key: 'Eau Claire, WI', value: 'Eau Claire, WI' },
	{ key: 'Edmonton, AB', value: 'Edmonton, AB' },
	{ key: 'El Paso', value: 'El Paso' },
	{ key: 'Elmira-Corning, NY', value: 'Elmira-Corning, NY' },
	{ key: 'Erie', value: 'Erie' },
	{ key: 'Eugene-Springfield', value: 'Eugene-Springfield' },
	{ key: 'Evansville', value: 'Evansville' },
	{ key: 'Eureka', value: 'Eureka' },
	{ key: 'Fairbanks', value: 'Fairbanks' },
	{ key: 'Fargo-Moorhead', value: 'Fargo-Moorhead' },
	{ key: 'Fayetteville (North West Arkansas)', value: 'Fayetteville (North West Arkansas)' },
	{ key: 'Fayetteville, NC', value: 'Fayetteville, NC' },
	{ key: 'Flagstaff-Prescott, AZ', value: 'Flagstaff-Prescott,  AZ' },
	{ key: 'Flint', value: 'Flint' },
	{ key: 'Florence, SC', value: 'Florence, SC' },
	{ key: 'Florence-Muscle Shoals, AL', value: 'Florence-Muscle Shoals, AL' },
	{ key: 'Frederick, MD', value: 'Frederick, MD' },
	{ key: 'Fredericksburg', value: 'Fredericksburg' },
	{ key: 'Fresno', value: 'Fresno' },
	{ key: 'Ft. Collins-Greeley, CO', value: 'Ft. Collins-Greeley, CO' },
	{ key: 'Ft. Myers-Naples', value: 'Ft. Myers-Naples' },
	{ key: 'Ft. Pierce-Stuart-Vero Beach', value: 'Ft. Pierce-Stuart-Vero Beach' },
	{ key: 'Ft. Smith, AR', value: 'Ft. Smith, AR' },
	{ key: 'Ft. Walton Beach-Destin, FL', value: 'Ft. Walton Beach-Destin, FL' },
	{ key: 'Ft. Wayne', value: 'Ft. Wayne' },
	{ key: 'Gainesville-Ocala', value: 'Gainesville-Ocala' },
	{ key: 'Glendive', value: 'Glendive' },
	{ key: 'Grand Forks, ND-MN', value: 'Grand Forks, ND-MN' },
	{ key: 'Grand Island-Kearney-Hastings, NE', value: 'Grand Island-Kearney-Hastings, NE' },
	{ key: 'Grand Junction, CO', value: 'Grand Junction, CO' },
	{ key: 'Grand Rapids', value: 'Grand Rapids' },
	{ key: 'Great Falls, MT', value: 'Great Falls, MT' },
	{ key: 'Green Bay', value: 'Green Bay' },
	{ key: 'Greensboro-Winston-Salem-High Point', value: 'Greensboro-Winston-Salem-High Point' },
	{ key: 'Greenville-New Bern-Jacksonville', value: 'Greenville-New Bern-Jacksonville' },
	{ key: 'Greenville-Spartanburg', value: 'Greenville-Spartanburg' },
	{ key: 'Hagerstown-Chambersburg-Waynesboro, MD-PA', value: 'Hagerstown-Chambersburg-Waynesboro, MD-PA' },
	{ key: 'Halifax, NS', value: 'Halifax, NS' },
	{ key: 'Harrisburg-Lebanon-Carlisle', value: 'Harrisburg-Lebanon-Carlisle' },
	{ key: 'Harrisonburg, VA', value: 'Harrisonburg, VA' },
	{ key: 'Hartford-New Britain-Middletown', value: 'Hartford-New Britain-Middletown' },
	{ key: 'Helena', value: 'Helena' },
	{ key: 'Honolulu', value: 'Honolulu' },
	{ key: 'Hot Springs, AR', value: 'Hot Springs, AR' },
	{ key: 'Houston-Galveston', value: 'Houston-Galveston' },
	{ key: 'Hudson Valley', value: 'Hudson Valley' },
	{ key: 'Huntington-Ashland', value: 'Huntington-Ashland' },
	{ key: 'Huntsville', value: 'Huntsville' },
	{ key: 'Idaho Falls-', value: 'Idaho Falls' },
	{ key: 'Indianapolis', value: 'Indianapolis' },
	{ key: 'Jackson, MS', value: 'Jackson, MS' },
	{ key: 'Jackson, TN', value: 'Jackson, TN' },
	{ key: 'Jacksonville', value: 'Jacksonville' },
	{ key: 'Johnson City-Kingsport-Bristol', value: 'Johnson City-Kingsport-Bristol' },
	{ key: 'Johnstown-Altoona', value: 'Johnstown-Altoona' },
	{ key: 'Jonesboro', value: 'Jonesboro' },
	{ key: 'Joplin, MO', value: 'Joplin, MO' },
	{ key: 'Juneau', value: 'Juneau' },
	{ key: 'Kalamazoo', value: 'Kalamazoo' },
	{ key: 'Kansas City', value: 'Kansas City' },
	{ key: 'Killeen-Temple, TX', value: 'Killeen-Temple, TX' },
	{ key: 'Kingston, ON', value: 'Kingston, ON' },
	{ key: 'Kitchener-Waterloo/Cambridge/Guelph, ON', value: 'Kitchener-Waterloo/Cambridge/Guelph, ON' },
	{ key: 'Knoxville', value: 'Knoxville' },
	{ key: 'La Crosse, WI', value: 'La Crosse, WI' },
	{ key: 'Lafayette IN', value: 'Lafayette IN' },
	{ key: 'Lafayette, LA', value: 'Lafayette, LA' },
	{ key: 'Lake Charles, LA', value: 'Lake Charles, LA' },
	{ key: 'Lakeland-Winter Haven', value: 'Lakeland-Winter Haven' },
	{ key: 'Lancaster', value: 'Lancaster' },
	{ key: 'Lansing-East Lansing', value: 'Lansing-East Lansing' },
	{ key: 'Laredo, TX', value: 'Laredo, TX' },
	{ key: 'Las Cruces-Deming, NM', value: 'Las Cruces-Deming, NM' },
	{ key: 'Las Vegas', value: 'Las Vegas' },
	{ key: 'Laurel-Hattiesburg, MS', value: 'Laurel-Hattiesburg, MS' },
	{ key: 'Lebanon-Hanover-White River Junction, NH-VT', value: 'Lebanon-Hanover-White River Junction, NH-VT' },
	{ key: 'Lexington-Fayette', value: 'Lexington-Fayette' },{ key: 'Lima, OH', value: 'Lima, OH' },
	{ key: 'Lincoln', value: 'Lincoln' },
	{ key: 'Little Rock', value: 'Little Rock' },
	{ key: 'London/Woodstock/Wingham, ON', value: 'London/Woodstock/Wingham, ON' },
	{ key: 'Los Angeles', value: 'Los Angeles' },
	{ key: 'Louisville', value: 'Louisville' },
	{ key: 'Lubbock', value: 'Lubbock' },
	{ key: 'Lufkin, TX', value: 'Lufkin, TX' },
	{ key: 'Macon', value: 'Macon' },
	{ key: 'Madison', value: 'Madison' },
	{ key: 'Manchester', value: 'Manchester' },
	{ key: 'Mankato-New Ulm-St. Peter, MN', value: 'Mankato-New Ulm-St. Peter, MN' },
	{ key: 'Marquette', value: 'Marquette' },
	{ key: 'Mcallen-Brownsville-Harlingen', value: 'Mcallen-Brownsville-Harlingen' },
	{ key: 'Medford-Ashland, OR', value: 'Medford-Ashland, OR' },
	{ key: 'Melbourne-Titusville-Cocoa', value: 'Melbourne-Titusville-Cocoa' },
	{ key: 'Memphis', value: 'Memphis' },
	{ key: 'Meridian, MS', value: 'Meridian, MS' },
	{ key: 'Miami-Ft. Lauderdale-Hollywood', value: 'Miami-Ft. Lauderdale-Hollywood' },
	{ key: 'Middlesex-Somerset-Union', value: 'Middlesex-Somerset-Union' },
	{ key: 'Milwaukee-Racine', value: 'Milwaukee-Racine' },
	{ key: 'Minneapolis-St. Paul', value: 'Minneapolis-St. Paul' },
	{ key: 'Missoula', value: 'Missoula' },
	{ key: 'Mobile', value: 'Mobile' },
	{ key: 'Modesto', value: 'Modesto' },
	{ key: 'Monmouth-Ocean', value: 'Monmouth-Ocean' },
	{ key: 'Monroe, LA', value: 'Monroe, LA' },
	{ key: 'Monterey-Salinas-Santa Cruz', value: 'Monterey-Salinas-Santa Cruz' },
	{ key: 'Montgomery', value: 'Montgomery' },
	{ key: 'Montpelier-Barre-St. Johnsbury', value: 'Montpelier-Barre-St. Johnsbury' },
	{ key: 'Montreal/Laval, QC', value: 'Montreal/Laval, QC' },
	{ key: 'Morgantown-Clarksburg-Fairmont, WV', value: 'Morgantown-Clarksburg-Fairmont, WV' },
	{ key: 'Morristown, NJ', value: 'Morristown, NJ' },
	{ key: 'Muncie-Marion, IN', value: 'Muncie-Marion, IN' },
	{ key: 'Muskegon, MI', value: 'Muskegon, MI' },
	{ key: 'Myrtle Beach, SC', value: 'Myrtle Beach, SC' },
	{ key: 'Nashville', value: 'Nashville' },
	{ key: 'Nassau-Suffolk (Long Island)', value: 'Nassau-Suffolk (Long Island)' },
	{ key: 'New Haven', value: 'New Haven' },
	{ key: 'New London, CT', value: 'New London, CT' },
	{ key: 'New Orleans', value: 'New Orleans' },
	{ key: 'New York', value: 'New York' },
	{ key: 'Newburgh-Middletown, NY', value: 'Newburgh-Middletown, NY' },
	{ key: 'Norfolk-Virginia Beach-Newport News', value: 'Norfolk-Virginia Beach-Newport News' },
	{ key: 'North Platte', value: 'North Platte' },
	{ key: 'Odessa-Midland, TX', value: 'Odessa-Midland, TX' },
	{ key: 'Oklahoma City', value: 'Oklahoma City' },
	{ key: 'Olean, NY', value: 'Olean, NY' },
	{ key: 'Omaha-Council Bluffs', value: 'Omaha-Council Bluffs' },
	{ key: 'Orlando', value: 'Orlando' },
	{ key: 'Ottawa, ON/Gatineau, QC', value: 'Ottawa, ON/Gatineau, QC' },
	{ key: 'Oxnard-Ventura', value: 'Oxnard-Ventura' },
	{ key: 'Paducah-Cape Girardeau', value: 'Paducah-Cape Girardeau' },
	{ key: 'Palm Springs', value: 'Palm Springs' },
	{ key: 'Panama City, FL', value: 'Panama City, FL' },
	{ key: 'Parkersburg-Marietta, WV-OH', value: 'Parkersburg-Marietta, WV-OH' },
	{ key: 'Pensacola', value: 'Pensacola' },
	{ key: 'Peoria', value: 'Peoria' },
	{ key: 'Philadelphia', value: 'Philadelphia' },
	{ key: 'Phoenix', value: 'Phoenix' },
	{ key: 'Pittsburgh, PA', value: 'Pittsburgh, PA' },
	{ key: 'Portland, ME', value: 'Portland, ME' },
	{ key: 'Portland, OR', value: 'Portland, OR' },
	{ key: 'Portsmouth-Dover-Rochester', value: 'Portsmouth-Dover-Rochester' },
	{ key: 'Poughkeepsie, NY', value: 'Poughkeepsie, NY' },
	{ key: 'Presque Isle', value: 'Presque Isle' },
	{ key: 'Providence-Warwick-Pawtucket', value: 'Providence-Warwick-Pawtucket' },
	{ key: 'Pueblo', value: 'Pueblo' },
	{ key: 'Puerto Rico', value: 'Puerto Rico' },
	{ key: 'Quad Cities (Davenport-Rock Island-Moline)', value: 'Quad Cities (Davenport-Rock Island-Moline)' },
	{ key: 'Quebec City/Lévis, QC', value: 'Quebec City/Lévis, QC' },
	{ key: 'Raleigh-Durham', value: 'Raleigh-Durham' },
	{ key: 'Rapid City', value: 'Rapid City' },
	{ key: 'Reading, PA', value: 'Reading, PA' },
	{ key: 'Red Deer, AB', value: 'Red Deer, AB' },
	{ key: 'Redding, CA', value: 'Redding, CA' },
	{ key: 'Regina/Moose Jaw, SK', value: 'Regina/Moose Jaw, SK' },
	{ key: 'Reno', value: 'Reno' },
	{ key: 'Richmond', value: 'Richmond' },
	{ key: 'Rimouski/Matane/Sept-Îles/Gaspé/Percé, QC', value: 'Rimouski/Matane/Sept-Îles/Gaspé/Percé, QC' },
	{ key: 'Riverside-San Bernardino', value: 'Riverside-San Bernardino' },
	{ key: 'Roanoke-Lynchburg', value: 'Roanoke-Lynchburg' },
	{ key: 'Rochester, MN', value: 'Rochester, MN' },
	{ key: 'Rochester, NY', value: 'Rochester, NY' },
	{ key: 'Rockford', value: 'Rockford' },
	{ key: 'Sacramento', value: 'Sacramento' },
	{ key: 'Saginaw-Bay City-Midland, MI', value: 'Saginaw-Bay City-Midland, MI' },
	{ key: 'Saguenay, QC', value: 'Saguenay, QC' },
	{ key: 'Saint John/Fredericton/Moncton, NB', value: 'Saint John/Fredericton/Moncton, NB' },
	{ key: 'Salina-Manhattan, KS', value: 'Salina-Manhattan, KS' },
	{ key: 'Salisbury-Ocean City', value: 'Salisbury-Ocean City' },
	{ key: 'Salt Lake City-Ogden-Provo', value: 'Salt Lake City-Ogden-Provo' },
	{ key: 'San Angelo, TX', value: 'San Angelo, TX' },
	{ key: 'San Antonio', value: 'San Antonio' },
	{ key: 'San Diego', value: 'San Diego' },
	{ key: 'San Francisco', value: 'San Francisco' },
	{ key: 'San Jose', value: 'San Jose' },
	{ key: 'San Luis Obispo, CA', value: 'San Luis Obispo, CA' },
	{ key: 'Santa Barbara, CA', value: 'Santa Barbara, CA' },
	{ key: 'Santa Maria-Lompoc, CA', value: 'Santa Maria-Lompoc, CA' },
	{ key: 'Santa Rosa, CA', value: 'Santa Rosa, CA' },
	{ key: 'Sarasota-Bradenton', value: 'Sarasota-Bradenton' },
	{ key: 'Saskatoon, SK', value: 'Saskatoon, SK' },
	{ key: 'Savannah', value: 'Savannah' },
	{ key: 'Seattle-Tacoma', value: 'Seattle-Tacoma' },
	{ key: 'Sheboygan, WI', value: 'Sheboygan, WI' },
	{ key: 'Shreveport', value: 'Shreveport' },
	{ key: 'Sherman-Ada', value: 'Sherman-Ada' },
	{ key: 'Sioux City, IA', value: 'Sioux City, IA' },
	{ key: 'Sioux Falls, SD', value: 'Sioux Falls, SD' },
	{ key: 'South Bend, IN', value: 'South Bend, IN' },
	{ key: 'Spokane', value: 'Spokane' },
	{ key: 'Springfield, IL', value: 'Springfield, IL' },
	{ key: 'Springfield, MA', value: 'Springfield, MA' },
	{ key: 'Springfield, MO', value: 'Springfield, MO' },
	{ key: 'St. Cloud, MN', value: 'St. Cloud, MN' },
	{ key: 'St. Louis', value: 'St. Louis' },
	{ key: 'Stockton', value: 'Stockton' },
	{ key: 'Sudbury-Timmins-North Bay, ON', value: 'Sudbury-Timmins-North Bay, ON' },
	{ key: 'Sunbury-Selinsgrove-Lewisburg, PA', value: 'Sunbury-Selinsgrove-Lewisburg, PA' },
	{ key: 'Sussex, NJ', value: 'Sussex, NJ' },
	{ key: 'Syracuse', value: 'Syracuse' },
	{ key: 'Tallahassee', value: 'Tallahassee' },
	{ key: 'Tampa-St. Petersburg-Clearwater', value: 'Tampa-St. Petersburg-Clearwater' },
	{ key: 'Terre Haute', value: 'Terre Haute' },
	{ key: 'Texarkana, TX-AR', value: 'Texarkana, TX-AR' },
	{ key: 'Toledo', value: 'Toledo' },
	{ key: 'Topeka', value: 'Topeka' },
	{ key: 'Toronto/Hamilton/Niagara Falls, ON', value: 'Toronto/Hamilton/Niagara Falls, ON' },
	{ key: 'Traverse City-Petoskey, MI', value: 'Traverse City-Petoskey, MI' },
	{ key: 'Trenton', value: 'Trenton' },
	{ key: 'Tri Cities, WA', value: 'Tri Cities, WA' },
	{ key: 'Trois-Rivières/Shawinigan, QC', value: 'Trois-Rivières/Shawinigan, QC' },
	{ key: 'Tucson', value: 'Tucson' },
	{ key: 'Tulsa', value: 'Tulsa' },
	{ key: 'Tuscaloosa, AL', value: 'Tuscaloosa, AL' },
	{ key: 'Twin Falls', value: 'Twin Falls' },
	{ key: 'Tyler-Longview', value: 'Tyler-Longview' },
	{ key: 'Utica-Rome', value: 'Utica-Rome' },
	{ key: 'Valdosta, GA', value: 'Valdosta, GA' },
	{ key: 'Vancouver-Victoria, BC', value: 'Vancouver-Victoria, BC' },
	{ key: 'Victor Valley', value: 'Victor Valley' },
	{ key: 'Visalia-Tulare-Hanford', value: 'Visalia-Tulare-Hanford' },
	{ key: 'Waco, TX', value: 'Waco, TX' },
	{ key: 'Washington, DC', value: 'Washington, DC' },
	{ key: 'Waterloo-Cedar Falls, IA', value: 'Waterloo-Cedar Falls, IA' },
	{ key: 'Watertown, NY', value: 'Watertown, NY' },
	{ key: 'Watertown, SD', value: 'Watertown, SD' },
	{ key: 'Wausau-Stevens Point, WI (CENTRAL WI)', value: 'Wausau-Stevens Point, WI (CENTRAL WI)' },
	{ key: 'West Palm Beach-Boca Raton', value: 'West Palm Beach-Boca Raton' },
	{ key: 'Wheeling', value: 'Wheeling' },
	{ key: 'Wichita', value: 'Wichita' },
	{ key: 'Wichita Falls, TX', value: 'Wichita Falls, TX' },
	{ key: 'Wilkes Barre-Scranton', value: 'Wilkes Barre-Scranton' },
	{ key: 'Williamsport, PA', value: 'Williamsport, PA' },
	{ key: 'Wilmington, DE', value: 'Wilmington, DE' },
	{ key: 'Wilmington, NC', value: 'Wilmington, NC' },
	{ key: 'Winchester, VA', value: 'Winchester, VA' },
	{ key: 'Windsor, ON', value: 'Windsor, ON' },
	{ key: 'Winnipeg/Brandon, MB', value: 'Winnipeg/Brandon, MB' },
	{ key: 'Worcester', value: 'Worcester' },
	{ key: 'Yakima', value: 'Yakima' },
	{ key: 'York', value: 'York' },
	{ key: 'Youngstown-Warren', value: 'Youngstown-Warren' },
];

export { mediaTypeOptions, bandOptions, typeOptions, marketOptions, isDigitallyInsertedOptions, readOptions};
