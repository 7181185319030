import { Outlet } from 'react-router-dom';

import Unauthorized from 'containers/Errors/Unauthorized';

import useAuth from 'hooks/useAuth';

import { UserRole } from 'constants/enums/UserRole';

interface IAccessControllerProps {
  permittedRoles: UserRole[];
}

const AccessController = ({ permittedRoles }: IAccessControllerProps) => {
  const { user } = useAuth();

  if (user && permittedRoles.includes(user.role)) {
    return <Outlet />;
  } else {
    return <Unauthorized />;
  }
};

export default AccessController;
