import { useState, useContext } from 'react';

import { CreativeAPI } from 'api/creativeAPI';

import { OutlinedButton, ActiveStatusLabel } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CreativeForm from 'components/forms/CreativeForm';

import { ModalContext } from 'context/ModalContext';

import useDeleteFlow from 'hooks/useDeleteFlow';

import { getUTCDate } from 'helpers/dateHelper';

import { ModelName } from 'constants/enums/ModelName';

import { ICreativeTable } from 'interfaces/Creative/ICreativeTable';
import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';

const columns = [
  { dataField: 'name', text: 'Creative' },
  { dataField: 'type', text: 'Ad Type' },
  { dataField: 'duration', text: 'Duration' },
  { dataField: 'productName', text: 'Product' },
  { dataField: 'lastUsed', text: 'Last Used' },
  { dataField: 'status', text: 'Status' },
];
const CREATIVE_FORM_MODAL_NAME = 'creativeFormModal';

const ListOfCreatives = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const [creativeId, setEditCreativeId] = useState(0);
  const [type, setType] = useState('');
  const [duplicateFlag, setDuplicateFlag] = useState(false);
  const [creativesProvider, setCreativesProvider] = useState(
    () => (props: IFilterParams) => CreativeAPI.getList(props)
  );

  const reloadList = () => {
    setCreativesProvider(() => (props: any) => CreativeAPI.getList(props));
  };

  const onDeleteClick = useDeleteFlow(ModelName.Creative, reloadList);

  const onAddNewClick = (type: string) => {
    setType(type);
    setEditCreativeId(0);
    onModalOpen(CREATIVE_FORM_MODAL_NAME);
  };

  const onEditClick = (creativeId: number, item?: Object) => {
    setEditCreativeId(creativeId);
    setType((item as { type: string }).type);
    onModalOpen(CREATIVE_FORM_MODAL_NAME);
  };

  const onDuplicateClick = (creativeId: number, type: string) => {
    setEditCreativeId(creativeId);
    setType(type);
    setDuplicateFlag(true);
    onModalOpen(CREATIVE_FORM_MODAL_NAME);
  };

  const handleModalClose = () => {
    setDuplicateFlag(false);
    onModalClose(CREATIVE_FORM_MODAL_NAME);
  };

  const dataFormatter = (item: ICreativeTable) => ({
    id: item.id,
    name: item.name,
    type: item.type,
    duration: item.duration,
    productName: item.product_name,
    lastUsed: item.last_used ? getUTCDate(item.last_used) : '',
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      { name: 'Duplicate', action: () => onDuplicateClick(item.id, item.type) },
      { name: 'Delete', action: () => onDeleteClick(item) },
    ],
  });

  const getModalTitle = (): string => {
    if (duplicateFlag) {
      return `Duplicate ${type} Creative`;
    }

    if (creativeId) {
      return `Edit ${type} Creative`;
    }

    return `Add ${type} Creative`;
  };

  return (
    <PageContainer>
      <PageTitle>Creatives</PageTitle>
      <ListOfRecords
        tableProps={{
          name: 'creatives',
          columns: columns as ITableColumn[],
          editAction: onEditClick,
          rowsAsLinks: true,
        }}
        barActions={
          <>
            <OutlinedButton onClick={() => onAddNewClick('Live')}>Add Live</OutlinedButton>
            <OutlinedButton onClick={() => onAddNewClick('Spot')}>Add Spot</OutlinedButton>
          </>
        }
        dataFormatter={dataFormatter}
        dataProvider={creativesProvider}
      />

      {
        <Modal name={CREATIVE_FORM_MODAL_NAME} title={getModalTitle()} onModalClose={handleModalClose}>
          <CreativeForm
            onModalClose={handleModalClose}
            successAction={reloadList}
            creativeId={creativeId}
            type={type}
            duplicate={duplicateFlag}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default ListOfCreatives;
