import * as Yup from 'yup';
import { textMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const orderValidationSchema = Yup.object({
  orders: Yup.array()
    .of(
      Yup.object().shape({
        comment: Yup.string()
          .max(
            textMaxLength,
            VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)
          ),
      })
    ),
});
