import useAuth from 'hooks/useAuth';

import { UserRole } from 'constants/enums/UserRole';

interface IWithPermissionsProps {
  children: React.ReactElement;
  permittedRoles: UserRole[];
}
const WithPermission = ({ permittedRoles, children }: IWithPermissionsProps) => {
  const { user } = useAuth();

  if (user && permittedRoles.includes(user.role)) {
    return children;
  } else {
    return null;
  }
};

export default WithPermission;
