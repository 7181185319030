import { getDate } from 'helpers/dateHelper';

const OrderTooltip = ({ sentAt }: { sentAt: string }) => {
  return (
    <div>
      <b>Sent: </b>
      {getDate(sentAt)}
    </div>
  );
};

export default OrderTooltip;
