import Grid from '@mui/material/Unstable_Grid2';

import styled from '@emotion/styled';

export const FooterGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const controllWrapperStyles = {
  wrapper: {
    margin: '0px !important',
  },
};
