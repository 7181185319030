import { useMemo, useCallback, useState, useEffect } from 'react';
import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { Form, Formik } from 'formik';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import { FormEvent } from 'react';
import {
  Editable,
  withReact,
  Slate,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react';
import { createEditor, Descendant, Transforms, Editor } from 'slate';
import RESPONSE_CODES from 'constants/responseCodes';
import { talkingPointsValidationSchema } from 'helpers/validation/talkingPointsValidationSchema';
import { CreativeAPI } from 'api/creativeAPI';
import { ICreativeDetails } from 'interfaces/Creative/ICreativeDetails';
import Toolbar from './Toolbar';
import Leaf from './Leaf';
import Element from './Element';
import { withHtml } from './serializer';

interface CreativeFormProps {
  onModalClose: () => void;
  successAction: () => void;
  currentCreative: ICreativeDetails;
}

type WithHtmlProp = ReactEditor & {
  transfromFromHtml: (data: any) => Descendant[];
  transformToHtml: (data: any) => Descendant[];
};

const EMPTY_TALKING_POINTS = '<p style="text-align: initial"></p>';

const TalkingPointsForm = ({
  onModalClose,
  successAction,
  currentCreative,
}: CreativeFormProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo<ReactEditor>(
    () => withHtml(withReact(createEditor()) as unknown as WithHtmlProp),
    []
  );

  const initialValues = {
    talkingPoints: currentCreative.talking_points || EMPTY_TALKING_POINTS,
  };

  useEffect(() => {
    Transforms.select(editor, Editor.start(editor, []));
  }, [editor]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={talkingPointsValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          const response = await CreativeAPI.updateTalkingPoints(
            currentCreative.id,
            values
          );
          if (response?.status === RESPONSE_CODES.OK) {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty, setFieldValue }) => {
          return (
            <Form>
              <DialogContent>
                <Slate
                  editor={editor}
                  value={(editor as unknown as WithHtmlProp).transfromFromHtml(
                    initialValues.talkingPoints
                  )}
                  onChange={(value) => {
                    setFieldValue(
                      'talkingPoints',
                      (editor as unknown as WithHtmlProp).transformToHtml(value)
                    );
                  }}
                >
                  <Toolbar />
                  <Editable
                    id="talkingPoints"
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    spellCheck
                    autoFocus
                  />
                </Slate>
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={
                    !dirty || !!Object.values(errors).length || isDisabled
                  }
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  Save
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TalkingPointsForm;
