import React, { useState, useEffect } from 'react';

import { TextField, CircularProgress, Popper, MenuItem } from '@mui/material';

import { useField } from 'formik';

import { ISelectOption } from 'interfaces/ISelectOption';

import { FormControl, Label } from 'components/UIComponents';
import { InputFieldContainer, StyledSelect } from './styledComponents';

interface SelectProps {
  label: string;
  name: string;
  getOptionsList: (
    v?: string,
    id?: string | number
  ) => Promise<Array<ISelectOption>>;
  onChange?: any;
  styles?: {
    wrapper?: object;
  };
  initial?: { key: string; value: string | number };
  required?: boolean;
  disabled?: boolean;
  parentId?: string | number;
  placeholder?: string;
  emptyOptionLabel?: string;
  direction?: 'column' | 'row';
}

const MIN_SEARCH_LENGTH = 2;
const DEFAULT_OPTION = { key: '', value: '' };

const SelectWithSearch = ({
  onChange,
  label,
  name = '',
  styles = {},
  getOptionsList,
  initial = DEFAULT_OPTION,
  required = false,
  disabled = false,
  parentId,
  placeholder = 'Start typing or select',
  emptyOptionLabel = 'None',
  direction,
}: SelectProps) => {
  const [field, meta, helpers] = useField(name);
  const [options, setOptions] = useState<Array<ISelectOption> | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<ISelectOption>(DEFAULT_OPTION);

  const handleSetOptions = (data: ISelectOption[]) => {
    const values =
      !required && data.length > 0 ? [DEFAULT_OPTION, ...data] : data;
    setOptions(values || []);
  };

  const handleGetOptions = (value: string, reason?: string) => {
    if (
      value === '' ||
      value?.length > MIN_SEARCH_LENGTH ||
      reason === 'reset'
    ) {
      (getOptionsList(value) as Promise<Array<ISelectOption>>).then(
        (data: Array<ISelectOption>) => {
          handleSetOptions(data);
          setIsLoading(false);
        }
      );
    }
  };

  const handleInputChange = (reason: string, value: string) => {
    reason !== 'reset' &&
      value.length > MIN_SEARCH_LENGTH &&
      setIsLoading(true);
    handleGetOptions(value || '', reason);
  };

  useEffect(() => {
    if (initial.value && initial.key) {
      setValue(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial.value, initial.key]);

  useEffect(() => {
    if (!field.value && field.value !== value.value) {
      setValue(DEFAULT_OPTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  useEffect(() => {
    if (!field.value) {
      handleGetOptions('');
      setValue(DEFAULT_OPTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      <Label required={required} disabled={disabled}>
        {label}
      </Label>
      <InputFieldContainer>
        <StyledSelect
          {...field}
          disabled={disabled}
          error={meta.touched && meta.error ? 1 : 0}
          loading={isLoading}
          loadingText="Loading…"
          noOptionsText="No results"
          value={value}
          disableClearable
          options={options}
          getOptionLabel={(option: any) => option?.key}
          isOptionEqualToValue={(option: any, value: any) =>
            option?.value === value?.value
          }
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            newValue: any
          ) => {
            helpers.setValue(newValue.value);
            setValue(newValue);
            onChange?.(event, newValue, value);
          }}
          onInputChange={(e, value, reason) => handleInputChange(reason, value)}
          renderInput={(params) => {
            return (
              <TextField
                name={name}
                {...params}
                ref={params.InputProps.ref}
                placeholder={
                  params?.inputProps?.['aria-expanded']
                    ? undefined
                    : placeholder
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                inputProps={{ ...params.inputProps, 'aria-label': label }}
              />
            );
          }}
          filterOptions={(options) => options}
          PopperComponent={({ style, ...props }) => (
            <Popper
              {...props}
              style={{ ...style, height: 0 }} //for opening dropdown down
            />
          )}
          renderOption={(props, option: any) => (
            <MenuItem {...props} key={option.value} style={{ whiteSpace: 'normal' }}>
              {!required &&
              option.key === DEFAULT_OPTION.key &&
              option.value === DEFAULT_OPTION.value ? (
                <em>{emptyOptionLabel}</em>
              ) : (
                option?.key
              )}
            </MenuItem>
          )}
        />
        <Label error>{meta.touched && meta.error}</Label>
      </InputFieldContainer>
    </FormControl>
  );
};

export default SelectWithSearch;
