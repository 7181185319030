import { useContext } from 'react';

import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton, TextArea } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import Modal from 'components/base/BaseModal';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { ModalContext } from 'context/ModalContext';

import { commentValidationSchema } from 'helpers/validation/commentValidationSchema';
import { mandatoryCommentValidationSchema } from 'helpers/validation/mandatoryCommentValidationSchema';

export const COMMENT_MODAL_FORM = 'COMMENT_MODAL_FORM';

const modalTitles = {
  'COMMENT_MODAL_FORM': 'Comment',
  'COMMENT_MODAL_FORM_SEND_ORDERS': 'Comment',
  'SINGLE_COMMENT_MODAL_FORM_FOR_Suggested': 'Suggesting Changes to Order',
  'SINGLE_COMMENT_MODAL_FORM_FOR_Declined': 'Declining Order',
  'COMMENT_MODAL_FORM_DECLINE_ORDERS': 'Declining Order',
};

interface Props {
  onSubmit: (comment?: string, status?: string) => void,
  isMandatory?: boolean,
  modalName?: string,
}
const CommentModalForm = ({
  onSubmit,
  isMandatory = false,
  modalName = COMMENT_MODAL_FORM,
}: Props) => {
  const { onModalClose } = useContext(ModalContext);

  const onModalSubmit = (comment?: string) => {
    onModalClose(modalName);
    onSubmit(comment);
  };

  //TODO: why modal is submitting on close? remove if comment will mandatory everywhere
  //TODO: commentValidationSchema validates body but not comment. refactor needed
  return (
    <Modal
      name={modalName}
      title={modalTitles[modalName as keyof typeof modalTitles]}
      onModalClose={(_: string) => onModalSubmit()}
    >
      <Formik
        initialValues={{ comment: '' }}
        enableReinitialize
        validationSchema={isMandatory ? mandatoryCommentValidationSchema : commentValidationSchema}
        validateOnChange
        onSubmit={(values) => onModalSubmit(values.comment)}
      >
        {({ errors, handleSubmit, dirty }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
                <TextArea name="comment" />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalSubmit}>Close</OutlinedButton>
                <SubmitButton
                  disabled={(!dirty && isMandatory ) || !!Object.values(errors).length}
                  onClick={handleSubmit}
                >
                  Save
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CommentModalForm;
