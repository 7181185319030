import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import { BulkSelectContext } from 'context/BulkSelectContext';

import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';
import { SELECTED_SPOT_ID_KEY } from 'constants/spots';

import { IResponse } from 'interfaces/api';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import Row from '../List/Row';
import SkeletonRow from '../List/SkeletonRow';
import { Container, StyledIconButton } from './styledComponents';

interface Props {
  editAction: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
  type: SpotType;
  dataProvider: Function;
}

// TODO: remove dataProvider dependecy. Just update highlighted item after each action.
const HighlightedSpot = ({ type, editAction, dataProvider }: Props) => {
  const [highlightedSpot, setHighlighedSpot] = useState<IPodcastSpotsTable | ILegacySpotsTable>();
  const [searchParams, setSearchParams] = useSearchParams();
  const spotId = searchParams.get(SELECTED_SPOT_ID_KEY);
  const { onChangeSingle } = useContext(BulkSelectContext);

  const removeSpotKeyFromURL = () => {
    searchParams.delete(SELECTED_SPOT_ID_KEY);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (spotId) {
      const spotAPI = type === SpotType.legacy ? LegacySpotAPI : PodcastSpotAPI;
      spotAPI.getOne(Number(spotId)).then(({ status, data }: IResponse) => {
        if (status === RESPONSE_CODES.OK) {
          setHighlighedSpot(data.data);
        } else {
          removeSpotKeyFromURL();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  const onClose = () => {
    onChangeSingle(highlightedSpot!.id, false);
    setHighlighedSpot(undefined);
    removeSpotKeyFromURL();
  };

  return (
    <Container>
      {spotId &&
        (highlightedSpot ? (
          <>
            <Row spot={highlightedSpot} editAction={editAction} highlighted />
            <StyledIconButton aria-label="close" onClick={onClose} size="small">
              <CloseIcon />
            </StyledIconButton>
          </>
        ) : (
          <SkeletonRow />
        ))}
    </Container>
  );
};

export default HighlightedSpot;
