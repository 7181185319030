import CheckboxCell from 'components/base/tableComponents/CheckboxCell';

import { Box } from './styledComponents';

interface Props {
  id: number;
}

const RowCheckBox = ({ id }: Props) => {
  return (
    <Box>
      <CheckboxCell id={id} />
    </Box>
  );
};

export default RowCheckBox;
