import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';

import logo from 'assets/images/logo.svg';

import useAuth from 'hooks/useAuth';

import { colors } from 'styles/globalStyles';

import NavBar from './NavBar';
import { HeaderDiv, AuthDiv, Username, LogoImage } from './styledHeaderBlocks';

const Header = () => {
  const { logout, user } = useAuth();

  return (
    <HeaderDiv>
      <LogoImage src={logo} alt="SMI Logo" />
      <NavBar />
      <AuthDiv>
        <Username>{user?.name}</Username>
        <IconButton onClick={() => logout(true)}>
          <LogoutIcon sx={{ height: '19px', color: colors.white }} />
        </IconButton>
      </AuthDiv>
    </HeaderDiv>
  );
};

export default Header;
