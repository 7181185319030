import { snakeCase, omitBy, camelCase } from 'lodash';

import { PopupType } from 'constants/enums/PopupType';

import { IGlobalPopupData } from 'interfaces/IGlobalPopupData';
import { IAPIError } from 'interfaces/api';

export const keysToSnakeCase = (obj: any): any => {
  return Object.keys(obj).reduce(
    (result: object, key: string) => ({
      ...result,
      [snakeCase(key)]: obj[key],
    }),
    {}
  );
};

export const keysToCamelCase = (obj: any): any => {
  return Object.keys(obj).reduce(
    (result: object, key: string) => ({
      ...result,
      [camelCase(key)]: obj[key],
    }),
    {}
  );
};

export const nonEmptyValues = (values: any): any => {
  return omitBy(values, (v: any) => (v !== 0 && v !== false && !v) || (typeof v === 'object' && v.length === 0));
};

// Be careful with using this function, no strict setted up rules with the BE part.
export const apiErrorToPopupData = (error: IAPIError): IGlobalPopupData => {
  const common = {
    title: error.title,
    content: error.details,
  };
  const typeOfErorr = error.type.toLowerCase();

  if (error.title === 'Warning' || typeOfErorr === PopupType.warning) {
    return {
      type: PopupType.warning,
      cancelText: 'No, cancel',
      submitText: 'Yes, continue',
      ...common,
    };
  }

  return {
    ...common,
    type: PopupType.error,
  };
};
