import { createContext, useState } from 'react';

export interface IRadioGroupContext {
  value: any;
  onSelect: (value: any) => void;
  isSelected: (value: any) => boolean;
}
interface IProps {
  children: React.ReactNode;
  comparator?: (value1: any, value2: any) => boolean;
}

export const RadioGroupContext = createContext<IRadioGroupContext>({
  value: '',
  onSelect: (value: any) => {},
  isSelected: (value: any) => false,
});

export const RadioGroupProvider = ({ children, comparator }: IProps) => {
  const [value, setValue] = useState<any>();

  const isSelected = (candidate: any) => (comparator ? comparator(value, candidate) : candidate === value);

  return (
    <RadioGroupContext.Provider
      value={{
        value,
        onSelect: setValue,
        isSelected,
      }}
    >
      {children}
    </RadioGroupContext.Provider>
  );
};
