import { useState, useContext } from 'react';

import { CustomerAPI } from 'api/customerAPI';

import { OutlinedButton, ActiveStatusLabel } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CustomerForm from 'components/forms/CustomerForm';

import { ModalContext } from 'context/ModalContext';

import useDeleteFlow from 'hooks/useDeleteFlow';

import { ModelName } from 'constants/enums/ModelName';

import { ICustomersTable } from 'interfaces/Customer/ICustomersTable';
import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';

const columns = [
  { dataField: 'name', text: 'Customer' },
  { dataField: 'contact_name', text: 'Contact' },
  { dataField: 'status', text: 'Status' },
  { dataField: 'products_count', text: 'Products', customSearchMatchRule: /^\d+ of \d+$/ },
];
const CUSTOMER_FORM_MODAL_NAME = 'customerFormModal';

const ListOfCustomers = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const [editCustomerId, setEditCustomerId] = useState(0);
  const [customersProvider, setCustomersProvider] = useState(
    () => (props: IFilterParams) => CustomerAPI.getAllCustomers(props)
  );

  const reloadList = () => {
    setCustomersProvider(() => (props: any) => CustomerAPI.getAllCustomers(props));
  };

  const onDeleteClick = useDeleteFlow(ModelName.Customer, reloadList);

  const onEditClick = (customerId: number) => {
    setEditCustomerId(customerId);
    onModalOpen(CUSTOMER_FORM_MODAL_NAME);
  };

  const onAddNewClick = () => {
    setEditCustomerId(0);
    onModalOpen(CUSTOMER_FORM_MODAL_NAME);
  };

  const dataFormatter = (item: ICustomersTable) => ({
    id: item.id,
    name: item.name,
    contact_name: item.contact_name,
    status: <ActiveStatusLabel active={item.is_active} />,
    products_count: item.matched_products ? `${item.matched_products} of ${item.products_count}` : item.products_count,
    actions: [{ name: 'Delete', action: () => onDeleteClick(item) }],
  });

  return (
    <PageContainer>
      <PageTitle>Customers</PageTitle>
      <ListOfRecords
        tableProps={{
          name: 'customers',
          columns: columns as ITableColumn[],
          editAction: onEditClick,
          rowsAsLinks: true,
        }}
        barActions={<OutlinedButton onClick={onAddNewClick}>Add Customer</OutlinedButton>}
        dataFormatter={dataFormatter}
        dataProvider={customersProvider}
      />
      {
        <Modal
          name={CUSTOMER_FORM_MODAL_NAME}
          title={editCustomerId ? 'Edit Customer' : 'Add Customer'}
          onModalClose={onModalClose}
        >
          <CustomerForm
            onModalClose={() => onModalClose(CUSTOMER_FORM_MODAL_NAME)}
            successAction={reloadList}
            editCustomerId={editCustomerId}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default ListOfCustomers;
