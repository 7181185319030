import { useContext, useEffect } from 'react';

import { UserAPI } from 'api/userAPI';

import Popup from 'components/base/Popup';

import { BaseListActionsContext } from 'context/BaseListActions';
import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';

import { IUserBase } from 'interfaces/User/IUserBase';

export const CHANGE_ACTIVATION_CONFIRM_POPUP_NAME = 'CHANGE_ACTIVATION_CONFIRM_POPUP';

const SwitchActivationAction = ({ user, onClose }: { user: IUserBase; onClose: () => void }) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { reloadList } = useContext(BaseListActionsContext);

  const newIsActive = !user.is_active;

  const onPopupClose = () => {
    onModalClose(CHANGE_ACTIVATION_CONFIRM_POPUP_NAME);
    onClose();
  };

  const onConfirm = () => {
    onPopupClose();
    return UserAPI.switchActivation(user.id, newIsActive).then(() => {
      reloadList();
    });
  };

  useEffect(() => {
    onModalOpen(CHANGE_ACTIVATION_CONFIRM_POPUP_NAME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Popup
        name={CHANGE_ACTIVATION_CONFIRM_POPUP_NAME}
        type={PopupType.warning}
        title={newIsActive ? 'Confirm User Reactivation' : 'Confirm User Deactivation'}
        onModalClose={onPopupClose}
        handleSubmit={onConfirm}
        submitText={newIsActive ? 'Yes, reactivate' : 'Yes, deactivate'}
        cancelText="No"
      >
        {newIsActive
          ? 'Are you sure you want to reactivate this user? Once reactivated, they will regain access to the system.'
          : `Are you sure you want to deactivate this user? Once deactivated, they will no 
             longer have access to the system. However, you can always reactivate the user later if needed.`}
      </Popup>
    </>
  );
};

export default SwitchActivationAction;
