import { useContext, useEffect } from 'react';

import { Checkbox } from '@mui/material';

import { RadioGroupContext } from 'context/RadioGroupContext';

import { ReactComponent as RadioChecked } from 'assets/icons/RadioChecked.svg';
import { ReactComponent as RadioUnchecked } from 'assets/icons/RadioUnchecked.svg';

import { colors } from 'styles/globalStyles';

interface Props {
  value: any;
  index: number;
}

const RadioCheckbox = ({ value, index }: Props) => {
  const { value: currentValue, onSelect, isSelected } = useContext(RadioGroupContext);

  useEffect(() => {
    if (index === 0 && !currentValue) {
      onSelect(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Checkbox
      checked={isSelected(value)}
      checkedIcon={<RadioChecked color={colors.red} />}
      icon={<RadioUnchecked />}
      onChange={(e) => onSelect(value)}
      sx={{ padding: 0 }}
    />
  );
};

export default RadioCheckbox;
