import { keysToSnakeCase } from 'helpers/utils';

import { SpotType } from 'constants/enums/SpotTypes';

import { IBulkActionData } from 'interfaces/IBulkActionData';
import { IFilterParams } from 'interfaces/IFilterParams';
import { IComments } from 'interfaces/Order/IComments';

import { request } from '../instance';

export const OrderAPI = {
  getList: async ({ page, per_page, filterOptions = {} }: IFilterParams) => {
    try {
      const response = await request({
        url: 'v1/orders',
        method: 'GET',
        params: {
          page,
          per_page,
          ...filterOptions,
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  groupSpots: async (bulkData: IBulkActionData, filterParams = {}, type: SpotType) => {
    try {
      const response = await request({
        url: `v1/orders/${type.toLowerCase()}_spots_by_group`,
        method: 'GET',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  groupSpotsByOrders: async (bulkData: IBulkActionData, filterParams = {}) => {
    try {
      const response = await request({
        url: 'v1/orders/group_spots_by_orders',
        method: 'GET',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  create: async (data: object, send: boolean = false) => {
    try {
      const response = await request({
        url: 'v1/orders',
        method: 'POST',
        data: { data: data, send },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  checkSpotOrderChange: async (type: SpotType, spotParams= {}, orderId: number) => {
    try {
      const response = await request({
        url: `v1/orders/${orderId}/${type.toLowerCase()}_spot_order_change_check`,
        method: 'GET',
        params: {
          ...spotParams,
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  orderChangeAfterSpotUpdate: async (data: object, orderId: number) => {
    try {
      const response = await request({
        url: `v1/orders/${orderId}/order_change_after_spot_update`,
        method: 'PATCH',
        data: {
          data: {
            order: data,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getSpots: async (orderId: string) => {
    const response = await request({
      url: `v1/orders/${orderId}/spots`,
      method: 'GET',
    });

    return response.data;
  },

  getPDFPreview: async (data: object) => {
    const response = await request({
      url: 'v1/orders/preview_pdf',
      method: 'GET',
      params: { data: data },
    });

    return response.data;
  },

  getSpotsForAdding: async (
    orderId: string,
    filterParams: {
      start_date?: string;
      end_date?: string;
      outlet_id?: number[];
    }
  ) => {
    const response = await request({
      url: `v1/orders/${orderId}/spots_for_adding`,
      method: 'GET',
      params: filterParams,
    });

    return response.data;
  },

  removeOrder: async (orderId: string, force_destroy?: boolean) => {
    try {
      const response = await request({
        url: `v1/orders/${orderId}`,
        method: 'DELETE',
        params: { force_destroy },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  changeStatus: async (orderId: string, status: string, comment?: string) => {
    try {
      const response = await request({
        url: `v1/orders/${orderId}/change_status`,
        method: 'PATCH',
        data: {
          data: {
            order: {
              status,
              comment,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  bulkChangeStatus: async (bulkData: IBulkActionData, filterParams: object = {}, status: string, comment?: string) => {
    try {
      const response = await request({
        url: 'v1/orders/bulk_change_status',
        method: 'PATCH',
        data: {
          data: {
            order: {
              ...keysToSnakeCase(bulkData),
              status,
              comment,
            },
          },
        },
        params: filterParams,
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  bulkCheckForStatusTransition: async (bulkData: IBulkActionData, filterParams: object = {}, status: string) => {
    try {
      const response = await request({
        url: 'v1/orders/bulk_check_for_status_transition',
        method: 'GET',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
          status,
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  bulkRemoveOrders: async (bulkData: IBulkActionData, filterParams: object = {}, force_destroy?: boolean) => {
    try {
      const response = await request({
        url: 'v1/orders/bulk_destroy',
        method: 'DELETE',
        data: {
          data: {
            order: {
              ...keysToSnakeCase(bulkData),
            },
          },
        },
        params: { force_destroy, ...filterParams },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  sendOrders: async (bulkData: IBulkActionData, filterParams: object = {}, force: boolean, comments: IComments) => {
    try {
      const response = await request({
        url: 'v1/orders/send',
        method: 'POST',
        data: {
          data: {
            ...keysToSnakeCase(bulkData),
            force,
            comments,
          },
        },
        params: filterParams,
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getEstimates: async (value: string = '') => {
    try {
      const response = await request({
        url: 'v1/orders/estimates',
        method: 'GET',
        params: { value },
      });

      return response.data.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getWeekStarts: async (value: string = '') => {
    try {
      const response = await request({
        url: 'v1/orders/week_starts',
        method: 'GET',
        params: { value },
      });

      return response.data.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};
