import { useContext, useMemo, useState } from 'react';

import { PaymentAPI } from 'api/paymentAPI';

import Popup from 'components/base/Popup';

import { ModalContext } from 'context/ModalContext';

import { paymentSerializer } from 'helpers/payments';
import { isPodcastSpot } from 'helpers/spotsHelper';

import { PopupType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IPaymentGroupInfo, IPaymentsForm, ISearializedPaymentsFormData } from 'interfaces/Payment/IPaymentsForm';
import { IAPIError, IErrorResponseData, IResponse } from 'interfaces/api';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

interface Props {
  onClose: () => void;
  successAction: () => void;
  type: string;
  groupedData: object;
}

export const groupingTypes = [
  { key: 'Group', value: 'Group' },
  { key: 'Outlet', value: 'Outlet' },
];

const PAYMENT_WARNING_POPUP_NAME = 'paymentCreateWarning';

const PaymentForm = ({ type, onClose, successAction, groupedData }: Props) => {
  const [step, setStep] = useState(0);
  const [groupingType, setGroupingType] = useState(groupingTypes[0].value);
  const [error, setError] = useState<IAPIError | null>();
  const [formData, setFormData] = useState<ISearializedPaymentsFormData>();
  const { onModalClose, onModalOpen } = useContext(ModalContext);

  const onFirstStepSubmit = (groupingType: string) => {
    setGroupingType(groupingType);
    setStep(1);
  };

  const getGroupedData = useMemo(() => {
    if (isPodcastSpot(type)) {
      const podcastData = groupedData as {
        podcast_spots_by_group: IPaymentGroupInfo[];
        podcast_spots_by_outlet: IPaymentGroupInfo[];
      };
      return podcastData[groupingType === 'Group' ? 'podcast_spots_by_group' : 'podcast_spots_by_outlet'];
    }

    const legacyData = groupedData as {
      legacy_spots_by_group: IPaymentGroupInfo[];
      legacy_spots_by_outlet: IPaymentGroupInfo[];
    };

    return legacyData[groupingType === 'Group' ? 'legacy_spots_by_group' : 'legacy_spots_by_outlet'];
  }, [groupedData, groupingType, type]);

  const onSubmit = (paymentsFormData: IPaymentsForm) => {
    const serializedData = {
      payments: paymentsFormData.payments.map((payment) => paymentSerializer(payment, groupingType)),
    };
    setFormData(serializedData);
    return createPayments(serializedData);
  };

  const handleForceSubmit = () => {
    setError(null);
    onModalClose(PAYMENT_WARNING_POPUP_NAME);
    createPayments(formData as ISearializedPaymentsFormData, true);
  };

  const createPayments = (data: ISearializedPaymentsFormData, force?: boolean) =>
    PaymentAPI.create(data, force).then((response: IResponse) => {
      if (response.status === RESPONSE_CODES.OK) {
        successAction();
        onClose();
      } else {
        setError((response.data as IErrorResponseData).error);
        onModalOpen(PAYMENT_WARNING_POPUP_NAME);
      }
      return response;
    });

  return (
    <>
      {step === 0 ? (
        <FirstStep groupingType={groupingType} onSubmit={onFirstStepSubmit} onCancel={onClose} />
      ) : (
        <SecondStep data={getGroupedData} onSubmit={onSubmit} onClose={onClose} onBack={() => setStep(0)} />
      )}
      {error && (
        <Popup
          name={PAYMENT_WARNING_POPUP_NAME}
          type={PopupType.warning}
          title={error.title}
          onModalClose={() => {
            setError(null);
            onModalClose(PAYMENT_WARNING_POPUP_NAME);
          }}
          cancelAction={() => {
            setError(null);
            onModalClose(PAYMENT_WARNING_POPUP_NAME);
            onClose();
          }}
          handleSubmit={handleForceSubmit}
          submitText="Yes, create"
          cancelText="No"
        >
          {error.details}
        </Popup>
      )}
    </>
  );
};

export default PaymentForm;
