import { createContext, useCallback, useState } from 'react';

import RefreshReminder from 'components/base/RefreshReminder';

interface IRefreshContextProps {
  resetTimer: () => void;
}

export const RefreshContext = createContext<IRefreshContextProps>({
  resetTimer: () => {},
});

export const RefreshContextProvider = ({ children, callback }: { children: React.ReactNode; callback: () => void }) => {
  const [actionCallback, setActionCallback] = useState<Function>(() => callback);

  const resetTimer = useCallback(() => setActionCallback(() => () => callback()), [callback]);

  return (
    <RefreshContext.Provider
      value={{
        resetTimer,
      }}
    >
      {children}
      <RefreshReminder callback={actionCallback} />
    </RefreshContext.Provider>
  );
};
