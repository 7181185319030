import { TableBody } from '@mui/material';
import { formatDate } from 'helpers/dateHelper';
import {
  HeadCell,
  BodyCell,
  TableRow,
  InformationTable,
  FlexChild,
  InfoBlockTitle,
} from 'components/base/BaseTable';
import { ISpotRecordInfo } from 'interfaces/spots/ISpotRecordInfo';

const SpotRecordInfo = ({ info }: { info: ISpotRecordInfo }) => {
  return (
    <FlexChild>
      <InfoBlockTitle>SPOT DETAILS</InfoBlockTitle>
      <InformationTable>
        <TableBody>
          <TableRow>
            <HeadCell>Buyer</HeadCell>
            <BodyCell>{info.buyer}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Created by</HeadCell>
            <BodyCell>{info.created_by}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Created when</HeadCell>
            <BodyCell>{formatDate(info.created_at)}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Updated by</HeadCell>
            <BodyCell>{info.updated_by}</BodyCell>
          </TableRow>
          <TableRow>
            <HeadCell>Updated when</HeadCell>
            <BodyCell>{formatDate(info.updated_at)}</BodyCell>
          </TableRow>
        </TableBody>
      </InformationTable>
    </FlexChild>
  );
};

export default SpotRecordInfo;
