import { useState } from 'react';
import { OrderAPI } from 'api/orderAPI';
import { CustomIcon, LinkButton } from 'components/UIComponents';
import { IOrderFormItem } from 'interfaces/Order/IOrderForm';
import { CircularProgress } from '@mui/material';
import { LinkButtonText } from '../../../containers/spots/ListOfSpots/ActionsPanel/styledComponents';

interface Props {
  orderInfo: IOrderFormItem;
}

const AttachmentPreviewForm = ({ orderInfo }: Props) => {
  const [loading, setLoading] = useState(false);
  const attachmentType = orderInfo.order_preferences;

  const previewPDF = async () => {
    setLoading(true);
    try {
      const attachmentParams = {
        group_id: orderInfo.group_id,
        comment: orderInfo.comment,
        spots_ids: orderInfo.spots_ids,
        spot_type: orderInfo.spots_type,
        order_ids: orderInfo.order_ids,
      };
      const response = await OrderAPI.getPDFPreview(attachmentParams);
      const content = response.data.content;
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);

      const blobType =
        orderInfo.order_preferences === 'PDF'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const blob = new Blob([byteArray], { type: blobType });
      const fileUrl = URL.createObjectURL(blob);

      if (orderInfo.order_preferences === 'PDF') {
        window.open(fileUrl, '_blank');
      } else {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'review.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <CircularProgress color="inherit" size={16} />
  ) : (
    <div style={{ marginBottom: 20 }}>
      <LinkButton onClick={previewPDF}>
        <CustomIcon name={attachmentType === 'PDF' ? 'newBlank' : 'download'} color="inherit" size="small" />
        <LinkButtonText>{attachmentType === 'PDF' ? 'Preview Order PDF' : 'Preview Order XLSX'}</LinkButtonText>
      </LinkButton>
    </div>
  );
};

export default AttachmentPreviewForm;