import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

export default styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '5px !important',
    backgroundColor: colors.tableHeadColor,
    borderRadius: 0,
    padding: '7px 8px 6px 8px',
    fontSize: '14px',
    lineHeight: '17px',
    color: colors.darkGrey,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.tableHeadColor,
  },
  '& .MuiButtonBase-root': {
    padding: 0,
  },
}));
