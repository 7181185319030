import { Typography } from '@mui/material';

import styled from '@emotion/styled';

import { fontFamily } from 'styles/globalStyles';

export const TrafficInfoTitle = styled.div`
  margin-bottom: 20px;
  font-family: ${fontFamily};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

export const TrafficInfo = styled.div`
  margin-top: 40px;

  &:first-of-type {
    margin-top: 0px;
  }
`;

export const ContactInfo = styled(Typography)`
  margin-left: 28px;
`;
