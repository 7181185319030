import { useState } from 'react';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useSearch from 'hooks/useSearch';
import searchSvg from 'assets/images/search.svg';
import {
  StyledIconButton as IconButton,
  StyledCircularProgress as CircularProgress,
  SearchIcon,
} from './styledComponents';
import { StyledInputBase } from './styledInputBase';


interface SearchFieldProps {
  onChange: (value: string, searchCallback: () => void) => void,
}

const SearchField = ({ onChange }: SearchFieldProps) => {
  const [value, setValue] = useState('');
  const { loading, handleSearch } = useSearch(onChange);

  const handleChange = (value: string) => {
    setValue(value);
    handleSearch(value);
  };

  const dismissSearch = () => {
    handleChange('');
  };

  const renderAdornment = () => {
    return (
      <InputAdornment position="end">
        {loading ? <CircularProgress size={14} /> : ''}
        {value ? <IconButton
          aria-label="Clear search"
          onClick={dismissSearch}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton> : ''}
      </InputAdornment>
    );
  };

  return (
    <StyledInputBase
      placeholder="Search"
      inputProps={{ 'aria-label': 'search' }}
      startAdornment={<InputAdornment position="start"><SearchIcon src={searchSvg} /></InputAdornment>}
      endAdornment={renderAdornment()}
      value={value}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default SearchField;
