import { StyledPageContainer } from './styledComponents';

interface Props {
  children: React.ReactNode;
}

const PageContainer = ({ children }: Props) => {
  return <StyledPageContainer>{children}</StyledPageContainer>;
};

export default PageContainer;
