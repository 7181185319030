import { FormEvent, useState } from 'react';

import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { SelectWithSearch } from 'components/UIComponents/index';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { getCodeOptions, getCreativeOptions } from 'helpers/collections';
import { getResponseHandler } from 'helpers/forms';
import { spotAllocateCodeValidationSchema } from 'helpers/validation/spotAllocateCodeValidationSchema';

import { ModelName } from 'constants/enums/ModelName';

import { IAllocatedData } from 'interfaces/IAllocatedData';

interface AllocateCodeFormProps {
  onModalClose: () => void;
  successAction: () => void;
  allocatedData: IAllocatedData;
  allocateCode: (values: { creativeId: number; codeId: number }, ids: Array<number>) => any;
}

const AllocateCodeForm = ({ onModalClose, successAction, allocatedData, allocateCode }: AllocateCodeFormProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues = {
    creativeId: 0,
    codeId: 0,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={spotAllocateCodeValidationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const responseHandler = getResponseHandler(
            ModelName.LegacySpot,
            () => {
              onModalClose();
              successAction();
            },
            actions
          );

          allocateCode(values, allocatedData.ids)
            .then(responseHandler)
            .finally(() => {
              setIsDisabled(false);
            });
        }}
      >
        {({ errors, handleSubmit, dirty, values, setFieldValue }) => {
          return (
            <Form>
              <DialogContent>
                <SelectWithSearch
                  name="creativeId"
                  label="Creative"
                  getOptionsList={(value: any) => getCreativeOptions(value, allocatedData.product_id)}
                  onChange={(e: React.SyntheticEvent<Element, Event>, newValue: any, value: any) => {
                    if (newValue.value !== value.value) {
                      setFieldValue('codeId', '');
                    }
                  }}
                />
                <SelectWithSearch
                  name="codeId"
                  label="Code"
                  getOptionsList={(value: any) => getCodeOptions(value, values.creativeId)}
                  disabled={!values.creativeId}
                  parentId={values.creativeId}
                />
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length || isDisabled}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  Allocate
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AllocateCodeForm;
