import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const StyledLink = styled(Link)(() => ({
  fontFamily: fontFamily,
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '-0.32px',
  color: colors.grey,
  marginTop: '20px',
  textDecoration: 'none',
}));
