import { ModelName } from 'constants/enums/ModelName';

import { CreativeAPI } from './creativeAPI';
import { CustomerAPI } from './customerAPI';
import { LegacySpotAPI } from './legacySpotAPI';
import { OutletAPI } from './outletAPI';
import { PodcastSpotAPI } from './podcastSpotAPI';
import { ProductAPI } from './productAPI';

const API: { [key in ModelName]: any } = {
  [ModelName.Customer]: CustomerAPI,
  [ModelName.Creative]: CreativeAPI,
  [ModelName.Outlet]: OutletAPI,
  [ModelName.Product]: ProductAPI,
  [ModelName.LegacySpot]: LegacySpotAPI,
  [ModelName.PodcastSpot]: PodcastSpotAPI,
};

export default API;
