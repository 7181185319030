import { GroupOrderAPI } from 'api/GroupScope/orderAPI';
import BaseListOfOrders from 'containers/Orders/shared/BaseListOfOrders';
import GroupOrderFilterForm from 'components/forms/GroupOrderFilterForm';
import ListOfOrdersActions from './ListOfOrdersActions';


const ListOfGroupOrders = () => {
  return (
    <BaseListOfOrders
      apiCall={GroupOrderAPI.getList}
      orderFilterForm={<GroupOrderFilterForm />}
      bulkActions={<ListOfOrdersActions />}
      disableSpotLinks
    />
  );
};

export default ListOfGroupOrders;
