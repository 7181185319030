export const paymentPopups: { [key: string]: string } = {
  CONFIRM_PAYMENT_DELETING: 'confirmPaymentDeletingPopup',
  CONFIRM_BULK_PAYMENT_DELETING: 'confirmBulkPaymentDeletingPopup',
};

export const paymentStatuses = {
  cleared: 'Cleared',
  settled: 'Settled',
};

export const statusOptions = Object.values(paymentStatuses).map((status) => ({
  key: status,
  value: status,
}));
