import { ActionsContainer, PanelContainer, SelectedInfo } from './styledComponents';

interface Props {
  children: React.ReactNode;
  selectAll: boolean;
  ids: Array<number>;
  excludedIds: Array<number>;
  totalCount: number;
}
const BulkActionsPanel = ({ children, selectAll, ids, excludedIds, totalCount }: Props) => {
  const selectedCount = selectAll ? totalCount - excludedIds.length : ids.length;
  if (selectedCount === 0) {
    return null;
  }

  return (
    <PanelContainer>
      <SelectedInfo>{`${selectedCount} SELECTED`}</SelectedInfo>
      <ActionsContainer>{children}</ActionsContainer>
    </PanelContainer>
  );
};

export default BulkActionsPanel;
