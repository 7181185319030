import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'constants/validationMessages';
import {
  minValue,
  spotNumberMaxValue,
  spotNumberMaxLength,
} from 'constants/fieldsLimits';

export const reviseSpotNumberValidationSchema = Yup.object({
  spotsNumber: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotNumberMaxValue,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(spotNumberMaxLength)
    ),
});
