/* eslint-disable no-magic-numbers */
import { jsx } from 'slate-hyperscript';
import { Text, BaseEditor, Descendant } from 'slate';
import escapeHtml from 'escape-html';
import React from 'react';
import { ReactEditor } from 'slate-react';
import * as CSS from 'csstype';

interface DeserializeElementProps {
  style: React.CSSProperties;
  nodeType: number;
  textContent?: string;
  nodeName: string;
  childNodes: Array<React.ReactElement & DeserializeElementProps>;
  getAttribute: (v: string) => void;
}

interface ElementProps {
  align: CSS.Property.TextAlign;
  type: string;
  children: Array<any>;
  code?: boolean;
  italic?: boolean;
  underline?: boolean;
  bold?: boolean;
  url: string;
}

type WithHtmlProp = BaseEditor &
  ReactEditor & {
    transfromFromHtml: (data: any) => Descendant[];
    transformToHtml: (data: any) => void;
  };

const ELEMENT_TAGS = {
  A: (el: DeserializeElementProps) => ({
    type: 'link',
    url: el.getAttribute('href'),
  }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: (el: DeserializeElementProps) => ({
    type: 'image',
    url: el.getAttribute('src'),
  }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
};

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: (el: React.ReactNode) => ({ bold: true }),
  U: () => ({ underline: true }),
};

const NODE_TYPES = {
  ELEMENT: 1,
  TEXT: 3,
};

const serialize = (node: any): string => {
  const element = node as unknown as ElementProps;
  const style = `'text-align: ${element.align || 'initial'}'`;
  const quoteStyles = `'text-align: ${
    element.align || 'initial'
  }; border-left: 2px solid rgb(221, 221, 221); 
   margin-left: 0px; margin-right: 0px; padding-left: 10px;
   color: rgb(170, 170, 170); font-style: italic;'`;
  const codeStyles =
    'font-family: monospace; background-color: rgb(238, 238, 238); padding: 3px;';
  if (Text.isText(element)) {
    let string = escapeHtml(element.text);
    if (element.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (element.code) {
      string = `<code style=${codeStyles}>${string}</code>`;
    }
    if (element.italic) {
      string = `<em>${string}</em>`;
    }

    if (element.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children: React.ReactNode = element.children
    ?.map((n) => serialize(n))
    .join('');

  switch (element.type) {
    case 'quote':
      return `<blockquote style=${quoteStyles}>${children}</blockquote>`;
    case 'paragraph':
      return `<p style=${style}>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(element.url)}">${children}</a>`;
    case 'code':
      return `<pre style=${style}><code>${children}</code></pre>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'heading-one':
      return `<h1 style=${style}>${children}</h1>`;
    case 'heading-two':
      return `<h2 style=${style}>${children}</h2>`;
    case 'list-item':
      return `<li style=${style}>${children}</li>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    default:
      return `${children}`;
  }
};

export const deserialize: any = (el: React.ReactNode) => {
  const element = el as unknown as DeserializeElementProps;
  const style = {
    align: element.style?.textAlign || 'initial',
  };
  if (element.nodeType === NODE_TYPES['TEXT']) {
    return element.textContent;
  } else if (element.nodeType !== NODE_TYPES['ELEMENT']) {
    return null;
  } else if (element.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = element;
  let parent = element;

  if (
    nodeName === 'PRE' &&
    element.childNodes[0] &&
    element.childNodes[0].nodeName === 'CODE'
  ) {
    parent = element.childNodes[0];
  }
  let children: Array<any> = Array.from(parent.childNodes)
    .map(deserialize)
    .flat();

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (element.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS]) {
    const attrs = {
      ...ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS](element),
      ...style,
    };
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]) {
    const attrs = {
      ...TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS](el),
      ...style,
    };
    return children.map((child) => jsx('text', attrs, child));
  }

  return children;
};

export const withHtml = (editor: WithHtmlProp) => {
  editor.transfromFromHtml = (data) => {
    if (data) {
      const parsed = new DOMParser().parseFromString(data, 'text/html');
      const fragment = deserialize(parsed.body);
      return fragment;
    }
  };

  editor.transformToHtml = (data) => serialize({ children: [...data] });

  return editor;
};
