import { useState } from 'react';

import { Typography } from '@mui/material';

import LoginForm from 'components/forms/users/LoginForm';

import ErrorBlock from '../shared/ErrorBlock';
import { StyledLink } from './styledComponents';

const Login = () => {
  const [showLock, setShowLock] = useState(false);

  return showLock ? (
    <ErrorBlock
      title="You've been rate limited for 5 minutes"
      details="We apologize for any inconvience, but you have made too many requests."
    />
  ) : (
    <>
      <Typography variant="h2">Log In</Typography>
      <LoginForm onLock={() => setShowLock(true)} />
      <StyledLink to="/send_recovery_email">Forgot your password?</StyledLink>
    </>
  );
};

export default Login;
