import styled from '@emotion/styled';
import { colors } from 'styles/globalStyles';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ error }) => {
  return {
    '&': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      borderRadius: '4px',
      position: 'relative',
      padding: '6px 4px 6px 0',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-root ': {
      cursor: 'pointer',
      paddingLeft: '10px',
      border: '1px solid',
      borderColor: error ? colors.red : colors.borderGrey,
      '&:focus': {
        borderColor: colors.focusBlue,
        borderRadius: '4px',
        backgroundColor: colors.white,
      },
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      marginLeft: '2px',
    },
  };
});

export const InputFieldContainer = styled('div')({
  width: '100%',
});

export const AsteriskContainer = styled('div')({
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  color: colors.red,
  display: 'inline',
  fontSize: '18px',
});

export const StyledContainer = styled('div')({
  position: 'relative',
  width: '100%',
  '& .MuiFormLabel-root.Mui-error ': {
    whiteSpace: 'initial',
    minWidth: '100%',
  },
});
