import { colors } from 'styles/globalStyles';

export const ORDER_STATUSES = ['Accepted', 'Declined', 'Sent', 'Suggested'];
export const FOR_REVIEW_STATUS = 'For review';

export const statusOptions = ORDER_STATUSES.map((status) => ({
  key: status,
  value: status,
}));

export const statusColorsMap: { [key: string]: string } = {
  Partial: '#9300AB',
  New: '#FFB800',
  Accepted: colors.green,
  Declined: colors.red,
  Sent: colors.blue,
  Suggested: colors.orange,
  [FOR_REVIEW_STATUS]: '#FFB800',
};

export const statusIconsMap: { [key: string]: string } = {
  Accepted: 'check',
  Declined: 'close',
  Suggested: 'pause',
};

export const orderPopups = {
  CONFIRM_ORDER_DELETING: 'confirmOrderDeletingPopup',
  CONFIRM_ORDER_WEEKS_DELETING: 'confirmOrderWeekDeletingPopup',
  CHOOSE_DELETE_OPTION: 'chooseDeleteOptionPopup',
  MANAGE_COMMENTS: 'messagesModal',
};

export const successDeleteOrderToast = 'successDeleteOrderToast';
