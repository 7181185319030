import RESPONSE_CODES from 'constants/responseCodes';

const uploadFileToCloud = async (file: File, uploadURL: string) => {
  const arrayBuffer = await file.arrayBuffer();
  const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

  const response = await fetch(uploadURL, {
    method: 'PUT',
    body: blob,
  });

  if (response.status !== RESPONSE_CODES.OK) {
    throw new Error('Unable to upload the file');
  }

  return response;
};

export default uploadFileToCloud;
