import { CircularProgress } from '@mui/material';

import { SubmitButton } from 'components/UIComponents';

import { ReactComponent as UploadDocument } from 'assets/icons/uploadDocument.svg';

import { UploadButtonText } from './styledComponents';

interface UploadButtonProps {
  onClick: () => void;
  loading?: boolean;
  title: string;
}

const UploadButton = ({ onClick, title, loading = false }: UploadButtonProps) => {
  return (
    <SubmitButton disabled={loading} onClick={onClick}>
      {loading ? <CircularProgress color="inherit" size={16} /> : <UploadDocument />}
      <UploadButtonText>{title}</UploadButtonText>
    </SubmitButton>
  );
};

export default UploadButton;
