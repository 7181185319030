import { useEffect, useState, useContext } from 'react';

import { ButtonsBlock, SearchField } from 'components/UIComponents';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';

import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useRefreshHint from 'hooks/useRefreshHint';
import useWebSocket from 'hooks/useWebSocket';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { DataResponse } from 'interfaces/api';

import { StyledSearchBar, FilterContainer } from './styledComponents';

interface Props {
  tableProps: {
    name: string;
    columns: ITableColumn[];
    editAction?: (id: number, item?: Object) => void;
    rowsAsLinks?: boolean;
    withActions?: boolean;
    tableStyle?: React.CSSProperties;
  };
  dataFormatter: ((item: any) => object) | ((item: any, index: number) => object);
  dataProvider: (params: IFilterParams) => Promise<DataResponse>;
  barActions?: React.ReactNode;
  filter?: React.ReactNode;
  extraPageElementsHeight?: number;
  webSocketChannel?: string;
  skipSearch?: boolean;
  skipBulkSelect?: boolean;
}

const PAGE_HEADER_PART_HEIGHT = 195;

const perPage = 100;
const defaultPage = 1;

const ListOfRecords = ({
  tableProps,
  dataFormatter,
  dataProvider,
  barActions,
  webSocketChannel,
  filter,
  extraPageElementsHeight = 0,
  skipSearch = false,
  skipBulkSelect = false,
}: Props) => {
  const tableContainerStyles = {
    maxHeight: window.innerHeight - PAGE_HEADER_PART_HEIGHT - extraPageElementsHeight,
  };

  const loadMore = () => {
    if (hasMore) {
      getData({ newPage: page + 1 });
    }
  };

  const { filterOptions, getSerializedData } = useContext(FilterContext);
  const { setTotalCount } = useContext(BulkSelectContext);
  const [ref] = useInfiniteScroll(loadMore);
  const { resetTimer } = useRefreshHint();
  const [rowData, setRowData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const doSearch = (searchValue: string, searchCallback: () => void): void => {
    setSearch(searchValue);
    setRowData([]);
    setHasMore(true);
    setPage(defaultPage);
    getData({ searchValue, newPage: 1 }).then(() => {
      searchCallback();
    });
  };

  const getData = ({ searchValue, newPage }: { searchValue?: string; newPage?: number }): Promise<void> => {
    if (loading) {
      return new Promise(() => {});
    }
    resetTimer();

    setLoading(true);
    const result = dataProvider({
      search: searchValue == null ? search : searchValue,
      per_page: perPage,
      page: newPage || page,
      filterOptions: getSerializedData(),
    }).then((data: DataResponse) => {
      const items = data.data;
      if (!skipBulkSelect) {
        setTotalCount(data.total_count);
      }
      setHasMore(items.length === perPage);
      setRowData((prevState) => prevState.concat(items));
      setLoading(false);
      if (newPage) {
        setPage(newPage);
      }
    });
    return result;
  };

  useEffect(() => {
    setRowData([]);
    getData({ newPage: defaultPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, filterOptions]);

  useWebSocket(webSocketChannel, (data: any, type: string) => {
    setRowData((state) => {
      const replacingIndex = state.findIndex((el) => el.id === data.id);
      if (replacingIndex === -1) {
        return [data, ...state];
      } else {
        state.splice(replacingIndex, 1, data);
        return [...state];
      }
    });
  });

  return (
    <>
      {!skipSearch && (
        <StyledSearchBar>
          <SearchField onChange={doSearch} />
          <ButtonsBlock>{barActions}</ButtonsBlock>
        </StyledSearchBar>
      )}
      {filter && <FilterContainer>{filter}</FilterContainer>}
      <TableWithLazyLoad
        {...tableProps}
        data={rowData.map(dataFormatter)}
        tableContainerStyles={tableContainerStyles}
        tableRef={ref}
        showSkeleton={loading}
        search={search}
      />
    </>
  );
};

export default ListOfRecords;
