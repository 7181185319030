import { keysToSnakeCase } from 'helpers/utils';

import { SpotType } from 'constants/enums/SpotTypes';

import { IBulkActionData } from 'interfaces/IBulkActionData';
import { IFilterParams } from 'interfaces/IFilterParams';
import { IAttributionParams } from 'interfaces/Response/IAttributionParams';
import { IResponseDataInlineUpdate, IResponseDataToUpdate } from 'interfaces/Response/IResponseInlineUpdate';

import { request } from '../instance';

export const ResponseAPI = {
  getList: async ({ page, per_page, filterOptions = {} }: IFilterParams) => {
    const response = await request({
      url: 'v1/responses',
      method: 'GET',
      params: {
        page,
        per_page,
        ...filterOptions,
      },
    });

    return response.data;
  },

  getOne: async (responseId: number) => {
    const response = await request({
      url: `v1/responses/${responseId}`,
      method: 'GET',
    });

    return response.data;
  },

  update: async (id: number, data: IResponseDataToUpdate | IResponseDataInlineUpdate) => {
    const response = await request({
      url: `v1/responses/${id}`,
      method: 'PATCH',
      data: { data: { response: data } },
    });

    return response.data;
  },

  upload: async (params: { file_name: string }, file: File) => {
    try {
      const response = await request({
        url: 'v1/responses',
        method: 'POST',
        headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        data: file,
        params,
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getFilesByName: async (value: string) => {
    const response = await request({
      url: 'v1/responses/files_by_file_name',
      method: 'GET',
      params: { value },
    });

    return response.data.data;
  },

  remove: async (responseId: number, force_destroy?: boolean) => {
    try {
      const response = await request({
        url: `v1/responses/${responseId}`,
        method: 'DELETE',
        params: { force_destroy },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  bulkRemove: async (bulkData: IBulkActionData, filterParams: object = {}, force_destroy?: boolean) => {
    try {
      const response = await request({
        url: 'v1/responses/bulk_destroy',
        method: 'DELETE',
        data: {
          data: {
            response: {
              ...keysToSnakeCase(bulkData),
            },
          },
        },
        params: { force_destroy, ...filterParams },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  attribute: async (bulkData: IBulkActionData, filterParams: object = {}, spotId: number, spotType: SpotType) => {
    try {
      const response = await request({
        url: 'v1/responses/attribute',
        method: 'POST',
        params: filterParams,
        data: {
          data: {
            response: {
              ...keysToSnakeCase(bulkData),
              spot_id: spotId,
              spot_type: spotType,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  checkForManualAttributing: async (bulkData: IBulkActionData, filterOptions = {}) => {
    try {
      const response = await request({
        url: 'v1/responses/check_for_attribution',
        method: 'GET',
        params: {
          ...filterOptions,
          ...keysToSnakeCase(bulkData),
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  attributeExceptions: async (bulkData: IBulkActionData, filterOptions = {}) => {
    try {
      const response = await request({
        url: 'v1/responses/attribute_exceptions',
        method: 'PATCH',
        params: {
          ...filterOptions,
          ...keysToSnakeCase(bulkData),
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getSpotsForAttribution: async (
    attriubtionParams: IAttributionParams,
    { page, per_page, filterOptions = {} }: IFilterParams
  ) => {
    try {
      const response = await request({
        url: 'v1/responses/spots_for_attribution',
        method: 'GET',
        params: {
          code: attriubtionParams.code,
          date_to: attriubtionParams.date,
          page,
          per_page,
          ...filterOptions,
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getAttributionFilterData: async (attributionParams: IAttributionParams) => {
    try {
      const response = await request({
        url: 'v1/responses/filter_options_for_attribution',
        method: 'GET',
        params: {
          code: attributionParams.code,
          date_to: attributionParams.date,
        },
      });

      return response.data.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getCodes: async (value: string) => {
    const response = await request({
      url: 'v1/responses/codes',
      method: 'GET',
      params: { value },
    });

    return response.data.data;
  },
};
