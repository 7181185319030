import styled from '@emotion/styled';
import { TableCell } from '@mui/material';
import { colors } from '../../../styles/globalStyles';

export default styled(TableCell)`
  padding: 0 0 6px 30px;

  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.7px;
  color: ${colors.darkGrey};

  border-bottom: none;
`;
