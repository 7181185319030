import { styled } from '@mui/material/styles';

export const InputContainer = styled('div')({
  width: '100%',
  marginTop: '-5px',
  marginBottom: '-5px',
});

export const DisplayContainer = styled('div')({
  width: '100%',
  minWidth: '50px',
  minHeight: '16px',
  marginTop: '-7px',
  marginBottom: '-7px',
  cursor: 'pointer',
});
