import { useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { ModalContext } from 'context/ModalContext';

import { colors } from '../../../styles/globalStyles';
import { StyledDialog as Dialog } from './styledDialog';
import { StyledDialogTitle as DialogTitle } from './styledDialogTitle';

interface ModalProps {
  name: string;
  children: React.ReactNode;
  title: string;
  onModalClose: (id: string) => void;
  size?: Breakpoint | false;
}

const Modal = ({ name, children, title, onModalClose, size = 'sm' }: ModalProps) => {
  const { modalOpen } = useContext(ModalContext);

  return (
    <Dialog maxWidth={size} onClose={() => onModalClose(name)} open={modalOpen[name] || false}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={() => onModalClose(name)} sx={{ color: colors.white, padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default Modal;
