import { request } from 'api/instance';

export const GroupProductAPI = {
  getByName: async (value: string, for_order?: boolean) => {
    const response = await request({
      url: 'v1/group/products/search',
      method: 'GET',
      params: {
        value,
        for_order,
      },
    });

    return response.data.data;
  },
};
