import { cellStyles } from 'constants/spots';

import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import { colors } from 'styles/globalStyles';

import ActivityStatus from '../ActivityStatus';
import Cell from '../Cell';

interface Props {
  spot: IPodcastSpotsTable;
}

const ActivitiesCells = ({ spot }: Props) => {
  return (
    <>
      <Cell
        cellProps={{ style: cellStyles.Podcast.testShow }}
        content={
          <>
            <ActivityStatus active={spot.is_test_show} title="Test show" activeColor={colors.darkGrey} />
            <ActivityStatus
              active={spot.is_digitally_inserted}
              title="Digitally inserted"
              activeColor={colors.darkGrey}
            />
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles.Podcast.pixelRequired }}
        content={'\u0020'}
      />
    </>
  );
};

export default ActivitiesCells;
