import styled from '@emotion/styled';

import { CHECKBOX_WIDTH } from 'constants/spots';

import { colors } from 'styles/globalStyles';

export const Box = styled.div`
  vertical-align: top;
  border-bottom: none;
  border-top: 1px solid ${colors.tableDelimiter};

  padding: 13px 8px 16px 8px;

  width: ${CHECKBOX_WIDTH};
  box-sizing: border-box;
`;
