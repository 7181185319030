import { useContext, useState } from 'react';

import { ResponseAPI } from 'api/responseAPI';

import { FileUploadControl } from 'components/UIComponents';
import Popup from 'components/base/Popup';
import UploadButton from 'components/base/UploadButton';

import { ModalContext } from 'context/ModalContext';
import { ToastContext } from 'context/ToastContext';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IErrorResponseData, IResponse } from 'interfaces/api';

const RESPONSE_UPLOAD_ERROR_MODAL_NAME = 'RESPONSE_UPLOAD_ERROR_MODAL';

const UploadResponsesForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { onToastOpen } = useContext(ToastContext);
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onProcessFile = (file: File) => {
    setLoading(true);
    const filename = file.name;
    ResponseAPI.upload({ file_name: filename }, file).then((response: IResponse) => {
      setLoading(false);
      if (response.status === RESPONSE_CODES.OK) {
        onToastOpen(
          'ATTRIBUTION_PROCESS_STARTED',
          `The file ${filename} has been successfully uploaded, and the attribution process has started`,
          ToastType.info
        );
      } else {
        setErrorMessage((response.data as IErrorResponseData).error.details);
        onModalOpen(RESPONSE_UPLOAD_ERROR_MODAL_NAME);
      }
    });
  };

  return (
    <>
      <FileUploadControl
        inputProps={{
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'data-testid': 'responses-input',
        }}
        maxFileSize={10}
        ActionButton={({ onClick }: { onClick: () => void }) => (
          <UploadButton title="Upload responses" loading={loading} onClick={onClick} />
        )}
        onProcessFile={onProcessFile}
      />
      {errorMessage && (
        <Popup
          name={RESPONSE_UPLOAD_ERROR_MODAL_NAME}
          type={PopupType.error}
          title="Error"
          onModalClose={() => {
            setErrorMessage(undefined);
            onModalClose(RESPONSE_UPLOAD_ERROR_MODAL_NAME);
          }}
        >
          {errorMessage}
        </Popup>
      )}
    </>
  );
};

export default UploadResponsesForm;
