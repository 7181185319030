import { useState, useContext } from 'react';

import { PaymentAPI } from 'api/paymentAPI';

import { OverflowTip, StatusLabel } from 'components/UIComponents';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import Toast, { ToastParams } from 'components/base/Toast';
import CheckboxCell from 'components/base/tableComponents/CheckboxCell';
import PaymentFilterForm from 'components/forms/PaymentFilterForm';

import { BulkSelectState } from 'context/BulkSelectContext';
import { FilterContextState } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { PaymentActionsContext } from 'context/PaymentActionsContext';

import { getDate } from 'helpers/dateHelper';
import { moneyDisplayView } from 'helpers/money';
import { prepareFilterData } from 'helpers/payments';

import { ToastType } from 'constants/enums/PopupType';
import { paymentPopups } from 'constants/payments';
import { paymentStatuses } from 'constants/payments';
import RESPONSE_CODES from 'constants/responseCodes';

import { IBulkActionData } from 'interfaces/IBulkActionData';
import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IPaymentTable } from 'interfaces/Payment/IPaymentTable';

import SelectAllCheckbox from '../../../components/base/tableComponents/SelectAllCheckbox';
import CheckCell from './CheckCell';
import PaymentActions from './PaymentActions';
import PaymentActionsPopups from './PaymentActionsPopups';
import { cellStyles, statusToColorsMap } from './styledComponents';

const columns = [
  {
    dataField: 'checkbox',
    text: <SelectAllCheckbox />,
    headStyle: { width: '4%' },
  },
  {
    dataField: 'paymentDate',
    text: 'Payment Date',
    style: cellStyles,
    headStyle: { width: '12%' },
  },
  {
    dataField: 'product',
    text: 'Product',
    style: cellStyles,
    headStyle: { width: '15%' },
  },
  {
    dataField: 'parent',
    text: 'Group/Outlet',
    style: cellStyles,
    headStyle: { width: '15%' },
  },
  {
    dataField: 'paidTo',
    text: 'Paid to',
    style: cellStyles,
    headStyle: { width: '8%' },
  },
  {
    dataField: 'spots',
    text: 'Spots',
    style: cellStyles,
    headStyle: { width: '5%' },
  },
  {
    dataField: 'invoice',
    text: 'Invoice #',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
  {
    dataField: 'amount',
    text: 'Amount',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
  {
    dataField: 'status',
    text: 'Status',
    headStyle: { width: '10%' },
  },
  {
    dataField: 'check',
    text: 'Check #',
    style: cellStyles,
    headStyle: { width: '10%' },
  },
];

const FILTER_HEIGHT = 122;

const ListOfPayments = () => {
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const [paymentsProvider, setPaymentsProvider] = useState(() => (props: IFilterParams) => PaymentAPI.getList(props));
  const [currentPayment, setCurrentPayment] = useState<IPaymentTable | undefined>(undefined);
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<ToastParams>();

  const reloadList = () => {
    setPaymentsProvider(() => (props: any) => PaymentAPI.getList(props));
  };

  const onDeleteClick = (payment: any) => {
    setCurrentPayment(payment);
    onModalOpen(paymentPopups.CONFIRM_PAYMENT_DELETING);
  };

  const onBulkDeleteClick = () => {
    onModalOpen(paymentPopups.CONFIRM_BULK_PAYMENT_DELETING);
  };

  const successRemove = (data: string) => {
    setShowToast(true);
    setToastParams({ type: ToastType.success, message: data });
    reloadList();
  };

  const handleSubmitRemove = () => {
    PaymentAPI.removePayment(Number(currentPayment?.id)).then((response) => {
      if (response.status === RESPONSE_CODES.OK) {
        successRemove(response.data.data);
      }
    });

    onModalClose(paymentPopups.CONFIRM_PAYMENT_DELETING);
  };

  const handleBulkSubmitRemove = ({ selectAll, excludedIds, ids }: IBulkActionData, filterParams: object) => {
    PaymentAPI.bulkRemovePayment({ selectAll, excludedIds, ids }, filterParams).then((response) => {
      if (response.status === RESPONSE_CODES.OK) {
        successRemove(response.data.data);
      }
    });

    onModalClose(paymentPopups.CONFIRM_BULK_PAYMENT_DELETING);
  };

  const handleResponse = (response: any) => {
    if (response?.status === RESPONSE_CODES.OK) {
      reloadList();
    }
  };

  const changeStatusClick = async (paymentId: number, newStatus: string) => {
    const response = await PaymentAPI.changeStatus(paymentId, newStatus);
    handleResponse(response);
  };

  const onBulkChangeStatusClick = async (
    { selectAll, excludedIds, ids }: IBulkActionData,
    filterParams: object,
    newStatus: string
  ) => {
    const response = await PaymentAPI.bulkChangeStatus(newStatus, { selectAll, excludedIds, ids }, filterParams);
    handleResponse(response);
  };

  const clearedAction = (payment: IPaymentTable) => {
    if (payment.status === paymentStatuses.settled) {
      return { name: 'Mark as Cleared', action: () => changeStatusClick(payment.id, paymentStatuses.cleared) };
    } else {
      return { name: 'Unmark as Cleared', action: () => changeStatusClick(payment.id, paymentStatuses.settled) };
    }
  };

  const dataFormatter = (item: IPaymentTable) => ({
    id: item.id,
    checkbox: <CheckboxCell id={item.id} />,
    paymentDate: <OverflowTip title={getDate(item.created_at)!}>{getDate(item.created_at)}</OverflowTip>,
    product: <OverflowTip title={item.product_name}>{item.product_name}</OverflowTip>,
    parent: <OverflowTip title={item.parent_name}>{item.parent_name}</OverflowTip>,
    paidTo: <OverflowTip title={item.paid_to}>{item.paid_to}</OverflowTip>,
    spots: <OverflowTip title={String(item.spots_number)}>{item.spots_number}</OverflowTip>,
    invoice: <OverflowTip title={item.invoice}>{item.invoice}</OverflowTip>,
    amount: <OverflowTip title={moneyDisplayView(item.amount)}>{moneyDisplayView(item.amount)}</OverflowTip>,
    status: <StatusLabel color={statusToColorsMap[item.status]}>{item.status}</StatusLabel>,
    check: <CheckCell id={item.id} check={item.check || ''} status={item.status} />,
    actions: [clearedAction(item), { name: 'Delete', action: onDeleteClick }],
  });

  return (
    <FilterContextState dataSerializer={(filters: object) => prepareFilterData(filters)}>
      <PaymentActionsContext.Provider
        value={{
          onBulkDeleteClick,
          onBulkChangeStatusClick,
        }}
      >
        <BulkSelectState actions={<PaymentActions />}>
          <PageContainer>
            <PageTitle>Payments</PageTitle>
            <ListOfRecords
              tableProps={{
                name: 'payments',
                columns: columns as ITableColumn[],
                rowsAsLinks: false,
                tableStyle: { display: 'table', tableLayout: 'fixed' },
              }}
              barActions={<></>}
              dataFormatter={dataFormatter}
              dataProvider={paymentsProvider}
              filter={<PaymentFilterForm />}
              extraPageElementsHeight={FILTER_HEIGHT}
            />
            <PaymentActionsPopups handleBulkRemove={handleBulkSubmitRemove} handleRemove={handleSubmitRemove} />
            {toastParams && <Toast open={showToast} params={toastParams} onClose={() => setShowToast(false)} />}
          </PageContainer>
        </BulkSelectState>
      </PaymentActionsContext.Provider>
    </FilterContextState>
  );
};

export default ListOfPayments;
