import * as Yup from 'yup';
import { stringMaxLength, textMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const groupValidationSchema = Yup.object({
  companyName: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  address1: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  address2: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  city: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  state: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  zip: Yup.string().optional().max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  country: Yup.string().optional(),
  terms: Yup.string().optional(),
  orderPreferences: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  comment: Yup.string().optional().max(textMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)),
});
