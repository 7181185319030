import { useContext } from 'react';

import { Skeleton } from '@mui/material';

import { SpotTypeContext } from 'context/SpotTypeContext';

import { isPodcastSpot } from 'helpers/spotsHelper';

import { cellStyles } from 'constants/spots';

import Cell from '../Cell';
import { RowContainer } from '../Row/styledComponents';

const SkeletonRow = () => {
  const type = useContext(SpotTypeContext);

  return (
    <RowContainer>
      <Cell cellProps={{ style: cellStyles[type].checkbox }} />
      <Cell
        cellProps={{ style: cellStyles[type].outlet }}
        header={<Skeleton variant="rectangular" />}
        content={
          <>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles[type].market }}
        header={<Skeleton variant="rectangular" />}
        content={
          <>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles[type].format }}
        header={<Skeleton variant="rectangular" />}
        content={
          <>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        }
      />
      <Cell
        cellProps={{ style: isPodcastSpot(type) ? cellStyles.Podcast.testShow : cellStyles.Legacy.code }}
        content={
          <>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        }
      />
      {isPodcastSpot(type) ? (
        <Cell cellProps={{ style: cellStyles.Podcast.pixelRequired }} content={<Skeleton variant="rectangular" />} />
      ) : null}

      <Cell cellProps={{ style: cellStyles[type].gross }} content={<Skeleton variant="rectangular" />} />
      <Cell
        cellProps={{ style: cellStyles[type].grossAmount }}
        content={
          <>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        }
      />
      <Cell cellProps={{ style: cellStyles[type].net }} content={<Skeleton variant="rectangular" />} />
      <Cell
        cellProps={{ style: cellStyles[type].netAmount }}
        content={
          <>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </>
        }
      />
      <Cell
        cellProps={{ style: cellStyles[type].actions }}
        header={<Skeleton variant="rectangular" />}
        content={<Skeleton variant="rectangular" />}
      />
    </RowContainer>
  );
};

export default SkeletonRow;
