import { StyledStatusLabel } from './styledStatusLabelBlocks';

interface Props {
  active?: boolean;
}

const ActiveStatusLabel = ({ active = false }: Props) => {
  return <StyledStatusLabel active={active}>{active ? 'Active' : 'Inactive'}</StyledStatusLabel>;
};

export default ActiveStatusLabel;
