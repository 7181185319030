import { Skeleton } from '@mui/material';

import { times } from 'lodash';

import { StyledBodyTableCell as BodyTableCell, StyledTableRow as BodyTableRow } from '../styledComponents';

const SKELETON_ROWS_COUNT = 10;

const TableSkeleton = ({ columns, withActions }: { columns: any[]; withActions: boolean }) => {
  const columnsCount = columns.length + (withActions ? 1 : 0);

  return (
    <>
      {times(SKELETON_ROWS_COUNT, (i: number) => (
        <BodyTableRow key={`${i}-skeleton`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {times(columnsCount, (i: number) => (
            <BodyTableCell key={i} align="right">
              <Skeleton variant="rectangular" />
            </BodyTableCell>
          ))}
        </BodyTableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
