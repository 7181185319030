import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

export const InputFieldContainer = styled('div')({
  width: '100%',
  display: 'flex',
});

export const StyledDash = styled('div')({
  margin: 'auto',
  marginTop: '10px',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '14px',
  letterSpacing: '0.06em',
  textAlign: 'center',
  color: colors.grey,
  minWidth: '32px',
});
