import { useEffect, useState } from 'react';

import { ResponseAPI } from 'api/responseAPI';
import { Form, Formik } from 'formik';

import { Input, OutlinedButton, SubmitButton, TextArea } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { responseValidationSchema } from 'helpers/validation/responseValidationSchema';

import { IResponseData } from 'interfaces/Response/IResponseData';
import { IResponseForm } from 'interfaces/Response/IResponseForm';

import { shortFieldStyles } from './styledComponents';

interface ResponseFormProps {
  id: number;
  onClose: () => void;
}

const ResponseForm = ({ id, onClose }: ResponseFormProps) => {
  const [data, setData] = useState<IResponseForm>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    ResponseAPI.getOne(id).then(({ data }: { data: IResponseData }) => {
      setData({
        callsIn: data.calls_in?.toString(),
        callsAnswered: data.calls_answered?.toString(),
        texts: data.texts?.toString(),
        visits: data.visits?.toString(),
        views: data.views?.toString(),
        midfunnel: data.midfunnel?.toString(),
        orders: data.orders?.toString(),
        revenue1: data.revenue1?.toString(),
        revenue2: data.revenue2?.toString(),
        revenue3: data.revenue3?.toString(),
        metric1: data.metric1?.toString(),
        metric2: data.metric2?.toString(),
        metric3: data.metric3?.toString(),
        impressions: data.impressions?.toString(),
        reach: data.reach?.toString(),
        text1: data.text1?.toString(),
        text2: data.text2?.toString(),
      });
    });
  }, [id]);

  return (
    <>
      <Formik
        initialValues={data || {}}
        enableReinitialize
        validationSchema={responseValidationSchema}
        validateOnBlur
        onSubmit={(values: { [key: string]: any }) => {
          setDisabled(true);

          const attributes: { [key: string]: any } = {};
          Object.keys(values).forEach((key) => (attributes[key] = values[key] && Number(values[key])));
          attributes['calls_in'] = attributes['callsIn'];
          attributes['calls_answered'] = attributes['callsAnswered'];
          delete attributes.callsIn;
          delete attributes.callsAnswered;

          ResponseAPI.update(id, {
            ...attributes,
            text1: values.text1,
            text2: values.text2,
          } as IResponseData).then(() => {
            setDisabled(false);
            onClose();
          });
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <Input name="callsIn" label="Calls In" styles={shortFieldStyles} />
              <Input name="callsAnswered" label="Calls Answered" styles={shortFieldStyles} />
              <Input name="texts" label="Texts" styles={shortFieldStyles} />
              <Input name="visits" label="Visits" styles={shortFieldStyles} />
              <Input name="views" label="Views" styles={shortFieldStyles} />
              <Input name="midfunnel" label="MidFunnel" styles={shortFieldStyles} />
              <Input name="orders" label="Orders" styles={shortFieldStyles} />
              <Input name="revenue1" label="Revenue1" styles={shortFieldStyles} />
              <Input name="revenue2" label="Revenue2" styles={shortFieldStyles} />
              <Input name="revenue3" label="Revenue3" styles={shortFieldStyles} />
              <Input name="metric1" label="Metric1" styles={shortFieldStyles} />
              <Input name="metric2" label="Metric2" styles={shortFieldStyles} />
              <Input name="metric3" label="Metric3" styles={shortFieldStyles} />
              <Input name="impressions" label="Impressions" styles={shortFieldStyles} />
              <Input name="reach" label="Reach" styles={shortFieldStyles} />
              <TextArea name="text1" label="Text1" />
              <TextArea name="text2" label="Text2" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton disabled={disabled || !dirty || !!Object.values(errors).length} onClick={handleSubmit}>
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResponseForm;
