import { ISelectOption } from 'interfaces/ISelectOption';

interface Props {
  source: Promise<any[]>,
  valueAttr?: string,
  keyAttr?: string,
}

export const getOptions = async ({
  source,
  valueAttr = 'id',
  keyAttr = 'name',
}: Props): Promise<ISelectOption[]> => {
  try {
    const items = await source;
    const results = items?.map((item: any) => ({
      key: item[keyAttr],
      value: item[valueAttr],
      add_attributes: item,
    }));
    return results;
  } catch (err) {
    return [];
  }
};
