import { useState, useContext } from 'react';

import { OutletAPI } from 'api/outletAPI';

import { OutlinedButton, ActiveStatusLabel, LinkButton } from 'components/UIComponents';
import Modal from 'components/base/BaseModal';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import OutletForm from 'components/forms/OutletForm';

import { ModalContext } from 'context/ModalContext';

import useDeleteFlow from 'hooks/useDeleteFlow';

import { ModelName } from 'constants/enums/ModelName';

import { ITableColumn } from 'interfaces/ITableColumn';
import { IOutletsTable } from 'interfaces/Outlet/IOutletTable';

const columns = [
  { dataField: 'name', text: 'Outlet' },
  { dataField: 'type', text: 'Media type' },
  { dataField: 'group', text: 'Group' },
  { dataField: 'adType', text: 'Ad Type' },
  { dataField: 'market', text: 'Market' },
  { dataField: 'status', text: 'Status' },
];
const OUTLET_FORM_MODAL_NAME = 'outletFormModal';

const ListOfOutlets = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const [editOutletId, setEditOutletId] = useState(0);
  const [outletsProvider, setOutletsProvider] = useState(() => (props: any) => OutletAPI.getList(props));

  const reloadList = () => {
    setOutletsProvider(() => (props: any) => OutletAPI.getList(props));
  };

  const onGroupClick = (groupId: number) => {
    window.open(`/groups/${groupId}`, '_blank', 'noreferrer');
  };

  const onDeleteClick = useDeleteFlow(ModelName.Outlet, reloadList);

  const dataFormatter = (item: IOutletsTable) => ({
    id: item.id,
    name: item.name,
    type: item.type,
    group: (
      <LinkButton
        mode="dark"
        onClick={(e) => {
          e.stopPropagation();
          onGroupClick(item.group_id);
        }}
      >
        {item.company_name}
      </LinkButton>
    ),
    adType: item.ad_type,
    market: item.market,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [{ name: 'Delete', action: () => onDeleteClick(item) }],
  });

  const onAddNewClick = () => {
    setEditOutletId(0);
    onModalOpen(OUTLET_FORM_MODAL_NAME);
  };

  const onEditClick = (outletId: number) => {
    setEditOutletId(outletId);
    onModalOpen(OUTLET_FORM_MODAL_NAME);
  };

  return (
    <PageContainer>
      <PageTitle>Outlets</PageTitle>
      <ListOfRecords
        tableProps={{
          name: 'outlets',
          columns: columns as ITableColumn[],
          editAction: onEditClick,
          rowsAsLinks: true,
        }}
        barActions={<OutlinedButton onClick={onAddNewClick}>Add Outlet</OutlinedButton>}
        dataFormatter={dataFormatter}
        dataProvider={outletsProvider}
      />
      {
        <Modal
          name={OUTLET_FORM_MODAL_NAME}
          title={editOutletId ? 'Edit Outlet' : 'Add Outlet'}
          onModalClose={onModalClose}
        >
          <OutletForm
            onModalClose={() => onModalClose(OUTLET_FORM_MODAL_NAME)}
            successAction={reloadList}
            editOutletId={editOutletId}
          />
        </Modal>
      }
    </PageContainer>
  );
};

export default ListOfOutlets;
