import { Navigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import { UserRole } from 'constants/enums/UserRole';

const IndexPage = () => {
  const { user } = useAuth();

  let path = '';
  if (!user) {
    path = '/login';
  } else if (user.role === UserRole.group) {
    path = '/orders';
  } else {
    path = '/groups';
  }

  return <Navigate to={path} replace />;
};

export default IndexPage;
