import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const CellContainer = styled.div`
  vertical-align: top;
  border-bottom: none;
  border-top: 1px solid ${colors.tableDelimiter};
  box-sizing: border-box;

  &:nth-of-type(2) {
    padding-left: 8px;
  }

  padding: 16px;
`;

export const CellBody = styled.div`
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 16.7px;
  color: ${colors.darkGrey};

  vertical-align: top;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const CellHeader = styled.div`
  width: 100%;
  height: 22px;
  line-height: 22px;
`;

export const CellContent = styled.div`
  width: 100%;
  line-height: 22px;
`;
