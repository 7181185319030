import { useEffect, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { ButtonsBlock, LinkButton, Snackbar } from 'components/UIComponents';

const RefreshReminder = ({ callback }: { callback: Function }) => {
  const [open, setOpen] = useState<boolean>(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleClose = () => {
    setOpen(false);
    setNewTimer();
  };

  const setNewTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setOpen(true);
      // eslint-disable-next-line no-magic-numbers
    }, 5 * 1000 * 60);
  };

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    setNewTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  const handleRefresh = () => {
    callback();
    handleClose();
  };

  const actions = (
    <div style={{ marginLeft: 70 }}>
      <ButtonsBlock>
        <LinkButton mode="light" onClick={handleRefresh}>
          Refresh
        </LinkButton>
        <IconButton size="medium" aria-label="Close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </ButtonsBlock>
    </div>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      message="Refresh the list to ensure you have the latest items"
      action={actions}
    />
  );
};

export default RefreshReminder;
