import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { UserAPI } from 'authApi/userAPI';

import ExpirationErrorBlock from 'containers/Auth/shared/ErrorBlock/ExpirationErrorBlock';

import RESPONSE_CODES from 'constants/responseCodes';

import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [resetPasswordToken, setToken] = useState<string>();
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role');

  useEffect(() => {
    (async () => {
      const token = searchParams.get('reset_password_token');

      if (token) {
        const response = await UserAPI.checkResetPasswordToken(token);
        if (response.status === RESPONSE_CODES.OK) {
          setToken(token);
        }
      }

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <CircularProgress sx={{ marginTop: '20px' }} color="inherit" />;
  }

  return resetPasswordToken ? (
    <ResetPasswordForm role={role} token={resetPasswordToken} />
  ) : (
    <ExpirationErrorBlock role={role} />
  );
};

export default ResetPassword;
