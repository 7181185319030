import { IFilterParams } from 'interfaces/IFilterParams';
import { IEmployeeForm } from 'interfaces/User/Employees/IEmployeeForm';

import { request } from '../instance';

export const UserAPI = {
  getByName: async (value: string, isFitler?: boolean) => {
    const response = await request({
      url: 'v1/users/search',
      method: 'GET',
      params: { value, is_filter: isFitler },
    });

    return response.data.data;
  },

  getList: async (params: IFilterParams) => {
    const response = await request({
      url: 'v1/users/employees',
      method: 'GET',
      params,
    });

    return response.data;
  },

  getOne: async (userId: number) => {
    const response = await request({
      url: `v1/users/employees/${userId}`,
      method: 'GET',
    });

    return response.data;
  },

  getGroupsUsersList: async (params: IFilterParams) => {
    const response = await request({
      url: 'v1/users/groups',
      method: 'GET',
      params,
    });

    return response.data;
  },

  create: async (data: IEmployeeForm, force: boolean = false) => {
    try {
      const response = await request({
        url: 'v1/users/invitation',
        method: 'POST',
        data: { data: { user: data, force } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  update: async (userId: number, data: IEmployeeForm) => {
    try {
      const response = await request({
        url: `v1/users/employees/${userId}`,
        method: 'PUT',
        data: { data: { user: data } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchActivation: async (userId: number, activate: boolean) => {
    const response = await request({
      url: `v1/users/${userId}/switch_activation`,
      method: 'PATCH',
      data: {
        data: {
          user: {
            activate: activate,
          },
        },
      },
    });

    return response.data;
  },
};
