import { useContext, useState } from 'react';

import { OrderAPI } from 'api/orderAPI';

import { CustomIcon } from 'components/UIComponents';
import Popup from 'components/base/Popup';
import { StyledIconButton } from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { ToastContext } from 'context/ToastContext';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import { orderPopups, successDeleteOrderToast } from 'constants/orders';
import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

import { colors } from 'styles/globalStyles';

interface IWarning {
  title: string;
  details: string;
  type: PopupType;
}

const DeleteOrdersAction = () => {
  const { onModalClose, onModalOpen } = useContext(ModalContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const { ids, excludedIds, selectAll, onChangeSelectAll } = useContext(BulkSelectContext);
  const { getSerializedData } = useContext(FilterContext);
  const { onToastOpen } = useContext(ToastContext);
  const [warning, setWarning] = useState<IWarning>({
    title: '',
    details: '',
    type: PopupType.warning,
  });

  const successOrderRemove = (message: string) => {
    onModalClose(orderPopups.CONFIRM_ORDER_DELETING);
    onToastOpen(successDeleteOrderToast, message, ToastType.success);
    onChangeSelectAll(false);
    reloadList();
  };

  const handleOrdersRemove = (force_destroy?: boolean) => {
    OrderAPI.bulkRemoveOrders({ selectAll, excludedIds, ids }, getSerializedData(), force_destroy).then(
      (response: IResponse) => {
        if (response.status === RESPONSE_CODES.OK) {
          successOrderRemove(response.data.data);
        }

        if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
          setWarning(response.data.error);
          onModalOpen(orderPopups.CONFIRM_ORDER_DELETING);
        }
      }
    );
  };

  const onDeleteClick = () => {
    handleOrdersRemove();
  };

  return (
    <>
      <StyledIconButton onClick={onDeleteClick}>
        <CustomIcon size="normal" name="trash" color={colors.trashIconColor} />
      </StyledIconButton>
      <Popup
        name={orderPopups.CONFIRM_ORDER_DELETING}
        type={PopupType.warning}
        title={warning.title}
        onModalClose={() => onModalClose(orderPopups.CONFIRM_ORDER_DELETING)}
        handleSubmit={() => handleOrdersRemove(true)}
        submitText="Delete"
        cancelText="Cancel"
      >
        {warning.details}
      </Popup>
    </>
  );
};

export default DeleteOrdersAction;
