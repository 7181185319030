import { ActionsGroup, DeleteActionItem } from 'components/base/tableComponents/BulkActionsPanel/styledComponents';

import DeclineAction from 'containers/Orders/shared/DeclineAction';
import BaseChangeStatusBulkAction from 'containers/Orders/shared/BaseChangeStatusBulkAction';
import DeleteOrdersAction from './DeleteOrdersAction';
import SendAction from './SendAction';

const ListOfOrdersActions = () => {
  return (
    <>
      <ActionsGroup>
        <SendAction />
      </ActionsGroup>
      <ActionsGroup>
        <BaseChangeStatusBulkAction buttonText="Accept" status='Accepted' />
        <DeclineAction />
      </ActionsGroup>
      <DeleteActionItem>
        <DeleteOrdersAction />
      </DeleteActionItem>
    </>
  );
};

export default ListOfOrdersActions;
