import { useContext } from 'react';

import { RefreshContext } from 'context/RefreshContext';

const useRefreshHint = () => {
  const { resetTimer } = useContext(RefreshContext);

  return { resetTimer };
};

export default useRefreshHint;
