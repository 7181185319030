import { createContext } from 'react';

import { IBulkActionData } from 'interfaces/IBulkActionData';

interface IPaymentActionsContext {
  onBulkDeleteClick: ({ selectAll, excludedIds, ids }: IBulkActionData, filterParams: object) => void;
  onBulkChangeStatusClick: (
    { selectAll, excludedIds, ids }: IBulkActionData,
    filterParams: object,
    newStatus: string
  ) => void;
}

export const PaymentActionsContext = createContext<IPaymentActionsContext>({
  onBulkDeleteClick: ({ selectAll, excludedIds, ids }: IBulkActionData, filterParams: object) => {},
  onBulkChangeStatusClick: (
    { selectAll, excludedIds, ids }: IBulkActionData,
    filterParams: object,
    newStatus: string
  ) => {},
});
