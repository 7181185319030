import { useState } from 'react';

import { IconButton, InputAdornment } from '@mui/material';

import { useField } from 'formik';

import { CustomIcon, FormControl, Label } from 'components/UIComponents';

import { StyledInputBase } from './styledComponents';

interface InputProps {
  label: string;
  name: string;
}

const PasswordInput = ({ label, name }: InputProps) => {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  const renderAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton sx={{ padding: '4px' }} onClick={() => setShowPassword(!showPassword)}>
          <CustomIcon size="large" name={showPassword ? 'eye' : 'crossedEye'} />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <FormControl direction="column">
      <Label shrink>{label}</Label>
      <StyledInputBase
        error={meta.touched && !!meta.error}
        {...field}
        type={showPassword ? 'text' : 'password'}
        id={name}
        name={name}
        endAdornment={renderAdornment()}
        placeholder="Enter your password"
        inputProps={{
          'aria-label': label,
        }}
      />
      <Label error>{meta.touched && meta.error}</Label>
    </FormControl>
  );
};

export default PasswordInput;
