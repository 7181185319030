import styled from '@emotion/styled';

import { colors } from 'styles/globalStyles';

interface Props {
  color?: string;
}

export const Details = styled.span<Props>`
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.51px;
  letter-spacing: -0.32;
  color: ${(props) => props.color || colors.grey};
  text-align: center;
`;
