import * as Yup from 'yup';

import VALIDATION_MESSAGES from 'constants/validationMessages';
import {
  minValue,
  spotRateMaxValue,
} from 'constants/fieldsLimits';

export const reviseRatesValidationSchema = Yup.object({
  grossRate: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotRateMaxValue,
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2')
    )
    .test(
      'is valid',
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2'),
      (value: number | undefined) =>
        /^[0-9]{0,7}[.]{0,1}[0-9]{0,2}$/.test(String(value))
    ),
  netRate: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .min(minValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      spotRateMaxValue,
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2')
    )
    .test(
      'is valid',
      VALIDATION_MESSAGES.FRACTIONAL_NUMBER_MAX_LENGTH('7', '2'),
      (value: number | undefined) =>
        /^[0-9]{0,7}[.]{0,1}[0-9]{0,2}$/.test(String(value))
    ),
});
