import WithPermission from 'components/base/WithPermission';

import { UserRole } from 'constants/enums/UserRole';

import SMINavItems from './SMINavItems';
import { NavBarContainer } from './styledNavBarBlocks';

const NavBar = () => {
  return (
    <NavBarContainer>
      <WithPermission permittedRoles={[UserRole.admin, UserRole.employee]}>
        <SMINavItems />
      </WithPermission>
    </NavBarContainer>
  );
};

export default NavBar;
