import { useEffect, useState, useRef } from 'react';
import { StyledChip } from './styledComponents';

interface ChipProps {
  option: string;
}

const Chip = ({ option, ...props }: ChipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && ref.current?.children[0]) {
      const compare =
        ref.current.children[0].scrollWidth >
        ref.current.children[0].clientWidth;
      setIsOverflow(compare);
    }
  }, []);

  return (
    <StyledChip
      ref={ref}
      variant="filled"
      label={option}
      title={isOverflowed ? option : undefined}
      data-testid='chip'
      {...props}
    />
  );
};

export default Chip;
