import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    width: '100%',
    maxHeight: '720px',
    borderRadius: '5px',
  },
}));
