import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

import { colors, defaultButtonsGap } from 'styles/globalStyles';

export const StyledDialogActions = styled(DialogActions)(() => ({
  '&.MuiDialogActions-root': {
    padding: '17px 30px',
    backgroundColor: colors.dialogActionsGrey,
    gap: defaultButtonsGap,
    position: 'sticky',
    zIndex: 1,
    bottom: 0,
    '& :not(:first-of-type)': {
      margin: 0,
    },
  },
}));

export const StyledCheckboxContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifySelf: 'left',
  marginLeft: '-10px',
  width: '100%',
}));
