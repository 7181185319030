import { useState, useEffect, useContext } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';
import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import NoResultsBlock from 'components/base/tableComponents/NoResultsBlock';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { SpotTypeContext } from 'context/SpotTypeContext';

import useRefreshHint from 'hooks/useRefreshHint';

import { isPodcastSpot, spotsPlayed } from 'helpers/spotsHelper';

import { SpotType } from 'constants/enums/SpotTypes';

import { IFilterParams } from 'interfaces/IFilterParams';
import { IListTotals } from 'interfaces/spots/IListTotals';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import ActionsPanel from './ActionsPanel';
import Filter from './Filter';
import Footer from './Footer';
import HighlightedSpot from './HighlightedSpot';
import List from './List';

interface DataResponse {
  data: IPodcastSpotsTable[] | ILegacySpotsTable[];
}

interface TotalsResponseData {
  overall_total_gross: number;
  overall_total_net: number;
  total_count: number;
  total_aired_spots: number;
  total_spots: number;
}

interface Props {
  dataProvider: (params: IFilterParams) => Promise<DataResponse>;
  onAddNewClick: () => void;
  editAction: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
  type: SpotType;
}

const PER_PAGE = 100;
const DEFAULT_PAGE = 1;

const ListOfSpots = ({ type, dataProvider, onAddNewClick, editAction }: Props) => {
  const loadMore = () => {
    if (hasMore) {
      getData({ newPage: page + 1 });
    }
  };

  const { filterOptions, getSerializedData } = useContext(FilterContext);
  const { setTotalCount } = useContext(BulkSelectContext);
  const { resetTimer } = useRefreshHint();
  const [rowData, setRowData] = useState<any[]>([]);

  const [totals, setTotals] = useState<IListTotals>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [hasMore, setHasMore] = useState(true);

  const getData = ({ newPage }: { newPage?: number }): Promise<void> => {
    if (loading) {
      return new Promise(() => {});
    }

    resetTimer();
    setLoading(true);

    const result = dataProvider({
      per_page: PER_PAGE,
      page: newPage || page,
      filterOptions: getSerializedData(),
    }).then((data: DataResponse) => {
      const items = data.data;
      setHasMore(items.length === PER_PAGE);
      setRowData((prevState) => prevState.concat(items));
      setLoading(false);
      if (newPage) {
        setPage(newPage);
      }
    });
    return result;
  };

  const getTotals = () => {
    const spotAPI = type === SpotType.legacy ? LegacySpotAPI : PodcastSpotAPI;
    spotAPI.getListTotals(getSerializedData()).then((data: TotalsResponseData) => {
      setTotals({
        totalGross: data.overall_total_gross,
        totalNet: data.overall_total_net,
        totalCount: data.total_count,
        totalAiredSpots: isPodcastSpot(type) ? '' : spotsPlayed(data.total_aired_spots, data.total_spots, true),
      });
      setTotalCount(data.total_count);
    });
  };

  useEffect(() => {
    setRowData([]);
    setTotals(undefined);
    getData({ newPage: DEFAULT_PAGE });
    getTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, filterOptions]);

  return (
    <SpotTypeContext.Provider value={type}>
      <Filter total={totals?.totalCount} />
      <ActionsPanel showSelectAll={rowData.length !== 0} onAddNewClick={onAddNewClick} />
      <HighlightedSpot type={type} editAction={editAction} dataProvider={dataProvider} />

      {rowData.length === 0 && !loading ? (
        <NoResultsBlock />
      ) : (
        <List
          hasNextPage={hasMore}
          isNextPageLoading={loading}
          items={rowData}
          loadNextPage={loadMore}
          editAction={editAction}
          type={type}
        />
      )}
      {rowData.length === 0 ? '' : <Footer totals={totals} />}
    </SpotTypeContext.Provider>
  );
};

export default ListOfSpots;
