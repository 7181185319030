import { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IFilterContext {
  filterOptions: object;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  onFilter: (filters: object) => void;
  getSerializedData: () => object;
}

export const FilterContext = createContext<IFilterContext>({
  filterOptions: {},
  expanded: false,
  setExpanded: (expanded: boolean) => {},
  onFilter: (filters: object) => {},
  getSerializedData: () => ({}),
});

interface FilterContextStateProps {
  children: React.ReactNode;
  dataSerializer: (data: object) => object;
  ignoreUrlParams?: boolean;
  initialOptions?: object;
}

export const FilterContextState = ({
  children,
  dataSerializer,
  ignoreUrlParams = false,
  initialOptions = {},
}: FilterContextStateProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParam = searchParams.get('filter');

  const [filterOptions, setFilterOptions] = useState(
    !ignoreUrlParams && filterParam ? JSON.parse(filterParam) : initialOptions
  );
  const [expanded, setExpanded] = useState<boolean>(false);

  const onFilter = (filters: object) => {
    setFilterOptions(filters);
    if (!ignoreUrlParams) {
      setSearchParams({ filter: JSON.stringify(filters) });
    }
  };

  const getSerializedData = () => dataSerializer(filterOptions);

  return (
    <FilterContext.Provider
      value={{
        filterOptions,
        expanded,
        setExpanded,
        onFilter,
        getSerializedData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
