import { useState } from 'react';

import { UserAPI } from 'api/userAPI';

import SwitchActivationAction from 'containers/users/shared/SwitchActivationAction';

import { ActiveStatusLabel, LinkButton } from 'components/UIComponents';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';

import { BaseListActionsContext } from 'context/BaseListActions';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IGroupsUsersTable } from 'interfaces/User/GroupsUsers/IGroupsUsersTable';

const columns = [
  { dataField: 'name', text: 'Name' },
  { dataField: 'companyName', text: 'Group' },
  { dataField: 'email', text: 'Email' },
  { dataField: 'status', text: 'Status' },
];

const ListOfGroupsUsers = () => {
  const [groupsUsersProvider, setGroupsUsersProvider] = useState(
    () => (props: IFilterParams) => UserAPI.getGroupsUsersList(props)
  );
  const [activationItem, setActivationItem] = useState<IGroupsUsersTable>();

  const reloadList = () => {
    setGroupsUsersProvider(() => (props: any) => UserAPI.getGroupsUsersList(props));
  };

  const onGroupClick = (groupId: number) => {
    window.open(`/groups/${groupId}`, '_blank', 'noreferrer');
  };

  const switchActivation = (item: IGroupsUsersTable) => {
    setActivationItem(item);
  };

  const dataFormatter = (item: IGroupsUsersTable) => ({
    id: item.id,
    name: item.name,
    companyName: (
      <LinkButton mode="dark" onClick={() => onGroupClick(item.group_id)}>
        {item.company_name}
      </LinkButton>
    ),
    email: item.email,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      {
        name: item.is_active ? 'Deactivate' : 'Activate',
        action: () => switchActivation(item),
      },
    ],
  });

  return (
    <PageContainer>
      <PageTitle>Groups</PageTitle>
      <BaseListActionsContext.Provider value={{ reloadList }}>
        <ListOfRecords
          tableProps={{
            name: 'groups/users',
            columns: columns as ITableColumn[],
            rowsAsLinks: false,
          }}
          barActions={<></>}
          dataFormatter={dataFormatter}
          dataProvider={groupsUsersProvider}
        />
        {activationItem && (
          <SwitchActivationAction user={activationItem} onClose={() => setActivationItem(undefined)} />
        )}
      </BaseListActionsContext.Provider>
    </PageContainer>
  );
};

export default ListOfGroupsUsers;
