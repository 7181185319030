import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const ResultsText = styled.span`
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.grey};

  & .results-value {
    font-weight: 500;
    color: ${colors.darkGrey};
  }
`;
