import { CSSProperties, Ref, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListOnItemsRenderedProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { FilterContext } from 'context/FilterContext';

import {
  ALL_EXCEPT_TABLE_HEIGHT,
  EXPANDED_LEGACY_FILTER_HEIGHT,
  EXPANDED_FILTER_HEIGHT,
  HIDDEN_FILTER_HEIGHT,
  ROW_HEIGHT,
  SELECTED_SPOT_ID_KEY,
  SKELETON_ROWS_COUNT,
} from 'constants/spots';

import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

import { isPodcastSpot } from 'helpers/spotsHelper';

import Row from './Row';
import SkeletonRow from './SkeletonRow';

interface ListProps {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: ILegacySpotsTable[] | IPodcastSpotsTable[];
  loadNextPage: () => void;
  editAction: (spot: IPodcastSpotsTable | ILegacySpotsTable) => void;
  type: string;
}

const List = ({ hasNextPage, isNextPageLoading, items, loadNextPage, editAction, type }: ListProps) => {
  const [searchParams] = useSearchParams();
  const spotId = searchParams.get(SELECTED_SPOT_ID_KEY);

  const { expanded } = useContext(FilterContext);

  const expandedFilterHeight = isPodcastSpot(type) ? EXPANDED_FILTER_HEIGHT : EXPANDED_LEGACY_FILTER_HEIGHT;
  const filter = expanded ? expandedFilterHeight : HIDDEN_FILTER_HEIGHT;
  const height = window.innerHeight - ALL_EXCEPT_TABLE_HEIGHT - filter - (spotId ? ROW_HEIGHT : 0);

  const itemCount = hasNextPage ? (items.length || SKELETON_ROWS_COUNT) + 1 : items.length;

  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = <SkeletonRow />;
    } else {
      content = <Row key={items[index].id} spot={items[index]} editAction={editAction} />;
    }

    return <div style={style}>{content}</div>;
  };

  return (
    <div data-testid="list-container" style={{ height }}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
            {({
              onItemsRendered,
              ref,
            }: {
              onItemsRendered: (props: ListOnItemsRenderedProps) => any;
              ref: Ref<any>;
            }) => (
              <FixedSizeList
                className="List"
                height={height}
                itemCount={itemCount}
                itemSize={ROW_HEIGHT}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
};

export default List;
