import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import { colors } from '../../../../../styles/globalStyles';

export const StyledDropdownNavLink = styled(NavLink)`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  text-transform: capitalize;

  &:hover {
    background-color: ${colors.aliceBlue} !important;
  }

  &.active {
    color: ${colors.white};
    background-color: ${colors.red};
    &:hover {
      background-color: ${colors.red} !important;
    }
  }
`;
