import searchSvg from 'assets/images/search.svg';
import {
  StyledBox as Box,
  StyledContainer as Container,
  SearchIcon,
} from './styledComponents';

const NoResultsBlock = ({ filtered }: { filtered?: boolean }) => {
  return (
    <Container>
      <Box>
        <SearchIcon src={searchSvg} />
        <span>
          {filtered ? 'No results found' : 'There is no data to display'}
        </span>
      </Box>
    </Container>
  );
};

export default NoResultsBlock;
