import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { colors } from 'styles/globalStyles';

const statusToColorsMap: { [key: string]: string } = {
  Error: colors.importRecordErrorStatus,
  Deleted: colors.importRecordDeletedStatus,
  Success: colors.green,
  Deleting: colors.blue,
  'In progress': colors.orange,
};

export const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const StyledLink = styled(Link)(() => ({
  textTransform: 'capitalize',
}));

export const StyledProgressBlock = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledStatusLabel = styled('div')(({ status }: { status: string }) => ({
  height: '20px',
  width: '82px',
  marginRight: 'auto',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.white,
  borderRadius: '12px',
  backgroundColor: statusToColorsMap[status],
}));
