import { useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useField } from 'formik';

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

import { FormControl, Label } from 'components/UIComponents';
import {
  StyledTextField,
  AsteriskContainer,
  StyledContainer,
} from './styledComponents';

export interface DatePickerFieldProps {
  name?: string;
  onChange?: any;
  required?: boolean;
  maxDate?: string;
  minDate?: string;
  label?: string;
  styles?: {
    wrapper?: object;
  };
  direction?: 'row' | 'column';
  placeholder?: string;
}

const DatePickerField = ({
  onChange,
  name = '',
  required = false,
  maxDate,
  minDate,
  label,
  styles = {},
  direction,
  placeholder = 'mm/dd/yyyy',
}: DatePickerFieldProps) => {
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField(name);

  const handleChange = async (value: any) => {
    const val = value?.format('YYYY-MM-DD') || '';
    helpers.setValue(val);
    await field.onBlur(val);
    onChange?.(val);
  };

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      {label ? <Label>{label}</Label> : ''}
      <StyledContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...field}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(value: any) => handleChange(value)}
            value={!field.value ? null : field.value}
            renderInput={(params: any) => {
              return (
                <>
                  <StyledTextField
                    {...params}
                    name={name}
                    ref={params.inputRef}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                      'aria-label': name,
                      placeholder,
                    }}
                    error={meta.touched && !!meta.error}
                    onClick={() => setOpen(true)}
                  />
                  {required && <AsteriskContainer> *</AsteriskContainer>}
                </>
              );
            }}
            components={{
              OpenPickerIcon: Calendar,
            }}
            InputAdornmentProps={{
              position: 'start',
            }}
            componentsProps={{
              actionBar: {
                actions: !required ? ['clear'] : [],
              },
            }}
            maxDate={maxDate}
            minDate={minDate}
            onAccept={() => helpers.setTouched(true, false)}
            closeOnSelect
            PopperProps={{ placement: 'top' }}
          />
        </LocalizationProvider>
        <Label error>{meta.touched && meta.error}</Label>
      </StyledContainer>
    </FormControl>
  );
};

export default DatePickerField;
