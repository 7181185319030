import * as Yup from 'yup';

import { stringMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const recoveryEmailValidationSchema = Yup.object({
  email: Yup.string()
    .email(VALIDATION_MESSAGES.EMAIL)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
});
