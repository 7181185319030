import { ResponseAPI } from 'api/responseAPI';

import InlineEdit from 'components/base/InlineEdit';

import { IResponsesTable } from 'interfaces/Response/IResponsesTable';

const EditableCell = ({ item, valueKey }: { item: IResponsesTable; valueKey: string }) => {
  const value = item[valueKey as keyof IResponsesTable];
  return (
    <InlineEdit
      initialValue={value ? String(value) : ''}
      update={(value: string) => ResponseAPI.update(item.id, { [valueKey]: value })}
    />
  );
};

export default EditableCell;
