import { request } from 'api/instance';

export const GroupOutletAPI = {
  getByName: async (value: string) => {
    const response = await request({
      url: 'v1/group/outlets/search',
      method: 'GET',
      params: { value },
    });

    return response.data.data;
  },
};
