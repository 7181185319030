import { colors } from 'styles/globalStyles';

export const statusToColorsMap: { [key: string]: string } = {
  Settled: colors.orange,
  Cleared: colors.green,
};

export const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
