import { useContext } from 'react';

import { OrderAPI } from 'api/orderAPI';

import CommentModalForm from 'components/forms/CommentModalForm';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { ToastContext } from 'context/ToastContext';

import { ToastType } from 'constants/enums/PopupType';

import { IResponse } from 'interfaces/api';

import BaseChangeStatusBulkAction from 'containers/Orders/shared/BaseChangeStatusBulkAction';

const DeclineAction = () => {
  const { onModalOpen } = useContext(ModalContext);
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const { getSerializedData } = useContext(FilterContext);

  const handleDecline = (comment: string = '') => {
    if (comment) {
      OrderAPI.bulkChangeStatus({ selectAll, ids, excludedIds }, getSerializedData(), 'Declined', comment).then(
        (response: IResponse) => {
          onToastOpen('SUCCESS_ORDERS_DECLINE', response.data.data, ToastType.success);
          reloadList();
        }
      );
    }
  };

  return (
    <>
      <BaseChangeStatusBulkAction
        buttonText="Decline"
        status='Declined'
        apiMethod={OrderAPI.bulkCheckForStatusTransition}
        handleSuccess={() => onModalOpen('COMMENT_MODAL_FORM_DECLINE_ORDERS')}
      />
      <CommentModalForm onSubmit={handleDecline} modalName='COMMENT_MODAL_FORM_DECLINE_ORDERS' isMandatory/>
    </>
  );
};

export default DeclineAction;
