import { MenuItem, OutlinedInput } from '@mui/material';

import { useField } from 'formik';

import { FormControl, Label } from 'components/UIComponents';

import { ISelectOption } from 'interfaces/ISelectOption';

import { InputFieldContainer, StyledSelect } from './styledComponents';

interface SelectProps {
  label: string;
  name: string;
  options: ISelectOption[];
  onChange?: any;
  styles?: {
    wrapper?: object;
    label?: object;
  };
  placeholder?: string;
  required?: boolean;
  direction?: 'column' | 'row';
  emptyOptionLabel?: string;
  allowEmpty?: boolean;
  disabled?: boolean;
}

const Select = ({
  onChange,
  label,
  name = '',
  options = [],
  styles = {},
  placeholder = '',
  required = false,
  emptyOptionLabel = 'None',
  direction,
  allowEmpty = true,
  disabled = false,
}: SelectProps) => {
  const [field, meta] = useField(name);

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      <Label required={required} style={styles.label} disabled={disabled}>
        {label}
      </Label>
      <InputFieldContainer>
        <StyledSelect
          {...field}
          input={<OutlinedInput inputProps={{ 'aria-label': label }} />}
          variant="standard"
          error={meta.touched && !!meta.error}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
          placeholder={placeholder}
          MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          disabled={disabled}
        >
          {!required && allowEmpty && (
            <MenuItem value="" key="none">
              <em>{emptyOptionLabel}</em>
            </MenuItem>
          )}
          {options.map(({ key, value }) => (
            <MenuItem key={String(value)} value={value as any}>
              <span>{key}</span>
            </MenuItem>
          ))}
        </StyledSelect>
        <Label error>{meta.touched && meta.error}</Label>
      </InputFieldContainer>
    </FormControl>
  );
};

export default Select;
