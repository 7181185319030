import { useContext, useState } from 'react';

import { IconButton } from '@mui/material';

import { ResponseAPI } from 'api/responseAPI';

import { CustomIcon } from 'components/UIComponents';
import Popup from 'components/base/Popup';

import { BaseListActionsContext } from 'context/BaseListActions';
import { ModalContext } from 'context/ModalContext';
import { ToastContext } from 'context/ToastContext';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { IResponse } from 'interfaces/api';

const DELETE_RESPONSE_WARNING = 'deleteResponseWarning';

const DeleteAction = ({ responseId }: { responseId: number }) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const { onToastOpen } = useContext(ToastContext);

  const [deleteInProgress, setDeleteInProgress] = useState<boolean>();

  const onDeleteClick = () => {
    setDeleteInProgress(true);
    handleDelete(false);
  };

  const onClose = () => {
    setDeleteInProgress(false);
    onModalClose(DELETE_RESPONSE_WARNING);
  };

  const handleDelete = (force: boolean) => {
    ResponseAPI.remove(responseId, force).then((response: IResponse) => {
      if (response.status === RESPONSE_CODES.OK) {
        onToastOpen('RESPONSE_SUCCESSFULLY_DELETED', response.data.data, ToastType.success);
        reloadList();
        onClose();
      }

      if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
        onModalOpen(DELETE_RESPONSE_WARNING);
      }
    });
  };

  return (
    <>
      <IconButton onClick={onDeleteClick}>
        <CustomIcon name="trash" size="normal" />
      </IconButton>
      {deleteInProgress && (
        <Popup
          name={DELETE_RESPONSE_WARNING}
          type={PopupType.warning}
          title="Confirm Response Deletion"
          onModalClose={onClose}
          handleSubmit={() => handleDelete(true)}
          submitText="Yes, delete"
          cancelText="No"
        >
          At least one Response is associated with a Spot record. Are you sure you want to delete the Response(s)?
        </Popup>
      )}
    </>
  );
};

export default DeleteAction;
