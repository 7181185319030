import { IconButton } from '@mui/material';
import { CustomIcon } from 'components/UIComponents';

import { StyledBox, StyledContactInfo } from './styledComponents';

interface TooltipProps {
  email: string | null;
  name: string | null;
}

const NO_CONTACT_TEXT =
  'The Group associated with this Spot has no active default contact';

const TooltipBlock = ({ email, name }: TooltipProps) => {
  const onCopyHandle = () => {
    const text = email;
    navigator.clipboard.writeText(text!);
  };

  return (
    <StyledBox>
      {email ? (
        <>
          <IconButton onClick={onCopyHandle} disableRipple>
            <CustomIcon name="copy" size="large" />
          </IconButton>
          <StyledContactInfo>
            {email && `${email}`}
            {name && email && ', '}
            {name && `${name}`}
          </StyledContactInfo>
        </>
      ) : (
        NO_CONTACT_TEXT
      )}
    </StyledBox>
  );
};

export default TooltipBlock;
