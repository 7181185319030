import { Form, Formik } from 'formik';

import { SubmitButton } from 'components/UIComponents';
import { Input } from 'components/UIComponents';

import { recoveryEmailValidationSchema } from 'helpers/validation/recoveryEmailValidationSchema';

const RecoveryEmailForm = ({ onSubmit }: { onSubmit: ({ email }: { email: string }) => void }) => {
  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={recoveryEmailValidationSchema}
        validateOnBlur
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, dirty, errors }) => (
          <Form style={{ width: '100%' }}>
            <Input direction="column" label="Email" name="email" placeholder="Enter your email" />
            <SubmitButton
              disabled={!dirty || !!Object.values(errors).length}
              onClick={handleSubmit}
              sx={{ width: '100%', marginTop: '20px' }}
            >
              Get password reset link
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RecoveryEmailForm;
