const messageForGroupRemoveModal = (type: string, groupName: string) => {
  if (type === 'warning') {
    return `You're about to permanently delete ${groupName} and all corresponding contacts`;
  } else {
    return `${groupName} has several media outlets. Delete them first, then delete the group`;
  }
};

const messageForCodeRemoveModal = (code?: string) => {
  return `You're about to permanently delete code ${code || ''}`;
};

const messageForContactRemoveModal = (contact?: string) => {
  return `You're about to permanently delete contact ${contact || ''}`;
};

export {
  messageForGroupRemoveModal,
  messageForCodeRemoveModal,
  messageForContactRemoveModal,
};
