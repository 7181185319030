import { css } from '@emotion/react';

export const fontFamily = "'SF Pro Text', sans-serif";

export const GlobalStyles = css`
  body {
    margin: 0;
    background-color: #ffffff;
    font-family: ${fontFamily};
  }
`;

export const defaultButtonsGap = '20px';

export const colors = {
  black: '#000000',

  blue: '#1949F1',
  aliceBlue: '#e8f4ff',
  darkBlue: '#18222d',
  focusBlue: '#0048b4',
  greyBlue: '#052547',

  grey: '#6c757d',
  darkGrey: '#212529',
  lightGrey: '#e1e1e1',
  dialogActionsGrey: '#f2f2f2',
  textGrey: '#959595',
  borderGrey: '#cfd4d9',
  groupDetailsBorderGrey: '#ececec',
  disabledTextGrey: '#c1c4c6',

  green: '#00ab3a',
  yellow: '#ff9313',

  red: '#da3832',
  hoveredRed: '#be0700',
  disabledRed: '#fa9996',

  white: '#ffffff',

  radioButtonBorder: '#00000026',

  inactiveStatusColor: '#d24949',
  tableHeadColor: '#f5f5f5',
  tableHoverColor: '#fff4f4',
  tableDelimiter: '#f3f2f2',

  successToastBackground: '#deffe6',
  successToastTextColor: '#0fa74f',
  successToastBorder: '#c3e6cb',

  errorToastBackground: '#ffe7e9',
  errorToastTextColor: '#eb4848',
  errorToastBorder: '#f5c6cb',

  warningToastBackground: '#fff3cd',
  warningToastTextColor: '#856404',
  warningToastBorder: '#ffeeba',

  infoToastBackground: '#ddeeff',
  infoToastTextColor: '#0048b4',
  infoToastBorder: '#b8daff',

  spotErrorStatus: '#DC4B0C',

  spinner: '#343A40',

  importRecordDeletedStatus: '#B9B9B9',
  importRecordErrorStatus: '#DC4B0C',

  orange: '#F18E19',

  trashIconColor: '#F46A6A',
};
