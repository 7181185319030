import { styled } from '@mui/material/styles';

interface StyledButtonProps {
  active: boolean;
}

export const StyledButton = styled('span')(({ active }: StyledButtonProps) => ({
  cursor: 'pointer',
  color: active ? 'black' : '#ccc',
}));

export const StyledToolbar = styled('div')(() => ({
  position: 'relative',
  padding: '1px 18px 17px',
  margin: '0 -20px',
  borderBottom: '2px solid #eee',
  marginBottom: '20px',
  '& > *': {
    display: 'inline-block',
  },
  '& > *  *': {
    marginLeft: '15px',
  },
  '& svg': {
    marginLeft: 0,
  },
}));

export const StyledIcon = styled('span')(() => ({
  fontSize: '18px',
  verticalAlign: 'text-bottom',
  '& svg': {
    width: '18px',
    height: '18px',
  },
}));
