import { OutlinedButton, SubmitButton } from '../../../UIComponents';
import { StyledDialogActions as DialogActions } from '../styledDialogActions';
import { StyledDialogContent as DialogContent } from '../styledDialogContent';

interface DeletePopupProps {
  type: 'warning' | 'info' | 'error';
  onModalClose: () => void;
  handleSubmit?: () => void;
  cancelAction?: () => void;
  children: React.ReactNode;
  submitText?: string;
  cancelText?: string;
}

const PopupContent = ({
  type,
  onModalClose,
  handleSubmit,
  cancelAction,
  children,
  submitText,
  cancelText,
}: DeletePopupProps) => {
  return (
    <>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <OutlinedButton onClick={cancelAction || onModalClose}>
          {cancelText || (type === 'warning' ? 'Cancel' : 'Close')}
        </OutlinedButton>
        {type === 'warning' && handleSubmit && <SubmitButton onClick={handleSubmit}>{submitText}</SubmitButton>}
      </DialogActions>
    </>
  );
};

export default PopupContent;
