import { useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

import { StyledInput } from '../InlineInput/styledComponents';

export interface InlineDateInputProps {
  onAccept: (value: string) => void;
  onClose: () => void;
  initialValue: string;
}

const InlineDateInput = ({ onAccept, onClose, initialValue }: InlineDateInputProps) => {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState<string>(initialValue);

  const handleAccept = (value: any) => {
    const val = value?.format('YYYY-MM-DD') || '';
    onAccept(val);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={onClose}
        value={value}
        onChange={(value: any) => {
          const val = value?.format('YYYY-MM-DD') || '';
          setValue(val);
        }}
        renderInput={(params: any) => {
          return (
            <StyledInput
              ref={params.inputRef}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                placeholder: 'mm/dd/yyyy',
              }}
            />
          );
        }}
        components={{
          OpenPickerIcon: Calendar,
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        onAccept={handleAccept}
        closeOnSelect
        PopperProps={{ placement: 'top' }}
      />
    </LocalizationProvider>
  );
};

export default InlineDateInput;
