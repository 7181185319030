import Switch from '@mui/material/Switch';
import { styled as styleMui } from '@mui/material/styles';

import styled from '@emotion/styled';

import { colors, fontFamily } from 'styles/globalStyles';

export const StyledSwitch = styleMui(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.red,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: colors.red,
    boxSizing: 'border-box',
  },
}));

export const Label = styled.span`
  font-size: 12px;
  line-height: 24px;
  color: ${colors.grey};
  font-family: ${fontFamily};
`;
