import { NavLink } from 'react-router-dom';

import styled from '@emotion/styled';

import { ROW_HEIGHT } from 'constants/spots';

import { colors } from 'styles/globalStyles';

export const MutedLabel = styled.span`
  color: ${colors.black};
  opacity: 50%;
  font-size: 12px;
`;

export const OutletLink = styled(NavLink)`
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  max-width: 100px;

  color: ${colors.black};
`;

export const DatesBox = styled.div`
  white-space: nowrap;
`;

interface CustomTableRowProps {
  highlighted?: boolean;
}

export const RowContainer = styled.div(({ highlighted }: CustomTableRowProps) => {
  const highlightStyles = {
    borderBottom: '1px solid #FFB4B4',
    borderTop: '1px solid #FFB4B4',
  };

  return {
    '&': {
      height: `${ROW_HEIGHT}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      ...(highlighted ? highlightStyles : {}),
    },
    '&:hover': {
      backgroundColor: colors.tableHoverColor,
    },
  };
});
