import * as Yup from 'yup';
import { stringMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const paymentValidationSchema = Yup.object({
  payments: Yup.array()
    .of(
      Yup.object().shape({
        invoice: Yup.string()
          .max(
            stringMaxLength,
            VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
          )
          .required(VALIDATION_MESSAGES.REQUIRED),
        check: Yup.string()
          .optional()
          .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
      })
    ),
});
