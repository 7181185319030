import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import { colors } from '../../../../../styles/globalStyles';

export const StyledNavLink = styled(NavLink)`
  padding: 12px 16px;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  text-transform: capitalize;
  color: ${colors.white};

  &.active {
    background-color: ${colors.red};
  }
`;
