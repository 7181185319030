import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

export const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 27px 16px 16px 16px;
    gap: 8px;
    
    & :not(:first-of-type) {
      margin: 0;
    };
  };
`;
