import { CircularProgress } from '@mui/material';

import { OverflowTip } from 'components/UIComponents';

import { spotImportRecordsStatuses } from 'constants/spots';

import { StyledProgressBlock } from '../styledComponents';

interface Props {
  status: string;
  executionTime: string | null;
}

export const getExecutionTimeCell = ({ status, executionTime }: Props) => {
  if (status === spotImportRecordsStatuses.inProgress) {
    return (
      <StyledProgressBlock>
        <CircularProgress color="inherit" size={16} />
      </StyledProgressBlock>
    );
  }
  return executionTime ? <OverflowTip title={executionTime}>{executionTime}</OverflowTip> : '';
};
