import styled from '@emotion/styled';
import { colors } from '../../../styles/globalStyles';

const PageSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 14px;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.red};
`;

const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.red};
`;

const InformationBlocks = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 33px;
  gap: 30px;

  > div:not(:last-child) {
    border-right: solid 1px ${colors.groupDetailsBorderGrey};
    padding-right: 30px;
  }
`;

const FlexChild = styled.div`
  flex: 1;

  >:last-child {
    margin-bottom: 0;
  }
`;

const InfoBlockTitle = styled.div`
  margin-bottom: 10px;

  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  color: ${colors.textGrey};
`;

const InfoBlockBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoBlockBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  PageSubtitle,
  TableTitle,
  InformationBlocks,
  FlexChild,
  InfoBlockTitle,
  InfoBlockBody,
  InfoBlockBodyColumn,
};
