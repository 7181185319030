import { FormEvent, useState } from 'react';

import { FieldArray, Form, Formik } from 'formik';

import { Checkbox, FormControl, Input, Label, OutlinedButton, SubmitButton } from 'components/UIComponents';
import { ModalInfoBlock, ModalInfoBlockTitle } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { paymentValidationSchema } from 'helpers/validation/paymentValidationSchema';

import { IPaymentGroupInfo, IPaymentsForm } from 'interfaces/Payment/IPaymentsForm';
import { IResponse } from 'interfaces/api';

import { Totals } from './styledComponents';

interface Props {
  onSubmit: (formDetails: IPaymentsForm) => Promise<IResponse>;
  onClose: () => void;
  onBack: () => void;
  data: IPaymentGroupInfo[];
}

const SecondStep = ({ onSubmit, onClose, onBack, data }: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const defaultValues = { invoice: '', cleared: false, check: '' };
  const initialValues = { payments: data.map((item) => ({ ...item, ...defaultValues })) };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={paymentValidationSchema}
        enableReinitialize={true}
        validateOnBlur
        onSubmit={(values) => {
          onSubmit(values as IPaymentsForm).then(() => setIsDisabled(false));
        }}
      >
        {({ handleSubmit, setFieldValue, values, dirty, errors }) => (
          <Form>
            <DialogContent>
              <FieldArray
                name="payments"
                render={() => {
                  return (
                    <>
                      {values.payments.map((payment, index) => (
                        <ModalInfoBlock key={index}>
                          <ModalInfoBlockTitle>{payment.name}</ModalInfoBlockTitle>
                          <FormControl>
                            <Label>Total Amount, $</Label>
                            <Totals>{payment.amount}</Totals>
                          </FormControl>
                          <Input required name={`payments[${index}].invoice`} label="Invoice #" />
                          <Checkbox
                            name={`payments[${index}].cleared`}
                            label="Cleared"
                            onChange={(value) => {
                              if (!value) {
                                setFieldValue(`payments[${index}].check`, '');
                              }
                            }}
                          />
                          <Input
                            disabled={!values.payments[index].cleared}
                            name={`payments[${index}].check`}
                            label="Check #"
                          />
                        </ModalInfoBlock>
                      ))}
                    </>
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton sx={{ marginRight: 'auto' }} onClick={onClose}>
                Cancel
              </OutlinedButton>
              <OutlinedButton onClick={onBack}>Back</OutlinedButton>
              <SubmitButton
                disabled={!dirty || !!Object.values(errors).length || isDisabled}
                onClick={(values: FormEvent<HTMLFormElement>) => {
                  setIsDisabled(true);
                  handleSubmit(values);
                }}
              >
                Create
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SecondStep;
