import { useNavigate } from 'react-router-dom';

import { LinkButton, ButtonsBlock, CustomIcon } from 'components/UIComponents';
import UploadButton from 'components/base/UploadButton';
import SelectAllCheckbox from 'components/base/tableComponents/SelectAllCheckbox';

import {
  PanelContainer,
  StyledFormGroup as FormGroup,
  StyledFormControlLabel as FormControlLabel,
  LinkButtonText,
} from './styledComponents';

interface Props {
  onAddNewClick: () => void;
  showSelectAll: boolean;
}

const ActionsPanel = ({ showSelectAll, onAddNewClick }: Props) => {
  const navigate = useNavigate();

  return (
    <PanelContainer>
      <FormGroup>
        {showSelectAll ? <FormControlLabel control={<SelectAllCheckbox />} label="Select All" /> : ''}
      </FormGroup>

      <ButtonsBlock>
        <LinkButton onClick={onAddNewClick}>
          <CustomIcon name="plus" color="inherit" size="x-small" />
          <LinkButtonText>Add new spot</LinkButtonText>
        </LinkButton>
        <UploadButton title="Upload spots" onClick={() => navigate('spot-import-monitor')} />
      </ButtonsBlock>
    </PanelContainer>
  );
};

export default ActionsPanel;
