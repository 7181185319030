import { useState, useEffect } from 'react';

import { TextField, CircularProgress } from '@mui/material';

import { useField } from 'formik';

import { FormControl, Label } from 'components/UIComponents';

import { ReactComponent as CheckboxChecked } from 'assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/CheckboxUnchecked.svg';

import { ISelectOption } from 'interfaces/ISelectOption';

import { colors } from 'styles/globalStyles';

import { StyledAutocomplete, StyledCheckbox, InputFieldContainer, StyledListItem } from './styledComponents';

interface SearchableMultiSelectProps {
  label: string;
  name: string;
  direction?: 'row' | 'column';
  getOptionsList: (v: string) => Promise<Array<ISelectOption>>;
  onChange?: any;
  styles?: {
    wrapper?: object;
  };
  initial?: Array<ISelectOption>;
  required?: boolean;
  disabled?: boolean;
  emptyOptionLabel?: string;
}

const sortOptions = (options: Array<ISelectOption>, selectedValues: Array<ISelectOption>, anyOption: ISelectOption,
                     showSelectAll: boolean) => {
  const selectedWithoutAny = selectedValues.filter((option) => option.value);
  const notSelectedOptions = options.filter(
    (option) => !selectedWithoutAny.map((selected) => selected.value).includes(option.value)
  );

  const sortedOptions = ([] as ISelectOption[]).concat([anyOption], selectedWithoutAny, notSelectedOptions);

  if (showSelectAll) {
    return [sortedOptions[0], { key: 'Select All', value: 'select-all' }, ...sortedOptions.slice(1)];
  } else {
    return sortedOptions;
  }
};

const MIN_SEARCH_LENGTH = 2;

const SearchableMultiSelect = ({
  label,
  name,
  direction,
  getOptionsList,
  onChange,
  required = false,
  disabled = false,
  styles = {},
  initial = [],
  emptyOptionLabel,
}: SearchableMultiSelectProps) => {
  const [field, meta, helpers] = useField(name);
  const anyOption = { key: emptyOptionLabel || `Any ${label}`, value: null };

  const [options, setOptions] = useState<Array<ISelectOption> | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState(anyOption.key);
  const [open, setOpen] = useState(false);
  const openDropdown = () => { setOpen(true); setShowSelectAll(false); setCheckedSelectAll(false); };
  const closeDropdown = () => setOpen(false);
  const [value, setValue] = useState<Array<ISelectOption>>([]);
  const [inputValue, setInputValue] = useState('');
  const [showSelectAll, setShowSelectAll] = useState(false);
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);

  const updatePlaceholder = (value: Array<ISelectOption>) => {
    if (value.length) {
      setPlaceholder(`${label}: ${value.length}`);
    } else {
      setPlaceholder(anyOption.key);
    }
  };

  const handleGetOptions = (value: string) => {
    if (value === '' || value?.length > MIN_SEARCH_LENGTH) {
      setIsLoading(true);
      (getOptionsList(value) as Promise<Array<ISelectOption>>).then((data: Array<ISelectOption>) => {
        setOptions(data || []);
        setIsLoading(false);
      });
    }
  };

  const handleInputChange = (reason: string, value: string) => {
    if (reason !== 'reset') {
      setInputValue(value);
      handleGetOptions(value);
    }
  };

  const handleInputBlur = () => {
    setInputValue('');
    handleGetOptions('');
  };

  const handleChange = (selectedOptions: Array<ISelectOption>) => {
    if (selectedOptions.find((option) => option.value === 'select-all')) {
      const allOptions = options.filter((option) => option.value !== null);
      if (!checkedSelectAll) {
        setValue(allOptions);
        helpers.setValue(allOptions);
        updatePlaceholder(allOptions);
      } else {
        setValue([]);
        helpers.setValue([]);
        updatePlaceholder([]);
      }
    } else if (selectedOptions.find((option) => option.value === null)) {
      setValue([]);
      helpers.setValue([]);
      updatePlaceholder([]);
      closeDropdown();
    } else {
      setValue(selectedOptions);
      helpers.setValue(selectedOptions);
      updatePlaceholder(selectedOptions);
    }
  };

  useEffect(() => {
    setValue(initial);
    updatePlaceholder(initial);
    // https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initial)]);

  useEffect(() => {
    if (JSON.stringify(field.value) !== JSON.stringify(value)) {
      updatePlaceholder(field.value);
      setValue(field.value);
    }

    if (options.length <= value.length) {
      setCheckedSelectAll(true);
    } else {
      setCheckedSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(field.value)]);

  useEffect(() => {
    handleGetOptions('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptionsList]);

  useEffect(() => {
    if (inputValue.length > MIN_SEARCH_LENGTH && Array.isArray(options) && options.length > 0) {
      setShowSelectAll(true);
    } else {
      setShowSelectAll(false);
    }

    if (options.length <= value.length) {
      setCheckedSelectAll(true);
    } else {
      setCheckedSelectAll(false);
    }
  }, [options, inputValue, value]);

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      <Label required={required} disabled={disabled}>
        {label}
      </Label>
      <InputFieldContainer>
        <StyledAutocomplete
          multiple
          {...field}
          open={open}
          value={value}
          onOpen={openDropdown}
          onClose={closeDropdown}
          disabled={disabled}
          error={meta.touched && meta.error ? 1 : 0}
          loading={isLoading}
          loadingText="Loading…"
          noOptionsText="No results"
          options={sortOptions(options, value, anyOption, showSelectAll)}
          filterOptions={(options) => options}
          disableCloseOnSelect
          onBlur={handleInputBlur}
          isOptionEqualToValue={(option: any, anotherOption: any) => option.value === anotherOption.value}
          getOptionLabel={(option: any) => option.key}
          inputValue={inputValue}
          onInputChange={(e, value, reason) => handleInputChange(reason, value)}
          renderOption={(props, option: any, { selected }) =>
            option.value === 'select-all' ? (
              <StyledListItem {...props} key={option.value}>
                <StyledCheckbox
                  checked={checkedSelectAll}
                  checkedIcon={<CheckboxChecked color={colors.red} />}
                  icon={<CheckboxUnchecked />}
                />
                {option.key}
              </StyledListItem>
            ) : option.value === null ? (
              <StyledListItem {...props}>{option.key}</StyledListItem>
            ) : (
              <StyledListItem {...props} key={option.value}>
                <StyledCheckbox
                  checked={selected}
                  checkedIcon={<CheckboxChecked color={colors.red} />}
                  icon={<CheckboxUnchecked />}
                />
                {option.key}
              </StyledListItem>
            )
          }
          renderInput={(params) => {
            return (
              <TextField
                name={name}
                {...params}
                ref={params.InputProps.ref}
                placeholder={params?.inputProps?.['aria-expanded'] ? undefined : placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                inputProps={{ ...params.inputProps, 'aria-label': label }}
              />
            );
          }}
          onChange={(e, newValue: any) => {
            onChange?.(newValue);
            handleChange(newValue);
          }}
        />
        <Label error>{meta.touched && meta.error}</Label>
      </InputFieldContainer>
    </FormControl>
  );
};

export default SearchableMultiSelect;
