import { request } from 'api/instance';

import { IFilterParams } from 'interfaces/IFilterParams';

export const GroupOrderAPI = {
  getList: async ({ page, per_page, filterOptions = {} }: IFilterParams) => {
    try {
      const response = await request({
        url: 'v1/group/orders',
        method: 'GET',
        params: {
          page,
          per_page,
          ...filterOptions,
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getEstimates: async (value: string = '') => {
    try {
      const response = await request({
        url: 'v1/group/orders/estimates',
        method: 'GET',
        params: { value },
      });

      return response.data.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getWeekStarts: async (value: string = '') => {
    try {
      const response = await request({
        url: 'v1/group/orders/week_starts',
        method: 'GET',
        params: { value },
      });

      return response.data.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};
