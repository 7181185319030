import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import { colors } from '../../../styles/globalStyles';

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  '&.MuiDialogTitle-root': {
    alignItems: 'center',
    backgroundColor: colors.darkBlue,
    color: colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 30px',
    position: 'sticky',
    zIndex: 1,
    top: 0,
  },
}));
