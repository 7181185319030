export const statusOptions = [
  { key: 'Exceptions', value: 'true' },
  { key: 'Attributed', value: 'false' },
];

export const timeFrameOptions = [
  { value: 0, key: '0 days' },
  { value: 7, key: '7 days' },
  { value: 30, key: '30 days' },
  { value: 60, key: '60 days' },
  { value: 90, key: '90 days' },
  { value: 300, key: '300 days' },
];

export const defaultAttributionOptions = {
  timeFrame: 30,
};
