import { useField } from 'formik';

import { FormControl, Label } from 'components/UIComponents';

import { formatPhone } from 'helpers/formatPhone';

import { InputFieldContainer, StyledInput } from './styledComponents';

interface InputProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  onChange?: any;
  onBlur?: () => any;
  styles?: {
    wrapper?: object;
  };
  direction?: 'column' | 'row';
}

const Input = ({
  label,
  name = '',
  required = false,
  disabled = false,
  placeholder = '',
  type = 'text',
  onChange,
  onBlur,
  styles = {},
  direction,
}: InputProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name);

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      <Label required={required} disabled={disabled}>
        {label}
      </Label>
      <InputFieldContainer>
        <StyledInput
          error={meta.touched && !!meta.error}
          {...field}
          value={field.value || ''}
          onChange={(e) => {
            onChange?.(e);
            if (type === 'phone') {
              setValue(formatPhone(e.target.value));
            } else {
              field.onChange(e);
            }
          }}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          inputProps={{
            'aria-label': label,
          }}
          onBlur={() => {
            setTouched(true); // To validate/show errors for field after trimming
            const trimmedValue = field.value?.trim();
            if (type === 'phone') {
              setValue(formatPhone(trimmedValue));
            } else {
              setValue(trimmedValue);
            }
            onBlur?.();
          }}
        />
        <Label error>{meta.touched && meta.error}</Label>
      </InputFieldContainer>
    </FormControl>
  );
};

export default Input;
