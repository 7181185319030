import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

export const StyledCheckbox = styled(Checkbox)(() => ({
  '&.MuiCheckbox-root': {
    padding: '9px',
    marginLeft: '-9px',
    marginRight: '-9px',
    top: '3px',
  },
}));
