import { useState, useContext } from 'react';

import { GroupContactAPI } from 'api/groupContactAPI';

import { ActiveStatusLabel, CustomIcon } from 'components/UIComponents';
import Popup from 'components/base/Popup';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';
import Toast, { ToastParams } from 'components/base/Toast';

import { ModalContext } from 'context/ModalContext';

import { messageForContactRemoveModal } from 'helpers/messageForRemoveModal/index';

import { PopupType, ToastType } from 'constants/enums/PopupType';

import { IContactsTable } from 'interfaces/Contact/IContactsTable';
import { ITableColumn } from 'interfaces/ITableColumn';
import { colors } from 'styles/globalStyles';

const columns = [
  { dataField: 'name', text: 'Contact' },
  { dataField: 'phone', text: 'Phone number' },
  { dataField: 'email', text: 'Email' },
  { dataField: 'isDefault', text: 'Default' },
  { dataField: 'isTraffic', text: 'Traffic' },
  { dataField: 'isAccounting', text: 'Accounting' },
  { dataField: 'status', text: 'Status' },
];

interface Props {
  groupId: number;
  editAction: (id: number) => void;
  contactsData: IContactsTable[];
  getGroupContacts: (groupId: number) => void;
}

const ALL_EXCEPT_TABLE_HEIGHT = 506;
const tableContainerStyles = {
  maxHeight: window.innerHeight - ALL_EXCEPT_TABLE_HEIGHT,
};

const checkMarkIcon = <CustomIcon name='check' color={colors.green} size='normal' />;
const REMOVE_CONTACT_MODAL_NAME = 'removeContact';

const ListOfGroupContacts = ({ groupId, editAction, contactsData, getGroupContacts }: Props) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);

  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<ToastParams>();
  const [contactToRemove, setContactToRemove] = useState<IContactsTable | null>(null);

  const markAsDefault = (contact: any) => {
    GroupContactAPI.markContactAsDefault(groupId, contact.id).then(() => {
      getGroupContacts(groupId);
    });
  };

  const switchGroupActivation = (contactId: number, activate: boolean) => {
    GroupContactAPI.switchContactActivation(groupId, contactId, activate).then(() => {
      getGroupContacts(groupId);
    });
  };

  const onDeleteClick = (contact: any) => {
    setContactToRemove(contact);
    onModalOpen(REMOVE_CONTACT_MODAL_NAME);
  };

  const onSubmitContactDelete = () => {
    GroupContactAPI.removeGroupContact(groupId, Number(contactToRemove?.id)).then(({ data }) => {
      setShowToast(true);
      setToastParams({ type: ToastType.success, message: data });
      getGroupContacts(groupId);
    });

    onModalClose(REMOVE_CONTACT_MODAL_NAME);
  };

  const preparedData = contactsData.map((item: IContactsTable) => ({
    id: item.id,
    name: item.name,
    phone: item.phone,
    email: item.email,
    isDefault: item.is_default && checkMarkIcon,
    isTraffic: item.is_traffic && checkMarkIcon,
    isAccounting: item.is_accounting && checkMarkIcon,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      !item.is_default && { name: 'Mark as default', action: markAsDefault },
      {
        name: item.is_active ? 'Deactivate' : 'Activate',
        action: () => switchGroupActivation(item.id, !item.is_active),
      },
      { name: 'Delete', action: onDeleteClick },
    ].filter(Boolean),
  }));

  return (
    <>
      <TableWithLazyLoad
        name="contacts"
        columns={columns as ITableColumn[]}
        data={preparedData}
        editAction={editAction}
        rowsAsLinks={false}
        tableContainerStyles={tableContainerStyles}
      />
      <Popup
        name={REMOVE_CONTACT_MODAL_NAME}
        type={PopupType.warning}
        title="Delete Contact"
        onModalClose={() => onModalClose(REMOVE_CONTACT_MODAL_NAME)}
        handleSubmit={onSubmitContactDelete}
        submitText="Delete Contact"
      >
        {messageForContactRemoveModal(contactToRemove?.name)}
      </Popup>
      {toastParams && <Toast open={showToast} params={toastParams} onClose={() => setShowToast(false)} />}
    </>
  );
};

export default ListOfGroupContacts;
