import * as Yup from 'yup';
import { stringMaxLength, textMaxLength } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

const durationMaxLength = 999;
const durationMaxСharacters = 4;
const shortCodeMaxLength = 999999999;
const shortCodeMaxСharacters = 10;
const minFeeValue = 0;

export const creativeValidationSchema = Yup.object({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(
      stringMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
    ),
  productId: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
  duration: Yup.number()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minFeeValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      durationMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(durationMaxСharacters)
    ),
  codePrefix: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(
      stringMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
    ),
  isciPrefix: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(
      stringMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
    ),
  shortCode: Yup.number()
    .optional()
    .typeError(VALIDATION_MESSAGES.NUMBER)
    .integer(VALIDATION_MESSAGES.INTEGER)
    .min(minFeeValue, VALIDATION_MESSAGES.POSITIVE)
    .max(
      shortCodeMaxLength,
      VALIDATION_MESSAGES.STRING_MAX_LENGTH(shortCodeMaxСharacters)
    ),
  keywords: Yup.array()
    .of(
      Yup.string().max(
        stringMaxLength,
        VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)
      )
    )
    .optional(),
  comment: Yup.string()
    .optional()
    .max(textMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(textMaxLength)),
});
