import { FormControl, Label, DatePickerField } from 'components/UIComponents';
import { DatePickerFieldProps } from '../DatePickerField';
import {
  InputFieldContainer,
  Connector,
  DashConnector,
} from './styledComponents';

interface SelectProps {
  label?: string;
  styles?: {
    wrapper?: object;
  };
  startProps: DatePickerFieldProps;
  endProps: DatePickerFieldProps;
  connector?: string;
  direction?: 'row' | 'column';
  placeholder?: string;
}

const DatePickerGroup = ({
  startProps,
  endProps,
  label,
  styles = {},
  connector,
  direction,
}: SelectProps) => {
  const datePickerStyles = {
    wrapper: {
      margin: '0px !important',
      width: '100%',
    },
  };

  return (
    <FormControl direction={direction} styles={styles.wrapper}>
      {label ? <Label>{label}</Label> : ''}
      <InputFieldContainer>
        <DatePickerField styles={datePickerStyles} {...startProps} />
        <Connector>{connector || <DashConnector>—</DashConnector>}</Connector>
        <DatePickerField styles={datePickerStyles} {...endProps} />
      </InputFieldContainer>
    </FormControl>
  );
};

export default DatePickerGroup;
