import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

export const request = async ({
  method,
  url,
  data,
  params,
  headers,
}: {
  method: string;
  url: string;
  data?: object;
  params?: object;
  headers?: object;
}) => {
  return await axiosInstance({
    method,
    data: data || {},
    url,
    params: params || {},
    headers: headers || {},
  });
};

export const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});
