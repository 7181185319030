import { IPaymentFormItem, ISearializedPayment } from 'interfaces/Payment/IPaymentsForm';
import { IPaymentFilterOptions } from 'interfaces/Payment/IPaymentFilterOptions';
import { ISelectOption } from 'interfaces/ISelectOption';

import { keysToSnakeCase } from 'helpers/utils';

export const paymentSerializer = (info: IPaymentFormItem, groupingType: string): ISearializedPayment => {
  const { cleared, ...data } = info;

  return {
    ...data,
    paid_to: groupingType,
    status: cleared ? 'Cleared' : 'Settled',
  };
};

export const prepareFilterData = (filterOptions: IPaymentFilterOptions) => {
  const options: { [key: string]: any } = keysToSnakeCase(filterOptions);

  if (options.product_id) {
    options.product_id = (options.product_id as Array<ISelectOption>).map(
      (option) => option.value
    );
  }

  return options;
};
