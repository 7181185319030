import styled from '@emotion/styled';
import { colors } from '../../../styles/globalStyles';

const StyledPageTitle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 19px;

  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  color: ${colors.darkGrey};
`;

export { StyledPageTitle };
