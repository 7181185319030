import { FormikHelpers } from 'formik';

import { parseValidationError } from 'helpers/apiErrors';

import { ModelName } from 'constants/enums/ModelName';
import RESPONSE_CODES from 'constants/responseCodes';

import { IAPIError, IResponse } from 'interfaces/api';

export const handleApiError = (error: IAPIError, modelName: ModelName, actions: FormikHelpers<any>) => {
  const details = parseValidationError(error, modelName);

  if (details) {
    const errors: { [key: string]: string } = {};

    Object.keys(details).forEach((key) => {
      errors[key] = details[key][0];
    });

    actions.setErrors(errors);
  }
};

export const getResponseHandler = (modelName: ModelName, onSuccess: () => void, actions: FormikHelpers<any>) => {
  return (response: IResponse) => {
    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      handleApiError(response.data.error, modelName, actions);
    } else {
      onSuccess();
    }
  };
};
