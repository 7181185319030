/* eslint-disable no-magic-numbers */

// TODO: cover with tests
const regexString = '^\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]';
const formattedPhoneRegex = new RegExp(regexString + '{0,4}');
const onlyFormattedPhoneRegex = new RegExp(regexString + '{4}$');

export const formatPhone = (value: string): string => {
  if (/^[0-9]{10}$/.test(value)) {
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
  } else if (value.match(onlyFormattedPhoneRegex)) {
    return value;
  } else {
    return removeFormatting(value);
  }
};

const removeFormatting = (value: string): string => {
  const formattedPhone = value.match(formattedPhoneRegex);
  if (formattedPhone) {
    let notFormattedPhone = formattedPhone[0].replace(/\(|\)|\s|-/g, '');
    return value.replace(formattedPhoneRegex, notFormattedPhone);
  }
  return value;
};
