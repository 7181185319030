import { useState, useEffect, FormEvent } from 'react';

import { CreativeAPI } from 'api/creativeAPI';
import { ProductAPI } from 'api/productAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, TextArea, SelectWithSearch, TagsInput } from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { duplicateValue } from 'helpers/duplicateValue';
import { getResponseHandler } from 'helpers/forms';
import { creativeValidationSchema } from 'helpers/validation/creativeValidationSchema';

import { ModelName } from 'constants/enums/ModelName';

import { ICreativeForm } from 'interfaces/Creative/ICreativeForm';
import { IProductDetails } from 'interfaces/Product/IProductDetails';

import { keywordFieldStyles } from './styledComponents';

interface CreativeFormProps {
  onModalClose: () => void;
  successAction: () => void;
  creativeId: number;
  type: string;
  duplicate?: Boolean;
}

const CreativeForm = ({ onModalClose, successAction, creativeId, type, duplicate }: CreativeFormProps) => {
  const [creativeFormInformation, setCreativeFormInformation] = useState<ICreativeForm>({
    type,
    name: '',
    productId: '',
    duration: '',
    codePrefix: '',
    isciPrefix: '',
    shortCode: '',
    keywords: [],
    comment: '',
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (creativeId) {
      CreativeAPI.getOne(creativeId).then(({ data }) => {
        setCreativeFormInformation({
          type: data.type,
          name: duplicate ? duplicateValue(data.name) : data.name,
          productId: data.product_id,
          duration: data.duration,
          codePrefix: data.code_prefix,
          isciPrefix: data.isci_prefix,
          shortCode: data.short_code || '',
          comment: data.comment || '',
          productName: data.product_name,
          keywords: duplicate ? data.keywords.filter((value: string) => value !== '') : undefined,
        });
      });
    }
  }, [creativeId, duplicate]);

  const getProductOptions = async (value: string) => {
    try {
      const response = await ProductAPI.getByName(value);
      const results = response?.map((el: IProductDetails) => ({
        key: el.name,
        value: el.id,
      }));
      return results;
    } catch (err) {
      return [];
    }
  };

  return (
    <>
      <Formik
        initialValues={creativeFormInformation}
        enableReinitialize={true}
        validationSchema={creativeValidationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          const responseHandler = getResponseHandler(
            ModelName.Creative,
            () => {
              onModalClose();
              successAction();
            },
            actions
          );

          let request;
          if (creativeId && !duplicate) {
            request = CreativeAPI.update(creativeId, values);
          } else {
            request = CreativeAPI.create(values);
          }

          request.then(responseHandler).finally(() => setIsDisabled(false));
        }}
      >
        {({ errors, handleSubmit, dirty, values }) => (
          <Form>
            <DialogContent>
              <FieldGroupTitleContainer>CREATIVE INFO</FieldGroupTitleContainer>
              <Input name="name" label="Creative" required />
              <SelectWithSearch
                name="productId"
                label="Product"
                getOptionsList={(value: any) => getProductOptions(value)}
                required
                initial={{
                  key: creativeFormInformation.productName || '',
                  value: creativeFormInformation.productId || '',
                }}
              />
              <Input name="duration" label="Duration, sec" required styles={shortFieldStyles} />
              <Input name="codePrefix" label="Prefix for Code" required styles={shortFieldStyles} />
              <Input name="isciPrefix" label="Prefix for ISCI" required styles={shortFieldStyles} />
              <Input name="shortCode" label="Short Code" styles={shortFieldStyles} />
              {(!creativeId || duplicate) && (
                <TagsInput
                  name="keywords"
                  label="Keywords"
                  styles={keywordFieldStyles}
                  placeholder="Specify keywords"
                />
              )}
              <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
              <TextArea name="comment" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={isDisabled || (!dirty && !duplicate) || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => {
                  setIsDisabled(true);
                  handleSubmit(values);
                }}
              >
                {creativeId && !duplicate ? 'Save' : 'Add'}
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreativeForm;
