import { useCallback, useContext, useEffect, useState } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { ResponseAPI } from 'api/responseAPI';
import { Form, Formik } from 'formik';

import { LinkButton, SearchableMultiSelect, SubmitButton, ButtonsBlock, Select } from 'components/UIComponents';

import { FilterContext } from 'context/FilterContext';
import { RadioGroupContext } from 'context/RadioGroupContext';

import { nonEmptyValues } from 'helpers/utils';

import { defaultAttributionOptions, timeFrameOptions } from 'constants/responses';

import { BEOptionFormat, ISelectOption } from 'interfaces/ISelectOption';
import { IAttributionParams } from 'interfaces/Response/IAttributionParams';

export const controllWrapperStyles = {
  wrapper: {
    margin: '0px !important',
  },
};

const initialValues = {
  timeFrame: 30,
  productId: [],
  outletId: [],
};

interface FilterProps {
  attributionParams: IAttributionParams;
}

const AttributionFilterForm = ({ attributionParams }: FilterProps) => {
  const { onSelect } = useContext(RadioGroupContext);
  const { onFilter, filterOptions } = useContext(FilterContext);
  const [productOptions, setProductOptions] = useState<ISelectOption[]>([]);
  const [outletOptions, setOutletOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    ResponseAPI.getAttributionFilterData(attributionParams).then(
      (data: { products: BEOptionFormat[]; outlets: BEOptionFormat[] }) => {
        setProductOptions(
          data.products.map((product) => ({
            key: product.name,
            value: product.id,
          }))
        );
        setOutletOptions(
          data.outlets.map((product) => ({
            key: product.name,
            value: product.id,
          }))
        );
      }
    );
  }, [attributionParams]);

  const outletOptionsProvider = useCallback(
    (value: any) => {
      const preparedValue = value.toLowerCase();
      return Promise.resolve(
        outletOptions.filter((item: ISelectOption) => String(item.key).toLowerCase().includes(preparedValue))
      );
    },
    [outletOptions]
  );

  const productOptionsProvider = useCallback(
    (value: any) => {
      const preparedValue = value.toLowerCase();
      return Promise.resolve(
        productOptions.filter((item: ISelectOption) => String(item.key).toLowerCase().includes(preparedValue))
      );
    },
    [productOptions]
  );

  return (
    <Formik
      initialValues={{ ...initialValues, ...filterOptions }}
      onSubmit={(values) => {
        onSelect(undefined);
        const present = nonEmptyValues(values);
        onFilter(present);
      }}
      enableReinitialize
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <Form style={{ marginBottom: 15 }}>
            <Grid container columnSpacing={{ xs: 2, lg: 3 }}>
              <Grid xs={3}>
                <SearchableMultiSelect
                  name="productId"
                  label="Product"
                  getOptionsList={productOptionsProvider}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={3}>
                <SearchableMultiSelect
                  name="outletId"
                  label="Outlet"
                  getOptionsList={outletOptionsProvider}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={3}>
                <Select
                  name="timeFrame"
                  label="Time frame"
                  options={timeFrameOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                  allowEmpty={false}
                />
              </Grid>
              <Grid display="flex" alignItems="flex-end" justifyContent="flex-end" marginBottom="2px" xs={3}>
                <ButtonsBlock>
                  <LinkButton
                    onClick={() => {
                      resetForm({ values: initialValues });
                      onSelect(undefined);
                      onFilter(defaultAttributionOptions);
                    }}
                  >
                    Clear filter
                  </LinkButton>
                  <SubmitButton onClick={handleSubmit}>Apply Filter</SubmitButton>
                </ButtonsBlock>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AttributionFilterForm;
