import styled from '@emotion/styled';

export const CellContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-top: -5px;
`;

export const ActivityStatusesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 35px;
`;
